import React, { useState, useEffect } from "react";
import PatientInfo from "./PatientInfo";
import FamilyInfo from "./FamilyInfo";
import ChoosePlan from "./ChoosePlan";
import CaseConfirm from "./CaseConfirm";
import AddressInfo from "./AddressInfo";

/**
 * 找照服員(居家看護) - 資料顯示頁面
 */
const FindInHomeCare = () => {
  const [currentStep, setCurrentStep] = useState(1); //步驟
  const initialData = {
    caseName: "", //被照顧者姓名
    cityId: 0,
    distId: 0,
    address: "",
    gender: 0,
    height: 0,
    weight: 0,
    age: 0,
    symptoms: [],
    tubes: [],
    nurseAidesGender: 2,
    languages: [], //溝通語言
    bedKind: [], //床位種類
    careServiceItems: [], //需求項目
    subFamilyName: "",
    subFamilyGender: -1,
    subFamilyPhone: "",
    subFamilyMail: "",
    forNurseAidesContent: "",
    planId: 0,
    serviceDates: "",
    serviceTime: "",
    familyPresentDuring: 2,
    overNight: 3,
    headshot: null,
  };

  // 如果有資料直接帶入
  const [createCareCase, setCreateCareCase] = useState(() => {
    const savedData = localStorage.getItem("createCareCase");
    return savedData ? JSON.parse(savedData) : initialData;
  });

  // 更新資料到localStorage
  useEffect(() => {
    localStorage.setItem("createCareCase", JSON.stringify(createCareCase));
  }, [createCareCase]);

  const [careServiceItems, setCareServiceItems] = useState([]); //服務需求列表(為了共用)
  const [plans, setPlans] = useState([]); //照顧方案列表(為了共用)

  //#region 下一頁
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  //#endregion

  //#region 上一頁
  const handlePreviousStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };
  const handlePreviousStepNoE = () => {
    setCurrentStep(currentStep - 1);
  };
  //#endregion

  //#region 被照顧者基本資料
  const handleChangeCaseData = (event) => {
    const { name, value } = event.target;
    setCreateCareCase((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //#endregion

  //#region 被照顧者病症Select2
  const handleChangeCaseSymptomsData = (value) => {
    setCreateCareCase({ ...createCareCase, symptoms: value });
  };
  //#endregion

  //#region 被照顧者管線Select2
  const handleChangeCaseTubesData = (value) => {
    setCreateCareCase({ ...createCareCase, tubes: value });
  };
  //#endregion

  //#region 被照顧者語言Select2
  const handleChangeCaseLanguagesData = (value) => {
    setCreateCareCase({ ...createCareCase, languages: value });
  };
  //#endregion

  //#region 被照顧者床位種類Select2
  const handleChangeBedKindData = (value) => {
    setCreateCareCase({ ...createCareCase, bedKind: value });
  };
  //#endregion

  //#region 被照顧者需求項目Select2
  const handleChangeCaseCareServiceItemsData = (value) => {
    setCreateCareCase({ ...createCareCase, careServiceItems: value });
  };
  //#endregion

  //#region 選擇照顧方案
  const handleChangePlan = (
    planId,
    behaviorMode,
    serviceDates,
    serviceTime
  ) => {
    // 更新 createCareCase 对象
    console.log(serviceDates);
    console.log(serviceTime);
    setCreateCareCase({
      ...createCareCase,
      planId: planId,
      serviceDates: serviceDates,
      serviceTime: serviceTime,
      behaviorMode: behaviorMode,
    });
  };
  //#endregion

  //#region 修改被照顧者照片
  const handleChangeCaseHeadshot = (value) => {
    setCreateCareCase({ ...createCareCase, headshot: value });
  };
  //#endregion

  return (
    <div className=" main-background normal">
      {/* 根據當前步驟狀態渲染不同的子元件 */}
      {currentStep === 1 && (
        <FamilyInfo
          onNextStep={handleNextStep}
          onChangeCaseData={handleChangeCaseData}
          createCareCase={createCareCase}
        />
      )}
      {currentStep === 2 && (
        <AddressInfo
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          onChangeCaseData={handleChangeCaseData}
          createCareCase={createCareCase}
        />
      )}
      {currentStep === 3 && (
        <PatientInfo
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          onChangeCaseData={handleChangeCaseData}
          onChangeCaseSymptomsData={handleChangeCaseSymptomsData}
          onChangeCaseTubesData={handleChangeCaseTubesData}
          onChangeCaseBedKindData={handleChangeBedKindData}
          onChangeCaseLanguagesData={handleChangeCaseLanguagesData}
          onChangeCaseCareServiceItemsData={
            handleChangeCaseCareServiceItemsData
          }
          createCareCase={createCareCase}
          onChangeHeadshot={handleChangeCaseHeadshot}
          setParentCareServiceItems={setCareServiceItems}
          type={"inHome"}
        />
      )}
      {currentStep === 4 && (
        <ChoosePlan
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          onChangePlan={handleChangePlan}
          createCareCase={createCareCase}
          setParentPlans={setPlans}
        />
      )}
      {currentStep === 5 && (
        <CaseConfirm
          onPreviousStep={handlePreviousStep}
          onPreviousStepNoE={handlePreviousStepNoE}
          createCareCase={createCareCase}
          careServiceItems={careServiceItems}
          plans={plans}
          type={"inHome"}
        />
      )}
    </div>
  );
};

export default FindInHomeCare;
