import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import {
  CreateValidator,
  ValidateFields,
  ValidateField,
} from "../../utils/Validator";
import {
  apiCreateNurseAides,
  apiUploadDocument,
  apiGetDocument,
  apiDownloadDocument,
  apiGetBankList,
  apiGetBankBranchList,
  apiUpdateNurseAides,
} from "../../utils/Api";
/**
 * 申請照服員-銀行資料(step3)
 */
const Bank = ({
  onNextStep,
  onPreviousStep,
  onChangeCreateNurseAides,
  onChangebankBookFileData,
  createNurseAides,
  reApply,
  reApplyComplete,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const validator = CreateValidator();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadDocumentDataUrl, setUploadDocumentDataUrl] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [currentBank, setCurrentBank] = useState();
  const [currentBankBranch, setCurrentBankBranch] = useState();

  const [errors, setErrors] = useState({
    bankCode: "",
    bankBranchCode: "",
    bankAccount: "",
    bankBookFileId: 0,
  });
  const fetchBank = async () => {
    const bankData = await apiGetBankList();
    setBankList(bankData.data.result);
  };

  const fetchBranches = async (bankCode) => {
    const branchData = await apiGetBankBranchList({ bankCode });
    setBranchList(branchData.data.result);
  };

  const changeBank = async (event) => {
    const bankCode = event.target.value;

    if (bankCode !== "" && bankCode.length === 3) {
      await fetchBranches(bankCode);
      const targetBank = bankList.find((bank) => bank.code === bankCode);
      if (targetBank !== undefined) {
        setCurrentBank(`(${targetBank.code}) ${targetBank.name}`);
      }
      setCurrentBankBranch("");
    } else {
      // 選擇「選選擇」時，清空 branchList 和 currentBankBranch
      setBranchList([]);
      setCurrentBankBranch("");
    }
    setCurrentBank(bankCode);
  };

  useEffect(() => {
    if (currentBankBranch !== "" && currentBankBranch >= 3) {
      const targetBankBranch = branchList.find(
        (branch) => branch.code === currentBankBranch
      );
      if (targetBankBranch !== undefined) {
        setCurrentBankBranch(
          `(${targetBankBranch.code}) ${targetBankBranch.name}`
        );
      }
    }
  }, [currentBankBranch]);

  //#region 渲染服務區域localStorage資料的下拉式選單
  useEffect(() => {
    const savedBank = localStorage.getItem("bankCode");
    const savedBranch = localStorage.getItem("bankBranchCode");

    if (savedBank) {
      setCurrentBank(bankList.find((bank) => bank.code === savedBank));
      fetchBranches(savedBank);
    }

    if (savedBranch) {
      setCurrentBankBranch(
        branchList.find((branch) => branch.code === savedBranch)
      );
    }

    fetchBank();
  }, []);

  useEffect(() => {
    const savedBankCode = localStorage.getItem("bankCode");
    const targetBank = bankList.find((bank) => bank.code === savedBankCode);
    if (targetBank) {
      setCurrentBank(targetBank);
    }
  }, [bankList]);

  useEffect(() => {
    const savedBranchCode = localStorage.getItem("bankBranchCode");
    const targetBranch = branchList.find(
      (branch) => branch.code === savedBranchCode
    );
    if (targetBranch) {
      setCurrentBankBranch(targetBranch);
    }
  }, [branchList]);
  //#endregion

  useEffect(() => {
    // 調用後端API獲取文件
    const fetchDocuments = async () => {
      try {
        // 從本地存儲中獲取文件ID
        const uploadDocumentDataId = localStorage.getItem("uploadDocumentData");
        console.log("uploadDocumentDataId", uploadDocumentDataId);

        if (uploadDocumentDataId != null && uploadDocumentDataId > 0) {
          onChangebankBookFileData(uploadDocumentDataId);
          const uploadDocumentData = await apiGetDocument({
            id: uploadDocumentDataId,
          });
          const uploadDocumentDataURL = uploadDocumentData.data;

          // 下載並設置身份證正面圖片的 Blob URL
          if (uploadDocumentDataURL) {
            try {
              const frontImageResponse = await apiDownloadDocument({
                fileName: uploadDocumentDataURL,
              });
              if (frontImageResponse.data instanceof Blob) {
                const uploadDocumentDataUrl = URL.createObjectURL(
                  frontImageResponse.data
                ); //創建 Blob URL
                setUploadDocumentDataUrl(uploadDocumentDataUrl); // 將 Blob URL 存儲在狀態中
              } else {
                console.error(
                  "apiDownloadDocument 返回的不是有效的 Blob 對象。"
                );
              }
            } catch (error) {
              console.error("下載並設置身份證正面圖片時出錯:", error);
            }
          }
        }
      } catch (error) {
        console.error("獲取文件時出錯:", error);
      }
    };

    // 當文件ID變化時，調用fetchDocuments函數
    fetchDocuments();
    fetchBank();
  }, []); // 空依賴項表示僅在初始載入時調用一次

  // 處理文件上傳
  const handleFileUpload = async (file) => {
    // 準備上傳的文件數據
    const formData = new FormData();
    formData.append("Photo", file, file.name);

    // 調用 API 進行上傳
    const uploadDocumentResponse = await apiUploadDocument(formData);
    if (uploadDocumentResponse && uploadDocumentResponse.code === 0) {
      onChangebankBookFileData(uploadDocumentResponse.data);
      console.log(uploadDocumentResponse.data);

      // 將 data 存入 localStorage 中
      localStorage.setItem(
        "uploadDocumentData",
        JSON.stringify(uploadDocumentResponse.data)
      );
    } else {
      showToast(
        "error",
        uploadDocumentResponse
          ? uploadDocumentResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };

  // 處理檔案輸入框變更事件
  const handleFileChange = (e, previewId) => {
    const file = e.target.files[0]; // 取得第一個文件，可以根據需要處理多個文件上傳
    if (file) {
      // 檢查文件類型是否為 jpg、png、jpeg
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        // 如果文件類型不符合要求，顯示錯誤訊息並返回
        showToast("error", "僅能上傳 jpg、png、jpeg 檔");
        return;
      }
      // 創建一個Blob URL來顯示圖片預覽
      const imageUrl = URL.createObjectURL(file);
      handleFileUpload(file)
        .then(() => setUploadDocumentDataUrl(imageUrl))
        .catch(() => showToast("error", "上傳銀行存摺封面失敗"));
    }
  };

  // #region 處理刪除按鈕的點擊事件
  const handleRemoveFile = (previewId) => {
    if (previewId == "uploadDocumentData") {
      localStorage.removeItem("uploadDocumentData");
      setUploadDocumentDataUrl(null);
      onChangebankBookFileData(0);
    }
  };
  //#endregion

  //#region 失去焦點Input的欄位
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    let newErrors = { ...errors };

    // 立即驗證空輸入並更新錯誤狀態
    if (value.trim() === "") {
      newErrors[name] = "此欄位為必填項目";
    } else {
      // 如果輸入不為空，則進行正常的驗證
      newErrors[name] = "";

      // 使用 ValidateField 驗證帳號欄位是否為數字
      const validateResult = await ValidateField(
        name,
        value,
        errors,
        validator
      );
      if (!validateResult.result) {
        showToast("error", "帳號須為數字");
        newErrors[name] = "帳號須為數字";
      }
    }

    // 將更新後的錯誤狀態設置為組件的狀態
    setErrors(newErrors);
  };
  //#endregion

  //#region 欄位驗證
  const checkValidator = async () => {
    let newErrors = { ...errors };
    var validateFieldsResult = await ValidateFields(
      createNurseAides,
      newErrors,
      ["bankBookFileId"]
    );
    if (!validateFieldsResult.result) {
      setErrors(newErrors);
    }

    return validateFieldsResult.result;
  };
  //#endregion

  useEffect(() => {
    console.log(createNurseAides);
  }, [createNurseAides]);

  //建立照服員
  const handleSubmit = async (e) => {
    e.preventDefault(); // 阻止表單的默認提交行為

    // 檢查是否符合條件
    const isFieldsValid = await checkValidator(); // 檢查欄位是否有效
    const isImageUploaded =
      createNurseAides.bankBookFileId !== 0 ||
      localStorage.getItem("uploadDocumentData") !== null; // 檢查是否有圖片上傳或存入 localStorage

    // 獲取分行代碼的值
    const bankBranchCode = document.getElementById("bankBranchCode").value;
    // 檢查分行代碼是否選擇
    if (!bankBranchCode) {
      showToast("error", "分行代碼為必填項目");
      return;
    }
    // TODO如果之後有要在申請照服員新增上傳大頭照功能要變為FormData格式，只是目前還沒有做heashor的屬性
    // const formData = new FormData();
    // formData.append("Name", createNurseAides.name);
    // formData.append("Gender", createNurseAides.gender);
    // formData.append("Birthday", createNurseAides.birthday);

    // formData.append("Height", parseInt(createNurseAides.height));

    // formData.append("Weight", parseInt(createNurseAides.weight));

    // formData.append("CityId", parseInt(createNurseAides.cityId));

    // formData.append("DistId", parseInt(createNurseAides.distId));

    // formData.append("Address", createNurseAides.address);

    // formData.append("Phone", createNurseAides.phone);

    // formData.append("Mail", createNurseAides.mail);

    // formData.append("IdentityNumber", createNurseAides.identityNumber);

    // formData.append(
    //   "MainServiceCityId",
    //   parseInt(createNurseAides.mainServiceCityId)
    // );

    // formData.append(
    //   "MainServiceDistId",
    //   parseInt(createNurseAides.mainServiceDistId)
    // );

    // formData.append(
    //   "SubServiceCityId",
    //   parseInt(createNurseAides.subServiceCityId)
    // );

    // formData.append(
    //   "SubServiceDistId",
    //   parseInt(createNurseAides.subServiceDistId)
    // );

    // formData.append("RecommendCode", createNurseAides.recommendCode);

    // formData.append("BankCode", createNurseAides.bankCode);

    // formData.append("BankAccount", createNurseAides.bankAccount);

    // formData.append("BankBranchCode", createNurseAides.bankBranchCode);

    // formData.append(
    //   "BankBookFileId",
    //   parseInt(createNurseAides.bankBookFileId)
    // );

    // createNurseAides.careCertificates.map((data) =>
    //   formData.append("CareCertificates", data)
    // );

    // formData.append(
    //   "IdentityCard.FrontFileId",
    //   parseInt(createNurseAides.identityCard.frontFileId)
    // );
    // formData.append(
    //   "IdentityCard.BackFileId",
    //   parseInt(createNurseAides.identityCard.backFileId)
    // );
    // formData.append("Headshot", createNurseAides.headshot);

    // 如果兩個條件中的任何一個符合，則允許提交表單
    if (isFieldsValid || isImageUploaded) {
      showToast("warning", "資料送審中，請稍等");
      const createNurseAidesResponse = reApply
        ? await apiUpdateNurseAides(createNurseAides)
        : await apiCreateNurseAides(createNurseAides);
      if (createNurseAidesResponse && createNurseAidesResponse.code === 0) {
        localStorage.removeItem("identityCardFront");
        localStorage.removeItem("identityCardBack");
        localStorage.removeItem("careCertificateFront");
        localStorage.removeItem("careCertificateBack");
        localStorage.removeItem("uploadDocumentData");
        reApply ? reApplyComplete() : onNextStep();
      } else if (
        createNurseAidesResponse &&
        createNurseAidesResponse.code === 1
      ) {
        showToast("error", "已申請過照服員，請勿重複申請");
      } else {
        showToast("error", "請檢查欄位是否正確填寫及圖片是否上傳");
        console.log("321", createNurseAidesResponse);
      }
    } else {
      // 如果都沒有圖片則跳出提示訊息showToast
      showToast("error", "請上傳本人存摺封面");
    }
  };

  const handleRegister = async (e) => {
    try {
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast("Failed to submit data", "error");
    }
  };

  return (
    <>
      <div className="content">
        <div className="applyHead">
          <h1>申請照服員</h1>
          <div>
            <div>
              <svg
                width="15"
                height="19"
                viewBox="0 0 15 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                  fill="#9E3223"
                />
              </svg>
            </div>
            <div className="active">
              <svg
                width="18"
                height="25"
                viewBox="0 0 18 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                  fill="#9E3223"
                />
              </svg>
            </div>
          </div>
        </div>
        <form
          className="flex flex-col gap-[1.875rem] mb-[10rem]"
          action="./regisiterVerifySuccess.html"
        >
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="bankCode"
            >
              銀行代碼*
            </label>
            <div className="flex items-center grow">
              <select
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="bankCode"
                id="bankCode"
                value={currentBank ? currentBank.code : ""} // 將 value 設為 currentBank
                onChange={(e) => {
                  onChangeCreateNurseAides(e);
                  changeBank(e);
                  setCurrentBank(e.target.value);
                }}
                onBlur={handleBlur}
              >
                <option value="">｜請選擇銀行</option>
                {bankList.map((bank) => {
                  return (
                    <option
                      value={String(bank.code)}
                    >{`${bank.code} ${bank.name}`}</option>
                  );
                })}
              </select>
            </div>
          </div>
          {errors.bankCode && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              此欄位為必填項目
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="bankBranchCode"
            >
              分行代碼*
            </label>
            <div className="flex items-center grow">
              <select
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="bankBranchCode"
                id="bankBranchCode"
                value={currentBankBranch ? currentBankBranch.code : ""}
                onChange={(e) => {
                  onChangeCreateNurseAides(e);
                  setCurrentBankBranch(e.target.value);
                }}
                onBlur={handleBlur}
              >
                <option value="">｜請選擇分行</option>
                {branchList.map((branch) => {
                  return (
                    <option
                      value={branch.code}
                    >{`${branch.code} ${branch.name}`}</option>
                  );
                })}
              </select>
            </div>
          </div>
          {errors.bankBranchCode && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              此欄位為必填項目
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              for="bankAccount"
            >
              帳號*
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="bankAccount"
                id="bankAccount"
                placeholder="｜請輸入銀行帳號"
                value={createNurseAides.bankAccount}
                onChange={onChangeCreateNurseAides}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {errors.bankAccount && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              此欄位為必填項目
            </div>
          )}
          <div
            className="InspireDropzone"
            id="uploadDocumentData"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => document.getElementById("upload-front")?.click()}
          >
            {uploadDocumentDataUrl ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "10px",
                    width: "36px",
                    height: "36px",
                  }}
                  onClick={(e) => handleRemoveFile("uploadDocumentData")}
                >
                  <img src="/img/times.png" alt="刪除本人存摺封面" />
                </div>
                <img src={uploadDocumentDataUrl} alt="本人存摺封面" />
              </>
            ) : (
              <>
                <div>
                  <span>
                    上傳本人存摺封面
                    <br />
                    (有帳號處)
                  </span>
                  <span>僅能上傳jpg、png、jpeg檔</span>
                </div>
                <input
                  type="file"
                  id="upload-front"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      "uploadDocumentData",
                      "uploadDocumentData"
                    )
                  }
                />
              </>
            )}
          </div>

          <div className="flex items-center justify-end gap-[0.75rem]">
            <a
              href="./careStep1.html"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="上一步"
              onClick={onPreviousStep}
            >
              上一步
            </a>
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="送出"
              onClick={handleSubmit}
            >
              送出
            </button>
          </div>
          {/* {showEmailVerification && ( // 根据状态决定是否显示邮箱验证区域
                        <>
                            <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">信箱驗證</h1>
                            <p className="text-primary text-[0.9375rem] leading-[1.5] mt-[0.8125rem]">
                                請至信箱查收驗證信，點擊連結進行驗證
                            </p>
                            <div className="flex items-center justify-end gap-[0.75rem]">
                                <button
                                    type="submit"
                                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                    title="下一步"
                                    onClick={handleRegister}
                                >
                                    完成驗證
                                </button>
                            </div>
                        </>
                    )} */}
        </form>
      </div>
    </>
  );
};

export default Bank;
