import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";

/**
 * 照服員接受案件-可服務方式(step1)
 */
const ConfirmStep1 = ({
  onNextStep,
  onPreviousStep,
  onHandleChangeConfirmDataStep1,
  confirmData,
  availableCaseDetail,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [selectedDayModel, setSelectedDayModel] = useState(); //選擇的模式
  const [serviceDay, setServiceDay] = useState(1); //可服務天數

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      setSelectedDayModel(confirmData.selectedDayModel);
      setServiceDay(confirmData.serviceDay);
    };
    fetchData();
    return () => {};
  }, [confirmData]);
  //#endregion

  //#region 選擇服務模式
  const handleChangeServiceModel = (e) => {
    const { name, value } = e.target;
    var tmpValue = parseInt(value);

    if (tmpValue === 0) {
      //配合家屬時間，需清空可服務時間
      setServiceDay(1);
    }

    setSelectedDayModel(tmpValue);
  };
  //#endregion

  //#region 服務天數增減
  const handleServiceStartDay = (type) => {
    if (serviceDay === 1 && type === "sub") {
      // This section prevents user from setting service day to zero or lower
    } else {
      if (type === "add") {
        if (serviceDay + 1 <= availableCaseDetail.plan.careCasePlanTimes.length)
          setServiceDay(serviceDay + 1);
      }
      if (type === "sub") {
        setServiceDay(serviceDay - 1);
      }
    }
  };
  //#endregion

  //#region 直接修改服務天數
  const handleChangeServiceDay = (event) => {
    const { value } = event.target;
    if (value > 0) {
      if (value > availableCaseDetail.plan.careCasePlanTimes.length) {
        showToast(
          "error",
          `最多只可以選擇${availableCaseDetail.plan.careCasePlanTimes.length}天`
        );
      } else {
        setServiceDay(parseInt(value));
      }
    }
  };
  //#endregion

  //#region 下一步
  const handleSubmit = (e) => {
    if (selectedDayModel === 0 || (selectedDayModel === 1 && serviceDay > 0)) {
      onHandleChangeConfirmDataStep1(selectedDayModel, serviceDay);
      onNextStep();
    } else {
      e.preventDefault();
      showToast("error", "請選擇可服務方式");
    }
  };
  //#endregion

  return (
    <div className="modal-body accept">
      <div className="bottomFormSection" style={{ height: "33.3333%" }}>
        <strong className="title">可服務方式</strong>
        <div className="w-full flex flex-col items-center gap-[1.25rem] mt-[1.5625rem] mx-auto">
          <div>
            <input
              type="checkbox"
              className="hidden peer"
              name="date"
              id="day-model-0"
              value="0"
              checked={selectedDayModel === 0}
              onChange={handleChangeServiceModel}
            />
            <label
              htmlFor="day-model-0"
              className="min-w-[14rem] block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
            >
              配合家屬的時間
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden peer"
              name="date"
              id="day-model-1"
              value="1"
              checked={selectedDayModel === 1}
              onChange={handleChangeServiceModel}
            />
            <label
              htmlFor="day-model-1"
              className="min-w-[14rem] block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
            >
              我只能服務
            </label>
          </div>
          {selectedDayModel === 1 && (
            <div className="w-full max-w-[15.625rem] flex flex-col gap-[0.9375rem] mx-auto">
              <strong className="text-primary text-[2rem] font-bold text-center">
                服務天數
              </strong>
              <div className="flex items-stretch justify-between">
                <button
                  id="lessDayButton"
                  type="button"
                  className="w-[1.875rem] flex items-center justify-center"
                  title="less day button"
                  onClick={(e) => handleServiceStartDay("sub")}
                >
                  <svg
                    width="16"
                    height="22"
                    viewBox="0 0 16 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                      fill="#9E3223"
                    />
                  </svg>
                </button>
                <div className="flex items-center gap-[0.875rem]">
                  <input
                    id="day"
                    type="number"
                    min="1"
                    max={availableCaseDetail.plan.careCasePlanTimes.length}
                    value={serviceDay}
                    className="w-[6.25rem] min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-[1.25rem] text-center leading-[1.4] placeholder:text-[rgba(0,0,0,0.3)]"
                    name="day"
                    onChange={(e) => handleChangeServiceDay(e)}
                  />
                  <label
                    className="text-primary text-[1.25rem] leading-[1.4]"
                    htmlFor="day"
                  >
                    天
                  </label>
                </div>
                <button
                  id="AddDayButton"
                  type="button"
                  className="w-[1.875rem] flex items-center justify-center"
                  title="add day button"
                  onClick={(e) => handleServiceStartDay("add")}
                >
                  <svg
                    width="16"
                    height="22"
                    viewBox="0 0 16 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                      fill="#9E3223"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] pb-[5.25rem]">
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="取消"
            onClick={onPreviousStep}
          >
            取消
          </button>
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStep1;
