import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MyUserProvider } from "./contexts/MyUserContext";
import { TargetCareCaseProvider } from "./contexts/TargetCareCaseContext";
import { ToastProvider } from "./contexts/ToastContext";
import "./utils/i18n";
import "./index.css";
import "./chats.css";
import PrivateRoute from "./utils/PrivateRoute";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import _Layout from "./layouts/_Layout";
import Index from "./pages/Index";

import Login from "./pages/Login/Login";
import ForgetAccount from "./pages/Login/ForgetAccount";
import ForgetPassword from "./pages/Login/ForgetPassword";

import Choose from "./pages/Choose/Choose";
import CareStep from "./pages/CareStep/CareStep";

import Family from "./pages/Family/Family";
import FindCareStep from "./pages/FindCareStep/FindCareStep";
import FindCareStepAll from "./pages/FindCareStep/FindCareStepAll";
import FindCareStepChoose from "./pages/FindCareStep/FindCareStepChoose";
import FindCareStepSuccess from "./pages/FindCareStep/FindCareStepSuccess";

import Pairing from "./pages/Pairing/Pairing";
import WaitingPairPanel from "./pages/Pairing/WaitingPairPanel";
import ProgressPairPanel from "./pages/Pairing/ProgressPairPanel";
import Pairing3 from "./pages/Pairing/Pairing3";
import Pairing3Fail from "./pages/Pairing/Pairing3Fail";
import Comment from "./pages/Comment/Comment";
import CommentSuccess from "./pages/Comment/CommentSuccess";

import NurseAides from "./pages/NurseAides/NurseAides";

import ProjectList from "./pages/ProjectList/ProjectList";
import ProjectListChoose from "./pages/ProjectList/ProjectListChoose";
import ProjectListChooseIDCard from "./pages/ProjectList/ProjectListChooseIDCard";
import ProjectListChooseSuccess from "./pages/ProjectList/ProjectListChooseSuccess";

import RegisiterAccount from "./pages/Regisiter/RegisiterAccount";

import ResetPassword from "./pages/Reset/ResetPassword";

import Checkin from "./pages/Checkin/Checkin";

import MailVerification from "./components/MailVerification/MailVerification";

import ReviewFail from "./pages/Review/ReviewFail";
import FindInHomeCare from "./pages/FindCareStep/FindInHomeCare";
import Chats from "./pages/Chats/Chats";
import UserProfile from "./pages/UserProfile/UserProfile";
import LiceseTrackPage from "./pages/LicenseTracker/LiceseTrackPage";
import CaseSchedule from "./pages/CaseSchedule/CaseSchedule";
import ProjectListFamilyChoosePage from "./pages/ProjectList/ProjectListFamilyChoose";
import ProgressPairDetailPage from "./pages/Pairing/ProgressPairDetailPage";

import CareVideo from "./pages/CareVideo/CareVideo";
import CareVideoList from "./pages/CareVideo/CareVideoList";
import Pairing3NureAides from "./components/Pairing/Pairing3NureAides";
import Pairing3NureAidePanel from "./pages/Pairing/Pairing3NureAidePanel";
import Pairing3CaregiverListPage from "./pages/Pairing/Pairing3CaregiverListPage";
import CheckingPage from "./pages/Checking/CheckingPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MyUserProvider>
    <TargetCareCaseProvider>
      <Router>
        <Routes>
          {/*首頁*/}
          <Route
            path="/"
            element={
              <_Layout>
                <Index />
              </_Layout>
            }
          />
          {/*申請會員*/}
          <Route
            path="/RegisiterAccount" //申請會員步驟
            element={
              <_Layout>
                <RegisiterAccount />
              </_Layout>
            }
          />
          {/*重置密碼*/}
          <Route
            path="/ResetPassword" //重置密碼  //還沒串
            element={
              <_Layout>
                <ResetPassword />
              </_Layout>
            }
          />
          {/*登入畫面*/}
          <Route
            path="/Login" //登入
            element={
              <_Layout>
                <Login />
              </_Layout>
            }
          />
          <Route
            path="/ForgetAccount" //忘記帳號
            element={
              <_Layout>
                <ForgetAccount />
              </_Layout>
            }
          />
          <Route
            path="/ForgetPassword" //忘記密碼
            element={
              <_Layout>
                <ForgetPassword />
              </_Layout>
            }
          />
          <Route element={<PrivateRoute />}>
            {/*選擇身分*/}
            <Route
              path="/Choose" //選擇身分
              element={
                <_Layout>
                  <Choose />
                </_Layout>
              }
            />
            {/*申請照服員*/}
            <Route
              path="/CareStep" //申請照服員-輸入    //還沒串
              element={
                <_Layout>
                  <CareStep />
                </_Layout>
              }
            />
            {/*我是家屬 基本上這一頁是做錯的*/}
            <Route
              path="/Family" //家屬畫面
              element={
                <_Layout>
                  <Family />
                </_Layout>
              }
            />
            {/*點選我是家屬後顯示*/}
            <Route
              path="/FindCareStep" //首頁 顯示(各種狀態中的)案件  /找看護按鈕
              element={
                <_Layout>
                  <FindCareStep />
                </_Layout>
              }
            />

            <Route
              path="/FindCareStepChoose" //找居家看護／找醫院看護
              element={
                <_Layout>
                  <FindCareStepChoose />
                </_Layout>
              }
            />
            <Route
              path="/FindInHomeCare" //找居家看護
              element={
                <_Layout>
                  <FindInHomeCare />
                </_Layout>
              }
            />
            <Route
              path="/FindCareStepAll" //找醫院看護
              element={
                <_Layout>
                  <FindCareStepAll />
                </_Layout>
              }
            />
            <Route
              path="/FindCareStepSuccess" //找看護流程完成
              element={
                <_Layout>
                  <FindCareStepSuccess />
                </_Layout>
              }
            />
            {/*點選我是家屬後 到首頁FindCareStep 選擇(各種狀態中的)案件*/}
            <Route
              path="/Pairing/:id" //配對中案件
              element={
                <_Layout>
                  <Pairing />
                </_Layout>
              }
            />
            <Route
              path="/WaitingPairs/:id" //配對成功案件
              element={
                <_Layout>
                  <WaitingPairPanel />
                </_Layout>
              }
            />
            <Route
              path="/ProgressPairs/:id" //進行中案件，這邊按下確認會跳到FindCareStep6
              element={
                <_Layout>
                  <ProgressPairPanel />
                </_Layout>
              }
            />
            <Route
              path="/ProgressPairDetail/careCaseId/:careCaseId/id/:id" //進行中案件的照服員打卡資訊
              element={
                <_Layout>
                  <ProgressPairDetailPage />
                </_Layout>
              }
            />
            <Route
              path="/Pairing3/:id" //已完成案件項目選擇
              element={
                <_Layout>
                  <Pairing3 />
                </_Layout>
              }
            />
            <Route
              path="/Pairing3CaregiverList/:id" //已完成案件照服員列表
              element={
                <_Layout>
                  <Pairing3CaregiverListPage />
                </_Layout>
              }
            />
            <Route
              path="/Pairing3Fail/:id" //選擇-已完成案件
              element={
                <_Layout>
                  <Pairing3Fail />
                </_Layout>
              }
            />
            <Route
              path="/UserProfile" //選擇-個人資料   //串在漢堡選單
              element={
                <_Layout>
                  <UserProfile />
                </_Layout>
              }
            />
            <Route
              path="/Comment" //選擇-意見回饋   //串在漢堡選單
              element={
                <_Layout>
                  <Comment />
                </_Layout>
              }
            />
            <Route
              path="/CommentSuccess" //選擇-意見回饋   //串在漢堡選單
              element={
                <_Layout>
                  <CommentSuccess />
                </_Layout>
              }
            />
            <Route
              path="/LicenseTracker" //選擇-證照管理(照服員才看的到)   //串在漢堡選單
              element={
                <_Layout>
                  <LiceseTrackPage />
                </_Layout>
              }
            />
            <Route
              path="/CaseSchedule" //選擇-行程查詢(照服員才看的到)   //串在漢堡選單
              element={
                <_Layout>
                  <CaseSchedule />
                </_Layout>
              }
            />

            {/*點選我是照服員後顯示 首頁(目前還沒有首頁)中的照服員找案件(缺彈跳視窗們)*/}
            <Route
              path="/NurseAides" //照服員主畫面
              element={
                <_Layout>
                  <NurseAides />
                </_Layout>
              }
            />
            <Route
              path="/ProjectList" //找案件(暫設為首頁)
              element={
                <_Layout>
                  <ProjectList />
                </_Layout>
              }
            />
            <Route
              path="/ProjectListChoose/:id" //案件詳細資料
              element={
                <_Layout>
                  <ProjectListChoose />
                </_Layout>
              }
            />
            <Route
              path="/ProjectListFamilyChoose/:id" //家屬選擇案件詳細資料
              element={
                <_Layout>
                  <ProjectListFamilyChoosePage />
                </_Layout>
              }
            />
            <Route
              path="/ProjectListChooseIDCard" //案件詳細資料
              element={
                <_Layout>
                  <ProjectListChooseIDCard />
                </_Layout>
              }
            />
            <Route
              path="/ProjectListChooseSuccess" //確認接案後的畫面
              element={
                <_Layout>
                  <ProjectListChooseSuccess />
                </_Layout>
              }
            />
            <Route
              path="/Checkin/:careCaseId" //確認接案後的畫面
              element={
                <_Layout>
                  <Checkin />
                </_Layout>
              }
            />
            <Route
              path="/Checking/:careCaseId" //確任中的畫面
              element={
                <_Layout>
                  <CheckingPage />
                </_Layout>
              }
            />

            <Route
              path="/Pairing3NurseAides/:id" //照服員已完成案件評價
              element={
                <_Layout>
                  <Pairing3NureAidePanel />
                </_Layout>
              }
            />
            <Route
              path="/MailVerify"
              element={
                <_Layout>
                  <MailVerification />
                </_Layout>
              }
            />
            <Route
              path="/ReviewFail"
              element={
                <_Layout>
                  <ReviewFail />
                </_Layout>
              }
            />
            {/*照服員提問*/}
            <Route
              path="/Chats/:id"
              element={
                <_Layout>
                  <Chats />
                </_Layout>
              }
            />
            <Route
              path="/CareVideo/:videosId"
              element={
                <_Layout>
                  <CareVideo />
                </_Layout>
              }
            />
            <Route
              path="/CareVideoList"
              element={
                <_Layout>
                  <CareVideoList />
                </_Layout>
              }
            />
          </Route>
        </Routes>
      </Router>
    </TargetCareCaseProvider>
  </MyUserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
