import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import moment from "moment";
import { apiSystemCode } from "../../utils/Api";

/**
 * 照服員接受案件-選擇期望金額(step3)
 */
const ConfirmStep3 = ({
  onNextStep,
  onPreviousStep,
  onHandleChangeConfirmDataStep3,
  confirmData,
  availableCaseDetail,
}) => {
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const iseEffectcalledRef = useRef(false);
  const { showToast } = useToast();
  const [selectionState, setSelectionState] = useState({
    expectedPrice: 0,
    expectedPriceStatus: 0,
  });
  const [addPercent, setAddPercent] = useState(0);

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      const params = [{ systemKey: "ExpectFee" }];
      const getAddPercentResponse = await apiSystemCode(params);
      if (getAddPercentResponse && getAddPercentResponse.code === 0) {
        console.log(getAddPercentResponse.data[0].systemValue);
        setAddPercent(Number(getAddPercentResponse.data[0].systemValue));
        setSelectionState({
          expectedPrice: confirmData.expectedPrice,
          expectedPriceStatus: 0,
        });
      } else {
        showToast(
          "error",
          getAddPercentResponse ? getAddPercentResponse.message : tRef.current
        );
      }
    };

    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      fetchData();
    }
  }, [confirmData, showToast]);
  //#endregion

  const formatDateTime = (dateTime) => {
    // 試著解析不同的日期格式
    const momentDateTime = moment.parseZone(dateTime, [
      "M/D/YYYY h:mm:ss A",
      "YYYY/M/D A h:mm:ss",
    ]);
    return momentDateTime.isValid() ? momentDateTime.format("MM/DD") : dateTime;
  };

  //#region 選擇期望金額
  const handleChangeExpectedAmount = (e, status) => {
    const { name, value } = e.target;
    let tmpValue = parseInt(value);
    setSelectionState({
      expectedPrice: tmpValue,
      expectedPriceStatus: status,
    });
  };
  //#endregion

  //#region 下一步
  const handleSubmit = (e) => {
    if (selectionState.expectedPrice > 0) {
      onHandleChangeConfirmDataStep3(selectionState);
      onNextStep();
    } else {
      e.preventDefault();
      showToast("error", "請選擇期望金額");
    }
  };
  //#endregion

  // #region 小數點第二位計算
  const caculatePrice = (price) => {
    const floatPrice = price * (addPercent + 1);
    const secondFloatPrice = Number(floatPrice.toFixed(2));
    return secondFloatPrice;
  };

  useEffect(() => {
    console.log(availableCaseDetail);
    console.log(addPercent);
  }, [addPercent, availableCaseDetail]);

  return (
    <div className="modal-body accept">
      <div className="bottomFormSection" style={{ height: "66.6666%" }}>
        <strong className="title">選擇期望金額</strong>
        <div className="w-full flex flex-col items-center gap-[1.25rem] mt-[3.75rem] mx-auto">
          <div>
            <input
              type="radio"
              className="hidden peer"
              name="price"
              id="expected-amount-default"
              value={availableCaseDetail.plan.price}
              onChange={(e) => {
                handleChangeExpectedAmount(e, 0);
              }}
              checked={
                availableCaseDetail.plan.price === selectionState.expectedPrice
              }
            />
            <label
              htmlFor="expected-amount-default"
              className="min-w-[14rem] block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
            >
              無需調整，$ {availableCaseDetail.plan.price.toLocaleString()} /{" "}
              {availableCaseDetail.plan.unit === 1 ? "天" : "小時"}
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="hidden peer"
              name="price"
              id="appy-2-price-2"
              value={caculatePrice(availableCaseDetail.plan.price)}
              onChange={(e) => {
                handleChangeExpectedAmount(e, 1);
              }}
              checked={
                caculatePrice(availableCaseDetail.plan.price) ===
                selectionState.expectedPrice
              }
            />
            <label
              htmlFor="appy-2-price-2"
              className="min-w-[14rem] block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
            >
              $ {caculatePrice(availableCaseDetail.plan.price).toLocaleString()}{" "}
              / {availableCaseDetail.plan.unit === 1 ? "天" : "小時"}
            </label>
          </div>
        </div>
        <div className="flex items-center justify-center gap-[0.75rem] mt-[3.25rem] pb-[5.25rem]">
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="上一步"
            onClick={onPreviousStep}
          >
            上一步
          </button>
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStep3;
