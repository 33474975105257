import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate(); //跳轉Router

  const handleGotoChoose = () => {
    localStorage.removeItem("PersonnelType");
    navigate("/Choose");
  };
  return (
    <>
      <header style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <div style={{ display: "inline-block", cursor: "pointer" }}>
          <a
            href={void 0}
            className="logo"
            title="馬上就來"
            onClick={handleGotoChoose}
          ></a>
        </div>
      </header>
    </>
  );
};

export default Header;
