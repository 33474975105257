import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //语系
import { Modal, Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import './../bootstrap.css';

/**
 * 漢堡選單
 * @param {*} param0 
 * @returns 
 */
const HamburgerModal = ({ showHamburgerModal, handleHamburgerClose }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate(); //跳轉Router

    // 如果頁面是在當前頁面則關閉選單
    const handleLinkClick = (path) => {
        if (location.pathname === path) {
            handleHamburgerClose();
        }
    };

    const handleGotoPersonalInfo = () => {
        navigate();
    }

    return (
        <Modal show={showHamburgerModal} onHide={handleHamburgerClose} class="modal fade" id="MenuModal" tabindex="-1" aria-labelledby="MenuModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-content hamburger">
                <div class="modal-body hamburger flex flex-col gap-[3.75rem] pb-[3.75rem]">
                    <div class="flex items-center justify-end">
                        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleHamburgerClose}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_26_919)">
                                    <path
                                        d="M0.0210866 4.72536L4.49542 0L14.0205 9.33526L23.351 0.0263966L27.8665 4.71106L18.6671 13.8892L28 23.0361L23.5257 27.7615L14.0605 18.485L4.5155 28.0079L0 23.3233L9.41401 13.9311L0.0210866 4.72536Z"
                                        fill="#9E3223"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_26_919">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <ul class="flex flex-col gap-[1.875rem]">
                        <li
                            class="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                        >
                            <a href={void (0)} class="flex items-center gap-[1rem]" title="個人資料" onClick={handleGotoPersonalInfo}
                            ><svg
                                width="30"
                                height="41"
                                viewBox="0 0 30 41"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                    <g clipPath="url(#clip0_596_10)">
                                        <path
                                            d="M2.08936 41.0002V30.4498C2.08936 23.5204 7.86791 17.9062 15.0002 17.9062C22.1325 17.9062 27.9111 23.5204 27.9111 30.4498V41.0002"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M30 41.0001H25.8217V30.4497C25.8217 24.6529 20.9666 19.9359 15 19.9359C9.03343 19.9359 4.17827 24.6529 4.17827 30.4497V41.0001H0V30.4497C0 22.4121 6.7312 15.8765 15 15.8765C23.2688 15.8765 30 22.4162 30 30.4497V41.0001Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M14.9459 17.9021C19.4572 17.9021 23.1144 14.3489 23.1144 9.96592C23.1144 5.58292 19.4572 2.02979 14.9459 2.02979C10.4345 2.02979 6.77734 5.58292 6.77734 9.96592C6.77734 14.3489 10.4345 17.9021 14.9459 17.9021Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M14.9456 19.9357C9.28827 19.9357 4.68799 15.4623 4.68799 9.9699C4.68799 4.47752 9.28827 0 14.9456 0C20.603 0 25.2033 4.47346 25.2033 9.96584C25.2033 15.4582 20.5988 19.9317 14.9456 19.9317V19.9357ZM14.9456 4.05941C11.5905 4.05941 8.86626 6.7102 8.86626 9.96584C8.86626 13.2215 11.5947 15.8723 14.9456 15.8723C18.2966 15.8723 21.025 13.2215 21.025 9.96584C21.025 6.7102 18.2966 4.05941 14.9456 4.05941Z"
                                            fill="#9E3223"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_596_10">
                                            <rect width="30" height="41" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                個人資料
                            </a>
                        </li>
                        <li
                            class="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                        >
                            <a href="#" class="flex items-center gap-[1rem]" title="付款紀錄"
                            ><svg
                                width="33"
                                height="39"
                                viewBox="0 0 33 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                    <g clipPath="url(#clip0_596_42)">
                                        <path
                                            d="M7.11337 39L0 32.8233V0H4.85223V30.6225L7.11337 32.5863L11.8055 28.5184L16.4976 32.5863L21.1848 28.5184L25.8769 32.5863L28.1478 30.6225V4.83691H8.176V0H33V32.8233L25.8769 39L21.1848 34.9273L16.4976 39L11.8055 34.9273L7.11337 39Z"
                                            fill="#9E3223"
                                        />
                                        <path d="M25.062 9.021H7.93848V12.4068H25.062V9.021Z" fill="#9E3223" />
                                        <path d="M25.062 15.1733H7.93848V18.5592H25.062V15.1733Z" fill="#9E3223" />
                                        <path d="M25.062 21.3306H7.93848V24.7164H25.062V21.3306Z" fill="#9E3223" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_596_42">
                                            <rect width="33" height="39" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                收款紀錄
                            </a>
                        </li>
                        <li
                            class="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                        >
                            <Link to="/Comment" onClick={() => handleLinkClick('/Comment')}>
                                <a href="#" class="flex items-center gap-[1rem]" title="意見回饋"
                                ><svg
                                    width="39"
                                    height="38"
                                    viewBox="0 0 39 38"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                        <g clipPath="url(#clip0_596_46)">
                                            <path
                                                d="M28.2674 -0.00116242L5.52002 22.3394L16.2528 32.8802L39.0002 10.5397L28.2674 -0.00116242Z"
                                                fill="#9E3223"
                                            />
                                            <path d="M0 37.9998L14.567 34.2347L3.83365 23.6934L0 37.9998Z" fill="#9E3223" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_596_46">
                                                <rect width="39" height="38" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    意見回饋
                                </a>
                            </Link>
                        </li>
                    </ul>
                    <ul class="flex flex-col gap-[1.875rem]">
                        <li
                            class="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                        >
                            <Link to="/Choose" >
                                <a href="#" class="flex items-center gap-[1rem]" title="切換身分"
                                ><svg
                                    width="42"
                                    height="34"
                                    viewBox="0 0 42 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                        <g clipPath="url(#clip0_596_72)">
                                            <path
                                                d="M1.7251 33.9998V25.409C1.7251 19.7667 6.49611 15.1953 12.3848 15.1953C18.2736 15.1953 23.0446 19.7667 23.0446 25.409V33.9998"
                                                fill="#9E3223"
                                            />
                                            <path
                                                d="M24.7692 33.9996H21.3195V25.4089C21.3195 20.6888 17.3109 16.8479 12.3846 16.8479C7.45837 16.8479 3.44975 20.6888 3.44975 25.4089V33.9996H0V25.4089C0 18.8642 5.55755 13.5425 12.3846 13.5425C19.2117 13.5425 24.7692 18.8675 24.7692 25.4089V33.9996Z"
                                                fill="#9E3223"
                                            />
                                            <path
                                                d="M12.34 15.1922C16.0647 15.1922 19.0842 12.299 19.0842 8.73014C19.0842 5.16123 16.0647 2.26807 12.34 2.26807C8.61522 2.26807 5.5957 5.16123 5.5957 8.73014C5.5957 12.299 8.61522 15.1922 12.34 15.1922Z"
                                                fill="#9E3223"
                                            />
                                            <path
                                                d="M12.3397 16.8481C7.66878 16.8481 3.87061 13.2055 3.87061 8.73331C3.87061 4.2611 7.66878 0.615234 12.3397 0.615234C17.0107 0.615234 20.8089 4.25779 20.8089 8.73001C20.8089 13.2022 17.0073 16.8448 12.3397 16.8448V16.8481ZM12.3397 3.92064C9.5696 3.92064 7.32036 6.07907 7.32036 8.73001C7.32036 11.3809 9.57305 13.5394 12.3397 13.5394C15.1065 13.5394 17.3591 11.3809 17.3591 8.73001C17.3591 6.07907 15.1065 3.92064 12.3397 3.92064Z"
                                                fill="#9E3223"
                                            />
                                        </g>
                                        <g clipPath="url(#clip1_596_72)">
                                            <path
                                                d="M30.1109 2.92902C33.305 2.92902 35.902 5.53038 35.902 8.73001C35.902 10.386 35.1859 11.9693 33.9386 13.0766L31.3846 15.3375L34.427 16.8745C37.6706 18.514 39.6868 21.7831 39.6868 25.4091V31.6861H20.6175V25.4091C20.6175 21.8062 22.6171 18.5438 25.8344 16.8977L28.8669 15.3441L26.3029 13.0965C25.0424 11.9891 24.3198 10.3992 24.3198 8.73001C24.3198 5.53038 26.92 2.92902 30.1109 2.92902ZM30.1109 0.615234C25.643 0.615234 22.01 4.25779 22.01 8.73001C22.01 11.1628 23.0857 13.3477 24.7818 14.8351C20.9441 16.8018 18.3076 20.8014 18.3076 25.4091V33.9999H41.9999V25.4091C41.9999 20.7815 39.3403 16.7655 35.473 14.8087C37.1526 13.3212 38.2151 11.1463 38.2151 8.73001C38.2151 4.25779 34.5788 0.615234 30.1109 0.615234Z"
                                                fill="#9E3223"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_596_72">
                                                <rect
                                                    width="24.7692"
                                                    height="33.3846"
                                                    fill="white"
                                                    transform="translate(0 0.615234)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip1_596_72">
                                                <rect
                                                    width="23.6923"
                                                    height="33.3846"
                                                    fill="white"
                                                    transform="translate(18.3076 0.615234)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    切換身分
                                </a>
                            </Link>
                        </li>
                        <li
                            class="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                        >
                            <Link to="/Login" >
                                <a href="#" class="flex items-center gap-[1rem]" title="登出"
                                ><svg
                                    width="36"
                                    height="39"
                                    viewBox="0 0 36 39"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                        <g clipPath="url(#clip0_596_92)">
                                            <path
                                                d="M31.0107 38.1818H0V0H31.0107V5.26646H5.33333V32.9154H31.0107V38.1818Z"
                                                fill="#9E3223"
                                            />
                                            <path
                                                d="M10.4265 16.3271L10.3716 21.5933L29.8799 21.7916L29.9348 16.5255L10.4265 16.3271Z"
                                                fill="#9E3223"
                                            />
                                            <path
                                                d="M19.3761 10.125L28.3281 19.1438L19.1895 27.9809L26.8668 28.0573L36.0001 19.2228L27.0508 10.2014L19.3761 10.125Z"
                                                fill="#9E3223"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_596_92">
                                                <rect width="36" height="38.1818" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    登出
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default HamburgerModal;