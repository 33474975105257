import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNurseAides from "../../layouts/HeaderNurseAides";
import Checkin from "../../components/Checkin/Checkin";

/**
 * 選擇身分
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <HeaderNurseAides />
        <Checkin />
      </div>
    </>
  );
};

export default InfoPanel;
