import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import CareStep from "../../components/CareStep/CareStep";

/**
 * 選擇身分
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header/>
        <CareStep/>
      </div>
    </>
  );
};

export default InfoPanel;
