import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import DatePicker from "react-multi-date-picker";
import { format, isBefore, startOfToday } from "date-fns";
import "react-multi-date-picker/styles/colors/yellow.css";

import { apiGetAllPlan } from "../../utils/Api";

import { convertTimeToDouble, formatTimeToHHmm } from "../../utils/Helpers";
import dayjs from "dayjs";

/**
 * 家屬找照服員-方案選擇(step4)
 */
const ChoosePlan = ({
  onNextStep,
  onPreviousStep,
  onChangePlan,
  createCareCase,
  setParentPlans,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const isUnmounted = useRef(false); // 聲明一個 ref，用於判斷頁面是否正在卸載
  const [plans, setPlans] = useState([]); //照顧方案列表
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem("selectedPlan");
    return savedPlan ? JSON.parse(savedPlan) : null;
  }); //選中的照顧方案
  const [currentSlide, setCurrentSlide] = useState(() => {
    const savedSlide = localStorage.getItem("currentSlide");
    return savedSlide ? parseInt(savedSlide, 10) : 0;
  }); //方案選擇
  const [content, setContent] = useState(() => {
    const savedContent = localStorage.getItem("content");
    return savedContent ? savedContent : "B";
  }); //越快越好、預約時間
  const [serviceDay, setServiceDay] = useState(() => {
    const savedServiceDay = localStorage.getItem("serviceDay");
    return savedServiceDay ? parseInt(savedServiceDay, 10) : 1;
  }); //服務天數
  const [dates, setDates] = useState(() => {
    const savedDates = localStorage.getItem("dates");
    return savedDates
      ? JSON.parse(savedDates)
          .map((date) => {
            if (dayjs(date).isAfter(dayjs())) {
              return format(new Date(date), "yyyy/MM/dd");
            }
            return null;
          })
          .filter((date) => date !== null)
      : [];
  }); //服務天數複選
  const overlayRef = useRef(null);
  //#region 日期相關
  const maxAllowServiceDate = new Date(
    new Date().setDate(new Date().getDate() + 31)
  ); //允許選擇的日期為今日算起31天
  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  const currentYear = currentDate.getFullYear(); //today的年
  const currentMonth = currentDate.getMonth() + 1; //today的月
  const currentDay = currentDate.getDate(); //today的日
  const [serviceStartTimeByDay, setServiceStartTimeByDay] = useState(() => {
    const serviceStartTimeByDay = localStorage.getItem("serviceStartTimeByDay");
    return serviceStartTimeByDay
      ? new Date(serviceStartTimeByDay)
      : currentDate;
  }); //開始時間 全天
  const [serviceStartTimeByHour, setServiceStartTimeByHour] = useState(() => {
    const savedStartTime = localStorage.getItem("serviceStartTimeByHour");
    return savedStartTime ? new Date(savedStartTime) : currentDate;
  }); //開始時間 喘息
  const [serviceYear, setServiceYear] = useState(() => {
    const savedYear = localStorage.getItem("serviceYear");
    return savedYear ? parseInt(savedYear, 10) : currentYear;
  }); //服務日期(年)
  const [serviceMonth, setServiceMonth] = useState(() => {
    const savedMonth = localStorage.getItem("serviceMonth");
    return savedMonth ? parseInt(savedMonth, 10) : currentMonth;
  }); //服務日期(月)
  const [serviceDate, setServiceDate] = useState(() => {
    const savedDate = localStorage.getItem("serviceDate");
    return savedDate ? parseInt(savedDate, 10) : currentDay;
  }); //服務日期(日)
  const [limitDate, setLimitDate] = useState(new Date()); //限制可選日期
  const [dayOptions, setDayOptions] = useState([]);
  const datepickerRef = useRef(null);
  const inputRef = useRef(null);

  const weekDays = ["一", "二", "三", "四", "五", "六", "日"]; //日曆的星期中文
  const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];

  //#region 預約時間 - 下拉式選單(年)
  const yearOptions = [];
  yearOptions.push(
    <option key="" value="">
      請選擇年份
    </option>
  );
  for (
    let year = currentYear;
    year <= maxAllowServiceDate.getFullYear();
    year++
  ) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  //#endregion
  useEffect(() => {
    console.log(serviceStartTimeByHour);
  }, [serviceStartTimeByHour]);

  //#region 預約時間 - 下拉式選單(月)
  const monthOptions = [];
  monthOptions.push(
    <option key="" value="">
      請選擇月份
    </option>
  );
  if (serviceYear === maxAllowServiceDate.getFullYear()) {
    for (
      let month = currentMonth;
      month <= maxAllowServiceDate.getMonth() + 1;
      month++
    ) {
      monthOptions.push(
        <option key={month} value={month}>
          {month}
        </option>
      );
    }
  } else if (serviceYear === currentYear) {
    for (let month = currentMonth; month <= 12; month++) {
      monthOptions.push(
        <option key={month} value={month}>
          {month}
        </option>
      );
    }
  } else {
    for (let month = 1; month <= 12; month++) {
      monthOptions.push(
        <option key={month} value={month}>
          {month}
        </option>
      );
    }
  }
  //#endregion

  //#region 預約時間 - 下拉式選單(日)
  useEffect(() => {
    const updateDayOptions = () => {
      if (serviceYear && serviceMonth) {
        const daysInSelectedMonth = getDaysInMonth(serviceYear, serviceMonth);
        const updatedDayOptions = [];
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1; // getMonth() returns month from 0 to 11
        const todayDay = today.getDate();
        updatedDayOptions.push(
          <option key="" value="">
            請選擇日期
          </option>
        );
        if (
          serviceYear === maxAllowServiceDate.getFullYear() &&
          serviceMonth === maxAllowServiceDate.getMonth() + 1
        ) {
          for (let day = 1; day <= maxAllowServiceDate.getDate(); day++) {
            if (
              serviceYear === todayYear &&
              serviceMonth === todayMonth &&
              day === todayDay
            ) {
              continue; // Skip today
            }
            updatedDayOptions.push(
              <option key={day} value={day}>
                {day}
              </option>
            );
          }
        } else if (
          serviceYear === currentYear &&
          serviceMonth === currentMonth
        ) {
          for (let day = currentDay; day <= daysInSelectedMonth; day++) {
            if (
              serviceYear === todayYear &&
              serviceMonth === todayMonth &&
              day === todayDay
            ) {
              continue; // Skip today
            }
            updatedDayOptions.push(
              <option key={day} value={day}>
                {day}
              </option>
            );
          }
        } else {
          for (let day = 1; day <= daysInSelectedMonth; day++) {
            if (
              serviceYear === todayYear &&
              serviceMonth === todayMonth &&
              day === todayDay
            ) {
              continue; // Skip today
            }
            updatedDayOptions.push(
              <option key={day} value={day}>
                {day}
              </option>
            );
          }
        }
        setDayOptions(updatedDayOptions);
      }
    };
    updateDayOptions();
  }, [serviceYear, serviceMonth]);
  //#endregion

  //取得該月份的總天數
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };
  //#endregion

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllPlan();
      const today = new Date();
      const todayDate = today.getDate();
      const startServiceTime = getValidStartTime();
      const startServiceDate = startServiceTime.getDate();
      console.log(startServiceDate);
      if (startServiceDate > todayDate) {
        today.setDate(todayDate + 1);
        setLimitDate(today);
      }
    };
    fetchData();
    return () => {};
  }, []); //沒有資料的話，使用撈取結果排序第一方案

  //#region 刷新方案列表
  const refreshAllPlan = async () => {
    const getAllPlanResponse = await apiGetAllPlan();
    if (getAllPlanResponse && getAllPlanResponse.code === 0) {
      setPlans(getAllPlanResponse.data.result);
      setParentPlans(getAllPlanResponse.data.result);
    } else {
      showToast(
        "error",
        getAllPlanResponse
          ? getAllPlanResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 方案列表更動時，檢查是否有資料
  useEffect(() => {
    if (plans.length > 0) {
      if (createCareCase.planId > 0) {
        //使用已選中的方案id
        const tmpSelectedPlan = plans.find(
          (x) => x.id === createCareCase.planId
        );
        // const tmpCurrentSlide = localStorage.getItem("currentSlide");
        // console.log(tmpCurrentSlide);

        setSelectedPlan(tmpSelectedPlan);
      } else {
        //沒有資料的話，使用撈取結果排序第一方案
        setSelectedPlan(plans[0]);
      }
    }
  }, [plans]);
  //#endregion

  //#region 服務天數增減
  const handleServiceStartDay = (type) => {
    if (serviceDay === 1 && type === "sub") {
      // This section prevents user from setting service day to zero or lower
    } else {
      if (type === "add") {
        setServiceDay(serviceDay + 1);
      }
      if (type === "sub") {
        setServiceDay(serviceDay - 1);
      }
    }
  };
  //#endregion

  //#region 直接修改服務天數
  const handleChangeServiceDay = (event) => {
    const { value } = event.target;
    if (value > 0) {
      setServiceDay(parseInt(value));
    }
  };
  //#endregion

  //#region 服務時間增減30min
  const handleServiceStartTimeByDay = (type) => {
    const date = new Date(serviceStartTimeByDay);
    const dateHour = date.getHours();
    const dateMin = date.getMinutes();
    if (type === "add") {
      // 增加半小時
      date.setMinutes(date.getMinutes() + 30);
    }
    if (type === "sub") {
      // 減少半小時
      date.setMinutes(date.getMinutes() - 30);
    }
    console.log(date);
    setServiceStartTimeByDay(date);
  };
  //#endregion

  //#region 服務時間增減30min
  const isToday = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  //#endregion

  const getValidStartTime = () => {
    const now = new Date();
    now.setHours(now.getHours() + 4);
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30); // 向上取整到下個整點或半點
    return now;
  };

  //#region 服務開始時間增減30min(喘息)
  const handleServiceStartTimeByHour = (type) => {
    const today = dayjs();
    const todayDate = today.format("YYYY-MM-DD");
    const isIncludeToday = dates.some((date) => date === todayDate);
    const date = new Date(serviceStartTimeByHour);
    const oldDate = new Date(date);
    const dateHour = date.getHours();
    const dateMin = date.getMinutes();
    // console.log(isTomorrow);
    if (type === "add") {
      if (dateHour !== 23 || dateMin !== 30) {
        date.setMinutes(date.getMinutes() + 30);
      }
    }
    if (type === "sub") {
      date.setMinutes(date.getMinutes() - 30);
    }
    const validStartTime = getValidStartTime();
    if (isIncludeToday) {
      if (
        date < validStartTime
        //  && dates.some((date) => isToday(date))
      ) {
        setServiceStartTimeByHour(validStartTime);
      } else {
        setServiceStartTimeByHour(date);
      }
    } else {
      const newToday = new Date(today);
      const todayHour = newToday.getHours();
      const tomorrow = today.add(1, "day").format("YYYY-MM-DD");
      const isIncludeTomorrow = dates.some((date) => date === tomorrow);
      if (isIncludeTomorrow) {
        const validateServiceTime = getValidStartTime();
        if (date < validStartTime && todayHour <= 23 && todayHour >= 20) {
          setServiceStartTimeByHour(validateServiceTime);
        } else {
          setServiceStartTimeByHour(date);
        }
      } else {
        setServiceStartTimeByHour(date);
      }
    }
  };
  //#endregion

  //#region 服務日期複選樣式設定(蓋一層div上去)
  const handleOverlayClick = (event) => {
    event.preventDefault();
    if (datepickerRef.current) {
      datepickerRef.current.openCalendar();
    }
  };
  //#endregion

  const handleInputClick = () => {
    if (datepickerRef.current) {
      datepickerRef.current.openCalendar();
    }
  };
  //#endregion

  //#region 下一步
  const handleSubmit = () => {
    onNextStep();
  };
  //#endregion

  //#region swiper 下一頁
  const handleNextSlide = () => {
    const nextSlide = (currentSlide + 1) % plans.length;
    setCurrentSlide(nextSlide);
    updateSelectedPlan(nextSlide);
  };
  //#endregion

  //#region swiper 上一頁
  const handlePrevSlide = () => {
    const prevSlide = (currentSlide - 1 + plans.length) % plans.length;
    setCurrentSlide(prevSlide);
    updateSelectedPlan(prevSlide);
  };
  //#endregion

  //#region 切換swiper時，就更新選中的方案
  const updateSelectedPlan = (slideIndex) => {
    if (plans.length > 0) {
      console.log(plans[slideIndex]);
      setSelectedPlan(plans[slideIndex]);
    }
  };
  //#endregion

  // 在重新整理時清空 sessionStorage
  //   useEffect(() => {
  //     const handleBeforeUnload = () => {
  //       localStorage.removeItem("selectedPlan");
  //       localStorage.removeItem("currentSlide");
  //       localStorage.removeItem("content");
  //       localStorage.removeItem("serviceYear");
  //       localStorage.removeItem("serviceMonth");
  //       localStorage.removeItem("serviceDate");
  //       localStorage.removeItem("serviceDay");
  //       localStorage.removeItem("serviceStartTimeByDay");
  //       localStorage.removeItem("serviceStartTimeByHour");
  //       localStorage.removeItem("dates");
  //     };

  //     window.addEventListener("beforeunload", handleBeforeUnload);

  //     return () => {
  //       isUnmounted.current = true; // 標記頁面正在卸載
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //     };
  //   }, []);
  //#endregion

  useEffect(() => {
    localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
    localStorage.setItem("currentSlide", currentSlide);
    localStorage.setItem("content", content);
    localStorage.setItem("serviceYear", serviceYear);
    localStorage.setItem("serviceMonth", serviceMonth);
    localStorage.setItem("serviceDate", serviceDate);
    localStorage.setItem("serviceDay", serviceDay);
    localStorage.setItem(
      "serviceStartTimeByDay",
      serviceStartTimeByDay.toISOString()
    );
    localStorage.setItem(
      "serviceStartTimeByHour",
      serviceStartTimeByHour.toISOString()
    );
    localStorage.setItem(
      "dates",
      JSON.stringify(dates.map((date) => format(new Date(date), "yyyy-MM-dd")))
    );
  }, [
    selectedPlan,
    currentSlide,
    content,
    serviceYear,
    serviceMonth,
    serviceDate,
    serviceStartTimeByDay,
    serviceStartTimeByHour,
    dates,
    serviceDay,
  ]);

  //#region 計算天數小時
  useEffect(() => {
    if (selectedPlan && selectedPlan.unit === "天") {
      let start;
      // let timeRange;
      let doubleTime;
      if (content === "A") {
        // Case 1: 越快越好
        start = new Date(); // 從今天開始
        const startFormatDayjs = dayjs(start);
        const startMin = startFormatDayjs.minute();
        console.log(startMin);
        // 越快越好模式是 先進到整點或半再加上半分鐘，也就是0.5+0.5
        if (startMin <= 30 && startMin > 0) {
          doubleTime = startFormatDayjs.hour() + 0.5 + 0.5;
        } else if (startMin > 30 && startMin <= 59) {
          doubleTime = startFormatDayjs.hour() + 1 + 0.5;
        } else if (startMin === 0) {
          doubleTime = startFormatDayjs.hour() + 0.5;
        }

        // timeRange = "00:00-24:00"; // 全天可用
      } else if (content === "B") {
        // Case 2: 預約時間

        start = new Date(serviceStartTimeByDay); // 從選擇的開始日期開始

        // console.log(serviceStartTimeByDay);
        const startTime = formatTimeToHHmm(serviceStartTimeByDay); // 格式化開始時間
        // const endTime = new Date(serviceStartTimeByDay); // 複製開始時間
        // endTime.setDate(endTime.getDate() + 1); // 增加一天
        // const formattedEndTime = formatTimeToHHmm(endTime); // 格式化結束時間
        // timeRange = `${startTime}-${formattedEndTime}`; // 時間範圍為開始時間到結束時間

        console.log(start);
        doubleTime = convertTimeToDouble(start);
        console.log(doubleTime);
      }
      console.log("serviceDay", serviceDay);

      const newDates = [];
      for (let i = 0; i < serviceDay; i++) {
        const date = new Date(start);
        date.setDate(start.getDate() + i);

        console.log(date);
        newDates.push(dayjs(date).format("YYYY/MM/DD")); // 格式化為YYYY/MM/DD並添加到陣列中
      }

      let behaviorMode = 0; //越快越好
      if (content !== "A") {
        //預約時間
        behaviorMode = 1;
      }
      const formattedDates = newDates.map((date) =>
        dayjs(date).format("YYYY/MM/DD")
      );
      console.log(formattedDates);
      onChangePlan(selectedPlan.id, behaviorMode, formattedDates, doubleTime);
    }
  }, [selectedPlan, serviceDay, serviceStartTimeByDay, content]);

  useEffect(() => {
    //判斷是否選中的日期有今天，如果是最小時間只能是當前時間進0.5+4hr
    const datesAreToday = dates.some((date) => isToday(date));
    if (datesAreToday) {
      const validStartTime = getValidStartTime();
      const serviceStartTimeByHourFormate = dayjs(
        serviceStartTimeByHour
      ).format("YYYY-MM-DD HH:mm");
      const validStartTimeFormat =
        dayjs(validStartTime).format("YYYY-MM-DD HH:mm");
      const isOverStartServiceTime = dayjs(
        serviceStartTimeByHourFormate
      ).isBefore(dayjs(validStartTimeFormat));
      console.log(isOverStartServiceTime);
      if (isOverStartServiceTime) {
        setServiceStartTimeByHour(validStartTime);
      }
    }

    //只保留今天之後的日期
    const today = startOfToday();
    const filteredDates = dates.filter(
      (date) => !isBefore(new Date(date), today)
    );

    if (filteredDates.length !== dates.length) {
      setDates(filteredDates);
      localStorage.setItem("dates", JSON.stringify(filteredDates));
    }

    if (selectedPlan && selectedPlan.unit === "小時") {
      let doubleTime;
      const start = new Date(serviceStartTimeByHour);
      doubleTime = convertTimeToDouble(start);
      const formattedDates = dates.map((date) =>
        format(new Date(date), "yyyy-MM-dd")
      );
      if (
        JSON.stringify(formattedDates) !==
          JSON.stringify(createCareCase.serviceDates) ||
        doubleTime !== createCareCase.serviceTime
      ) {
        const newFormattedDates = formattedDates.map((date) =>
          dayjs(date).format("YYYY/MM/DD")
        );
        onChangePlan(selectedPlan.id, 1, newFormattedDates, doubleTime);
      }
    }
  }, [
    dates,
    createCareCase.serviceDates,
    createCareCase.serviceTime,
    selectedPlan,
    serviceStartTimeByHour,
  ]);

  //   #region 處理選擇日期
  const handleSelectDates = (value) => {
    const today = new Date();
    const tomorrow = format(today.setDate(today.getDate() + 1), "yyyy-MM-dd");
    const formattedDates = value
      .map((date) => format(new Date(date), "yyyy-MM-dd"))
      .sort();
    const hasTomorrow = formattedDates.some((date) => date === tomorrow);
    // const
    if (hasTomorrow) {
      const todayHour = today.getHours();
      const validateServiceTime = getValidStartTime();
      if (todayHour < 24 && todayHour > 20) {
        setServiceStartTimeByHour(validateServiceTime);
      }
    }
    // console.log(formattedDates);
    setDates(formattedDates);
  };
  //   #endgion

  return (
    <div className="content">
      <div className="applyHead">
        <h1>找照服員</h1>
        <div>
          <div className="active">
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.14103 23.0686V18.7213H0.277778V14.1832L10.7829 0.921875H14.2943V14.1499H17.1325V18.7165H14.2943V23.0638H9.13618L9.14103 23.0686ZM5.36809 14.1546H9.75128V8.53929L5.36809 14.1546Z"
                fill="white"
              />
            </svg>
          </div>
          <div>
            <svg
              width="17"
              height="24"
              viewBox="0 0 17 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.84729 23.4213C6.19088 23.4213 4.69914 23.0876 3.36723 22.4203C2.03532 21.7529 1.01339 20.8377 0.306267 19.6746L3.1735 16.5332C3.79829 17.2959 4.51994 17.8918 5.34815 18.3256C6.17635 18.7593 7.01908 18.9738 7.87635 18.9738C8.53988 18.9738 9.12108 18.8404 9.6151 18.5782C10.1091 18.316 10.4966 17.949 10.7775 17.4866C11.0584 17.0194 11.1989 16.4713 11.1989 15.8373C11.1989 15.2462 11.0681 14.7171 10.8114 14.2499C10.5547 13.7828 10.2011 13.43 9.74586 13.1869C9.29544 12.9438 8.76752 12.8246 8.16695 12.8246C7.56638 12.8246 7.0433 12.9533 6.5396 13.206C6.03589 13.4586 5.64359 13.8209 5.36268 14.2833H0.882621L3.55612 0.578613H14.933V5.20721H7.29515L6.65099 9.04452C6.78176 8.89675 7.03362 8.76328 7.40655 8.64887C7.78433 8.53447 8.28319 8.4725 8.90313 8.4725C10.3852 8.4725 11.6977 8.77758 12.8359 9.3925C13.9741 10.0074 14.8652 10.8607 15.5094 11.9618C16.1536 13.063 16.4781 14.3214 16.4781 15.7372C16.4781 17.2578 16.1051 18.5973 15.3641 19.7508C14.6231 20.9044 13.606 21.8006 12.3177 22.4489C11.0293 23.0924 9.53276 23.4165 7.83761 23.4165L7.84729 23.4213Z"
                fill="#9E3223"
              />
            </svg>
          </div>
          <div>
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9977 19.6127C15.2567 20.7997 14.2444 21.7435 12.9707 22.4538C11.692 23.164 10.2584 23.5168 8.6698 23.5168C6.88747 23.5168 5.36183 23.0925 4.09289 22.2488C2.82394 21.4051 1.85527 20.18 1.17721 18.5831C0.499145 16.9862 0.160126 15.0652 0.160126 12.8247C0.160126 10.203 0.523364 7.9721 1.2547 6.1321C1.98604 4.2921 3.02252 2.89065 4.36411 1.92775C5.70571 0.96485 7.31369 0.483398 9.1832 0.483398C10.6023 0.483398 11.9245 0.774176 13.1499 1.35573C14.3752 1.93728 15.4359 2.75718 16.3416 3.81542L13.4405 7.0521C12.9029 6.37521 12.2587 5.84609 11.508 5.46475C10.7573 5.0834 9.94845 4.89272 9.09118 4.89272C8.23392 4.89272 7.55584 5.09293 6.93106 5.49335C6.30627 5.89376 5.82194 6.47531 5.48291 7.23801C5.13904 8.0007 4.9453 8.9207 4.90171 9.99801C5.202 9.59759 5.55556 9.26392 5.96724 8.99697C6.37408 8.7348 6.84872 8.52982 7.38633 8.39635C7.92393 8.25811 8.50513 8.19138 9.12507 8.19138C10.6507 8.19138 12.0165 8.52029 13.2177 9.17335C14.4188 9.83117 15.3729 10.713 16.0704 11.8237C16.7678 12.9344 17.1165 14.1976 17.1165 15.6133C17.1165 17.0291 16.7436 18.4258 16.0026 19.608L15.9977 19.6127ZM10.4231 18.7404C10.951 18.4115 11.3772 17.9729 11.7114 17.4247C12.0456 16.8766 12.2103 16.2712 12.2103 15.6181C12.2103 14.9651 12.0504 14.4073 11.7259 13.8877C11.4014 13.3682 10.9752 12.9582 10.4376 12.6531C9.90001 12.3481 9.30913 12.1955 8.66497 12.1955C8.02081 12.1955 7.42992 12.3481 6.89232 12.6531C6.35471 12.9582 5.92365 13.3729 5.604 13.8877C5.27949 14.4073 5.11967 14.9937 5.11967 15.6467C5.11967 16.2998 5.28434 16.8718 5.61853 17.4247C5.95271 17.9729 6.38861 18.4115 6.92137 18.7404C7.45898 19.0693 8.04018 19.2314 8.66012 19.2314C9.28006 19.2314 9.89032 19.0693 10.4182 18.7404H10.4231Z"
                fill="#9E3223"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[2.5rem] mb-[10rem]">
        <h1 className="text-primary text-[2rem] font-bold text-center leading-[1.4]">
          方案選擇
        </h1>
        <div
          id="Swiper"
          className="w-full swiper bg-primary rounded-[1.25rem] overflow-hidden"
        >
          <div className="swiper-button relative">
            <button
              className="w-[48px] h-full flex items-start justify-end pt-[3.25rem] pr-[0.75rem] absolute left-0 z-[3] swiper-button-prev"
              style={{ top: "45px" }}
              title="swiper prev"
              onClick={handlePrevSlide}
            >
              <svg
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              className="w-[48px] h-full flex items-end justify-start pb-[3.25rem] pl-[0.75rem] absolute right-0 z-[3] swiper-button-next"
              style={{ top: "125px" }}
              title="swiper next"
              onClick={handleNextSlide}
            >
              <svg
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div
            className="swiper-wrapper"
            style={{
              display: "flex",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentSlide * 100}%)`,
            }}
          >
            {plans.map((plan, index) => (
              <div
                key={index}
                className="swiper-slide w-full flex-shrink-0"
                style={{ flexBasis: "100%" }}
              >
                <div className="w-full h-[10.125rem] flex flex-col items-center justify-center pt-[2.25rem] pb-[1.6875rem] px-[3rem] bg-primary rounded-[1.25rem]">
                  <strong className="w-full pb-[1.875rem] border-b border-white text-white text-[1.5rem] leading-[1.5] text-center">
                    {plan.name}
                  </strong>
                  <span className="pt-[0.4375rem] text-white text-[1.25rem] leading-[1.5] text-center">
                    ${plan.fee.toLocaleString()} / {plan.unit}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedPlan && selectedPlan.unit === "天" ? (
          <>
            <div className="flex justify-between gap-[0.75rem]">
              <button
                className="flex-1 py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] text-center"
                title="越快越好"
                onClick={() => setContent("A")}
              >
                越快越好
              </button>
              <button
                type="submit"
                className="flex-1 py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] text-center"
                title="預約時間"
                onClick={() => setContent("B")}
              >
                預約時間
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="mt-[1.25rem]">
          <form
            id="form01"
            className="SwiperForm flex flex-col item gap-[2.1875rem]"
            style={{
              display:
                selectedPlan && selectedPlan.unit === "天" ? "flex" : "none",
            }}
          >
            {selectedPlan && selectedPlan.unit === "天" ? (
              <>
                <div
                  className="w-full max-w-[15.625rem] flex flex-col gap-[0.9375rem] mx-auto"
                  style={{ marginTop: "-20px" }}
                >
                  <strong className="text-primary text-[2rem] font-bold text-center">
                    服務天數
                  </strong>
                  <div className="flex items-stretch justify-between">
                    <button
                      id="lessDayButton"
                      type="button"
                      className="w-[1.875rem] flex items-center justify-center"
                      title="less day button"
                      onClick={(e) => handleServiceStartDay("sub")}
                    >
                      <svg
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                          fill="#9E3223"
                        />
                      </svg>
                    </button>
                    <div className="flex items-center gap-[0.875rem]">
                      <input
                        id="day"
                        type="number"
                        min="1"
                        max="100"
                        value={serviceDay}
                        className="w-[6.25rem] min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-[1.25rem] text-center leading-[1.4] placeholder:text-[rgba(0,0,0,0.3)]"
                        name="day"
                        onChange={(e) => handleChangeServiceDay(e)}
                      />
                      <label
                        className="text-primary text-[1.25rem] leading-[1.4]"
                        htmlFor="day"
                      >
                        天
                      </label>
                    </div>
                    <button
                      id="AddDayButton"
                      type="button"
                      className="w-[1.875rem] flex items-center justify-center"
                      title="add day button"
                      onClick={(e) => handleServiceStartDay("add")}
                    >
                      <svg
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                          fill="#9E3223"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-[0.9375rem]">
                  {content === "A" && <></>}
                  {content === "B" && (
                    <>
                      <strong className="text-primary text-[2rem] font-bold text-center">
                        開始日期*
                      </strong>
                      <div className="grid grid-cols-3 gap-[1rem]">
                        <div className="flex items-center gap-[0.375rem]">
                          <select
                            name="serviceYear"
                            value={serviceYear}
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            onChange={(e) => {
                              if (e.target.value) {
                                setServiceYear(Number(e.target.value));
                                setServiceStartTimeByDay(
                                  new Date(
                                    Number(e.target.value),
                                    Number(serviceMonth) - 1,
                                    Number(serviceDate)
                                  )
                                );
                              }
                            }}
                          >
                            {yearOptions}
                          </select>
                          <label
                            className="text-primary text-[1.25rem] leading-[1.4]"
                            htmlFor="year"
                          >
                            年
                          </label>
                        </div>
                        <div className="flex items-center gap-[0.375rem]">
                          <select
                            name="serviceMonth"
                            value={serviceMonth}
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            onChange={(e) => {
                              if (e.target.value) {
                                setServiceMonth(Number(e.target.value));
                                setServiceStartTimeByDay(
                                  new Date(
                                    Number(serviceYear),
                                    Number(e.target.value) - 1,
                                    Number(serviceDate)
                                  )
                                );
                              }
                            }}
                          >
                            {monthOptions}
                          </select>
                          <label
                            className="text-primary text-[1.25rem] leading-[1.4]"
                            htmlFor="month"
                          >
                            月
                          </label>
                        </div>
                        <div className="flex items-center gap-[0.375rem]">
                          <select
                            name="serviceDate"
                            value={serviceDate}
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            onChange={(e) => {
                              if (e.target.value) {
                                setServiceDate(Number(e.target.value));
                                setServiceStartTimeByDay(
                                  new Date(
                                    Number(serviceYear),
                                    Number(serviceMonth) - 1,
                                    Number(e.target.value)
                                  )
                                );
                              }
                            }}
                          >
                            {dayOptions}
                          </select>
                          <label
                            className="text-primary text-[1.25rem] leading-[1.4]"
                            htmlFor="date"
                          >
                            日
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-full max-w-[15.625rem] flex flex-col gap-[0.9375rem] mx-auto">
                  {content === "A" && <></>}
                  {content === "B" && (
                    <>
                      <strong className="text-primary text-[2rem] font-bold text-center">
                        開始時間*
                      </strong>
                      <div className="flex items-stretch justify-between">
                        <button
                          id="lessTimeButton"
                          type="button"
                          className="w-[1.875rem] flex items-center justify-center"
                          title="less time button"
                          onClick={(e) => handleServiceStartTimeByDay("sub")}
                        >
                          <svg
                            width="16"
                            height="22"
                            viewBox="0 0 16 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                              fill="#9E3223"
                            />
                          </svg>
                        </button>
                        <div className="flex items-center gap-[0.875rem]">
                          <input
                            id="time"
                            type="text"
                            data-min="06:00"
                            data-max="20:00"
                            data-step="30"
                            className="w-[9.375rem] min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-[1.25rem] text-center leading-[1.4] placeholder:text-[rgba(0,0,0,0.3)]"
                            name="time"
                            readOnly
                            aria-label="開始時間"
                            value={formatTimeToHHmm(serviceStartTimeByDay)}
                          />
                        </div>
                        <button
                          id="AddTimeButton"
                          type="button"
                          className="w-[1.875rem] flex items-center justify-center"
                          title="add time button"
                          onClick={(e) => handleServiceStartTimeByDay("add")}
                        >
                          <svg
                            width="16"
                            height="22"
                            viewBox="0 0 16 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                              fill="#9E3223"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex items-center justify-end gap-[0.75rem]">
                  <button
                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="上一步"
                    onClick={onPreviousStep}
                  >
                    上一步
                  </button>
                  <button
                    type="submit"
                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="下一步"
                    onClick={handleSubmit}
                  >
                    下一步
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
          <form
            id="form02"
            className="SwiperForm flex flex-col item gap-[2.1875rem]"
            style={{
              display:
                selectedPlan && selectedPlan.unit === "小時" ? "flex" : "none",
            }}
          >
            {selectedPlan && selectedPlan.unit === "小時" ? (
              <>
                <div className="w-full max-w-[15.625rem] flex flex-col gap-[0.9375rem] mx-auto">
                  <>
                    <strong className="text-primary text-[2rem] font-bold text-center">
                      服務開始時間
                    </strong>
                    <div className="flex items-stretch justify-between">
                      <button
                        id="lessTimeButton"
                        type="button"
                        className="w-[1.875rem] flex items-center justify-center"
                        title="less time button"
                        onClick={(e) => handleServiceStartTimeByHour("sub")}
                      >
                        <svg
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                            fill="#9E3223"
                          />
                        </svg>
                      </button>
                      <div className="flex items-center gap-[0.875rem]">
                        <input
                          id="time"
                          type="text"
                          data-min="06:00"
                          data-max="20:00"
                          data-step="30"
                          className="w-[9.375rem] min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-[1.25rem] text-center leading-[1.4] placeholder:text-[rgba(0,0,0,0.3)]"
                          name="time"
                          readOnly
                          aria-label="服務開始時間"
                          value={formatTimeToHHmm(serviceStartTimeByHour)}
                        />
                      </div>
                      <button
                        id="AddTimeButton"
                        type="button"
                        className="w-[1.875rem] flex items-center justify-center"
                        title="add time button"
                        onClick={(e) => handleServiceStartTimeByHour("add")}
                      >
                        <svg
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                            fill="#9E3223"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                </div>
                <div className="flex flex-col gap-[1.5rem]">
                  <div className="flex items-center justify-center gap-[0.5rem] relative container-service-date">
                    <strong className="text-primary text-[2rem] font-bold text-center">
                      服務日期(可複選)*
                    </strong>
                    <div className="relative inline-block">
                      <div
                        ref={overlayRef}
                        onClick={handleOverlayClick}
                        className="absolute datepicker-overlay"
                      >
                        <input
                          ref={inputRef}
                          id="serviceDatepickerButton"
                          className="size-[1.875rem] bg-contain bg-no-repeat bg-center text-[0rem] cursor-pointer"
                          type="button"
                          aria-label="服務日期"
                          onClick={handleInputClick}
                        />
                      </div>
                      <div
                        id="datepicker"
                        className="absolute"
                        style={{ margin: "0px 0px -25px 0px", bottom: "4px" }}
                      >
                        <DatePicker
                          ref={datepickerRef}
                          multiple
                          value={dates}
                          onChange={(value) => {
                            handleSelectDates(value);
                          }}
                          className="brown"
                          style={{
                            cursor: "pointer",
                            width: "35px",
                            height: "35px",
                            color: "rgba(255, 255, 255, 0)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            border: "none",
                            outline: "none",
                            borderRadius: "5px",
                            padding: "10px",
                            fontSize: "18px",
                          }}
                          weekDays={weekDays}
                          months={months}
                          minDate={limitDate}

                          //   mapDays={({ date }) => {
                          //     const today = new Date().setHours(0, 0, 0, 0); // 取得今天的日期，並將時間設為 00:00:00
                          //     if (date < today) {
                          //       return {
                          //         disabled: true,
                          //         style: { color: "#ccc" },
                          //         onClick: () =>
                          //           showToast("error", "無法選擇今天之前的日期"),
                          //       };
                          //     }
                          //     return {};
                          //   }}
                        />
                      </div>
                    </div>
                    <style>
                      {`
                                                    #serviceDatepickerButton {
                                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" fill="%239e3223" /></svg>');
                                                    }
                                                `}
                    </style>
                  </div>
                  <div
                    id="serviceDatepickerContainer"
                    className="w-full min-h-[10.125rem] flex flex-col items-center justify-center pt-[2.25rem] pb-[1.6875rem] px-[3rem] bg-primary rounded-[1.25rem]"
                  >
                    {/* <input
                      id="serviceDatepickerInput"
                      type="hidden"
                      name="dates"
                      //   value={dates.join(",")}
                    /> */}
                    <ul className="flex flex-col gap-0.5rem list-decimal list-inside text-white text-[1.25rem]">
                      {dates.length === 0 ? (
                        <p>尚未選擇天數</p>
                      ) : (
                        dates.map((date, index) => <li key={index}>{date}</li>)
                      )}
                    </ul>
                    <div className="w-[80%] flex justify-center mt-[0.9375rem] p-[0.9375rem] border-t border-white text-white text-[1.25rem] text-center tracking-[0.3125rem]">
                      共{dates.length}天
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-[0.75rem]">
                  <button
                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="上一步"
                    onClick={onPreviousStep}
                  >
                    上一步
                  </button>
                  <button
                    type="submit"
                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="下一步"
                    onClick={handleSubmit}
                  >
                    下一步
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
