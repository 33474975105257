import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNurseAides from "../../layouts/HeaderNurseAides";
import ProjectListChooseSuccess from "../../components/ProjectList/ProjectListChooseSuccess";
import ProjectListFamilyChoose from "../../components/ProjectList/ProjectListFamilyChoose";
import Header from "../../layouts/Header";

/**
 * 選擇身分
 * @returns
 */
const ProjectListFamilyChoosePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <ProjectListFamilyChoose />
      </div>
    </>
  );
};

export default ProjectListFamilyChoosePage;
