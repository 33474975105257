import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import HamburgerModal from "../HamburgerModal";
import ReactPlayer from 'react-player';
import {
    apiGetCareVideoDetail,
    apiGetAuthEncrypto
} from '../../utils/Api';

/**
 * 證照管理(照服員)
 * 申請照服員-上傳身分證、證書(step2)
 */
const CareVideo = () => {
  const { videosId } = useParams();
  const [id, setgetId] = useState(videosId);
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [mediaTemplate, setMediaTemplate] = useState({
    id: 0,
    title: "",
    videoURL: "",
    outline:"",
    fileName: "",
    videoPath: "",
});
const [mediaData, setMediaData] = useState("");
const [fileName, setFileName] = useState("");

    //#region 初始載入
    useEffect(() => {
        const fetchData = async () => {
            //const id = 1;
            if (id > 0) {
                var params = {
                    id: id
                };
                let apiGetOnlineCourseVideoDetailResponse = await apiGetCareVideoDetail(params);
                console.log("A")
                console.log(apiGetOnlineCourseVideoDetailResponse)
                if (apiGetOnlineCourseVideoDetailResponse && apiGetOnlineCourseVideoDetailResponse.code == 0) {
                    setMediaTemplate(apiGetOnlineCourseVideoDetailResponse.data);
                    console.log("mediaTemplate",mediaTemplate)

                    if (apiGetOnlineCourseVideoDetailResponse.data.videoPath != "" && apiGetOnlineCourseVideoDetailResponse.data.videoPath != undefined) {
                        downloadFile(apiGetOnlineCourseVideoDetailResponse.data.videoPath)
                        let newFileName = apiGetOnlineCourseVideoDetailResponse.data.videoPath.split("\\");
                        setFileName(newFileName[newFileName.length - 1]);
                    }

                } else {
                    showToast('error', apiGetOnlineCourseVideoDetailResponse ? apiGetOnlineCourseVideoDetailResponse.message : t("toast.unknownError"));
                }
            }
        };
        fetchData();
    }, []);
    //#endregion
  //#endregion
  const downloadFile = async (parameter) => {
    var filePath = parameter
    if (parameter == undefined)
        return
    if (mediaTemplate.videoPath != "") {
        filePath = mediaTemplate.videoPath
    }
    let apiGetAuthEncryptoResponse = await apiGetAuthEncrypto();
    if (apiGetAuthEncryptoResponse && apiGetAuthEncryptoResponse.code == 0) {
        let authString = apiGetAuthEncryptoResponse.data;
        console.log(encodeURI(authString.split("&")[0]));
        console.log(encodeURI(authString.split("&")[1]));
        console.log(filePath)
        console.log(`${window.apiUrl}/api/File/StreamVideo/${encodeURI(filePath)}?${encodeURI(authString.split("&")[0])}&${encodeURI(authString.split("&")[1])}`);
        setMediaData(`${window.apiUrl}/api/File/StreamVideo/${encodeURI(filePath)}?${encodeURI(authString.split("&")[0])}&${encodeURI(authString.split("&")[1])}`);
    }
}
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <>
      <div className="content">
        <div className="applyHead">
          <h1>照顧影音</h1>
          <span
            className="flex-none cursor-pointer"
            title="Sort Button"
            onClick={handleShowHamburgerModal}
          >
            <svg
              width="28"
              height="23"
              viewBox="0 0 28 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_87_2023)">
                <path
                  d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                  fill="#9E3223"
                />
                <path
                  d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                  fill="#9E3223"
                />
                <path
                  d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                  fill="#9E3223"
                />
              </g>
              <defs>
                <clipPath id="clip0_87_2023">
                  <rect width="28" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
        <form
          className="flex flex-col gap-[2.5rem] mb-[10rem]"
        >
          <div className="col-6">
                        {
                            (mediaTemplate && mediaTemplate.videoPath != "") || mediaData != null ?
                                <ReactPlayer
                                    url={mediaData}
                                    width={"100%"}
                                    controls={true}
                                />
                                :
                                <div className="col-6">
                                    沒有影片
                                </div>
                        }
                    </div>
        </form>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </>
  );
};

export default CareVideo;
