import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import SvgNurseAides from './SvgNurseAides';
import SvgFamily from './SvgFamily';

/**
 * 資料顯示頁面
 */
const InfoPanel = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate(); //跳轉Router

  //#region 初始載入
  useEffect(() => {
    // const fetchData = async () => {
    //   var personnelType = localStorage.getItem("PersonnelType");
    //   switch (personnelType) {
    //     case "Family":
    //       navigate("/FindCareStep");
    //       break;
    //     case "NurseAides":
    //       navigate("/NurseAides");
    //       break;
    //     default:
    //       localStorage.removeItem("PersonnelType");
    //   }
    // };
    // fetchData();
    // return () => { };
  }, []);
  //#endregion

  return (
    <>
      <div className="content md:justify-center">
        <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">
          選擇身分
        </h1>
        <div className="flex flex-col md:flex-row items-center gap-[1.875rem] my-[3.125rem]">
          <SvgNurseAides />
          <SvgFamily />
        </div>
        <style>
          {`
            @media (min-width: 1024px) {
                .content {
                    --max-width: 50rem;
                }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default InfoPanel;
