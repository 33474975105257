import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { apiGetAllSecondReason } from "../../utils/Api";

/**
 * 照服員接受案件-可服務方式(step1)
 */
const CancelReqStep2 = ({
  onPreviousStep,
  step2DataCallback,
  firstReason,
  isRemarke,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [secondReasons, setSecondReasons] = useState([
    {
      id: 0,
      name: "",
      isRemark: "",
    },
  ]);
  const [selectedRes, setSelectedRes] = useState(0); //選擇的模式
  const [remark, setRemark] = useState("");

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      if (firstReason > 0) {
        const param = { cancelReasonId: firstReason };
        const getAllSecondReason = await apiGetAllSecondReason(param);
        if (getAllSecondReason && getAllSecondReason.code === 0) {
          setSecondReasons(getAllSecondReason.data.result);
        } else {
          showToast(
            "error",
            getAllSecondReason ? getAllSecondReason.message : "網路連線錯誤"
          );
        }
      }
    };
    fetchData();
    return () => {};
  }, [firstReason]);
  //#endregion

  //#region 選擇取消原因
  const handleSelectCancelRes = (e) => {
    const { name, value } = e.target;
    var tmpValue = parseInt(value);

    setSelectedRes(tmpValue);
  };
  //#endregion

  //#region 送出
  const handleSubmit = (e) => {
    if (selectedRes !== 0 && isRemarke === false) {
      step2DataCallback(selectedRes, remark);
    } else if (isRemarke === true && remark !== "") {
      step2DataCallback(selectedRes, remark);
    } else {
      e.preventDefault();
      showToast("error", "請選擇或填寫取消原因");
    }
  };
  //#endregion

  return (
    <div className="modal-body accept" style={{ width: "100%" }}>
      <div
        className="bottomFormSection"
        style={{ height: "33.3333%", width: "100%", alignItems: "center" }}
      >
        <strong className="title">
          {isRemarke ? "請填寫取消原因" : "請選擇取消原因"}
        </strong>
        <strong
          className="text-center"
          style={{ color: "var(--color-primary)" }}
        >
          *僅提供平台，服務員不會收到此消息
        </strong>
        {isRemarke ? (
          <>
            <div className="w-full flex flex-col items-center gap-[1.625rem] mt-[1rem]">
              <textarea
                className="w-full p-[1rem] bg-white border-[0.125rem] border-primary text-primary text-base placeholder:text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="remark"
                id="remark"
                maxLength="500"
                cols="30"
                rows="6"
                placeholder="感謝您寶貴的意見(0/500)"
                aria-label="其他取消原因填寫"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              ></textarea>
            </div>
          </>
        ) : (
          <div className="w-[100%]  flex items-center justify-between gap-[0.75rem] mt-[2.25rem] flex-wrap ">
            {secondReasons &&
              secondReasons.map((reason) => {
                return (
                  <div
                    key={reason.id}
                    style={{ width: "34%" }}
                    className="w-[100%] min-w-[7rem] flex items-center justify-center "
                  >
                    <input
                      type="checkbox"
                      className="hidden peer "
                      name="secondReason"
                      id={`reason-${reason.id}`}
                      value={reason.id}
                      checked={selectedRes === reason.id}
                      onChange={handleSelectCancelRes}
                    />
                    <label
                      htmlFor={`reason-${reason.id}`}
                      className=" min-h-[5rem] min-w-[7rem] rounded-2xl block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] text-white text-[1.5rem] flex  items-center font-bold leading-[1.5] peer-checked:bg-primary"
                    >
                      {reason.name}
                    </label>
                  </div>
                );
              })}

            {/* <div
              style={{ width: "34%" }}
              className="rounded-2xl overflow-hidden min-w-[9rem]"
            >
              <input
                type="checkbox"
                className="hidden peer w-2/4"
                name="date"
                id="day-model-1"
                value="1"
                checked={selectedRes === 1}
                onChange={handleSelectCancelRes}
              />
              <label
                htmlFor="day-model-1"
                className="block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)]  text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
              >
                我只配
              </label>
            </div> */}
          </div>
        )}

        <div className="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] pb-[5.25rem]">
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="上一步"
            onClick={onPreviousStep}
          >
            上一步
          </button>
          <button
            type="button"
            className="w-[7.55rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            確認取消
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelReqStep2;
