import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; //语系
import { Modal, Button, Form } from "react-bootstrap";
import { useToast } from "../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import "./../../bootstrap.css";
import ConfirmStep1 from "./ConfirmStep1";
import ConfirmStep2 from "./ConfirmStep2";
import ConfirmStep3 from "./ConfirmStep3";
import ConfirmStep4 from "./ConfirmStep4";
import { apiNurseAidesCareCasePairSubmit } from "../../utils/Api";

/**
 * 按下接受跳出的視窗
 * @param {*} param0
 * @returns
 */
const DetailModal = ({
  showDetailModal,
  handleDetailClose,
  availableCaseDetail,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [confirmData, setConfirmData] = useState({
    caseNo: "",
    selectedDayModel: -1, //服務方式
    serviceDay: 1, //預估可服務天數
    selectedDays: [], //選擇的可服務日
    expectedPrice: 0, //期望金額
    expectedPriceStatus: 0, //選擇是否調整金額
  });

  const isResponseRef = useRef(true);

  //#region 初始載入
  useEffect(() => {
    console.log("為了看值", availableCaseDetail);
    setConfirmData({ ...confirmData, caseNo: availableCaseDetail.careCaseNo });
  }, [availableCaseDetail]);
  //#endregion

  //#region 下一頁
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  //#endregion

  useEffect(() => {
    console.log(currentStep);
  }, [currentStep]);

  //#region 上一頁
  const handlePreviousStep = (e) => {
    e.preventDefault();
    if (currentStep - 1 === 0) {
      //第1步-取消
      setConfirmData({
        selectedDayModel: -1, //服務方式
        serviceDay: 1, //預估可服務天數
        selectedDays: [], //選擇的可服務日
        expectedPrice: 0, //期望金額
      });
      setCurrentStep(1);
      handleDetailClose();
    } else if (currentStep === 3 && confirmData.selectedDayModel === 0) {
      //當選擇配合家屬，從step3直接跳回step1
      setCurrentStep(currentStep - 2);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };
  //#endregion

  //#region 步驟1回傳的資料
  const handleChangeConfirmDataStep1Callback = (
    selectedDayModel,
    serviceDay
  ) => {
    setConfirmData({
      ...confirmData,
      selectedDayModel: selectedDayModel,
      serviceDay: serviceDay,
    });
  };
  //#endregion

  //#region 步驟2回傳的資料
  const handleChangeConfirmDataStep2Callback = (selectedDays) => {
    setConfirmData({
      ...confirmData,
      selectedDays: selectedDays,
    });
  };
  //#endregion

  //#region 步驟3回傳的資料
  const handleChangeConfirmDataStep3Callback = (selectionState) => {
    setConfirmData({
      ...confirmData,
      expectedPrice: selectionState.expectedPrice,
      expectedPriceStatus: selectionState.expectedPriceStatus,
    });
  };
  //#endregion
  useEffect(() => {
    console.log(confirmData);
  }, [confirmData]);

  //#region 送出
  const handleSubmit = async (e) => {
    //檢查資料內容是否有誤
    if (checkValidator() && isResponseRef.current) {
      console.log("送出");
      isResponseRef.current = false;
      const param = {
        caseNo: confirmData.caseNo,
        serviceDates: confirmData.selectedDays,
        expectedPrice: confirmData.expectedPrice,
        expectedPriceStatus: confirmData.expectedPriceStatus,
      };

      const response = await apiNurseAidesCareCasePairSubmit(param);
      if (response && response.code === 0) {
        console.log("送出回應");
        isResponseRef.current = true;
        handleDetailClose();
        showToast("success", "申請成功");
        navigate("/NurseAides");
      } else {
        showToast(
          "error",
          response ? response.message : t("toast.unknownError")
        ); // [回傳的訊息] : 網路連線錯誤
      }
    }
  };
  //#endregion

  //#region 欄位驗證
  const checkValidator = async () => {
    let result = true;
    //檢查step1.
    if (confirmData.selectedDayModel > 1 || confirmData.selectedDayModel < 0) {
      result = false;
      showToast("error", "服務方式資料錯誤");
    }

    if (confirmData.selectedDayModel == 1) {
      if (confirmData.serviceDay < 0) {
        result = false;
        showToast("error", "服務方式資料錯誤");
      }
    }

    //檢查step2.
    if (confirmData.selectedDays.length == 0) {
      result = false;
      showToast("error", "時段尚未選擇");
    }

    //檢查step3.
    if (confirmData.expectedPrice == 0) {
      result = false;
      showToast("error", "期望金額金額尚未選擇");
    }

    return result;
  };
  //#endregion

  return (
    <Modal
      show={showDetailModal}
      onHide={handleDetailClose}
      className="modal fade ProjectModal"
      id="MenuModal"
      tabIndex="-1"
      aria-labelledby="MenuModalLabel"
      aria-hidden="true"
      backdrop="static"
    >
      <div className="modal-content accept">
        {currentStep === 1 && (
          <ConfirmStep1
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onHandleChangeConfirmDataStep1={
              handleChangeConfirmDataStep1Callback
            }
            confirmData={confirmData}
            availableCaseDetail={availableCaseDetail}
          />
        )}
        {currentStep === 2 && (
          <ConfirmStep2
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onHandleChangeConfirmDataStep2={
              handleChangeConfirmDataStep2Callback
            }
            confirmData={confirmData}
            availableCaseDetail={availableCaseDetail}
          />
        )}
        {currentStep === 3 && (
          <ConfirmStep3
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onHandleChangeConfirmDataStep3={
              handleChangeConfirmDataStep3Callback
            }
            confirmData={confirmData}
            availableCaseDetail={availableCaseDetail}
          />
        )}
        {currentStep === 4 && (
          <ConfirmStep4
            onNextStep={handleSubmit}
            onPreviousStep={handlePreviousStep}
            confirmData={confirmData}
            availableCaseDetail={availableCaseDetail}
          />
        )}
      </div>
    </Modal>
  );
};

export default DetailModal;
