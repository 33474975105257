import React, { createContext, useContext, useState } from "react";
import Toast from "../components/Toast";

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [type, setType] = useState(""); //顯示的icon樣式
  const [message, setMessage] = useState(""); //顯示的訊息
  const [visible, setVisible] = useState(false); //是否顯示Modal

  const showToast = (type, message, duration = 2000) => {
    return new Promise((resolve) => {
      setType(type);
      setMessage(message);
      setVisible(true);

      if (type !== "error") {
        // Optional: Set a timeout to clear the toast after a certain time
        setTimeout(() => {
          setVisible(false);
          resolve(); // 調用 resolve，表示 Toast 顯示完畢
        }, duration);
      } else {
        resolve();
      }
    });
  };

  //   #region 確定關閉
  const handleConfirm = () => {
    console.log("確認關閉");
    setVisible(false);
  };
  // #endregion

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        type={type}
        message={message}
        visible={visible}
        handleConfirm={handleConfirm}
      />
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export { ToastProvider, useToast };
