import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import {
  apiUploadDocument,
  apiGetDocument,
  apiDownloadDocument,
  apiGetCareCertificates,
  apiUpdateCareCertificates,
} from "../../utils/Api";
import HamburgerModal from "../HamburgerModal";
import { useMyUserContext } from "../../contexts/MyUserContext";

/**
 * 證照管理(照服員)
 * 申請照服員-上傳身分證、證書(step2)
 */
const LicenseTracker = () => {
  const { myUser } = useMyUserContext();
  const { t } = useTranslation();
  const tRef = useRef(t("網路連線錯誤"));
  const isEffectCalledRef = useRef(false);
  const { showToast } = useToast();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [backImageUrl, setBackImageUrl] = useState(null);
  const [careCertificateUrl, setCareCertificateUrl] = useState(null);
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [fileDatas, setFileDatas] = useState([
    {
      isDelete: 0,
      fileURL: "",
      fileId: -1,
    },
  ]);

  // #region在頁面跳轉後重新讀取剛剛上傳的資料

  // 空依賴項表示僅在初始載入時調用一次

  // TODO 這是有加useCallback的
  // const refreshLicense = useCallback(async () => {
  //   const getCareCertificatesResponse = await apiGetCareCertificates();
  //   if (getCareCertificatesResponse && getCareCertificatesResponse.code === 0) {
  //     const licenseIds = getCareCertificatesResponse.data.result;
  //     const licenseNames = await Promise.all(
  //       licenseIds.map((id) => apiGetDocument(id))
  //     );
  //     const tempLicenseNames = licenseNames.map((name) => ({
  //       fileName: name.data,
  //     }));
  //     const licenseFiles = await Promise.all(
  //       tempLicenseNames.map((fileName) => apiDownloadDocument(fileName))
  //     );
  //     console.log(licenseFiles);

  //     const isAllFilesHasBlob = licenseFiles.every(
  //       (file) => file.data instanceof Blob
  //     );

  //     if (isAllFilesHasBlob) {
  //       const tempFileDatas = licenseIds.map((id, index) => {
  //         return {
  //           fileId: id.id,
  //           fileURL: URL.createObjectURL(licenseFiles[index].data),
  //         };
  //       });
  //       console.log(tempFileDatas);
  //       setFileDatas([{ fileId: 0, fileURL: "" }, ...tempFileDatas]);
  //     } else {
  //       console.error("apiDownloadDocument 返回的不是有效的 Blob 對象。");
  //     }
  //   } else {
  //     showToast(
  //       "error",
  //       getCareCertificatesResponse
  //         ? getCareCertificatesResponse.message
  //         : tRef.current
  //     );
  //   }
  // }, [showToast]);

  //# region 刷新證照列表
  const refreshLicense = async () => {
    const getCareCertificatesResponse = await apiGetCareCertificates();
    if (getCareCertificatesResponse && getCareCertificatesResponse.code === 0) {
      const licenseIds = getCareCertificatesResponse.data.result;
      console.log(licenseIds);
      const licenseNames = await Promise.all(
        licenseIds.map((id) => apiGetDocument(id))
      );
      const tempLicenseNames = licenseNames.map((name) => ({
        fileName: name.data,
      }));
      const licenseFiles = await Promise.all(
        tempLicenseNames.map((fileName) => apiDownloadDocument(fileName))
      );
      console.log(tempLicenseNames);

      const isAllFilesHasBlob = licenseFiles.every(
        (file) => file.data instanceof Blob
      );

      if (isAllFilesHasBlob) {
        const tempFileDatas = licenseIds.map((id, index) => {
          return {
            isDelete: 0,
            fileId: id.id,
            fileURL: URL.createObjectURL(licenseFiles[index].data),
          };
        });
        console.log(tempFileDatas);
        setFileDatas(tempFileDatas);
      } else {
        console.error("apiDownloadDocument 返回的不是有效的 Blob 對象。");
      }
    } else {
      showToast(
        "error",
        getCareCertificatesResponse
          ? getCareCertificatesResponse.message
          : tRef.current
      );
    }
  };
  //#endregion

  // #region 初始載入 只會做動在一開始
  useEffect(() => {
    if (!isEffectCalledRef.current) {
      isEffectCalledRef.current = true;
      console.log("初始mount");
      refreshLicense();
    }
  });
  //#endregion

  useEffect(() => {
    console.log(fileDatas, myUser);
  }, [fileDatas, myUser]);

  // #region 增加證照
  const handleFileAddChange = async (e) => {
    try {
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          showToast("error", "僅能上傳 jpg、png、jpeg 檔");
          return;
        }
        const formData = new FormData();
        formData.append("Photo", file, file.name);

        const response = await apiUploadDocument(formData);
        const tmplicenses = fileDatas.map((file) => {
          return {
            isDelete: file.isDelete,
            careCertificateId: 0,
            fileId: file.fileId,
          };
        });
        tmplicenses.push({
          isDelete: 0,
          careCertificateId: 0,
          fileId: response.data,
        });
        console.log(tmplicenses);
        const params = {
          updateCareCertificateDatas: tmplicenses,
        };
        const uploadLicensePrsponse = await apiUpdateCareCertificates(params);
        if (uploadLicensePrsponse && uploadLicensePrsponse.code === 0) {
          refreshLicense();
        } else {
          showToast(
            "error",
            uploadLicensePrsponse ? uploadLicensePrsponse.message : tRef.current
          );
        }
      }
    } catch (e) {
      showToast("error", e.message);
    }
  };
  //#endregion

  // #region 處理刪除按鈕的點擊事件
  const handleRemoveFile = async (fileId) => {
    const tmplicenses = fileDatas.map((file) => {
      if (file.fileId === fileId) {
        return {
          isDelete: 1,
          careCertificateId: 0,
          fileId: fileId,
        };
      }
      return file;
    });
    const params = {
      updateCareCertificateDatas: tmplicenses,
    };
    const uploadLicensePrsponse = await apiUpdateCareCertificates(params);
    if (uploadLicensePrsponse && uploadLicensePrsponse.code === 0) {
      refreshLicense();
    } else {
      showToast(
        "error",
        uploadLicensePrsponse ? uploadLicensePrsponse.message : tRef.current
      );
    }
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <>
      <div className="content">
        <div className="applyHead">
          <h1>上傳證照</h1>
          <span
            className="flex-none cursor-pointer"
            title="Sort Button"
            onClick={handleShowHamburgerModal}
          >
            <svg
              width="28"
              height="23"
              viewBox="0 0 28 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_87_2023)">
                <path
                  d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                  fill="#9E3223"
                />
                <path
                  d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                  fill="#9E3223"
                />
                <path
                  d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                  fill="#9E3223"
                />
              </g>
              <defs>
                <clipPath id="clip0_87_2023">
                  <rect width="28" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
        <form className="flex flex-col gap-[2.5rem] mb-[10rem]">
          <div
            className="InspireDropzone"
            id="licenseAdd"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => document.getElementById("uploadFileAdd")?.click()}
          >
            <div>
              <span>點擊上傳證照</span>
              <span>僅能上傳jpg、png、jpeg檔</span>
            </div>
            <input
              type="file"
              id="uploadFileAdd"
              style={{ display: "none" }}
              onChange={(e) => handleFileAddChange(e)}
            />
          </div>
          {fileDatas &&
            fileDatas.map((fileData, index) => {
              return (
                <div
                  key={fileData.fileId}
                  className="InspireDropzone"
                  id={`license-${fileData.fileId}`}
                  style={{
                    overflow: "hidden",
                    position: "relative",
                  }}
                  onClick={() =>
                    document
                      .getElementById(`uploadFile-${fileData.fileId}`)
                      ?.click()
                  }
                >
                  {fileData.fileURL && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "10px",
                          width: "36px",
                          height: "36px",
                        }}
                        onClick={(e) => handleRemoveFile(fileData.fileId)}
                      >
                        <img src="/img/times.png" alt="刪除證照" />
                      </div>
                      <img src={fileData.fileURL} alt="證照預覽" />
                    </>
                  )}
                </div>
              );
            })}

          {/* <div className="flex items-center justify-end gap-[0.75rem]">
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="下一步"
            >
              下一步
            </button>
          </div> */}
        </form>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </>
  );
};

export default LicenseTracker;
