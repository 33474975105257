import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";


/**
 * 資料顯示頁面
 */
const InfoPanel = ({ keyword, CareServiceItemLists, refreshAllCareServiceItemList, name, creatorNum }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    return (
        <div className=" main-background normal">
            <div className="content h-[calc(100vh-9.875rem)] md:h-auto">
                <form className="h-full flex flex-col gap-[2.1875rem] mb-0 pt-[30%] md:pt-0" action="./findCareStep6.html">
                    <div className="flex flex-col items-center gap-[1.25rem]">
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_135_628)">
                                <path
                                    d="M56.5 56.149H0.5V0.609131H52.6382V8.26925H8.22358V48.4889H48.7764V30.1027H56.5V56.149Z"
                                    fill="#4CC764"
                                />
                                <path
                                    d="M18.6433 25.8291L13.251 31.3132L27.6521 45.2415L33.0444 39.7573L18.6433 25.8291Z"
                                    fill="#4CC764"
                                />
                                <path
                                    d="M50.6928 11.6761L30.3623 31.6504L35.7979 37.0924L56.1284 17.1181L50.6928 11.6761Z"
                                    fill="#4CC764"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_135_628">
                                    <rect width="56" height="55.5399" fill="white" transform="translate(0.5 0.609131)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="text-primary text-[1.25rem] text-center leading-[1.5]">
                            已收到您寶貴的意見，<br />
                            我們會盡快回覆您！
                        </p>
                    </div>
                    <div className="flex items-center justify-center gap-[0.75rem] mt-[5rem]">
                        <Link to="/FindCareStep">
                            <button
                                type="submit"
                                className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                title="回主畫面"
                            >
                                回主畫面
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InfoPanel;
