import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //语系
import { useToast } from '../../contexts/ToastContext';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../bootstrap.css';

const SureModal = ({ showModal, handleClose,handleconfirm, title, id, delCallback }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    return (
        <Modal show={showModal}  class="modal fade ProjectModal" id="checkinModal-1-1" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content accept">
                    <div class="modal-body">
                        <div class="bottomFormSection" >
                            <div class="w-full flex flex-col items-center gap-[2.125rem] mx-auto mt-[3.75rem]">
                                <svg
                                    width="71"
                                    height="71"
                                    viewBox="0 0 71 71"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_787_333)">
                                        <path d="M40.2098 16.8154H30.7922V41.1752H40.2098V16.8154Z" fill="#9E3223" />
                                        <path d="M40.2098 45.8457H30.7922V54.1847H40.2098V45.8457Z" fill="#9E3223" />
                                        <path
                                            d="M9.5165 31.0627C11.6484 18.7455 22.484 9.34138 35.501 9.34138C48.518 9.34138 59.3536 18.7455 61.4855 31.0627H71.002C68.791 13.5766 53.7192 0 35.501 0C17.2828 0 2.20905 13.5766 0 31.0627H9.5165Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M61.4004 40.4043C59.0787 52.49 48.3518 61.6568 35.501 61.6568C22.6501 61.6568 11.9233 52.49 9.60154 40.4043H0.0593262C2.47603 57.6649 17.443 71.0002 35.499 71.0002C53.555 71.0002 68.522 57.6649 70.9387 40.4043H61.3984H61.4004Z"
                                            fill="#9E3223"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_787_333">
                                            <rect width="71" height="71" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <strong class="text-primary text-[1.5rem] leading-[1.5] text-center">確定要重新簽到嗎</strong>
                            </div>
                            <div class="flex items-center justify-center gap-[0.75rem] mt-[2.0625rem] pb-[8.9375rem]">
                                <a
                                    class="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                                    title="取消"
                                    onClick={handleClose}
                                >
                                    取消
                                </a>
                                <button
                                    type="button"
                                    class="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                                    title="簽到"
                                    data-bs-toggle="modal"
                                    data-bs-target="#checkinModal-1-2"
                                    data-submit
                                    onClick = {handleconfirm}
                                >
                                    簽到
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SureModal;