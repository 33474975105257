import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import CareStep from "../../components/CareStep/CareStep";
import InforPanel from "../../components/UserProfile/InforPanel";
import { useMyUserContext } from "../../contexts/MyUserContext";

/**
 * 選擇身分
 * @returns
 */
const UserProfile = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <InforPanel />
      </div>
    </>
  );
};

export default UserProfile;
