import SimpleReactValidator from "simple-react-validator";

const CreateValidator = () => {
  const validator = new SimpleReactValidator({
    validators: {
      pawFormat: {
        rule: (val, params, validator) => {
          let result = true;

          // if (val.length < 4 || val.length > 30) {
          //   return result;
          // }

          // let strRep = reactStringReplace(val, /(\d+)/g, (match, i) => (""));
          // strRep = reactStringReplace(strRep, /([a-zA-Z])/g, (match, i) => (""));
          // strRep = reactStringReplace(strRep, /([-)(*#+])/g, (match, i) => (""));

          // if (strRep.join("") == "") {
          //     result = true;
          // }

          const regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,12}$/;
          return regex.test(val);
        },
      },
      codeFormat: {
        rule: (val, params, validator) => {
          let result = false;
          const regex = /^[A-Z0-9-]+$/; //只可允許大寫英文及"-"

          return regex.test(val);
        },
      },
      identityNumberFormat: {
        rule: (val, params, validator) => {
          let result = false;
          const regex = /^[A-Z][12]\d{8}$/; //只可允許大寫英文及"-"

          return regex.test(val);
        },
      },
      mail: {
        rule: (val, params, validator) => {
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 信箱格式驗證
          return regex.test(val);
        },
      },
    },
    autoForceUpdate: this,
  });

  return validator;
};

const ValidateField = async (fieldName, value, errors, validator) => {
  let result = true;
  switch (fieldName) {
    case "height":
      if (!validator.check(value, "required")) {
        errors.height = "required";
        result = false;
      } else if (!validator.check(value, "min:0,num|max:200,num")) {
        errors.height = "heightRange";
        result = false;
      } else {
        errors.height = "";
      }
      break;
    case "weight":
      if (!validator.check(value, "required")) {
        errors.weight = "required";
        result = false;
      } else if (!validator.check(value, "min:0,num|max:150,num")) {
        errors.weight = "weightRange";
        result = false;
      } else {
        errors.weight = "";
      }
      break;
    case "age":
      if (!validator.check(value, "required")) {
        errors.weight = "required";
        result = false;
      } else if (!validator.check(value, "min:0,num|max:150,num")) {
        errors.weight = "ageRange";
        result = false;
      } else {
        errors.weight = "";
      }
      break;
    case "identityNumber":
      if (!validator.check(value, "required")) {
        errors.identityNumber = "required";
        result = false;
      } else if (!validator.check(value, "identityNumberFormat")) {
        errors.identityNumber = "identityNumberFormat";
        result = false;
      } else {
        errors.identityNumber = "";
      }
      break;
    case "password":
      if (!validator.check(value, "required")) {
        errors.password = "required";
        result = false;
      } else if (!validator.check(value, "pawFormat")) {
        errors.password = "pawFormat";
        result = false;
      } else {
        errors.password = "";
      }
      break;
    case "code":
      if (!validator.check(value, "required")) {
        errors.code = "required";
        result = false;
      } else if (!validator.check(value, "codeFormat")) {
        errors.code = "codeFormat";
        result = false;
      } else {
        errors.code = "";
      }
      break;
    case "hour":
      if (!validator.check(value, "numeric|min:1,num")) {
        errors.hour = "numeric";
        result = false;
      } else if (!validator.check(value, "required")) {
        errors.hour = "required";
        result = false;
      } else {
        errors.hour = "";
      }
      break;
    case "bankAccount":
      if (!validator.check(value, "required")) {
        errors.bankAccount = "required";
        result = false;
      } else if (!validator.check(value, "numeric")) {
        errors.bankAccount = "numeric";
        result = false;
      } else {
        errors.bankAccount = "";
      }
      break;
    case "distId":
      if (!validator.check(value, "numeric|min:1,num")) {
        errors.distId = "numeric";
        result = false;
      } else {
        errors.distId = "";
      }
      break;
    case "cityId":
      if (!validator.check(value, "numeric|min:1,num")) {
        errors.cityId = "numeric";
        result = false;
      } else {
        errors.cityId = "";
      }
      break;
    default:
      if (!validator.check(value, "required")) {
        errors[fieldName] = "required";
        result = false;
      } else {
        errors[fieldName] = "";
      }
      break;
  }

  return { result, errors };
};

const ValidateFields = async (obj, errors, excludedFields = []) => {
  const validator = CreateValidator();
  let fields = Object.entries(obj).map(([name, value]) => ({ name, value }));
  let result = true;
  let finalResult = { result: true, errors: {} };

  for (const { name, value } of fields) {
    if (!excludedFields.includes(name)) {
      let validateFieldResult = await ValidateField(
        name,
        value,
        errors,
        validator
      );
      if (result && !validateFieldResult.result) {
        result = false;
      }
    }
  }

  finalResult.result = result;

  return finalResult;
};

export { CreateValidator, ValidateFields, ValidateField };
