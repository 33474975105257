import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/ToastContext";
import { useTargetCareCaseContext } from "../../contexts/TargetCareCaseContext";
import { Link } from "react-router-dom";
import HamburgerNurseAidesModal from "./../HamburgerNurseAidesModal";
import {
  apiDownloadFile,
  apiGetAvailableFamilyDetail,
  apiSystemCode,
} from "../../utils/Api";
import { convertDoubleToDate, formatTimeToHHmm } from "../../utils/Helpers";
import dayjs from "dayjs";
import HamburgerModal from "../HamburgerModal";

/**
 * 家屬查看案件詳細資料頁面
 */
const ProjectListFamilyChoose = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const iseEffectcalledRef = useRef(false);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [showDetailModal, setShowDetailModal] = useState(false); //顯示modal
  const [availableCaseDetail, setAvailableCaseDetail] = useState({
    //可承接的案件資料
    careCaseId: 0,
    careCaseNo: "",
    hospitalName: "",
    mainFamily: {
      name: "",
      phone: "",
      address: "",
    },
    subFamily: {
      name: "",
      phone: "",
      address: null,
    },
    patient: {
      gender: 0,
      height: 0,
      weight: 0,
      age: 0,
      symptom: [],
      language: [],
      tube: [],
    },
    plan: {
      name: "",
      hours: 0,
      days: 0,
      unit: 0,
      price: 0.0,
      careCasePlanTimes: [],
      careCasePlanPairs: [],
    },
    forNurseAidesContent: "",
  });
  const [serviceTime, setServiceTime] = useState({
    timeRange: "",
    startDates: "",
  });
  const [fee, setFee] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        id: parseInt(id),
      };

      const response = await apiGetAvailableFamilyDetail(params);
      if (response && response.code === 0) {
        const data = response.data;
        console.log(data);
        if (data.headshotFilePath) {
          const param = encodeURI(data.headshotFilePath);
          const downloadFile = await apiDownloadFile(param);
          if (downloadFile) {
            const fileURL = URL.createObjectURL(downloadFile.data);
            setAvailableCaseDetail({
              ...data,
              fileURL: fileURL,
            });
          }
        } else {
          setAvailableCaseDetail({ ...data, fileURL: "" });
        }

        //顯示服務時段

        const startTime = data.plan.careCasePlanTimes[0].startTime;
        const startTimeDate = convertDoubleToDate(startTime);
        const endTimeDate = new Date(startTimeDate);
        endTimeDate.setHours(endTimeDate.getHours() + data.plan.hours);

        const startDates = data.plan.careCasePlanTimes.map((time) => {
          const date = time.startDate.split("T")[0];
          return dayjs(date).format("YYYY/MM/DD");
        });
        console.log(startDates);

        setServiceTime({
          timeRange: `${formatTimeToHHmm(startTimeDate)} ~ ${formatTimeToHHmm(
            endTimeDate
          )}`,
          startDates: startDates,
        });
      } else {
        showToast("error", response ? response.message : tRef.current); // [回傳的訊息] : 網路連線錯誤
      }

      const keyNames = [
        {
          systemKey: "PlatformFee",
        },
      ];
      const feeResponse = await apiSystemCode(keyNames);
      if (feeResponse && feeResponse.code === 0) {
        setFee(Number(feeResponse.data[0].systemValue));
      } else {
        showToast("error", feeResponse ? feeResponse.message : tRef.current); // [回傳的訊息] : 網路連線錯誤
      }
    };
    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      if (parseInt(id) === 0 || isNaN(parseInt(id))) {
        navigate("/ProjectList", {
          replace: true,
        });
      }
      fetchData();
    }

    return () => {};
  }, [id, navigate, showToast]);

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  // #region 計算總金額
  const caculationAmount = (price) => {
    const itemsTotalAmount = availableCaseDetail.lCareServiceItemDataDTO
      ? availableCaseDetail.lCareServiceItemDataDTO.reduce(
          (total, item) => total + Number(item.fee),
          0
        )
      : 0;

    const totalDays = availableCaseDetail.plan.careCasePlanTimes.length;
    console.log(totalDays);
    if (availableCaseDetail.plan.unit === 1) {
      // 全天候算法
      const totalAmount = (Number(price) + itemsTotalAmount) * totalDays;
      return Math.round(totalAmount * (fee + 1));
    }
    if (availableCaseDetail.plan.unit === 0) {
      const hours = Number(availableCaseDetail.plan.hours);
      // 喘息照顧算法

      const totalAmount =
        (Number(price) * hours + itemsTotalAmount) * totalDays;
      return Math.round(totalAmount * (fee + 1));
    }
  };
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-start justify-between gap-[0.625rem]"
            action="./projectListChoose.html"
          >
            <strong className="max-w-[6em] grow text-primary text-[1.5rem] leading-[1.5]">
              案件詳細資料
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <div className="flex flex-col gap-[2.1875rem]">
            <div className="flex flex-col items-center gap-[1.5625rem]">
              <div className="flex flex-col gap-[1.25rem] justify-center">
                <div className="w-[11.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
                  <img
                    src={
                      availableCaseDetail.fileURL
                        ? availableCaseDetail.fileURL
                        : "./img/user.png"
                    }
                    width="130"
                    height="130"
                    alt="user avator"
                    className="img-pairing-case"
                  />
                </div>
                <ul className="min-w-[15.625rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
                  <li>案件編號：{availableCaseDetail.careCaseNo}</li>
                  {availableCaseDetail.hospitalName ? (
                    <li>醫院：{availableCaseDetail.hospitalName}</li>
                  ) : (
                    <></>
                  )}
                  <li>
                    家屬：{availableCaseDetail.mainFamily.name} |{" "}
                    <a href={`tel:${availableCaseDetail.mainFamily.phone}`}>
                      {availableCaseDetail.mainFamily.phone}
                    </a>
                  </li>
                  {availableCaseDetail.subFamily.name ? (
                    <li>
                      次要家屬：{availableCaseDetail.subFamily.name} |{" "}
                      <a href={`tel:${availableCaseDetail.subFamily.phone}`}>
                        {availableCaseDetail.subFamily.phone}
                      </a>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>地址：{availableCaseDetail.mainFamily.address} </li>
                  <li>方案名稱：{availableCaseDetail.plan.name}</li>

                  <li>
                    預計總費用：${" "}
                    {caculationAmount(availableCaseDetail.plan.price)}{" "}
                  </li>
                  <li>
                    需求： {serviceTime.timeRange} 服務{" "}
                    {availableCaseDetail.plan.careCasePlanTimes.length} 天
                  </li>

                  <li>
                    服務項目：
                    <ul style={{ listStyle: "disc", marginLeft: "26px" }}>
                      {availableCaseDetail.lCareServiceItemDataDTO &&
                        availableCaseDetail.lCareServiceItemDataDTO.map(
                          (item) => {
                            return (
                              <li key={item.id}>
                                {item.name} ${item.fee} (1天)
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </li>
                  <li>
                    開始服務日期：
                    <ul style={{ listStyle: "disc", marginLeft: "26px" }}>
                      {serviceTime.startDates &&
                        serviceTime.startDates.map((date, index) => {
                          return <li key={index}> {date}</li>;
                        })}
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="w-full flex flex-col gap-[1.875rem]">
                <div className="p-[1rem] bg-white border-[0.125rem] border-primary text-dark text-xs md:text-sm lg:text-base font-[300] leading-[1.5]">
                  <p>病患狀況：</p>
                  <p>• 姓名： {availableCaseDetail.patient.name} </p>
                  <p>{`• ${
                    availableCaseDetail.patient.gender === 0
                      ? "男性"
                      : availableCaseDetail.patient.gender === 1
                      ? "女性"
                      : "不公開"
                  }  ${availableCaseDetail.patient.age}歲，${
                    availableCaseDetail.patient.height
                  }公分 ${availableCaseDetail.patient.weight}公斤`}</p>
                  <p>
                    {availableCaseDetail.patient.symptom.length === 0
                      ? `• 症狀：無`
                      : `• 症狀：${availableCaseDetail.patient.symptom.join(
                          "、"
                        )}`}
                  </p>
                  <p>
                    {availableCaseDetail.patient.tube.length === 0
                      ? `• 管線：無`
                      : `• 管線：${availableCaseDetail.patient.tube.join(
                          "、"
                        )}`}
                  </p>
                  <p>
                    {availableCaseDetail.patient.language.length === 0
                      ? `•語言：無`
                      : `• 語言：${availableCaseDetail.patient.language.join(
                          "、"
                        )}`}
                  </p>
                  <p></p>
                </div>
                <div className="min-h-[10rem] p-[1rem] bg-white border-[0.125rem] border-primary text-dark text-xs md:text-sm lg:text-base font-[300] leading-[1.5]">
                  <p>更詳細的需求描述：</p>
                  <p>
                    {availableCaseDetail.forNurseAidesContent !== ""
                      ? availableCaseDetail.forNurseAidesContent
                      : "無"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default ProjectListFamilyChoose;
