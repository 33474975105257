import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import {
  apiUploadDocument,
  apiGetDocument,
  apiDownloadDocument,
} from "../../utils/Api";

/**
 * 申請照服員-上傳身分證、證書(step2)
 */
const UploadFile = ({ onNextStep, onPreviousStep, onUploadFile }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [backImageUrl, setBackImageUrl] = useState(null);
  const [careCertificateUrl, setCareCertificateUrl] = useState(null);
  const [fileData, setFileData] = useState({
    careCertificates: [
      {
        frontFileId: 0,
        backFileId: 0,
      },
    ],
    identityCard: {
      frontFileId: 0,
      backFileId: 0,
    },
  });

  const identityCardFrontRef = useRef(null);
  const identityCardBackRef = useRef(null);
  const careCertificateFrontRef = useRef(null);

  // #region在頁面跳轉後重新讀取剛剛上傳的資料
  useEffect(() => {
    // 從本地存儲中獲取文件ID
    const identityCardFrontId = localStorage.getItem("identityCardFront");
    const identityCardBackId = localStorage.getItem("identityCardBack");
    const careCertificateFrontId = localStorage.getItem("careCertificateFront");
    const careCertificateBackId = localStorage.getItem("careCertificateBack");

    if (identityCardFrontId != 0) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        identityCard: {
          ...prevFileData.identityCard,
          frontFileId: identityCardFrontId,
        },
      }));
    }

    if (identityCardBackId != 0) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        identityCard: {
          ...prevFileData.identityCard,
          backFileId: identityCardBackId,
        },
      }));
    }

    if (careCertificateFrontId != 0) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        careCertificates: [
          {
            frontFileId: careCertificateFrontId,
            backFileId: prevFileData.careCertificates[0].backFileId,
          },
        ],
      }));
    }

    // 調用後端API獲取文件
    const fetchDocuments = async () => {
      try {
        const identityCardFront = await apiGetDocument({
          id: identityCardFrontId,
        });
        const identityCardFrontURL = identityCardFront.data;
        const identityCardBack = await apiGetDocument({
          id: identityCardBackId,
        });
        const identityCardBackURL = identityCardBack.data;
        const careCertificateFront = await apiGetDocument({
          id: careCertificateFrontId,
        });
        const careCertificateFrontURL = careCertificateFront.data;

        // 下載並設置證照正面圖片的 Blob URL
        if (careCertificateFrontURL) {
          try {
            const careCertificateResponse = await apiDownloadDocument({
              fileName: careCertificateFrontURL,
            });
            if (careCertificateResponse.data instanceof Blob) {
              const careCertificateUrl = URL.createObjectURL(
                careCertificateResponse.data
              ); //創建 Blob URL
              setCareCertificateUrl(careCertificateUrl); // 將 Blob URL 存儲在狀態中
            } else {
              console.error("apiDownloadDocument 返回的不是有效的 Blob 對象。");
            }
          } catch (error) {
            console.error("下載並設置身份證正面圖片時出錯:", error);
          }
        }

        // 下載並設置身份證正面圖片的 Blob URL
        if (identityCardFrontURL) {
          try {
            const frontImageResponse = await apiDownloadDocument({
              fileName: identityCardFrontURL,
            });
            if (frontImageResponse.data instanceof Blob) {
              const frontImageUrl = URL.createObjectURL(
                frontImageResponse.data
              ); //創建 Blob URL
              setFrontImageUrl(frontImageUrl); // 將 Blob URL 存儲在狀態中
            } else {
              console.error("apiDownloadDocument 返回的不是有效的 Blob 對象。");
            }
          } catch (error) {
            console.error("下載並設置身份證正面圖片時出錯:", error);
          }
        }

        // 下載並設置身份證背面圖片的 Blob URL
        if (identityCardBackURL) {
          try {
            const backImageResponse = await apiDownloadDocument({
              fileName: identityCardBackURL,
            });
            if (backImageResponse.data instanceof Blob) {
              const backImageUrl = URL.createObjectURL(backImageResponse.data);
              setBackImageUrl(backImageUrl);
            } else {
              console.error("apiDownloadDocument 返回的不是有效的 Blob 對象。");
            }
          } catch (error) {
            console.error("下載並設置身份證背面圖片時出錯:", error);
          }
        } else {
        }
      } catch (error) {
        console.error("獲取文件時出錯:", error);
      }
    };

    // 當文件ID變化時，調用fetchDocuments函數
    fetchDocuments();
  }, []); // 空依賴項表示僅在初始載入時調用一次

  useEffect(() => {
    console.log(fileData);
  }, [fileData]);
  //#endregion

  // #region處理文件上傳
  const handleFileUpload = async (file, type) => {
    try {
      // 準備上傳的文件數據
      const formData = new FormData();
      formData.append("Photo", file, file.name);

      // 調用 API 進行上傳
      const response = await apiUploadDocument(formData);
      const fileId = response.data;

      if (response.code === 0) {
        // 更新 fileData 狀態
        if (type === "identityCardFront") {
          setFileData((prevFileData) => ({
            ...prevFileData,
            identityCard: {
              ...prevFileData.identityCard,
              frontFileId: fileId,
            },
          }));
        } else if (type === "identityCardBack") {
          setFileData((prevFileData) => ({
            ...prevFileData,
            identityCard: {
              ...prevFileData.identityCard,
              backFileId: fileId,
            },
          }));
        } else if (type === "careCertificateFront") {
          setFileData((prevFileData) => ({
            ...prevFileData,
            careCertificates: [
              {
                frontFileId: fileId,
                backFileId: prevFileData.careCertificates[0].backFileId,
              },
            ],
            identityCard: { ...prevFileData.identityCard },
          }));
        }

        // 將文件 id 存儲在本地存儲中
        localStorage.setItem(type, fileId);
        return true;
      } else {
        if (type === "identityCardFront") {
          identityCardFrontRef.current.value = null;
        } else if (type === "identityCardBack") {
          identityCardBackRef.current.value = null;
        } else if (type === "careCertificateFront") {
          careCertificateFrontRef.current.value = null;
        }
        return false;
      }
    } catch (error) {
      if (type === "identityCardFront") {
        identityCardFrontRef.current.value = null;
      } else if (type === "identityCardBack") {
        identityCardBackRef.current.value = null;
      } else if (type === "careCertificateFront") {
        careCertificateFrontRef.current.value = null;
      }
      showToast("error", "上傳檔案失敗");
      return false;
    }
  };
  //#endregion

  // #region 處理檔案輸入框變更事件
  const handleFileChange = async (e, previewId, type) => {
    const file = e.target.files[0]; // 取得第一個文件，可以根據需要處理多個文件上傳
    if (file) {
      // 檢查文件類型是否為 jpg、png、jpeg
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        // 如果文件類型不符合要求，顯示錯誤訊息並返回
        showToast("error", "僅能上傳 jpg、png、jpeg 檔");
        return;
      }
      // 創建一個Blob URL來顯示圖片預覽
      const imageUrl = URL.createObjectURL(file);
      const result = await handleFileUpload(file, type); // 呼叫處理文件上傳的函數，並將 type 參數傳遞進去
      if (result) {
        if (previewId == "identityCardFront") {
          setFrontImageUrl(imageUrl);
        } else if (previewId == "identityCardBack") {
          setBackImageUrl(imageUrl);
        } else if (previewId == "careCertificateFront") {
          setCareCertificateUrl(imageUrl);
        }
      }

      // // 將圖片預覽設置到指定的區域
      // const previewArea = document.getElementById(previewId);
      // if (previewArea) {
      //   previewArea.innerHTML = `<img src="${imageUrl}" alt="File Preview" />`;
      // }
    }
  };
  //#endregion

  // #region 處理刪除按鈕的點擊事件
  const handleRemoveFile = (previewId) => {
    if (previewId == "identityCardFront") {
      localStorage.removeItem("identityCardFront");
      setFrontImageUrl(null);
      setFileData((prevFileData) => ({
        ...prevFileData,
        identityCard: {
          ...prevFileData.identityCard,
          frontFileId: 0,
        },
      }));
    } else if (previewId == "identityCardBack") {
      localStorage.removeItem("identityCardBack");
      setBackImageUrl(null);
      setFileData((prevFileData) => ({
        ...prevFileData,
        identityCard: {
          ...prevFileData.identityCard,
          backFileId: 0,
        },
      }));
    } else if (previewId == "careCertificateFront") {
      localStorage.removeItem("careCertificateFront");
      setCareCertificateUrl(null);
      setFileData((prevFileData) => ({
        ...prevFileData,
        careCertificates: [
          {
            frontFileId: 0,
            backFileId: prevFileData.careCertificates[0].backFileId,
          },
        ],
        identityCard: { ...prevFileData.identityCard },
      }));
    }
  };
  //#endregion

  //#region 下一步
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "fileData.careCertificates[0].frontFileId",
      fileData.careCertificates[0].frontFileId
    );
    // 檢查是否每個文件都已上傳或 localStorage 中有相應資料
    const isUploaded =
      fileData.identityCard.frontFileId > 0 &&
      fileData.identityCard.backFileId > 0 &&
      fileData.careCertificates[0].frontFileId > 0;

    const isLocalStorageAvailable =
      localStorage.getItem("identityCardFront") &&
      localStorage.getItem("identityCardBack") &&
      localStorage.getItem("careCertificateFront");

    if (isUploaded || isLocalStorageAvailable) {
      // 執行下一步操作
      onUploadFile(fileData);
      onNextStep();
    } else {
      // 顯示提示訊息
      showToast("error", "請上傳所有文件");
    }
  };
  //#endregion

  return (
    <>
      <div className="content">
        <div className="applyHead">
          <h1>申請照服員</h1>
          <div>
            <div>
              <svg
                width="15"
                height="19"
                viewBox="0 0 15 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="active">
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                  fill="#9E3223"
                />
              </svg>
            </div>
            <div>
              <svg
                width="18"
                height="25"
                viewBox="0 0 18 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                  fill="#9E3223"
                />
              </svg>
            </div>
          </div>
        </div>
        <form
          className="flex flex-col gap-[2.5rem] mb-[10rem]"
          onSubmit={handleSubmit}
        >
          <div
            className="InspireDropzone"
            id="identityCardFront"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => document.getElementById("upload-front")?.click()}
          >
            {frontImageUrl ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "10px",
                    width: "36px",
                    height: "36px",
                  }}
                  onClick={(e) => handleRemoveFile("identityCardFront")}
                >
                  <img src="/img/times.png" alt="刪除身分證正面預覽" />
                </div>
                <img src={frontImageUrl} alt="身分證正面預覽" />
              </>
            ) : (
              <>
                <div>
                  <span>點擊上傳身份證正面{frontImageUrl}</span>
                  <span>僅能上傳jpg、png、jpeg檔</span>
                </div>
                <input
                  type="file"
                  id="upload-front"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      "identityCardFront",
                      "identityCardFront"
                    )
                  }
                  ref={identityCardFrontRef}
                />
              </>
            )}
          </div>
          <div
            className="InspireDropzone"
            id="identityCardBack"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => document.getElementById("upload-back")?.click()}
          >
            {backImageUrl ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "10px",
                    width: "36px",
                    height: "36px",
                  }}
                  onClick={(e) => handleRemoveFile("identityCardBack")}
                >
                  <img src="/img/times.png" alt="刪除身分證背面預覽" />
                </div>
                <img src={backImageUrl} alt="身分證背面預覽" />
              </>
            ) : (
              <>
                <div>
                  <span>點擊上傳身份證背面</span>
                  <span>僅能上傳jpg、png、jpeg檔</span>
                </div>
                <input
                  type="file"
                  id="upload-back"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleFileChange(e, "identityCardBack", "identityCardBack")
                  }
                  ref={identityCardBackRef}
                />
              </>
            )}
          </div>

          <div
            className="InspireDropzone"
            id="careCertificateFront"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() =>
              document.getElementById("care-certificate-front")?.click()
            }
          >
            {careCertificateUrl ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "10px",
                    width: "36px",
                    height: "36px",
                  }}
                  onClick={(e) => handleRemoveFile("careCertificateFront")}
                >
                  <img src="/img/times.png" alt="刪除證照面預覽" />
                </div>
                <img src={careCertificateUrl} alt="證照面預覽" />
              </>
            ) : (
              <>
                <div>
                  <span>點擊上傳照服員證書正面</span>
                  <span>僅能上傳jpg、png、jpeg檔</span>
                </div>
                <input
                  type="file"
                  id="care-certificate-front"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      "careCertificateFront",
                      "careCertificateFront"
                    )
                  }
                  ref={careCertificateFrontRef}
                />
              </>
            )}
          </div>
          <div className="flex items-center justify-end gap-[0.75rem]">
            <a
              href="./careStep1.html"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="上一步"
              onClick={onPreviousStep}
            >
              上一步
            </a>
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="下一步"
            >
              下一步
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadFile;
