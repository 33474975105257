import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getAuthToken } from './JWToken';


const PrivateRoute = () => {
    const location = useLocation()
    const token = getAuthToken();
    const redirectToRoot = (token === null || token === "") && location.pathname !== "/MailVerify";
    return redirectToRoot ? <Navigate to="/" /> : <Outlet />;
}

export default PrivateRoute;