import Cookies from "universal-cookie";
import { apiMyUserData } from "./Api";

const cookies = new Cookies();

const TOKEN_NAME = "userToken";

export const setAuthToken = (token) => {
  cookies.set(TOKEN_NAME, token, { HttpOnly: true, path: "/" });
};

export const getAuthToken = () => {
  var token = cookies.get(TOKEN_NAME);
  if (token != null) {
    return token;
  } else {
    return null;
  }
};

export const removeAuthToken = () => {
  cookies.remove(TOKEN_NAME, { path: "/" });
};

export const checkAuthToken = async () => {
  const token = cookies.get(TOKEN_NAME);
  const fetchMyUserResult = await apiMyUserData();

  return token !== undefined && token !== null && fetchMyUserResult.code === 0;
};
