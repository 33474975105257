import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useMyUserContext } from "../../contexts/MyUserContext";
import InfoPanel from "./InfoPanel";
import UploadFile from "./UploadFile";
import Bank from "./Bank";
import CareStepVerify from "./CareStepVerify";
import ReApplyComplete from "./ReApplyComplete";
import { useLocation } from "react-router-dom";

/**
 * 資料顯示頁面(申請照服員)
 */
const CareStep = () => {
  const { myUser } = useMyUserContext();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { state } = useLocation();
  const [currentStep, setCurrentStep] = useState(1); //步驟
  const [reApply, setReapply] = useState(false);
  const [createNurseAides, setCreateNurseAides] = useState({
    //建立照服員
    // name: "",
    // gender: 1,
    // birthday: "",
    // height: "",
    // weight: "",
    // cityId: 0,
    // distId: 0,
    // address: "",
    // phone: "",
    // mail: "",
    // identityNumber: "",
    // mainServiceCityId: 0,
    // mainServiceDistId: 0,
    // subServiceCityId: 0,
    // subServiceDistId: 0,
    // recommendCode: "",
    // bankCode: "",
    // bankAccount: "",
    // bankBranchCode:"",
    // bankBookFileId: 0,
    // careCertificates: [],
    // identityCard: []
  });

  //#region 初始載入
  useEffect(() => {
    console.log("myUser", myUser);
    // Check if state passed from parent
    if (state) {
      if (state.currentStep !== undefined) {
        setCurrentStep(state.currentStep);
      }
      // If reapply
      if (state.reApply !== undefined) {
        setReapply(state.reApply);
      }
    }
    if (myUser) {
      setCreateNurseAides({
        ...createNurseAides,
        name: myUser ? myUser.personnelName : "",
        gender: myUser ? myUser.gender : "",
        birthday: myUser ? myUser.birthday : "",
        cityId: myUser ? myUser.cityId : "",
        distId: myUser ? myUser.distId : "",
        address: myUser ? myUser.address : "",
        phone: myUser ? myUser.phone : "",
        mail: myUser ? myUser.mail : localStorage.getItem("mail") || "",
        height: localStorage.getItem("height") || "",
        weight: localStorage.getItem("weight") || "",
        identityNumber: localStorage.getItem("identityNumber") || "",
        mainServiceCityId: localStorage.getItem("mainServiceCityId") || 0,
        mainServiceDistId: localStorage.getItem("mainServiceDistId") || 0,
        subServiceCityId: localStorage.getItem("subServiceCityId") || 0,
        subServiceDistId: localStorage.getItem("subServiceDistId") || 0,
        recommendCode: localStorage.getItem("recommendCode") || "",
        bankCode: localStorage.getItem("bankCode") || "",
        bankBranchCode: localStorage.getItem("bankBranchCode") || "",
        bankAccount: localStorage.getItem("bankAccount") || "",
      });
    } else {
      setCreateNurseAides({
        ...createNurseAides,
        mail: localStorage.getItem("mail") || "",
        height: localStorage.getItem("height") || "",
        weight: localStorage.getItem("weight") || "",
        identityNumber: localStorage.getItem("identityNumber") || "",
        mainServiceCityId: localStorage.getItem("mainServiceCityId") || 0,
        mainServiceDistId: localStorage.getItem("mainServiceDistId") || 0,
        subServiceCityId: localStorage.getItem("subServiceCityId") || 0,
        subServiceDistId: localStorage.getItem("subServiceDistId") || 0,
        recommendCode: localStorage.getItem("recommendCode") || "",
        bankCode: localStorage.getItem("bankCode") || "",
        bankBranchCode: localStorage.getItem("bankBranchCode") || "",
        bankAccount: localStorage.getItem("bankAccount") || "",
      });
    }
  }, [myUser]);
  //#endregion

  //#region 下一頁
  const handleNextStep = (e) => {
    setCurrentStep(currentStep + 1);
  };
  //#endregion

  // Redirect to re-apply complete page.
  const reApplyComplete = () => {
    setCurrentStep(5);
  };

  //#region 上一頁
  const handlePreviousStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };
  //#endregion

  //#region 更新基本資料
  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    let newValue = value;
    if (name === "height" || name === "weight") {
      newValue = parseInt(value);
    }
    createNurseAides[name] = value ? parseInt(value) : 0; // 如果值為空字串，設置為0
    setCreateNurseAides({ ...createNurseAides, [name]: newValue });
    localStorage.setItem(name, value);
  };
  //#endregion

  //#region 更新主要縣市
  const handleChangeMainServiceCityData = (value) => {
    setCreateNurseAides({
      ...createNurseAides,
      ["mainServiceCityId"]: parseInt(value),
    });
  };
  //#endregion

  //#region 更新主要地區
  const handleChangeMainServiceDistData = (value) => {
    setCreateNurseAides({
      ...createNurseAides,
      ["mainServiceDistId"]: parseInt(value),
    });
  };
  //#endregion

  //#region 更新次要縣市
  const handleChangeSubServiceCityData = (value) => {
    const cityId = parseInt(value);
    setCreateNurseAides({
      ...createNurseAides,
      subServiceCityId: cityId,
      subServiceDistId: cityId === 0 ? 0 : createNurseAides.subServiceDistId, // Reset subServiceDistId if cityId is 0
    });
    localStorage.setItem("subServiceCityId", cityId);
    if (cityId === 0) {
      localStorage.setItem("subServiceDistId", 0);
    }
  };
  //#endregion

  //#region 更新次要地區
  const handleChangeSubServiceDistData = (value) => {
    setCreateNurseAides({
      ...createNurseAides,
      ["subServiceDistId"]: parseInt(value),
    });
  };
  //#endregion

  //#region 更新UploadFile這頁返回的數據
  const handleUploadFile = (data) => {
    setCreateNurseAides({
      ...createNurseAides,
      ["careCertificates"]: data.careCertificates,
      ["identityCard"]: data.identityCard,
    });
  };
  //#endregion

  //#region 更新銀行圖片id
  const handleChangebankBookFileData = (value) => {
    setCreateNurseAides({
      ...createNurseAides,
      ["bankBookFileId"]: parseInt(value),
    });
  };
  //#endregion

  // 監聽頁面關閉與重整，清空 localStorage，不然必填欄位那些會不準確，一次清空身分證、證照和銀帳戶圖片
  useEffect(() => {
    const clearLocalStorageOnUnload = () => {
      // 清空 localStorage 中的資料
      localStorage.removeItem("height");
      localStorage.removeItem("weight");
      localStorage.removeItem("identityNumber");
      localStorage.removeItem("recommendCode");
      localStorage.removeItem("mail");
      localStorage.removeItem("mainServiceCityId");
      localStorage.removeItem("mainServiceDistId");
      localStorage.removeItem("subServiceCityId");
      localStorage.removeItem("subServiceDistId");
      localStorage.removeItem("identityCardFront");
      localStorage.removeItem("identityCardBack");
      localStorage.removeItem("careCertificateFront");
      localStorage.removeItem("uploadDocumentData");
      localStorage.removeItem("bankBranchCode");
      localStorage.removeItem("selectedBranch");
      localStorage.removeItem("bankAccount");
      localStorage.removeItem("bankCode");
    };

    // 在頁面關閉或重新整理時觸發清空 localStorage 資料
    window.addEventListener("beforeunload", clearLocalStorageOnUnload);

    return () => {
      // 移除事件監聽器，避免記憶體洩漏
      window.removeEventListener("beforeunload", clearLocalStorageOnUnload);
    };
  }, []);

  return (
    <div className=" main-background normal">
      <div className="content">
        {/* 根據當前步驟狀態渲染不同的子元件 */}
        {currentStep === 1 && (
          <InfoPanel
            onNextStep={handleNextStep}
            onChangeCreateNurseAides={handleChange}
            onChangeMainServiceCityData={handleChangeMainServiceCityData}
            onChangeMainServiceDistData={handleChangeMainServiceDistData}
            onChangeSubServiceCityData={handleChangeSubServiceCityData}
            onChangeSubServiceDistData={handleChangeSubServiceDistData}
            createNurseAides={createNurseAides}
          />
        )}
        {currentStep === 2 && (
          <UploadFile
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onUploadFile={handleUploadFile}
          />
        )}
        {currentStep === 3 && (
          <Bank
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onChangeCreateNurseAides={handleChange}
            onChangebankBookFileData={handleChangebankBookFileData}
            createNurseAides={createNurseAides}
            reApply={reApply}
            reApplyComplete={reApplyComplete}
          />
        )}
        {currentStep === 4 && (
          <CareStepVerify createNurseAides={createNurseAides} />
        )}
        {currentStep === 5 && (
          <ReApplyComplete createNurseAides={createNurseAides} />
        )}
      </div>
    </div>
  );
};

export default CareStep;
