import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderLine from "../../layouts/HeaderLine";
import InfoPanel from "../../components/Family/InfoPanel";

/**
 * 選擇身分
 * @returns
 */
const CareStep = () => {
  const { t } = useTranslation();

  return (
    <>      
      <div className="design">
        <HeaderLine/>
        <InfoPanel/>
      </div>
    </>
  );
};

export default CareStep;
