import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import HamburgerFamilyModal from "../HamburgerModal";
import CancelReqModule from "./CancelReqModule";

/**
 * 等待配對中
 */
const InfoPanel = ({
  keyword,
  CareServiceItemLists,
  refreshAllCareServiceItemList,
  name,
  creatorNum,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [showModule, setShowModule] = useState("");

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  //#region 開啟取消申請模組
  const cancelCareCasePlan = (e) => {
    e.preventDefault();
    setShowModule("cancelApply");
  };
  //#endregion

  const handleCloseClick = (e) => {
    // e.preventDefault();
    setShowModule("");
  };

  const handleProfileLinkClick = (e, page) => {
    e.preventDefault();
    switch (page) {
      case "ProjectListChoose":
        navigate(`/ProjectListFamilyChoose/${id}`);
        break;
      case "WaitingPairs":
        navigate(`/WaitingPairs/${id}`);
        break;
      case "Chats":
        navigate(`/Chats/${id}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem]">
          <form className="flex items-start justify-between gap-[0.625rem]">
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              等待配對中
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <form className="flex flex-col gap-[2.1875rem]">
            <div className="flex flex-col gap-[1.5625rem]">
              {/* TODO暫時拿掉，不能刪 */}
              {/* <button
                className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="照服員QA"
                onClick={(e) => {
                  handleProfileLinkClick(e, "Chats");
                }}
              >
                照服員QA
              </button> */}
              <button
                type="submit"
                className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="案件詳細內容"
                onClick={(e) => {
                  handleProfileLinkClick(e, "ProjectListChoose");
                }}
              >
                案件詳細內容
              </button>
              <button
                type="submit"
                className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="查看配對紀錄"
                onClick={(e) => {
                  handleProfileLinkClick(e, "WaitingPairs");
                }}
              >
                查看配對紀錄
              </button>
            </div>
            <div className="flex items-center justify-center gap-[0.75rem] mt-[0.9375rem]">
              <button
                className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="取消申請"
                onClick={cancelCareCasePlan}
              >
                取消申請
              </button>
            </div>
          </form>
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
      <CancelReqModule showModule={showModule} handleClose={handleCloseClick} />
    </div>
  );
};

export default InfoPanel;
