import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";


/**
 * 資料顯示頁面
 */
const InfoPanel = ({ keyword, CareServiceItemLists, refreshAllCareServiceItemList, name, creatorNum }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    return (
        <div className="main-background index">
            <div className="content">
                <div className="flex flex-col gap-[1.5rem]">
                    <a href="./findCareStep.html" className="flex items-center gap-[0.75rem] pb-[0.5625rem] border-b-[0.1875rem] border-primary">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_10_89)">
                                <path
                                    d="M16.0758 31.9783C11.9592 31.9783 7.84269 30.4185 4.70791 27.299C1.67073 24.2822 0 20.2657 0 15.992C0 11.7183 1.67073 7.70176 4.70791 4.67933C10.9775 -1.55978 21.1741 -1.55978 27.4437 4.67933C30.4808 7.70176 32.1516 11.7183 32.1516 15.992C32.1516 20.2657 30.4808 24.2822 27.4437 27.3047C24.3089 30.4242 20.1923 31.984 16.0758 31.984V31.9783ZM16.0758 5.70776C13.429 5.70776 10.7823 6.70761 8.76705 8.71304C6.81499 10.6556 5.74135 13.2381 5.74135 15.9863C5.74135 18.7345 6.81499 21.317 8.76705 23.2595C12.7975 27.2704 19.3541 27.2704 23.3845 23.2595C25.3366 21.317 26.4102 18.7345 26.4102 15.9863C26.4102 13.2381 25.3366 10.6556 23.3845 8.71304C21.3693 6.70761 18.7225 5.70776 16.0758 5.70776Z"
                                    fill="#9E3223"
                                />
                                <path
                                    d="M31.3133 27.2018C30.7564 27.9446 30.1421 28.653 29.4704 29.3215C28.7699 30.0186 28.0235 30.6528 27.237 31.2241L35.9007 39.9943L39.9943 35.9891L31.3076 27.1961L31.3133 27.2018Z"
                                    fill="#9E3223"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10_89">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="text-primary text-[2rem] font-bold leading-[1.4]"> 找看護 </span>
                    </a>
                    <a href="#" className="flex items-center gap-[0.75rem] pb-[0.5625rem] border-b-[0.1875rem] border-primary">
                        <svg width="33" height="39" viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_10_105)">
                                <path
                                    d="M7.11337 39L0 32.8233V0H4.85223V30.6225L7.11337 32.5863L11.8055 28.5184L16.4976 32.5863L21.1848 28.5184L25.8769 32.5863L28.1478 30.6225V4.83691H8.176V0H33V32.8233L25.8769 39L21.1848 34.9273L16.4976 39L11.8055 34.9273L7.11337 39Z"
                                    fill="#9E3223"
                                />
                                <path d="M25.0617 9.02084H7.93823V12.4067H25.0617V9.02084Z" fill="#9E3223" />
                                <path d="M25.0617 15.1734H7.93823V18.5592H25.0617V15.1734Z" fill="#9E3223" />
                                <path d="M25.0617 21.3308H7.93823V24.7166H25.0617V21.3308Z" fill="#9E3223" />
                            </g>
                            <defs>
                                <clipPath id="clip0_10_105">
                                    <rect width="33" height="39" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="text-primary text-[2rem] font-bold leading-[1.4]"> 居家照護 </span>
                    </a>
                    <a href="#" className="flex items-center gap-[0.75rem] pb-[0.5625rem] border-b-[0.1875rem] border-primary">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_10_127)">
                                <path
                                    d="M20 40C8.97 40 0 31.03 0 20C0 8.97 8.97 0 20 0C31.03 0 40 8.97 40 20C40 31.03 31.03 40 20 40ZM20 5C11.73 5 5 11.73 5 20C5 28.27 11.73 35 20 35C28.27 35 35 28.27 35 20C35 11.73 28.27 5 20 5Z"
                                    fill="#9E3223"
                                />
                                <path
                                    d="M22.5 25.095H17.5V19.58H20C21.74 19.58 23.155 18.165 23.155 16.425C23.155 14.685 21.74 13.27 20 13.27C18.26 13.27 16.845 14.685 16.845 16.425H11.845C11.845 11.93 15.505 8.26999 20 8.26999C24.495 8.26999 28.155 11.93 28.155 16.425C28.155 20.05 25.78 23.13 22.5 24.185V25.09V25.095Z"
                                    fill="#9E3223"
                                />
                                <path d="M22.5 27.79H17.5V32.46H22.5V27.79Z" fill="#9E3223" />
                            </g>
                            <defs>
                                <clipPath id="clip0_10_127">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="text-primary text-[2rem] font-bold leading-[1.4]"> 機構中心 </span>
                    </a>
                </div>
            </div>
            <img className="absolute bottom-[2.5rem] left-[3.25rem] z-0" src="/img/index-bottom-img.png" alt=""></img>
        </div>
    );
};

export default InfoPanel;
