import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import HamburgerModal from "../HamburgerModal";
import {
  apiDownloadFile,
  apiGetAvailableFamilyDetail,
  apiGetCompletePair,
} from "../../utils/Api";
import Pairing3Detail from "./Pairing3Detail";
import { convertDoubleToDate, formatTimeToHHmm } from "../../utils/Helpers";
import dayjs from "dayjs";

/**
 * 完成案件照服員評價
 *
 */
const Pairing3CaregiverList = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError")); // [回傳的訊息] : 網路連線錯誤
  const iseEffectcalledRef = useRef(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [completePairs, setCompletePairs] = useState([]); //完成案件的照服員列表

  const [selectedCompletePair, setSelectedCompletePair] = useState(null); // 選擇2完成配對照服員
  const [availableCaseDetail, setAvailableCaseDetail] = useState({
    //可承接的案件資料
    careCaseId: 0,
    careCaseNo: "",
    hospitalName: "",
    mainFamily: {
      name: "",
      phone: "",
      address: "",
    },
    subFamily: {
      name: "",
      phone: "",
      address: null,
    },
    patient: {
      gender: 0,
      height: 0,
      weight: 0,
      age: 0,
      symptom: [],
      language: [],
      tube: [],
    },
    plan: {
      name: "",
      hours: 0,
      days: 0,
      unit: 0,
      price: 0.0,
      careCasePlanTimes: [],
      careCasePlanPairs: [],
    },
    forNurseAidesContent: "",
  });
  const [serviceTime, setServiceTime] = useState({
    timeRange: "",
    startDate: "",
  });

  //#region 刷新進行中照服員及配對日期
  const refreshCompletePairs = useCallback(async () => {
    // 取得完成案件耖服員列表
    const params = {
      CareCaseId: parseInt(id),
    };
    try {
      const getCompletePairResponse = await apiGetCompletePair(params);

      if (getCompletePairResponse && getCompletePairResponse.code === 0) {
        const results = getCompletePairResponse.data.result;
        const apiDownloadFileArray = await Promise.all(
          results.map(async (result) => {
            if (result.headshotFilePath) {
              const param = encodeURI(result.headshotFilePath);
              return { ...result, headshotFile: await apiDownloadFile(param) };
            }
            return { ...result, headshotFile: "" };
          })
        );
        console.log(apiDownloadFileArray);
        setCompletePairs(getCompletePairResponse.data.result);
      } else {
        showToast(
          "error",
          getCompletePairResponse
            ? getCompletePairResponse.message
            : tRef.current
        );
      }

      // 取得該案件詳細資料
      const params2 = {
        id: parseInt(id),
      };

      const response = await apiGetAvailableFamilyDetail(params2);
      if (response && response.code === 0) {
        const data = response.data;
        console.log(data);
        if (data.plan.headshotFilePath) {
          const param = encodeURI(data.plan.headshotFilePath);
          const downloadFile = await apiDownloadFile(param);
          if (downloadFile) {
            const fileURL = URL.createObjectURL(downloadFile.data);
            setAvailableCaseDetail({
              ...data,
              fileURL: fileURL,
            });
          }
        } else {
          setAvailableCaseDetail({ ...data, fileURL: "" });
        }

        //顯示服務時段
        const startTime = data.plan.careCasePlanTimes[0].startTime;
        const startTimeDate = convertDoubleToDate(startTime);
        const endTimeDate = new Date(startTimeDate);
        endTimeDate.setHours(endTimeDate.getHours() + data.plan.hours);

        // 顯示開始服務日期
        const weeks = ["日", "一", "二", "三", "四", "五", "六"];

        const startDate =
          data.plan.careCasePlanTimes[0].startDate.split("T")[0];

        setServiceTime({
          timeRange: `${formatTimeToHHmm(startTimeDate)} ~ ${formatTimeToHHmm(
            endTimeDate
          )}`,
          startDate: `${startDate}(${weeks[dayjs(startDate).day()]})`,
        });
      } else {
        showToast("error", response ? response.message : tRef.current); // [回傳的訊息] : 網路連線錯誤
      }
    } catch (err) {
      console.log(err);
      showToast("error", err.message);
    }
  }, [id, showToast]);
  //#endregion

  // region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshCompletePairs();
    };

    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      fetchData();
    }

    return () => {};
  }, [refreshCompletePairs]);
  //#endregion

  //#region 選擇完成案件的照服員
  const handleSelectedCompletePair = (id) => {
    const selected = completePairs.find((x) => x.nurseAidesId === id);
    console.log("選中的照服員", selected);
    setSelectedCompletePair(selected);
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[2.1875rem]">
          <form
            className="flex items-start justify-between gap-[0.625rem]"
            action="./pairing2.html"
          >
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              完成案件照服員
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>

          {selectedCompletePair === undefined ||
          selectedCompletePair === null ? (
            <>
              <form className="flex flex-col gap-[2.1875rem]">
                {completePairs && completePairs.length > 0 ? (
                  completePairs.map((completePair, index) => {
                    return (
                      <div
                        href={void 0}
                        className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden cursor-pointer hover:drop-shadow-xl"
                        key={index}
                        onClick={(e) =>
                          handleSelectedCompletePair(completePair.nurseAidesId)
                        }
                      >
                        <div className="bg-[#FFDA5E] container-img ">
                          <img
                            src={
                              completePair.headshotFile !== "" &&
                              completePair.headshotFile !== undefined
                                ? URL.createObjectURL(
                                    completePair.headshotFile.data
                                  )
                                : "./img/user.png"
                            }
                            width="110"
                            height="110"
                            alt="user avator"
                            className="h-full object-center object-cover"
                          />
                        </div>
                        <ul className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6]">
                          <li>照服員姓名：{completePair.nurseAidesName}</li>
                          <li>性別：{completePair.nurseAidesGender}</li>
                          <li>年齡：{completePair.nurseAidesAge}</li>
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </form>
            </>
          ) : (
            <Pairing3Detail id={id} completePair={selectedCompletePair} />
          )}
        </div>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default Pairing3CaregiverList;
