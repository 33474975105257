import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";


/**
 * 資料顯示頁面
 */
const InfoPanel = ({ keyword, CareServiceItemLists, refreshAllCareServiceItemList, name, creatorNum }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    return (
        <div class="main-background success">
            <div className="content">
                <div class="applyHead">
                    <h1>找照服員</h1>
                    <div>
                        <div>
                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.14103 23.0686V18.7213H0.277778V14.1832L10.7829 0.921875H14.2943V14.1499H17.1325V18.7165H14.2943V23.0638H9.13618L9.14103 23.0686ZM5.36809 14.1546H9.75128V8.53929L5.36809 14.1546Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div>
                            <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.84729 23.4213C6.19088 23.4213 4.69914 23.0876 3.36723 22.4203C2.03532 21.7529 1.01339 20.8377 0.306267 19.6746L3.1735 16.5332C3.79829 17.2959 4.51994 17.8918 5.34815 18.3256C6.17635 18.7593 7.01908 18.9738 7.87635 18.9738C8.53988 18.9738 9.12108 18.8404 9.6151 18.5782C10.1091 18.316 10.4966 17.949 10.7775 17.4866C11.0584 17.0194 11.1989 16.4713 11.1989 15.8373C11.1989 15.2462 11.0681 14.7171 10.8114 14.2499C10.5547 13.7828 10.2011 13.43 9.74586 13.1869C9.29544 12.9438 8.76752 12.8246 8.16695 12.8246C7.56638 12.8246 7.0433 12.9533 6.5396 13.206C6.03589 13.4586 5.64359 13.8209 5.36268 14.2833H0.882621L3.55612 0.578613H14.933V5.20721H7.29515L6.65099 9.04452C6.78176 8.89675 7.03362 8.76328 7.40655 8.64887C7.78433 8.53447 8.28319 8.4725 8.90313 8.4725C10.3852 8.4725 11.6977 8.77758 12.8359 9.3925C13.9741 10.0074 14.8652 10.8607 15.5094 11.9618C16.1536 13.063 16.4781 14.3214 16.4781 15.7372C16.4781 17.2578 16.1051 18.5973 15.3641 19.7508C14.6231 20.9044 13.606 21.8006 12.3177 22.4489C11.0293 23.0924 9.53276 23.4165 7.83761 23.4165L7.84729 23.4213Z"
                                    fill="#9E3223"
                                />
                            </svg>
                        </div>
                        <div class="active">
                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.9977 19.6127C15.2567 20.7997 14.2444 21.7435 12.9707 22.4538C11.692 23.164 10.2584 23.5168 8.6698 23.5168C6.88747 23.5168 5.36183 23.0925 4.09289 22.2488C2.82394 21.4051 1.85527 20.18 1.17721 18.5831C0.499145 16.9862 0.160126 15.0652 0.160126 12.8247C0.160126 10.203 0.523364 7.9721 1.2547 6.1321C1.98604 4.2921 3.02252 2.89065 4.36411 1.92775C5.70571 0.96485 7.31369 0.483398 9.1832 0.483398C10.6023 0.483398 11.9245 0.774176 13.1499 1.35573C14.3752 1.93728 15.4359 2.75718 16.3416 3.81542L13.4405 7.0521C12.9029 6.37521 12.2587 5.84609 11.508 5.46475C10.7573 5.0834 9.94845 4.89272 9.09118 4.89272C8.23392 4.89272 7.55584 5.09293 6.93106 5.49335C6.30627 5.89376 5.82194 6.47531 5.48291 7.23801C5.13904 8.0007 4.9453 8.9207 4.90171 9.99801C5.202 9.59759 5.55556 9.26392 5.96724 8.99697C6.37408 8.7348 6.84872 8.52982 7.38633 8.39635C7.92393 8.25811 8.50513 8.19138 9.12507 8.19138C10.6507 8.19138 12.0165 8.52029 13.2177 9.17335C14.4188 9.83117 15.3729 10.713 16.0704 11.8237C16.7678 12.9344 17.1165 14.1976 17.1165 15.6133C17.1165 17.0291 16.7436 18.4258 16.0026 19.608L15.9977 19.6127ZM10.4231 18.7404C10.951 18.4115 11.3772 17.9729 11.7114 17.4247C12.0456 16.8766 12.2103 16.2712 12.2103 15.6181C12.2103 14.9651 12.0504 14.4073 11.7259 13.8877C11.4014 13.3682 10.9752 12.9582 10.4376 12.6531C9.90001 12.3481 9.30913 12.1955 8.66497 12.1955C8.02081 12.1955 7.42992 12.3481 6.89232 12.6531C6.35471 12.9582 5.92365 13.3729 5.604 13.8877C5.27949 14.4073 5.11967 14.9937 5.11967 15.6467C5.11967 16.2998 5.28434 16.8718 5.61853 17.4247C5.95271 17.9729 6.38861 18.4115 6.92137 18.7404C7.45898 19.0693 8.04018 19.2314 8.66012 19.2314C9.28006 19.2314 9.89032 19.0693 10.4182 18.7404H10.4231Z"
                                    fill="#9E3223"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="mb-[10rem]">
                    <div class="flex flex-col gap-[2.5rem]">
                        <div class="flex items-center gap-[0.625rem]">
                            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_113_3391)">
                                    <path
                                        d="M35 35.5188H0V0.806396H32.5864V5.59397H4.82724V30.7313H30.1728V19.2399H35V35.5188Z"
                                        fill="#4CC764"
                                    />
                                    <path
                                        d="M11.3397 16.569L7.96948 19.9966L16.9702 28.7017L20.3404 25.2741L11.3397 16.569Z"
                                        fill="#4CC764"
                                    />
                                    <path
                                        d="M31.3705 7.72332L18.6639 20.2073L22.0612 23.6085L34.7678 11.1246L31.3705 7.72332Z"
                                        fill="#4CC764"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_113_3391">
                                        <rect width="35" height="34.7124" fill="white" transform="translate(0 0.806396)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <strong class="text-primary text-[1.25rem] font-[500] leading-[1.5]">案件成立</strong>
                        </div>
                        <div>
                            <Link to="/FindCareStep">
                                <a
                                    href="./banner.html"
                                    class="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                    title="回主畫面"
                                >
                                    回主畫面
                                </a>
                            </Link>
                        </div>
                    </div>
                    <br />
                    {/* fail */}
                    {/* <div class="flex flex-col gap-[2.5rem]">
                        <div class="flex items-center gap-[0.625rem]">
                            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_113_3556)">
                                    <path
                                        d="M35 35.5868H0V0.866455H32.5864V5.65512H4.82724V30.7981H30.1728V9.72579H35V35.5868Z"
                                        fill="#FF0000"
                                    />
                                    <path
                                        d="M24.6208 8.01737L7.14258 25.0203L10.5224 28.4393L28.0006 11.4364L24.6208 8.01737Z"
                                        fill="#FF0000"
                                    />
                                    <path
                                        d="M10.5074 7.99828L7.15833 11.447L12.3746 16.4318L15.7236 12.9831L10.5074 7.99828Z"
                                        fill="#FF0000"
                                    />
                                    <path
                                        d="M22.6261 20.0193L19.2771 23.468L24.4933 28.4528L27.8424 25.0041L22.6261 20.0193Z"
                                        fill="#FF0000"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_113_3556">
                                        <rect width="35" height="34.7203" fill="white" transform="translate(0 0.866455)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <strong class="text-primary text-[1.25rem] font-[500] leading-[1.5]">案件失敗</strong>
                        </div>
                        <div class="flex items-center gap-[0.9375rem]">
                            <a
                                href="./findCareStep.html"
                                class="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                title="回主畫面"
                            >
                                回主畫面
                            </a>
                            <a
                                href="./findCareStep.html"
                                class="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                title="重新派案"
                            >
                                重新派案
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default InfoPanel;
