import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";
import HamburgerFamilyModal from "../HamburgerModal";
import { apiCreateFeedback } from "../../utils/Api";
import { useMyUserContext } from "../../contexts/MyUserContext";
import TextEmailModal from "./TextEmailModal";

/**
 * 資料顯示頁面
 */
const InfoPanel = ({
  keyword,
  CareServiceItemLists,
  refreshAllCareServiceItemList,
  name,
  creatorNum,
}) => {
  const { t } = useTranslation();
  const { myUser } = useMyUserContext();
  const isSendResponse = useRef(true); //是否送出意見成功
  const { showToast } = useToast();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [feedbackContent, setFeedbackContent] = useState(""); // 儲存textarea內容
  const navigate = useNavigate();
  const [showModule, setShowModule] = useState(false);
  const [email, setEmail] = useState("");

  //#region 星星評分
  function Star({ marked, starId }) {
    return (
      <span
        star-id={starId}
        style={{ color: "#ff9933", fontSize: "2.5rem" }}
        role="button"
      >
        {/* 空星，實星 */}
        {marked ? "\u2605" : "\u2606"}
      </span>
    );
  }

  function StarRating(props) {
    // 分數顯示
    const [rating, setRating] = React.useState(
      typeof props.rating == "number" ? props.rating : 0
    );
    // 鼠標移入效果
    const [selection, setSelection] = React.useState(0);
    const hoverOver = (event) => {
      let val = 0;
      if (event && event.target && event.target.getAttribute("star-id"))
        val = event.target.getAttribute("star-id");
      setSelection(val);
    };
    return (
      <div
        // 鼠標移入效果
        onMouseOut={() => hoverOver(null)}
        // 點擊選中分數
        onClick={(event) =>
          setRating(event.target.getAttribute("star-id") || rating)
        }
        onMouseOver={hoverOver}
      >
        {/* 創建5個组件 */}
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1} `}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
          />
        ))}
      </div>
    );
  }
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  //#region 關閉模組
  const handleClose = () => {
    setShowModule(false);
  };
  //#endregion

  //#region 送出評價
  const handleSubmitFeedback = async (event) => {
    event.preventDefault();
    if (!isSendResponse.current) {
      return;
    }

    if (feedbackContent !== "" && (myUser?.mail !== "" || email !== "")) {
      isSendResponse.current = false;
      const feedbackData = {
        id: 0,
        content: feedbackContent,
        mail: email !== "" ? email : "",
      };

      try {
        const response = await apiCreateFeedback(feedbackData);
        console.log("response", response);
        if (response && response.code === 0) {
          showToast("success", "評價已成功送出");
          navigate("/CommentSuccess");
        } else {
          showToast("error", "送出評價失敗，請稍後再試");
          console.log("有讀到API但送出失敗");
        }
      } catch (error) {
        showToast("error", "送出評價失敗，請稍後再試");
        console.log(error);
      }
    } else {
      console.log(myUser);
      myUser.mail !== ""
        ? showToast("error", "欄位不得為空")
        : setShowModule(true);
    }
  };
  //#endregion
  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-center  justify-between gap-[0.625rem] "
            action="./pairing-comment.html"
          >
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              意見回饋
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <form
            className="flex flex-col gap-[2.1875rem]"
            action="./findCareStep6.html"
          >
            <input type="hidden" name="id" value="1" />
            <div className="flex flex-col items-center gap-[1.5625rem]">
              <p className="text-primary text-[1.25rem] text-justify leading-[1.5]">
                <strong>您的反饋對我們非常重要</strong>
                <br />
                感謝您使用本系統，為幫助我們不斷改進並為您提供更好的服務，誠摯地邀請您分享您的使用體驗和建議。
              </p>
              <div className="w-full flex flex-col items-center gap-[1.625rem] mt-[1rem]">
                <textarea
                  className="w-full p-[1rem] bg-white border-[0.125rem] border-primary text-primary text-base placeholder:text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  name="comment"
                  id="comment"
                  maxLength="500"
                  cols="30"
                  rows="6"
                  placeholder="感謝您寶貴的意見(0/500)"
                  aria-label="評論"
                  value={feedbackContent}
                  onChange={(e) => setFeedbackContent(e.target.value)}
                ></textarea>
                {/* 先暫時拿掉用不到 */}
                {/* <div className="-mb-[1.0625rem]">
                  <StarRating />
                  <input type="hidden" name="rating" id="rating" value="0" />
                </div> */}
              </div>
            </div>
            <div className="flex items-center justify-center gap-[0.75rem]">
              <button
                type="submit"
                className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="送出意見"
                onClick={handleSubmitFeedback}
              >
                送出意見
              </button>
            </div>
          </form>
        </div>
      </div>
      <TextEmailModal
        showModal={showModule}
        handleClose={handleClose}
        setEmail={setEmail}
        email={email}
      />

      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default InfoPanel;
