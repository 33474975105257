import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { CreateValidator, ValidateFields, ValidateField } from '../../utils/Validator';

import {
    GetDistsByCityId,
    apiGetAllCity,
} from "../../utils/Api";

/**
 * 建立家屬帳號-基本資料(step2)
 */
const RegisiterAccountStep2 = ({ onNextStep, onPreviousStep, onChangeCreateFamily, onChangeBirthdayData, onChangeCityData, onChangeDistData, createFamily }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const validator = CreateValidator();
    const [cities, setCities] = useState([]);//縣市
    const [towns, setTowns] = useState([]);//地區
    // 拿到現在的時間
    const currentDate = new Date();
    const currentyear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const [birthdayYear, setBirthdayYear] = useState(currentyear.toString());//生日 - 年
    const [birthdayMonth, setBirthdayMonth] = useState(currentMonth.toString());//生日 - 月
    const [birthdayDay, setBirthdayDay] = useState(currentDay.toString());//生日 -日
    const [dayOptions, setDayOptions] = useState([]);
    const [password, setPassword] = useState('');//確認密碼

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const [errors, setErrors] = useState({
        username: "",
        password: "",
        name: "",
        gender: "",
        birthday: "",
        cityId: "",
        distId: "",
        address: "",
        phone: "",
        mail: ""
    });


    //#region 初始載入
    useEffect(() => {
        const fetchData = async () => {
            await refreshAllCity();

            if (createFamily.birthday == "") {
                const birthdayString = `${birthdayYear}/${birthdayMonth}/${birthdayDay}`;
                onChangeBirthdayData(birthdayString);
            }
            else {
                var splitBirthday = createFamily.birthday.split("/");
                setBirthdayYear(splitBirthday[0]);
                setBirthdayMonth(parseInt(splitBirthday[1]));
                setBirthdayDay(parseInt(splitBirthday[2]));
            }

        };
        fetchData();
        return () => { };
    }, []);
    //#endregion

    //#region 取得縣市
    const refreshAllCity = async () => {
        const getAllCityResponse = await apiGetAllCity();
        if (getAllCityResponse && getAllCityResponse.code === 0) {
            setCities(getAllCityResponse.data.result);
        } else {
            showToast(
                "error",
                getAllCityResponse
                    ? getAllCityResponse.message
                    : "取得縣市失敗"
            );
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (cities.length > 0 && createFamily && createFamily.cityId != 0) {
                var params = {
                    cityId: parseInt(createFamily.cityId),
                };
                const getDistsResponse = await GetDistsByCityId(params);
                if (getDistsResponse && getDistsResponse.code === 0) {
                    // 更新 towns
                    setTowns(getDistsResponse.data.result);
                } else {
                    showToast(
                        "error",
                        getDistsResponse ? getDistsResponse.message : "取得城市失敗"
                    );
                }
            }
        };
        fetchData();
        return () => { };
    }, [cities]);
    //#endregion

    //#region 更改生日-年
    const handlebirthdayYear = (e) => {
        const { name, value } = e.target;
        setBirthdayYear(value);

        const birthdayString = `${value}/${birthdayMonth}/${birthdayDay}`;
        onChangeBirthdayData(birthdayString);
    };
    //#endregion

    //#region 更改生日-月
    const handlebirthdayMonth = (e) => {
        const { name, value } = e.target;
        setBirthdayMonth(value);

        const birthdayString = `${birthdayYear}/${value}/${birthdayDay}`;
        onChangeBirthdayData(birthdayString)
    };
    //#endregion

    //#region 更改生日-日
    const handlebirthdayDay = (e) => {
        const { name, value } = e.target;
        setBirthdayDay(value);

        const birthdayString = `${birthdayYear}/${birthdayMonth}/${value}`;
        onChangeBirthdayData(birthdayString)
    };
    //#endregion

    //#region 生日相關
    const currentYear = new Date().getFullYear();
    const startYear = 1911;

    const yearOptions = [];
    yearOptions.push(
        <option key="" value="">
            請選擇年份
        </option>
    );
    for (let year = currentYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    const monthOptions = [];
    monthOptions.push(
        <option key="" value="">
            請選擇月份
        </option>
    );
    for (let month = 1; month <= 12; month++) {
        monthOptions.push(
            <option key={month} value={month}>
                {month}
            </option>
        );
    }

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const updateDayOptions = () => {
        if (birthdayYear && birthdayMonth) {
            const daysInSelectedMonth = getDaysInMonth(birthdayYear, birthdayMonth);
            const updatedDayOptions = [];
            updatedDayOptions.push(
                <option key="" value="">
                    請選擇日期
                </option>
            );

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1;
            const currentDay = currentDate.getDate();

            // 檢查是否是當月或之前的日期
            if (
                parseInt(birthdayYear) < currentYear ||
                (parseInt(birthdayYear) === currentYear && parseInt(birthdayMonth) <= currentMonth)
            ) {
                // 如果是當月，則僅添加當日及之前的日期
                if (parseInt(birthdayMonth) === currentMonth) {
                    for (let day = 1; day <= currentDay; day++) {
                        updatedDayOptions.push(
                            <option key={day} value={day}>
                                {day}
                            </option>
                        );
                    }
                } else {
                    // 如果不是當月，則添加整個月份的日期
                    for (let day = 1; day <= daysInSelectedMonth; day++) {
                        updatedDayOptions.push(
                            <option key={day} value={day}>
                                {day}
                            </option>
                        );
                    }
                }
            }
            setDayOptions(updatedDayOptions);
        }
    };


    useEffect(() => {
        updateDayOptions();
    }, [birthdayYear, birthdayMonth]);
    //#endregion

    //#region 取得區域
    const handleCityChange = async (cityId) => {
        // 更新父層中createFamily的cityId
        onChangeCityData(parseInt(cityId))
        
        if (cityId === "") {
            // 清空地區的下拉選單
            setTowns([]);
            // 清空選中的地區
            onChangeDistData("");
            return;
        }
        var params = {
            cityId: parseInt(cityId),
        };
        const getDistsResponse = await GetDistsByCityId(params);
        if (getDistsResponse && getDistsResponse.code === 0) {
            // 更新 towns
            setTowns(getDistsResponse.data.result);
        } else {
            showToast(
                "error",
                getDistsResponse ? getDistsResponse.message : "取得城市失敗"
            );
        }
    };
    //#endregion

    //#region 失去焦點Input的欄位
    const handleBlur = async (event) => {
        const { name, value } = event.target;
        let newErrors = { ...errors };

        // 立即驗證空輸入並更新錯誤狀態
        if (value.trim() == "") {
            newErrors[name] = "此欄位為必填項目";
            console.log("空輸入！")
        } else {
            // 如果輸入不為空，則進行密碼驗證
            const { result, errors: validationErrors } = await ValidateField(name, value, newErrors, validator);
            newErrors = { ...newErrors, ...validationErrors };
            if (name === "password" && !result) {
                showToast('warning', "密碼須為 6 到 12 位的英文大小寫與數字！");
            }
            // 比對確認密碼與密碼欄位是否一致
            if (name === "confirmPassword" && value !== createFamily.password) {
                newErrors.confirmPassword = "確認密碼與密碼不一致";
                showToast('warning', "確認密碼與密碼不一致！");
            }

            if (name === "username") {
                if (!/^[a-zA-Z0-9]{4,12}$/.test(value)) {
                    newErrors[name] = "帳號需為 4 到 12 位的英文大小寫與數字";
                    showToast('warning', "帳號需為 4 到 12 位的英文大小寫或數字");
                } else {
                    newErrors[name] = "";
                }
            }
        }
        // 對地址的縣市和地區進行驗證
        if (name === "addressCity" || name === "addressTown") {
            if (value.trim() === "") {
                newErrors[name] = "此欄位為必填項目";
            } else {
                newErrors[name] = "";
            }
        }
        setErrors(newErrors);
    };
    //#endregion

    //#region 欄位驗證
    const checkValidator = async () => {
        let newErrors = { ...errors };
        var validateFieldsResult = await ValidateFields(createFamily, newErrors, ["phone", "mail"]);
        if (!validateFieldsResult.result) {
            setErrors(newErrors);
        }
        // 檢查地址下拉框的值是否為空
        if (!createFamily.cityId) {
            newErrors.addressCity = "此欄位為必填項目";
        } else {
            newErrors.addressCity = "";
        }
    
        if (!createFamily.distId) {
            newErrors.addressTown = "此欄位為必填項目";
        } else {
            newErrors.addressTown = "";
        }
    
        setErrors(newErrors);
    
        return validateFieldsResult.result && !newErrors.addressCity && !newErrors.addressTown;
    };
    //#endregion

    //#region 下一步
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // 檢查地址下拉框的值是否為空
        const addressCity = createFamily.cityId;
        const addressTown = createFamily.distId;
    
        if (!addressCity || !addressTown) {
            let newErrors = { ...errors };
    
            if (!addressCity) {
                newErrors.addressCity = "此欄位為必填項目";
            }
    
            if (!addressTown) {
                newErrors.addressTown = "此欄位為必填項目";
            }
    
            setErrors(newErrors);
    
            showToast("error", "請選擇完整地址");
            return; // 阻止表單提交
        }
    
        // 檢查生日欄位是否為空
        if (!birthdayYear || !birthdayMonth || !birthdayDay) {
            showToast("error", "請選擇完整生日");
            return; // 阻止表單提交
        }
    
        // 進行其他表單提交邏輯
        if (await checkValidator()) {
            onNextStep();
        } else {
            showToast("error", "有必填欄位未填寫");
        }
    };
    //#endregion


    // 處理Enter 鍵的按下 不要有任何動作
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <>
            <div className="applyHead">
                <h1>申請會員</h1>
                <div>
                    <div>
                        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="active">
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </div>
                    <div>
                        <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <form className="flex flex-col gap-[1.875rem]" action="./regisiterAccountStep3.html">
                <div className={`flex items-center gap-[0.5rem] ${errors.username ? 'error' : ''}`}>
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]" htmlFor="username">
                        帳號*
                    </label>
                    <div className="flex items-center grow">
                        <input
                            type="text"
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            name="username"
                            id="username"
                            placeholder="｜帳號需為 4 到 12 位的英文大小寫或數字"
                            value={createFamily.username}
                            onChange={onChangeCreateFamily}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                {errors.username && (
                    <div className="text-red-500 text-sm mt-1 ml-1 error-message">此欄位為必填欄位</div>
                )}
                <div className="flex items-center gap-[0.5rem]">
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]" htmlFor="password">
                        密碼*
                    </label>
                    <div className="flex items-center grow">
                        <input
                            type="password"
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            name="password"
                            id="password"
                            placeholder="｜須為 6 到 12 位的英文大小寫及數字"
                            value={createFamily.password}
                            onChange={onChangeCreateFamily}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                {errors.password && (
                    <div className="text-red-500 text-sm mt-1 ml-1 error-message">此欄位為必填欄位</div>
                )}
                <div className="flex items-center gap-[0.5rem]">
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]" htmlFor="confirmPassword">
                        確認密碼*
                    </label>
                    <div className="flex items-center grow">
                        <input
                            type="password"
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="｜再次輸入密碼"
                            value={createFamily.confirmPassword}
                            onChange={onChangeCreateFamily}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                {errors.confirmPassword && (
                    <div className="text-red-500 text-sm mt-1 ml-1 error-message">此欄位為必填欄位</div>
                )}
                <div className="flex items-center gap-[0.5rem]">
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]" htmlFor="name">
                        姓名*
                    </label>
                    <div className="flex items-center grow">
                        <input
                            type="text"
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            name="name"
                            id="name"
                            placeholder="｜請輸入姓名"
                            value={createFamily.name}
                            onChange={onChangeCreateFamily}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                {errors.name && (
                    <div className="text-red-500 text-sm mt-1 ml-1 error-message">此欄位為必填欄位</div>
                )}
                <div className="flex items-center gap-[0.5rem]">
                    <label htmlFor="man" className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]">
                        性別*
                    </label>
                    <div className="flex items-center gap-[0.75rem] grow">
                        <div className="inline-flex items-center justify-center">
                            <label className="relative flex items-center p-1 rounded-full cursor-pointer" htmlFor="man">
                                <input
                                    id="man"
                                    type="radio"
                                    name="gender"
                                    value="1"
                                    checked={createFamily.gender == 1}
                                    onChange={onChangeCreateFamily}
                                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                                    onKeyDown={handleKeyDown}
                                />
                                <span
                                    className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="1"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                            </label>
                            <label className="font-light text-primary text-[1.25rem] cursor-pointer select-none" htmlFor="man">
                                男
                            </label>
                        </div>
                        <div className="inline-flex items-center justify-center">
                            <label className="relative flex items-center p-1 rounded-full cursor-pointer" htmlFor="woman">
                                <input
                                    id="woman"
                                    type="radio"
                                    name="gender"
                                    value="2"
                                    checked={createFamily.gender == 2}
                                    onChange={onChangeCreateFamily}
                                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                                    onKeyDown={handleKeyDown}
                                />
                                <span
                                    className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="1"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                            </label>
                            <label className="font-light text-primary text-[1.25rem] cursor-pointer select-none" htmlFor="woman">
                                女
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-[0.5rem]">
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]">
                        生日*
                    </label>
                    <div className="flex items-center grow">
                        <div className="grid grid-cols-3 gap-[1.125rem]">
                            <div className="form-group col-md-4 col-4">
                                <select
                                    name="birthdayYear"
                                    value={birthdayYear}
                                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                    onChange={handlebirthdayYear}
                                    onBlur={handleBlur}
                                >
                                    {yearOptions}
                                </select>
                            </div>
                            <div className="form-group col-md-4 col-4">
                                <select
                                    name="birthdayMonth"
                                    value={birthdayMonth}
                                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                    onChange={handlebirthdayMonth}
                                    onBlur={handleBlur}
                                >
                                    {monthOptions}
                                </select>
                            </div>
                            <div className="form-group col-md-4 col-4">
                                <select
                                    name="birthdayDay"
                                    value={birthdayDay}
                                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                    onChange={handlebirthdayDay}
                                    onBlur={handleBlur}
                                >
                                    {dayOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-start gap-[0.5rem]">
                    <label htmlFor="addressCity" className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]">
                        住址*
                    </label>
                    <div className="flex items-center grow">
                        <div className="w-full grid grid-cols-2 gap-[1.125rem]">
                            <select
                                id="addressCity"
                                name="addressCity"
                                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                aria-label="縣市"
                                onChange={(e) => handleCityChange(e.target.value)}
                                value={createFamily.cityId}
                                onBlur={handleBlur}
                            >
                                <option value="">請選擇</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                            <select
                                id="addressTown"
                                name="addressTown"
                                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                aria-label="地區"
                                onChange={(e) => onChangeDistData(parseInt(e.target.value))}
                                value={createFamily.distId}
                                onBlur={handleBlur}
                            >
                                <option value="">請選擇</option>
                                {towns.map((town) => (
                                    <option key={town.id} value={town.id}>
                                        {town.name}
                                    </option>
                                ))}
                            </select>

                            <div className="col-span-2">
                                <input
                                    type="text"
                                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                                    name="address"
                                    id="address"
                                    placeholder="Ex:河南路2段"
                                    value={createFamily.address}
                                    onChange={onChangeCreateFamily}
                                    error={errors.address}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {(errors.addressCity || errors.addressTown || errors.address) && (
                    <div className="text-red-500 text-sm mt-1 ml-1 error-message">請填寫完整地址</div>
                )}
                <div className="flex items-center gap-[0.5rem]">
                    <label className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]" htmlFor="mail">
                        信箱
                    </label>
                    <div className="flex items-center grow">
                        <input
                            type="mail"
                            className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                            name="mail"
                            id="mail"
                            placeholder="｜請輸入信箱"
                            value={createFamily.mail}
                            onChange={onChangeCreateFamily}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-end gap-[0.75rem]">
                    <button
                        type="submit"
                        className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                        title="上一步"
                        onClick={onPreviousStep}
                    >
                        上一步
                    </button>

                    <button
                        type="submit"
                        className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                        title="下一步"
                        onClick={handleSubmit}
                    >
                        下一步
                    </button>
                </div>
            </form >
        </>
    );
};

export default RegisiterAccountStep2;
