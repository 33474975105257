import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { apiGetCareVideoGetAll } from "../../utils/Api";
import HamburgerFamilyModal from "../HamburgerModal";

/**
 * 資料顯示頁面
 */
const CareVideoList = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate(); //跳轉Router
  const [CareVideosData, setCareVideos] = useState([]);
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllCareCaseVideos();
    };
    fetchData();
    return () => {};
  }, []);

  //#region 刷新案件列表
  const refreshAllCareCaseVideos = async () => {
    var response = await apiGetCareVideoGetAll();
    if (response && response.code === 0) {
      console.log(response.data.result);
      setCareVideos(response.data.result);
    } else {
      showToast("error", response ? response.message : t("toast.unknownError")); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  
  const handleCareVideo = (id) =>{
    navigate(`/CareVideo/${id}`)
  }
  //#endregion

  return (
    <div className="main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-start justify-start gap-[0.625rem]"
            action="./projectList.html"
          >
            <strong className="max-w-[6.5em] text-primary text-[1.5rem] leading-[1.5]">
              照顧影音
            </strong>
            <div className="flex items-center justify-end gap-[0.625rem] grow">
              <a
                data-bs-toggle="modal"
                href="#"
                className="flex-none"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <form className="flex flex-col gap-[2.1875rem]" action="">
          <div className="flex flex-col gap-[1.5625rem]" >

          {CareVideosData.map((careVideosData, index) => {              
                return (
                  <a onClick={(e) => handleCareVideo(careVideosData.id)}
                  className={
                    "flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden"
                  }>
                    <ul className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] text-white text-xs md:text-sm lg:text-base leading-[1.6]">
                      <li>標題 : {careVideosData.title}</li>
                      <li>
                        影片大綱 : {careVideosData.outLine}
                      </li>
                    </ul>
                  </a>
                );
                })}
            </div>
          </form>
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default CareVideoList;
