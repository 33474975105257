import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { apiVerifyMailToken } from "../../utils/Api";
import MailVerificationPending from "./MailVerificationPending";
import MailVerificationSuccess from "./MailVerificationSuccess";
import MailVerificationFail from "./MailVerificationFail";


const MailVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("verifyToken"));
  const [verificationStatus, setVerificationStatus] = useState("pending");

  // Verify token
  useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      apiVerifyMailToken({
        verifyCode: token
      })
        .then(response => {
          // Since fetchDataCall only returns data, we must check whether response data contains an error to judge whether verification passes.
          if (response.message.includes("Error")) {
            setVerificationStatus("fail")
          } else {
            setVerificationStatus("success")
          }
        })
    } else {
      setVerificationStatus("fail")
    }
  }, [token])
  
  switch (verificationStatus) {
    case "pending":
      return <MailVerificationPending />
    case "success":
      return <MailVerificationSuccess />
    case "fail":
      return <MailVerificationFail />
    default:
      return <MailVerificationPending />
  }
}

export default MailVerification;