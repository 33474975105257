import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import HamburgerModal from "../HamburgerModal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import { apiCareCaseQueryByDate } from "../../utils/Api";
/**
 *
 * 行程查詢(照服員)
 */

const InfoPanel = () => {
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const isEffectCalledRef = useRef(false);
  const { showToast } = useToast();
  const navigate = useNavigate(); //跳轉Router
  const [status, setStatus] = useState(-1);
  const [careCases, setCareCases] = useState([]);
  const [selectDay, setSelectDay] = useState(new Date()); //Calender選擇的值
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const colorGreen = {
    "--color": "#4cc764",
    height: "3rem",
  };
  const colorOrange = {
    "--color": "#ed6c00",
    height: "3rem",
  };
  const colorBlue = {
    "--color": "#90CBFB",
    height: "3rem",
  };
  const colorYellow = {
    "--color": "#FFDA40",
    height: "3rem",
  };
  const colorRed = {
    "--color": "#ff0000",
    height: "3rem",
  };

  //  #region 初始載入
  useEffect(() => {
    if (!isEffectCalledRef) {
      isEffectCalledRef.current = true;
      refreshCareCase();
    }
  });
  //#endregion

  // #region 刷新選擇日期的案件列表
  const refreshCareCase = async (day) => {
    const tempDay = day ? day : new Date();
    const params = {
      date: dayjs(tempDay).format("YYYY/MM/DD"),
    };
    const apicheckScheduleResponse = await apiCareCaseQueryByDate(params);
    if (apicheckScheduleResponse && apicheckScheduleResponse.code === 0) {
      console.log(apicheckScheduleResponse.data.result);

      setCareCases(apicheckScheduleResponse.data.result);
    } else {
      showToast(
        "error",
        apicheckScheduleResponse ? apicheckScheduleResponse.message : tRef
      );
    }
  };
  //#endregion

  //#region跳轉頁面
  const handleNavigate = (status, id) => {
    switch (status) {
      case "配對成功":
        navigate(`/Chats/${id}`);
        break;
      default:
        break;
    }
  };
  //#endregion

  //   #region 日期選擇
  const handleSelectDayClick = (day) => {
    setSelectDay(day);
    refreshCareCase(day);
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  useEffect(() => {
    console.log(selectDay);
  }, [selectDay]);

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-start justify-start gap-[0.625rem]"
            action="./projectList.html"
          >
            {/* <strong className="max-w-[6.5em] text-primary text-[1.5rem] leading-[1.5]">照服員 主畫面</strong> */}
            <div className="flex items-center gap-[0.625rem] grow justify-between">
              <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
                行程查詢
              </strong>
              <span
                className="flex-none cursor-pointer"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </form>
          <form className="flex flex-col gap-[2.1875rem]">
            <Calendar
              minDate={new Date()}
              className="!w-full"
              onChange={handleSelectDayClick}
              value={selectDay}
            />
            {careCases.length > 0 ? (
              careCases.map((careCase, index) => {
                return (
                  <div
                    key={careCase.id}
                    // style={{ cursor: "pointer" }}
                    className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden
                  hover:shadow-2xl"
                  >
                    <div className="flex-none bg-[#FFDA5E]">
                      <img
                        src="./img/user.png"
                        className="h-full object-center object-cover"
                        width="110"
                        height="100"
                        alt="user avator"
                      />
                    </div>
                    <ul
                      style={{ cursor: "pointer" }}
                      className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6] "
                      onClick={() => {
                        handleNavigate(careCase.status, careCase.id);
                      }}
                    >
                      <li>案件編號：{careCase.caseNo}</li>
                      <li>
                        家屬：{careCase.mainFamily.name} |{" "}
                        <a
                          className="hover:text-[#FFDA40] "
                          href={`tel:${careCase.mainFamily.phone}`}
                        >
                          {careCase.mainFamily.phone}
                        </a>
                      </li>
                      {careCase.subFamily.name != "" ? (
                        <li>
                          次要家屬：{careCase.subFamily.name} |{" "}
                          <a href={`tel:${careCase.subFamily.phone}`}>
                            {careCase.subFamily.phone}
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                      {careCase.hospitalName != "" ? (
                        <li>醫院：{careCase.hospitalName}</li>
                      ) : (
                        <></>
                      )}
                      {careCase.homeAddress != "" ? (
                        <li>住址：{careCase.homeAddress}</li>
                      ) : (
                        <></>
                      )}
                      {careCase.status == "進行中" ? (
                        <>
                          <li
                            className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                            style={colorOrange}
                          >
                            <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                              進行中
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {careCase.status == "配對中" ? (
                        <>
                          <li
                            className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                            style={colorBlue}
                          >
                            <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                              配對中
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {careCase.status == "已完成" ? (
                        <>
                          <li
                            className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                            style={colorGreen}
                          >
                            <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                              已完成
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {careCase.status == "失敗/取消" ? (
                        <>
                          <li
                            className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                            style={colorRed}
                          >
                            <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                              失敗取消
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {careCase.status == "配對成功" ? (
                        <>
                          <li
                            className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-full bg-[var(--color)]"
                            style={colorYellow}
                          >
                            <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                              配對成功
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default InfoPanel;
