import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNoLink from "../../layouts/HeaderNoLink";
import RegisiterAccount from "../../components/Regisiter/RegisiterAccount";

/**
 * 選擇身分
 * @returns
 */
const InfoPanel = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="design">
                <HeaderNoLink />
                <RegisiterAccount />
            </div>
        </>
    );
};

export default InfoPanel;
