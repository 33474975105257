import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useParams } from "react-router-dom";
import HamburgerFamilyModal from "../HamburgerModal";
import WaitingPairDetail from "./WaitingPairDetail";
import {
  apiCareCaseFamilyMainGetWaitingPair,
  apiCareCaseFamilyMainReplyWaitingPair,
  apiDownloadFile,
} from "../../utils/Api";

/**
 * 配對成功案件列表
 */
const WaitingPairPanel = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [waitingPairs, setWaitingPairs] = useState([]); //等待配對照服員
  const [selectedWaitingPair, setSelectedWaitingPair] = useState(); //選擇中的照服員

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllWaitingPairs();
    };
    fetchData();
    return () => {};
  }, []);
  //#endregion

  //#region 刷新等待配對照服員
  const refreshAllWaitingPairs = async () => {
    var params = {
      CareCaseId: parseInt(id),
    };

    const response = await apiCareCaseFamilyMainGetWaitingPair(params);
    if (response && response.code === 0) {
      const results = response.data.result;
      let waitingPair = [];
      if (results) {
        waitingPair = await Promise.all(
          results.map(async (result) => {
            if (result.headshotFilePath) {
              const param = encodeURI(result.headshotFilePath);

              return { ...result, headshotFile: await apiDownloadFile(param) };
            }
            return { ...result, headshotFile: "" };
          })
        );
      }

      setWaitingPairs(waitingPair);
    } else {
      showToast("error", response ? response.message : t("toast.unknownError")); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 選擇配對照服員
  const handleSelectedWaitingPair = (id) => {
    let selected = waitingPairs.find((x) => x.pairId == id);
    console.log("選中的照服員", selected);
    setSelectedWaitingPair(selected);
  };
  //#endregion

  //#region 選擇配對照服員
  const handleReplyWaitingPair = async (isAccept) => {
    var params = {
      pairId: selectedWaitingPair.pairId,
      isAccept: isAccept,
    };

    var replyWaitingPairResponse = await apiCareCaseFamilyMainReplyWaitingPair(
      params
    );
    if (replyWaitingPairResponse && replyWaitingPairResponse.code === 0) {
      refreshAllWaitingPairs();
      setSelectedWaitingPair(null);
    } else {
      showToast(
        "error",
        replyWaitingPairResponse
          ? replyWaitingPairResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form className="flex items-start justify-between gap-[0.625rem]">
            <strong className="max-w-[7.7em] grow text-primary text-[1.5rem] leading-[1.5]">
              申請服務照服員
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          {selectedWaitingPair == undefined || selectedWaitingPair == null ? (
            <form className="flex flex-col gap-[2.1875rem]">
              {waitingPairs && waitingPairs.length > 0 ? (
                waitingPairs.map((waitingPair, index) => {
                  return (
                    <div
                      className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden"
                      key={index}
                      onClick={(e) =>
                        handleSelectedWaitingPair(waitingPair.pairId)
                      }
                    >
                      <div className="bg-[#FFDA5E] container-img ">
                        <img
                          src={
                            waitingPair.headshotFile !== "" &&
                            waitingPair.headshotFile !== undefined
                              ? URL.createObjectURL(
                                  waitingPair.headshotFile.data
                                )
                              : "./img/user.png"
                          }
                          width="110"
                          height="100"
                          alt="user avator"
                          className="h-full object-center object-cover"
                        />
                      </div>
                      <ul className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6]">
                        <li>照服員姓名：{waitingPair.nurseAidesName}</li>
                        <li>性別：{waitingPair.nurseAidesGender}</li>
                        <li>年齡：{waitingPair.nurseAidesAge}</li>
                      </ul>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </form>
          ) : (
            <WaitingPairDetail
              waitingPair={selectedWaitingPair}
              onReplyWaitingPair={handleReplyWaitingPair}
            />
          )}
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default WaitingPairPanel;
