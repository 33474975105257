import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { format } from "date-fns";
import {
  apiCareCaseFamilyMainCreate,
  apiCareCaseFamilyMainCreateInHome,
  apiSystemCode,
} from "../../utils/Api";

import {
  convertDoubleToDate,
  convertTimeToDouble,
  formatTimeToHHmm,
  formatToMMDD,
} from "../../utils/Helpers";
import dayjs from "dayjs";

/**
 * 家屬找照服員-案件確認資訊(step5)
 */
const CaseConfirm = ({
  onPreviousStep,
  onPreviousStepNoE,
  createCareCase,
  careServiceItems,
  plans,
  type,
}) => {
  const navigate = useNavigate(); //跳轉Router
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const { showToast } = useToast();
  const [serviceDateTimes, setServiceDateTimes] = useState([]); //服務日期時間預覽
  const [careServiceItemPreviews, setCareServiceItemPreviews] = useState([]); //服務需求項目預覽
  const [tmpTotalPrice, setTmpTotalPrice] = useState(0); //預估總費用(未算平台費)
  const [totalPrice, setTotalPrice] = useState(0); //預估總費用
  const [platformFee, setPlatformFee] = useState(0); //平台費用
  const [agree, setAgree] = useState(false); //是否同意上述規範

  //#region 整理出服務資訊、總金額
  useEffect(() => {
    console.log("createCareCase", createCareCase);
    //#region 服務日期時間
    if (createCareCase && createCareCase.planId > 0) {
      var selectedPlan = plans.find((x) => x.id === createCareCase.planId);

      const tempDatas = createCareCase.serviceDates.map((date) => {
        const formatDate = formatToMMDD(date);
        var dateTime = convertDoubleToDate(createCareCase.serviceTime);
        const formattedStartTime = formatTimeToHHmm(dateTime);
        console.log(typeof createCareCase.serviceTime);
        var endTime = new Date(dateTime.getTime()); // 結束時間，複製開始時間

        endTime.setHours(endTime.getHours() + selectedPlan.duration); // 增加時間

        const formattedEndTime = formatTimeToHHmm(endTime); // 格式化結束時間
        console.log(formattedEndTime);
        // 調整時間格式，處理時間跨越一天的情況
        const adjustedDateTime = adjustDateTime(
          formatDate,
          formattedStartTime,
          formattedEndTime
        );
        return adjustedDateTime;
      });

      setServiceDateTimes(tempDatas);
    }
    //#endregion

    //#region 服務需求項目
    if (createCareCase && createCareCase.careServiceItems.length > 0) {
      const tmpCareServiceItems = createCareCase.careServiceItems.map(
        (data) => {
          const selectCareServiceItem = careServiceItems.find(
            (x) => x.id == data.value
          );

          //費用名稱格式化
          return `${selectCareServiceItem.name}，費用小計：$ ${
            selectCareServiceItem.fee * createCareCase.serviceDates.length
          }`;
        }
      );

      setCareServiceItemPreviews(tmpCareServiceItems);
    }
    //#endregion

    //#region 總金額
    let totalPrice = 0;
    if (createCareCase && createCareCase.careServiceItems.length > 0) {
      // 計算服務需求項目金額
      createCareCase.careServiceItems.forEach((item) => {
        const targetCareServiceItem = careServiceItems.find(
          (x) => x.id === item.value
        );
        if (targetCareServiceItem) {
          totalPrice +=
            targetCareServiceItem.fee * createCareCase.serviceDates.length;
        }
      });
    }

    if (createCareCase && createCareCase.planId > 0) {
      // 計算照顧方案天數時間
      const targetPlan = plans.find((x) => x.id === createCareCase.planId);
      if (targetPlan) {
        if (targetPlan.unit === "天") {
          totalPrice += targetPlan.fee * createCareCase.serviceDates.length;
        } else if (targetPlan.unit === "小時") {
          totalPrice +=
            targetPlan.fee *
            targetPlan.duration *
            createCareCase.serviceDates.length;
        }
      }
    }
    // #region 取得計算趴數計算金額(還在effect裡面)
    const fetchData = async () => {
      const param = [
        {
          systemKey: "PlatformFee",
        },
      ];
      const serviceFeeRateResponse = await apiSystemCode(param);
      if (serviceFeeRateResponse && serviceFeeRateResponse.code === 0) {
        console.log(serviceFeeRateResponse.data[0].systemValue);
        const serviceFeeRate = Number(
          serviceFeeRateResponse.data[0].systemValue
        );
        if (serviceFeeRate) {
          // 四捨五入
          const customRound = (totalPrice) => {
            const tempResult = totalPrice * serviceFeeRate;
            const floatRound = Number(tempResult.toFixed(2));

            return Math.round(floatRound); //整數四捨五入
          };
          const tmpPlatformFee = customRound(totalPrice);
          setPlatformFee(tmpPlatformFee);
          totalPrice = totalPrice + tmpPlatformFee;
          setTotalPrice(totalPrice);
        }
      } else {
        showToast(
          "error",
          serviceFeeRateResponse ? serviceFeeRateResponse.message : tRef.current
        );
      }
    };
    //#endregion

    fetchData();
  }, [createCareCase]);
  //#endregion

  //#region 顯示案件確認資訊正確的時間與日期，處理了跨一天的情形
  function adjustDateTime(formatDate, startTime, endTime) {
    // 將時間轉換為24小時制的數值表示
    const startHour = parseInt(startTime.split(":")[0], 10);
    const endHour = parseInt(endTime.split(":")[0], 10);

    // 處理時間跨越一天的情況
    if (endHour < startHour || endHour == startHour) {
      const currentDate = new Date(formatDate);
      const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

      // 取得日期及星期幾
      const currentDayOfWeek = getDayOfWeek(currentDate);
      const nextDayOfWeek = getDayOfWeek(nextDay);

      // 格式化日期字串
      const formattedCurrentDate = formatDateWithDayOfWeek(
        currentDate,
        currentDayOfWeek
      );
      const formattedNextDate = formatDateWithDayOfWeek(nextDay, nextDayOfWeek);

      // const newFormattedCurrentDate = dayjs(formattedCurrentDate);
      // console.log(formattedCurrentDate);
      return `${formattedCurrentDate} ${startTime} - ${formattedNextDate} ${endTime}`;
    } else {
      // 同一天內的時間
      const currentDate = new Date(formatDate);
      const currentDayOfWeek = getDayOfWeek(currentDate);
      return `${formatDateWithDayOfWeek(
        currentDate,
        currentDayOfWeek
      )} ${startTime} - ${formatDateWithDayOfWeek(
        currentDate,
        currentDayOfWeek
      )} ${endTime}`;
    }
  }

  function getDayOfWeek(date) {
    const days = ["日", "一", "二", "三", "四", "五", "六"];
    return days[date.getDay()];
  }

  function formatDateWithDayOfWeek(date, dayOfWeek) {
    return `${date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    })}(${dayOfWeek})`;
  }
  //#endregion

  //#region 確認派案
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agree) {
      showToast("warning", "請閱讀並同意規範");
      return;
    }

    let response;
    let valid;
    switch (type) {
      case "hospital":
        var selectedPlan = plans.find((x) => x.id == createCareCase.planId);
        if (selectedPlan.unit === "天") {
          //全天，需判斷是否已經有過期的天數
          if (createCareCase.behaviorMode !== 0) {
            //預約時間
            var expiredDate = createCareCase.serviceDates.filter((x) => {
              let now = new Date();
              let tmpDate = new Date(x);
              let hoursToAdd = Math.floor(createCareCase.serviceTime);
              let minutesToAdd = (createCareCase.serviceTime - hoursToAdd) * 60;
              tmpDate.setHours(hoursToAdd);
              tmpDate.setMinutes(minutesToAdd);

              return tmpDate.getTime() < now.getTime();
            });

            if (expiredDate.length > 0) {
              showToast("error", "頁面停滯逾時，請重新選擇時間");
              onPreviousStepNoE();
              return;
            }
          }
        } else {
          const today = dayjs(new Date()).format("YYYY-MM-DD ");

          const isIncludeToday = createCareCase.serviceDates.some(
            (date) => date === today
          );
          if (isIncludeToday) {
            //喘息，需要多判斷是否有小於進0.5 + 4h
            valid = validateServiceTime(createCareCase.serviceTime);
            if (!valid) {
              showToast("error", "頁面停滯逾時，請重新選擇時間");
              onPreviousStepNoE();
              return;
            }
          }
        }
        const formData = new FormData();
        formData.append("CaseName", createCareCase.caseName);
        formData.append("HospitalId", createCareCase.hospitalId);
        formData.append("Gender", parseInt(createCareCase.gender));
        formData.append("Height", parseFloat(createCareCase.height));
        formData.append("Weight", parseFloat(createCareCase.weight));
        formData.append("Age", parseInt(createCareCase.age));

        createCareCase.symptoms.map((x) =>
          formData.append("SymptomId", x.value)
        );

        formData.append("BedKindId", createCareCase.bedKind.value);

        createCareCase.tubes.map((x) => formData.append("TubeId", x.value));
        formData.append(
          "OrderNurseAidesGender",
          createCareCase.nurseAidesGender
        );
        formData.append("LanguageId", createCareCase.languages.value);
        createCareCase.careServiceItems.forEach((item) => {
          formData.append("ServiceItemId", item.value);
        });
        formData.append("SubFamilyName", createCareCase.subFamilyName);
        formData.append("SubFamilyGender", createCareCase.subFamilyGender);
        formData.append("SubFamilyPhone", createCareCase.subFamilyPhone);
        formData.append("SubFamilyMail", createCareCase.subFamilyMail);
        formData.append(
          "ForNurseAidesContent",
          createCareCase.forNurseAidesContent
        );
        formData.append("PlanId", createCareCase.planId);
        formData.append("TotalPrice", totalPrice);
        formData.append("BehaviorMode", createCareCase.behaviorMode);
        // 處理 careCasePlanTimeDTOs
        createCareCase.serviceDates.forEach((date) => {
          const plan = plans.find((p) => p.id === createCareCase.planId);
          if (plan.unit === "小時") {
            formData.append(
              "UseStringCareCasePlanTimeDTO",
              JSON.stringify({
                startDate: date,
                startTime: parseFloat(createCareCase.serviceTime.toFixed(1)),
              })
            );
          } else {
            formData.append(
              "UseStringCareCasePlanTimeDTO",
              JSON.stringify({
                startDate: date,
                startTime: parseFloat(createCareCase.serviceTime.toFixed(1)),
              })
            );
          }
        });
        if (createCareCase.headshot != null) {
          formData.append("Headshot", createCareCase.headshot);
        }
        // response = await apiCareCaseFamilyMainCreate({
        //     caseName: createCareCase.caseName,
        //     hospitalId: createCareCase.hospitalId,
        //     gender: parseInt(createCareCase.gender),
        //     height: parseFloat(createCareCase.height),  // 轉換為數字
        //     weight: parseFloat(createCareCase.weight),  // 轉換為數字
        //     age: parseInt(createCareCase.age),          // 轉換為數字
        //     symptomId: createCareCase.symptoms.map(x => x.value),
        //     bedKindId: createCareCase.bedKind[0].value, //床位種類
        //     tubeId: createCareCase.tubes.map(x => x.value),
        //     orderNurseAidesGender: createCareCase.nurseAidesGender,
        //     languageId: createCareCase.languages[0].value, //語言
        //     serviceItemId: createCareCase.careServiceItems.map(x => x.value),
        //     subFamilyName: createCareCase.subFamilyName,
        //     subFamilyGender: createCareCase.subFamilyGender,
        //     subFamilyPhone: createCareCase.subFamilyPhone,
        //     subFamilyMail: createCareCase.subFamilyMail,
        //     forNurseAidesContent: createCareCase.forNurseAidesContent,
        //     planId: createCareCase.planId,
        //     totalPrice: totalPrice,
        //     behaviorMode: createCareCase.behaviorMode,
        //     careCasePlanTimeDTOs: createCareCase.serviceDates.map(x => {
        //         const plan = plans.find(x => x.id === createCareCase.planId);
        //         if (plan.unit === "小時") {
        //             return {
        //                 startDate: x,
        //                 startTime: plan.duration
        //             }
        //         } else {
        //             return {
        //                 startDate: x
        //             }
        //         }
        //     })
        // });

        response = await apiCareCaseFamilyMainCreate(formData);

        if (response && response.code === 0) {
          localStorage.removeItem("createCareCase"); // 清空本地存儲的資料
          navigate("/FindCareStepSuccess");
        } else {
          showToast(
            "error",
            response ? response.message : t("toast.unknownError")
          ); // [回傳的訊息] : 網路連線錯誤
        }
        break;
      case "inHome":
        const languageArray = [createCareCase.languages];
        const languageArrayValue = languageArray[0].value;
        const bedKindArray = [createCareCase.bedKind];
        const bedKindArrayValue = bedKindArray[0].value;

        selectedPlan = plans.find((x) => x.id === createCareCase.planId);
        if (selectedPlan.unit === "天") {
          //全天，需判斷是否已經有過期的天數
          if (createCareCase.behaviorMode !== 0) {
            //預約時間
            var expiredDate = createCareCase.serviceDates.filter((x) => {
              let now = new Date();
              let tmpDate = new Date(x);
              let hoursToAdd = Math.floor(createCareCase.serviceTime);
              let minutesToAdd = (createCareCase.serviceTime - hoursToAdd) * 60;
              tmpDate.setHours(hoursToAdd);
              tmpDate.setMinutes(minutesToAdd);

              return tmpDate.getTime() < now.getTime();
            });

            if (expiredDate.length > 0) {
              showToast("error", "頁面停滯逾時，請重新選擇時間");
              onPreviousStepNoE();
              return;
            }
          }
        } else {
          console.log(serviceDateTimes);
          // serviceDateTimes.includes((date)=>date===)

          const today = dayjs(new Date()).format("YYYY-MM-DD");

          const isIncludeToday = createCareCase.serviceDates.some(
            (date) => date === today
          );
          if (isIncludeToday) {
            //喘息，需要多判斷是否有小於進0.5 + 4h
            valid = validateServiceTime(createCareCase.serviceTime);
            if (!valid) {
              showToast("error", "頁面停滯逾時，請重新選擇時間");
              onPreviousStepNoE();
              return;
            }
          }
        }
        const formDataInHome = new FormData();
        formDataInHome.append(
          "mainFamilyMail",
          createCareCase.mainFamilyMail
            ? createCareCase.mainFamilyMail
            : "sdfsdfsdf@gmail.com"
        );
        formDataInHome.append("caseName", createCareCase.caseName);
        formDataInHome.append("cityId", createCareCase.cityId);
        formDataInHome.append("distId", createCareCase.distId);
        formDataInHome.append("address", createCareCase.address);
        formDataInHome.append("gender", parseInt(createCareCase.gender));
        formDataInHome.append("height", parseFloat(createCareCase.height));
        formDataInHome.append("weight", parseFloat(createCareCase.weight));
        formDataInHome.append("age", parseInt(createCareCase.age));
        // formDataInHome.append(
        //   "symptomId",
        //   createCareCase.symptoms.map((x) => x.value)
        // );
        createCareCase.symptoms.map((x) =>
          formDataInHome.append("SymptomId", x.value)
        );
        formDataInHome.append("bedKindId", bedKindArrayValue);
        // formDataInHome.append(
        //   "tubeId",
        //   createCareCase.tubes.map((x) => x.value)
        // );
        createCareCase.tubes.map((x) =>
          formDataInHome.append("tubeId", x.value)
        );
        formDataInHome.append(
          "orderNurseAidesGender",
          parseFloat(createCareCase.nurseAidesGender)
        );
        formDataInHome.append("languageId", languageArrayValue);
        createCareCase.careServiceItems.forEach((item) => {
          formDataInHome.append("ServiceItemId", item.value);
        });
        formDataInHome.append("subFamilyName", createCareCase.subFamilyName);
        formDataInHome.append(
          "subFamilyGender",
          parseInt(createCareCase.subFamilyGender)
        );
        formDataInHome.append("subFamilyPhone", createCareCase.subFamilyPhone);
        formDataInHome.append("subFamilyMail", createCareCase.subFamilyMail);
        formDataInHome.append(
          "forNurseAidesContent",
          createCareCase.forNurseAidesContent
        );
        formDataInHome.append("planId", createCareCase.planId);
        formDataInHome.append("totalPrice", totalPrice);
        formDataInHome.append(
          "familyPresentDuring",
          parseInt(createCareCase.familyPresentDuring)
        );
        formDataInHome.append("overNight", parseInt(createCareCase.overNight));
        formDataInHome.append("behaviorMode", createCareCase.behaviorMode);

        // 處理 careCasePlanTimeDTOs
        createCareCase.serviceDates.forEach((date) => {
          const plan = plans.find((p) => p.id === createCareCase.planId);
          if (plan.unit === "小時") {
            formDataInHome.append(
              "UseStringCareCasePlanTimeDTO",
              JSON.stringify({
                startDate: date,
                startTime: parseFloat(createCareCase.serviceTime.toFixed(1)),
              })
            );
          } else {
            formDataInHome.append(
              "UseStringCareCasePlanTimeDTO",
              JSON.stringify({
                startDate: date,
                startTime: parseFloat(createCareCase.serviceTime.toFixed(1)),
              })
            );
          }
        });
        if (createCareCase.headshot != null) {
          formDataInHome.append("Headshot", createCareCase.headshot);
        }

        console.log(formDataInHome);
        response = await apiCareCaseFamilyMainCreateInHome(formDataInHome);

        if (response && response.code === 0) {
          localStorage.removeItem("createCareCase"); // 清空本地存儲的資料
          navigate("/FindCareStepSuccess");
        } else {
          console.log("response", response);
          showToast(
            "error",
            response ? response.message : t("toast.unknownError")
          ); // [回傳的訊息] : 網路連線錯誤
          // // showToast("error","頁面停滯逾時，請重新選擇時間1");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log(createCareCase);
  }, [createCareCase]);

  //驗證時間是否合法
  const validateServiceTime = (serviceTime) => {
    //取得當下時間
    const now = new Date();
    const validStart = new Date(now);

    const minutes = validStart.getMinutes();
    if (minutes > 0 && minutes <= 30) {
      validStart.setMinutes(30, 0, 0);
    } else if (minutes > 30 && minutes <= 59) {
      validStart.setHours(validStart.getHours() + 1, 0, 0, 0);
    }

    validStart.setHours(validStart.getHours() + 4);

    console.log("validStart", validStart);
    const isSameDate = dayjs(now).isSame(dayjs(validStart), "day");
    console.log(isSameDate);
    let doubleValidStartTime = convertTimeToDouble(validStart);
    if (!isSameDate) {
      doubleValidStartTime += 24;
    }

    if (serviceTime >= doubleValidStartTime) {
      return true;
    } else {
      return false;
    }
  };
  //#endregion

  return (
    <div className="content">
      <div className="applyHead">
        <h1>找照服員</h1>
        <div>
          <div>
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.14103 23.0686V18.7213H0.277778V14.1832L10.7829 0.921875H14.2943V14.1499H17.1325V18.7165H14.2943V23.0638H9.13618L9.14103 23.0686ZM5.36809 14.1546H9.75128V8.53929L5.36809 14.1546Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="active">
            <svg
              width="17"
              height="24"
              viewBox="0 0 17 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.84729 23.4213C6.19088 23.4213 4.69914 23.0876 3.36723 22.4203C2.03532 21.7529 1.01339 20.8377 0.306267 19.6746L3.1735 16.5332C3.79829 17.2959 4.51994 17.8918 5.34815 18.3256C6.17635 18.7593 7.01908 18.9738 7.87635 18.9738C8.53988 18.9738 9.12108 18.8404 9.6151 18.5782C10.1091 18.316 10.4966 17.949 10.7775 17.4866C11.0584 17.0194 11.1989 16.4713 11.1989 15.8373C11.1989 15.2462 11.0681 14.7171 10.8114 14.2499C10.5547 13.7828 10.2011 13.43 9.74586 13.1869C9.29544 12.9438 8.76752 12.8246 8.16695 12.8246C7.56638 12.8246 7.0433 12.9533 6.5396 13.206C6.03589 13.4586 5.64359 13.8209 5.36268 14.2833H0.882621L3.55612 0.578613H14.933V5.20721H7.29515L6.65099 9.04452C6.78176 8.89675 7.03362 8.76328 7.40655 8.64887C7.78433 8.53447 8.28319 8.4725 8.90313 8.4725C10.3852 8.4725 11.6977 8.77758 12.8359 9.3925C13.9741 10.0074 14.8652 10.8607 15.5094 11.9618C16.1536 13.063 16.4781 14.3214 16.4781 15.7372C16.4781 17.2578 16.1051 18.5973 15.3641 19.7508C14.6231 20.9044 13.606 21.8006 12.3177 22.4489C11.0293 23.0924 9.53276 23.4165 7.83761 23.4165L7.84729 23.4213Z"
                fill="#9E3223"
              />
            </svg>
          </div>
          <div>
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9977 19.6127C15.2567 20.7997 14.2444 21.7435 12.9707 22.4538C11.692 23.164 10.2584 23.5168 8.6698 23.5168C6.88747 23.5168 5.36183 23.0925 4.09289 22.2488C2.82394 21.4051 1.85527 20.18 1.17721 18.5831C0.499145 16.9862 0.160126 15.0652 0.160126 12.8247C0.160126 10.203 0.523364 7.9721 1.2547 6.1321C1.98604 4.2921 3.02252 2.89065 4.36411 1.92775C5.70571 0.96485 7.31369 0.483398 9.1832 0.483398C10.6023 0.483398 11.9245 0.774176 13.1499 1.35573C14.3752 1.93728 15.4359 2.75718 16.3416 3.81542L13.4405 7.0521C12.9029 6.37521 12.2587 5.84609 11.508 5.46475C10.7573 5.0834 9.94845 4.89272 9.09118 4.89272C8.23392 4.89272 7.55584 5.09293 6.93106 5.49335C6.30627 5.89376 5.82194 6.47531 5.48291 7.23801C5.13904 8.0007 4.9453 8.9207 4.90171 9.99801C5.202 9.59759 5.55556 9.26392 5.96724 8.99697C6.37408 8.7348 6.84872 8.52982 7.38633 8.39635C7.92393 8.25811 8.50513 8.19138 9.12507 8.19138C10.6507 8.19138 12.0165 8.52029 13.2177 9.17335C14.4188 9.83117 15.3729 10.713 16.0704 11.8237C16.7678 12.9344 17.1165 14.1976 17.1165 15.6133C17.1165 17.0291 16.7436 18.4258 16.0026 19.608L15.9977 19.6127ZM10.4231 18.7404C10.951 18.4115 11.3772 17.9729 11.7114 17.4247C12.0456 16.8766 12.2103 16.2712 12.2103 15.6181C12.2103 14.9651 12.0504 14.4073 11.7259 13.8877C11.4014 13.3682 10.9752 12.9582 10.4376 12.6531C9.90001 12.3481 9.30913 12.1955 8.66497 12.1955C8.02081 12.1955 7.42992 12.3481 6.89232 12.6531C6.35471 12.9582 5.92365 13.3729 5.604 13.8877C5.27949 14.4073 5.11967 14.9937 5.11967 15.6467C5.11967 16.2998 5.28434 16.8718 5.61853 17.4247C5.95271 17.9729 6.38861 18.4115 6.92137 18.7404C7.45898 19.0693 8.04018 19.2314 8.66012 19.2314C9.28006 19.2314 9.89032 19.0693 10.4182 18.7404H10.4231Z"
                fill="#9E3223"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-[10rem]">
        <h1 className="text-primary text-[2rem] font-bold text-left leading-[1.4]">
          案件確認資訊
        </h1>
        <ul className="flex flex-col gap-[1.5em] mt-[1.1875rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
          {serviceDateTimes && serviceDateTimes.length > 0 ? (
            serviceDateTimes.map((dateTime, index) => {
              let tmpPrice = 0;
              let targetPlan = plans.find(
                (x) => x.id === createCareCase.planId
              );

              if (targetPlan) {
                if (targetPlan.unit === "天") {
                  tmpPrice = targetPlan.fee;
                } else if (targetPlan.unit === "小時") {
                  tmpPrice += targetPlan.fee * targetPlan.duration;
                }
              }
              return (
                <li key={index} className="flex flex-col">
                  <span>
                    {dateTime}&nbsp;&nbsp;&nbsp;
                    {`$ ${tmpPrice.toLocaleString()}`}
                  </span>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
        <ul className="flex flex-col gap-[1.5em] mt-[1.1875rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
          {careServiceItemPreviews && careServiceItemPreviews.length > 0 ? (
            careServiceItemPreviews.map((careServiceItemPreview, index) => {
              return (
                <li key={index} className="flex flex-col">
                  <span>
                    {careServiceItemPreview} (
                    {createCareCase.serviceDates.length}天)
                  </span>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
        <ul className="flex flex-col gap-[1.5em] mt-[1.1875rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
          <li className="flex flex-col">
            <span>平台服務費：{`$ ${platformFee.toLocaleString()}`}</span>
          </li>
        </ul>
        <div className="flex justify-between mt-[2.5rem]">
          <strong className="flex-none text-primary text-[2rem] leading-[1.5]">
            預估總金額
          </strong>
          <strong className="flex-none text-primary text-[2rem] leading-[1.5]">
            ${totalPrice.toLocaleString()}
          </strong>
        </div>
        <div className="mt-[2.5rem] pt-[2.8125rem] border-t border-primary">
          <h1 className="text-primary text-[2rem] font-bold text-left leading-[1.4]">
            同意事項
          </h1>
          <div className="h-[23.235rem] py-[0.5rem] px-[0.75rem] bg-white border border-primary overflow-scroll set">
            <p>[待提供同意事項]</p>
          </div>
          <div className="flex items-center justify-center mt-[2rem]">
            <div className="inline-flex items-center justify-center ">
              <label
                className="relative flex items-center p-3 rounded-full cursor-pointer"
                htmlFor="check"
              >
                <input
                  id="check"
                  type="checkbox"
                  className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none border border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                  onChange={() => setAgree(!agree)}
                  checked={agree}
                />
                <span className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </label>
              <label
                className="font-light text-primary text-[0.9375rem] lg:text-base cursor-pointer select-none"
                htmlFor="check"
              >
                我已閱讀並同意上述規範
              </label>
            </div>
          </div>
        </div>
        <form className="flex flex-col gap-[1.875rem] mt-[1.875rem] pt-[2.8125rem]">
          <div className="flex items-center justify-end gap-[0.75rem]">
            <button
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="上一步"
              onClick={onPreviousStep}
            >
              上一步
            </button>
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="下一步"
              onClick={(e) => handleSubmit(e)}
            >
              確認派案
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseConfirm;
