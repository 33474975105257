import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import SureModal from "./SureModal";
import RecordModal from "./Record";
import HamburgerNurseAidesModal from "./../HamburgerNurseAidesModal";
import {
  apiUpdateCareCaseSignin,
  apiGetSigninRecord,
  apiGetNurseAidesCaseDetail,
  apiUpdateCareCaseSignoff,
} from "../../utils/Api";

/**
 * 資料顯示頁面
 */
const InfoPanel = ({}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate(); //跳轉Router
  const [showModal, setShowModal] = useState(false); //顯示modal
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [showRecordModal, setRecordModal] = useState(false); //顯示modal
  const [careCases, setCareCases] = useState([]);
  const { careCaseId } = useParams();
  const [getId, setgetId] = useState(careCaseId);
  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      refreshAllCareCase();
    };
    fetchData();
    return () => {};
  }, []);

  //#region 刷新案件列表
  const refreshAllCareCase = async () => {
    const params = {
      CareCaseId: careCaseId,
    };

    const response = await apiGetNurseAidesCaseDetail(params);
    console.log(response.data);

    setCareCases(response.data);
  };
  //#endregion

  //#region 簽到modal
  const handleShowModal = () => {
    const fetchData = async () => {
      try {
        const GetSigninRecord = {
          careCaseId: careCaseId,
          signinTime: new Date(),
        };
        const response = await apiGetSigninRecord(GetSigninRecord);
        if (response.data) {
          setShowModal(true);
        } else {
          const UpdateCareCaseSigninInfo = {
            careCaseId: careCaseId,
            signinTime: new Date(),
          };
          const response = await apiUpdateCareCaseSignin(
            UpdateCareCaseSigninInfo
          );
          if (!response.data) {
            alert("打卡失敗");
          } else {
            alert("打卡成功");
          }
        }
        const data = response.data;
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchData();
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleconfirm = () => {
    const fetchData = async () => {
      try {
        const UpdateCareCaseSigninInfo = {
          careCaseId: careCaseId,
          signinTime: new Date(),
        };
        const response = await apiUpdateCareCaseSignin(
          UpdateCareCaseSigninInfo
        );
        setShowModal(false);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchData();
  };
  const handleShowRecordModal = () => {
    setRecordModal(true);
  };

  const handleRecordClose = () => {
    setRecordModal(false);
  };
  //#endregion

  //#region 簽退
  const handleSignoff = () => {
    const fetchData = async () => {
      var param = {
        careCaseId: careCaseId,
        signOutTime: new Date(),
      };
      const response = await apiUpdateCareCaseSignoff(param);
      if (response.data) {
        alert("打下班卡成功");
        navigate("/ProjectList");
      } else {
        alert("下班打卡失敗");
      }
    };
    fetchData();
  };

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <>
      <div className="main-background normal">
        <div className="content">
          <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
            <form
              className="flex items-start justify-between gap-[0.625rem]"
              action="./projectListChoose.html"
            >
              <strong className="max-w-[6em] grow text-primary text-[1.5rem] leading-[1.5]">
                案件詳細資料
              </strong>
              <div className="flex items-center gap-[0.625rem] flex-none">
                <select
                  name="type"
                  id="type"
                  className="w-[4.375rem] md:w-[9.375rem] min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  aria-label="案件詳細資料"
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  <option value="all" selected>
                    全部
                  </option>
                  <option value="1">ＸＸＸ</option>
                </select>
                <a
                  data-bs-toggle="modal"
                  href="#MenuModal"
                  role="button"
                  title="Sort Button"
                  onClick={handleShowHamburgerModal}
                >
                  <svg
                    width="28"
                    height="23"
                    viewBox="0 0 28 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_87_2023)">
                      <path
                        d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                        fill="#9E3223"
                      />
                      <path
                        d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                        fill="#9E3223"
                      />
                      <path
                        d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                        fill="#9E3223"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_87_2023">
                        <rect width="28" height="23" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </form>
            <div className="flex flex-col gap-[2.1875rem]">
              <input type="hidden" name="id" value="1" />
              <div className="flex flex-col items-center gap-[1.5625rem]">
                <div className="flex flex-col gap-[1.25rem]">
                  <div className="w-[8.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
                    <img
                      src="./img/user.png"
                      width="130"
                      height="130"
                      alt="user avator"
                    />
                  </div>
                  <ul className="min-w-[15.625rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
                    <li>案件編號：{careCases.careCaseNo}</li>
                    <li>醫院：新北市土城醫院</li>
                    {/* <li>費用： {careCases.plan.price}/ 天</li>
                                        <li>天數： {careCases.plan.careCasePlanNurseAides.careCasePlanNurseAidesTimes.length}天</li> */}
                    <li>家屬：林ＸＸ |0987654321</li>
                    <li>次要家屬：林ＸＸ |0987654321</li>
                    <li>地址：</li>
                  </ul>
                </div>
                <div className="w-full flex flex-col gap-[1.875rem]">
                  <div className="p-[1rem] bg-white border-[0.125rem] border-primary text-dark text-xs md:text-sm lg:text-base font-[300] leading-[1.5]">
                    <p>病患狀況：</p>
                    <p>•男50歲，180公分60公斤</p>
                    <p>•症狀：[家屬選擇的症狀]</p>
                    <p>•管線：[家屬選擇的管線]</p>
                    <p>•語言：[家屬選擇的語言]</p>
                    <p></p>
                  </div>
                  <div className="min-h-[10rem] p-[1rem] bg-white border-[0.125rem] border-primary text-dark text-xs md:text-sm lg:text-base font-[300] leading-[1.5]">
                    <p>家屬備註：</p>
                    <p>[家屬給照服員的話]</p>
                  </div>
                  <div className="min-h-[10rem] p-[1rem] bg-white border-[0.125rem] border-primary text-dark text-xs md:text-sm lg:text-base font-[300] leading-[1.5]">
                    <p>備忘錄:</p>
                    <p>[給自己查看]</p>
                  </div>
                </div>
              </div>
              <div
                id="SubmitButtons"
                className="w-[calc(100%+(8.14vw*2))] flex items-center justify-between gap-[0.75rem] -mx-[8.14vw] py-[0.625rem] px-[8.14vw] sticky checkin bottom-0 left-0 z-[7]"
              >
                <button
                  type="button"
                  className="flex-none bg-transparent text-primary text-base leading-[1.5]"
                  title="簽到記錄"
                  data-bs-toggle="modal"
                  data-bs-target="#checkinModal-1-2"
                  onClick={handleShowRecordModal}
                >
                  簽到記錄
                </button>
                <div className="flex items-center justify-end gap-[0.75rem]">
                  <button
                    type="button"
                    className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="簽到"
                    data-bs-toggle="modal"
                    data-bs-target="#checkinModal-1-1"
                    onClick={handleShowModal}
                  >
                    簽到
                  </button>
                  <button
                    type="button"
                    className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="簽到"
                    data-bs-toggle="modal"
                    data-bs-target="#checkinModal-1-1"
                    onClick={handleSignoff}
                  >
                    下工
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SureModal
          showModal={showModal}
          handleClose={handleClose}
          handleconfirm={handleconfirm}
        />
        <HamburgerNurseAidesModal
          showHamburgerModal={showHamburgerModal}
          handleHamburgerClose={handleHamburgerClose}
        />
        <RecordModal
          showRecordModal={showRecordModal}
          handleRecordClose={handleRecordClose}
          getId={getId}
        />
      </div>
    </>
  );
};

export default InfoPanel;
