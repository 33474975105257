import React, { createContext, useContext, useState } from 'react';

export const TargetCareCaseContext = createContext();

export const TargetCareCaseProvider = ({ children }) => {
  const [targetCareCase, setTargetCareCase] = useState({});

  return (
    <TargetCareCaseContext.Provider value={{ targetCareCase, setTargetCareCase }}>
      { children }
    </TargetCareCaseContext.Provider>
  );
};

export const useTargetCareCaseContext = () => useContext(TargetCareCaseContext);