import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import InfoPanel from "../../components/FindCareStep/InfoPanel";

/**
 * 家屬主畫面
 * @returns
 */
const FindCareStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <InfoPanel />
      </div>
    </>
  );
};

export default FindCareStep;
