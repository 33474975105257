import moment from 'moment/moment';

export const sleep = (time: number) =>
  new Promise((res) => setTimeout(res, time));

export const hasWindowClass = (className: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    return window.classList.contains(className);
  }
}

export const getWindowClass = () => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    return window.classList;
  }
}

export const setWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList = classList;
  }
};
export const addWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.remove(classList);
  }
};

export const convertYearOfROC = (adYear) => {
  let tempADYear = moment(adYear);

  let tempYear = tempADYear.year() - 1911;

  let adYearList = adYear.split("-");
  adYearList[0] = tempYear;

  return adYearList.join("/");
}

/**
 * 轉換時間(Date型態)成double
 * @param {*} date 
 * @returns 
 */
export const convertTimeToDouble = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours + minutes / 60;
};


/**
 * 轉換時間(double型態)成Date
 * @param {*} date 
 * @returns 
 */
export const convertDoubleToDate = (doubleTime) => {
  const hours = Math.floor(doubleTime);
  const minutes = (doubleTime - hours) * 60;
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // 设置小时和分钟，秒和毫秒为0
  return date;
};

/**
 * 格式化時間成 "HH:mm"
 * @param {*} date 
 * @returns 
 */
export const formatTimeToHHmm = (date) => {
  // 取得小時和分鐘
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // 回傳格式化後的時間字串
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

/**
 * 將日期格式從"YYYY-MM-DD"轉換為"MM/DD (星期)"
 * @param {*} date 
 * @returns 
 */
export const formatToMMDD = (dateStr) => {
  const date = new Date(dateStr);
  const month = String(date.getMonth() + 1).padStart(2, '0');  // 月份從0開始，所以需要+1
  const day = String(date.getDate()).padStart(2, '0');  // 獲取日期並補零
  const dayName = ['日', '一', '二', '三', '四', '五', '六'][date.getDay()]; // 獲取星期幾
  return `${month}/${day}(${dayName})`;
}

