import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { checkAuthToken } from '../utils/JWToken';


/**
 * 登入畫面
 */
const Login = () => {
    const { t } = useTranslation();

    useEffect(() => {
        checkAuthToken().then(result => {
            if (!result) {
                window.location.href = "./Login";
            } else {
                window.location.href = "./Choose";
            }
        })
            .catch(() => window.location.href = "./Login")
    }, []); // 空依賴數組，表示只在組件首次渲染時執行

    return (
        <>
            <div className="min-w-screen min-h-screen flex items-center justify-center bg-[#ED6C00] body-back">
                <a href={void (0)} title="馬上就來">
                    <svg width="189" height="302" className="max-w-[11.8125rem]" viewBox="0 0 189 302" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_10_163)">
                            <path
                                d="M81.5741 232.085H74.3633V217.563H13.0821V178.617H81.5741V185.834H20.2928V210.346H81.5741V232.085Z"
                                fill="white"
                            />
                            <path d="M81.574 189.194H16.6895V196.412H81.574V189.194Z" fill="white" />
                            <path d="M81.574 199.768H16.6895V206.986H81.574V199.768Z" fill="white" />
                            <path
                                d="M70.7599 232.085C66.2034 232.085 63.7464 230.691 61.7742 229.568C60.1636 228.651 58.9968 227.993 56.3425 227.993C53.6883 227.993 52.5256 228.655 50.915 229.568C48.9428 230.691 46.4858 232.085 41.9292 232.085C37.3727 232.085 34.9157 230.691 32.9394 229.568C31.3247 228.651 30.1619 227.993 27.5077 227.993C24.8535 227.993 23.6866 228.655 22.076 229.568C20.0997 230.687 17.6427 232.085 13.0862 232.085V224.868C15.7404 224.868 16.9073 224.205 18.5179 223.292C20.4942 222.174 22.9512 220.775 27.5077 220.775C32.0643 220.775 34.5213 222.17 36.4975 223.292C38.1123 224.21 39.275 224.868 41.9292 224.868C44.5835 224.868 45.7462 224.205 47.3568 223.292C49.329 222.174 51.786 220.775 56.3425 220.775C60.8991 220.775 63.3561 222.17 65.3283 223.292C66.9389 224.21 68.1057 224.868 70.7599 224.868V232.085Z"
                                fill="white"
                            />
                            <path d="M52.7351 182.223H45.5243V213.953H52.7351V182.223Z" fill="white" />
                            <path d="M126.363 182.223H117.71V224.868H126.363V182.223Z" fill="white" />
                            <path d="M172.867 193.423L130.139 202.031L131.846 210.518L174.574 201.91L172.867 193.423Z" fill="white" />
                            <path d="M179.143 220.541H98.0992V229.202H179.143V220.541Z" fill="white" />
                            <path d="M31.5884 240.033L25.028 243.029L29.3881 252.597L35.9485 249.601L31.5884 240.033Z" fill="white" />
                            <path d="M76.7691 241.68L70.2086 244.676L74.5687 254.244L81.1292 251.248L76.7691 241.68Z" fill="white" />
                            <path d="M49.9412 248.511H10.5429V255.729H49.9412V248.511Z" fill="white" />
                            <path
                                d="M42.7346 266.59V274.244H17.7537V266.59H42.7346ZM49.9453 259.373H10.5429V281.462H49.9412V259.373H49.9453Z"
                                fill="white"
                            />
                            <path d="M33.8474 277.16H26.6367V301.42H33.8474V277.16Z" fill="white" />
                            <path d="M84.1132 255.766H49.9453V262.983H84.1132V255.766Z" fill="white" />
                            <path
                                d="M73.1512 301.42C62.1646 301.42 62.1646 296.181 62.1646 291.353V261.795H69.3753V291.353C69.3753 294.252 69.9054 294.207 73.1512 294.207C76.3971 294.207 76.9271 294.252 76.9271 291.353V285.615H84.1379V291.353C84.1379 296.181 84.1379 301.42 73.1512 301.42Z"
                                fill="white"
                            />
                            <path d="M175.408 250.646H101.838V257.863H175.408V250.646Z" fill="white" />
                            <path d="M175.408 271.632H101.838V278.85H175.408V271.632Z" fill="white" />
                            <path d="M142.227 244.23H135.016V301.478H142.227V244.23Z" fill="white" />
                            <path
                                d="M114.55 277.9L109.677 272.578L118.215 264.752L109.677 256.913L114.55 251.596L128.886 264.752L114.55 277.9Z"
                                fill="white"
                            />
                            <path
                                d="M156.126 277.9L151.258 272.578L159.791 264.752L151.253 256.913L156.126 251.596L170.462 264.752L156.126 277.9Z"
                                fill="white"
                            />
                            <path d="M16.2951 277.732L9.85676 301.42H17.3305L23.2511 279.623L16.2951 277.732Z" fill="white" />
                            <path d="M44.1931 277.732L37.233 279.623L43.1578 301.42H50.6274L44.1931 277.732Z" fill="white" />
                            <path
                                d="M59.4775 241.532V256.486C59.4775 272.689 55.4879 282.778 52.1393 288.387C51.4655 289.514 50.7917 290.51 50.1425 291.386L52.8707 301.42C58.1832 296.148 66.6882 281.848 66.6882 256.486V241.532H59.4775Z"
                                fill="white"
                            />
                            <path d="M120.019 273.179L104.727 301.42H114.567L127.624 277.304L120.019 273.179Z" fill="white" />
                            <path d="M157.223 273.179L149.618 277.304L162.676 301.42H172.516L157.223 273.179Z" fill="white" />
                            <path
                                d="M22.2897 163.091L0 134.953L10.9209 126.283L21.4967 139.633L48.4004 100.493L59.8842 108.401L22.2897 163.091Z"
                                fill="white"
                            />
                            <path
                                d="M147.099 163.091C124.161 163.091 105.141 145.909 102.857 123.088L116.724 121.698C118.289 137.358 131.347 149.166 147.095 149.166V163.091H147.099Z"
                                fill="white"
                            />
                            <path
                                d="M189 163.091H166.23V156.141C166.23 136.853 162.91 123.984 156.365 117.897C149.581 111.589 139.063 112.366 127.924 113.184C116.428 114.032 104.542 114.908 96.1969 107.143C90.4242 101.776 87.618 93.2997 87.618 81.2373H101.555C101.555 89.1007 102.943 94.3772 105.68 96.9229C109.579 100.546 117.989 99.9252 126.897 99.2713C139.244 98.3624 154.614 97.2314 165.848 107.678C174.5 115.726 179.16 129.335 180.018 149.17H189V163.096V163.091Z"
                                fill="white"
                            />
                            <path
                                d="M102.487 163.091H79.7169V156.141C79.7169 125.67 69.3959 111.478 47.2295 111.478C34.5048 111.478 22.3061 110.421 12.9506 97.6796C8.01195 90.9513 7.69558 81.336 12.1206 72.5801C18.5836 59.7939 32.812 52.1608 50.1795 52.1608C62.0208 52.1608 65.5871 58.7534 67.7154 62.6933C69.2603 65.5516 69.4575 65.9176 72.9951 65.9176C78.7801 65.9176 83.423 63.1786 88.8013 60.0036C95.0341 56.3269 102.097 52.1567 111.687 52.1567C123.528 52.1567 127.094 58.7493 129.227 62.6892C130.772 65.5475 130.973 65.9135 134.511 65.9135C138.964 65.9135 142.14 64.3054 146.943 61.8666C155.029 57.7663 166.106 52.1526 189.008 52.1526V66.1027C169.434 66.1027 160.831 70.4621 153.242 74.3115C147.86 77.0423 142.292 79.8636 134.511 79.8636C122.669 79.8636 119.103 73.271 116.97 69.3311C115.426 66.4728 115.224 66.1068 111.687 66.1068C105.902 66.1068 101.259 68.8458 95.8805 72.0208C89.6476 75.6975 82.5848 79.8677 72.9951 79.8677C61.1538 79.8677 57.5875 73.2752 55.4592 69.3352C53.9143 66.4769 53.7171 66.1109 50.1795 66.1109C38.178 66.1109 28.6006 70.8816 24.5577 78.8766C22.5485 82.8494 22.4006 86.9867 24.1838 89.4132C29.2786 96.3513 35.4128 97.5234 47.2336 97.5234C66.4006 97.5234 80.4072 106.152 87.7412 122.475C91.7759 131.449 93.0948 141.159 93.4934 149.166H102.487V163.091Z"
                                fill="white"
                            />
                            <path
                                d="M55.0689 27.8838C53.4706 30.0923 50.8821 31.5358 47.9608 31.5358C43.1167 31.5358 39.1764 27.5918 39.1764 22.743C39.1764 17.8942 43.1167 13.9501 47.9608 13.9501C50.8862 13.9501 53.4747 15.3937 55.0689 17.6022H70.0738C67.736 7.53438 58.7174 0 47.9608 0C35.4334 0 25.2397 10.2035 25.2397 22.743C25.2397 35.2825 35.4334 45.486 47.9608 45.486C58.7215 45.486 67.736 37.9516 70.0738 27.8838H55.0689Z"
                                fill="white"
                            />
                            <path
                                d="M133.574 15.3237C135.78 16.9236 137.222 19.5145 137.222 22.4386C137.222 27.2874 133.282 31.2315 128.438 31.2315C123.594 31.2315 119.653 27.2874 119.653 22.4386C119.653 19.5104 121.096 16.9194 123.302 15.3237V0.304321C113.244 2.64442 105.717 11.6676 105.717 22.4386C105.717 34.9781 115.91 45.1816 128.438 45.1816C140.965 45.1816 151.159 34.9781 151.159 22.4386C151.159 11.6676 143.632 2.64442 133.574 0.304321V15.3237Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_10_163">
                                <rect width="189" height="301.478" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </>
    );
};

export default Login;
