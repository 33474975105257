import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";
import { apiSendVerificationMail } from "../../utils/Api"
import Line from "../../layouts/Line";

/**
 * 申請照服員-驗證信箱(step4)
 */
const CareStepVerify = ({ createNurseAides }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [countdownSec, setCountdownSec] = useState(120);
    const [isButtonVisible, setIsButtonVisible] = useState(false);//控制按鈕是否顯示

    const resendVerification = () => {
        apiSendVerificationMail()
            .then(_ => showToast(
                "success",
                "驗證信已寄送"
            ))
            .catch(_ => showToast(
                "error",
                "驗證信寄送失敗"
            ))
        setCountdownSec(120);
        setIsButtonVisible(false);
    }


    //#region 倒數計時器(120s)
    useEffect(() => {
        let interval;
        if (countdownSec > 0) {
            interval = setInterval(() => {
                setCountdownSec(prevSeconds => {
                    if (prevSeconds > 0) {
                        return prevSeconds - 1;
                    } else {
                        setIsButtonVisible(true);
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        } else {
            setIsButtonVisible(true);
        }

        return () => clearInterval(interval);
    }, [countdownSec]);
    //#endregion

    return (
        <>
            <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">信箱驗證</h1>
            <div className="flex items-center gap-[0.625rem] mt-[1.6875rem]">
                <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
                    成功寄送驗證信
                </p>
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_32_1078)">
                        <path d="M35 35H0V0H32.5864V4.82724H4.82724V30.1728H30.1728V18.5862H35V35Z" fill="#4CC764" />
                        <path
                            d="M11.3397 15.8931L7.96948 19.3491L16.9702 28.1263L20.3404 24.6703L11.3397 15.8931Z"
                            fill="#4CC764"
                        />
                        <path
                            d="M31.3705 6.97422L18.6639 19.5616L22.0612 22.991L34.7678 10.4036L31.3705 6.97422Z"
                            fill="#4CC764"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_32_1078">
                            <rect width="35" height="35" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
                Hi,  {createNurseAides.name}，趕快去驗證信箱吧！
            </p>
            <div className="flex flex-col items-center justify-center gap-[0.625rem] mt-[8rem]">
                <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
                    信箱沒有收到驗證信？
                </p>
                {!isButtonVisible ?
                    <div id="sendCodeCountDown" className="flex-col items-end">
                        <span id="codeCountDownNum" data-second="120">{countdownSec} 秒之後，可以重新寄送驗證碼</span>
                    </div>
                    :
                    <button
                        className="min-w-[8.3125rem] mx-a py-1 px-[1.125rem] bg-primary rounded-full text-white text-[1rem] font-bold text-center leading-[1.5]"
                        title="重新寄送驗證信"
                        onClick={resendVerification}
                    >
                        重新寄送驗證信
                    </button>
                }
            </div>
            <Line />
        </>
    );
};

export default CareStepVerify;
