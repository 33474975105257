import React, { useEffect, useRef, useState } from "react";
import { useMyUserContext } from "../../contexts/MyUserContext";
import dayjs from "dayjs";
import { useToast } from "../../contexts/ToastContext";

function ChatForm({ parentId, addReply, addChat }) {
  const [message, setMessage] = useState("");

  const { showToast } = useToast();
  const replyRef = useRef("");
  const { myUser } = useMyUserContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(parentId);
    const newMessage = {
      id: parentId ? parentId : 0,
      name: myUser ? myUser.account : "123",
      message: message,
      date: new Date(),
      replies: [],
    };

    // 送出訊息後，變為""
    if (replyRef.current) {
      if (parentId !== 0 && parentId !== undefined) {
        addReply(parentId, newMessage);
      } else {
        addChat(newMessage);
      }
      replyRef.current.textContent = "";
    } else {
      showToast("error", "不可傳送空白訊息");
    }

    setMessage("");
  };

  return (
    <form className="chatForm" onSubmit={handleSubmit}>
      <div className="chatFormTop">
        <div className="chatFormAvator">
          <img src="img/user.png" width="30" height="30" alt="avatar" />
        </div>
        <div className="chatInput">
          <div
            contentEditable
            ref={replyRef}
            onInput={(e) => {
              console.log(e);
              setMessage(e.currentTarget.textContent);
            }}
          ></div>
          <button type="submit" title="Submit">
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8.00118L0 0L2.27013 6.15964L7.5 8.00118L2.27013 9.84036L0 16L18 8.00118Z"
                fill="#9E3223"
              />
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

export default ChatForm;
