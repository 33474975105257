import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNoLink from "../../layouts/HeaderNoLink";
import ForgetPassword from "../../components/Login/ForgetPassword";

/**
 * 選擇身分
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <HeaderNoLink/>
        <ForgetPassword/>
      </div>
    </>
  );
};

export default InfoPanel;
