import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";

/**
 * 配對成功詳細資料
 */
const InfoPanel = ({ waitingPair, onReplyWaitingPair }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [currentSlide, setCurrentSlide] = useState(0); //當前評價內容

  //#region 星星評分
  function Star({ marked, starId }) {
    return (
      <span
        star-id={starId}
        style={{ color: "#ff9933", fontSize: "2.5rem" }}
        role="button"
      >
        {/* 空星，實星 */}
        {marked ? "\u2605" : "\u2606"}
      </span>
    );
  }

  function StarRating(props) {
    // 分數顯示
    const [rating, setRating] = React.useState(
      typeof props.rating == "number" ? props.rating : 0
    );
    // 鼠標移入效果
    const [selection, setSelection] = React.useState(0);
    const hoverOver = (event) => {
      let val = 0;
      if (event && event.target && event.target.getAttribute("star-id"))
        val = event.target.getAttribute("star-id");
      setSelection(val);
    };
    return (
      <div>
        {/* 創建5個组件 */}
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1} `}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
          />
        ))}
      </div>
    );
  }
  //#endregion

  //#region 評價內容 下一則
  const handleNextSlide = () => {
    const nextSlide = (currentSlide + 1) % waitingPair.evaluates.length;
    setCurrentSlide(nextSlide);
  };
  //#endregion

  //#region 評價內容 上一則
  const handlePrevSlide = () => {
    const prevSlide =
      (currentSlide - 1 + waitingPair.evaluates.length) %
      waitingPair.evaluates.length;
    setCurrentSlide(prevSlide);
  };
  //#endregion

  return (
    <div className="flex flex-col gap-[2.1875rem]">
      <div className="flex flex-col items-center gap-[1.5625rem]">
        <div className="w-[8.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
          <img
            src={
              waitingPair.headshotFile
                ? URL.createObjectURL(waitingPair.headshotFile.data)
                : "./img/user.png"
            }
            width="130"
            height="130"
            alt="user avator"
            className="h-full w-full object-center object-cover"
          />
        </div>
        <div className="flex flex-col gap-[0.9375rem]">
          <div className="flex flex-col items-center gap-[1.25rem] mt-[0.75rem] -mb-[1.0625rem]">
            <span className="text-primary text-[1.25rem] leading-[1.5]">
              {waitingPair.nurseAidesName}({waitingPair.nurseAidesGender} /{" "}
              {waitingPair.nurseAidesAge}歲)
            </span>
            <div style={{ marginTop: "-25px", marginBottom: "5px" }}>
              <StarRating rating={waitingPair.evaluateScore} />
            </div>
          </div>
          <div
            id="Swiper"
            className="w-full swiper bg-primary rounded-[1.25rem] overflow-hidden"
          >
            <div className="swiper-button relative">
              <button
                className="w-[48px] h-full flex items-start justify-end pt-[0.75rem] pr-[0.75rem] absolute left-0 z-[3] swiper-button-prev"
                style={{ top: "45px" }}
                title="swiper prev"
                onClick={handlePrevSlide}
              >
                <svg
                  width="16"
                  height="22"
                  viewBox="0 0 16 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.42819 10.9378L15.9266 2.69068L14.0734 0.331543L0.571817 10.9378L14.0734 21.544L15.9266 19.1849L5.42819 10.9378Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                className="w-[48px] h-full flex items-end justify-start pt-[0.75rem] pl-[0.75rem] absolute right-0 z-[3] swiper-button-next"
                style={{ top: "65px" }}
                title="swiper next"
                onClick={handleNextSlide}
              >
                <svg
                  width="16"
                  height="22"
                  viewBox="0 0 16 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5718 10.9377L0.0733948 19.1848L1.92663 21.5439L15.4282 10.9377L1.92663 0.331467L0.073395 2.6906L10.5718 10.9377Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div
              className="swiper-wrapper"
              style={{
                display: "flex",
                transition: "transform 0.5s ease-in-out",
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              {waitingPair.evaluates.map((evaluate, index) => {
                return (
                  <div
                    key={index}
                    className="swiper-slide w-full flex-shrink-0"
                    style={{ flexBasis: "100%" }}
                  >
                    <div className="flex flex-col py-[0.8125rem] text-white text-center leading-[1.5]">
                      <b className="text-[1.5rem]">評價內容</b>
                      <div className="py-[0.1875rem] text-[0.9375rem] lg:text-base text-center">
                        <p>{evaluate.description}</p>
                      </div>
                      <div className="flex items-center justify-center gap-[1.25rem] text-[0.9375rem] lg:text-base">
                        {/* <span>李OO</span> */}
                        <span>{evaluate.createdDateTime}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[0.8125rem]">
          <b className="pl-[0.625rem] text-primary text-[1.5rem] leading-[1.5]">
            服務時間
          </b>
          <ul
            className="text-primary text-[0.9375rem] lg:text-base leading-[1.5]"
            style={{ listStyleType: "disc", listStylePosition: "inside" }}
          >
            {waitingPair.careCasePlanTimeDTOs.map((planTime, index) => {
              return <li>{planTime.startDate} 12：00 -20：00</li>;
            })}
          </ul>
        </div>
        {/* <div className="w-full flex flex-col gap-[2.5rem]">
                    <div className="InspireDropzone">
                        <span>點擊上傳照服員證書</span>
                    </div>
                </div> */}
      </div>
      <div
        id="SubmitButtons"
        className="w-[calc(100%+(8.14vw*2))] flex items-center justify-center gap-[0.75rem] -mx-[8.14vw] py-[0.625rem] px-[8.14vw] sticky bottom-0 left-0 z-[7]"
      >
        <button
          className=" py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] text-center"
          title="拒絕,等待下一位"
          onClick={(e) => onReplyWaitingPair(false)}
        >
          拒絕,等待下一位
        </button>
        <button
          type="submit"
          className="flex-[0.5_0.5] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] text-center"
          title="接受"
          onClick={(e) => onReplyWaitingPair(true)}
        >
          接受
        </button>
      </div>
    </div>
  );
};

export default InfoPanel;
