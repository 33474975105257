import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";
import RegisiterAccountStep1 from "./RegisiterAccountStep1"
import RegisiterAccountStep2 from "./RegisiterAccountStep2"
import RegisiterAccountStep3 from "./RegisiterAccountStep3"
import RegisiterVerifySuccess from "./RegisiterVerifySuccess"
import RegisiterVerifyFailed from "./RegisiterVerifyFailed"

/**
 * 資料顯示頁面(註冊家屬)
 */
const RegisiterAccount = () => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [currentStep, setCurrentStep] = useState(1); //當前步驟
    const [checkTermsOfService, setCheckTermsOfService] = useState(false); //是否已同意服務條款
    const [createFamily, setCreateFamily] = useState({ //創建家屬
        username: "", //帳號
        password: "",
        confirmPassword: "",
        name: "",     //姓名
        gender: 1,
        birthday: "",
        cityId: 0,
        distId: 0,
        address: "",
        phone: "",
        mail: ""
    });

    const [countdownSec, setCountdownSec] = useState(0); //倒數計時的預設秒數

    // 下一頁
    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    // 上一頁
    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    //#region 到達信箱驗證失敗頁面
    const handlePreviousStepFailed = () => {
        setCurrentStep(currentStep + 2);
    };
    //#endregion

    //#region 更新基本資料
    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (name === "gender") {
            newValue = parseInt(value);
        }
        setCreateFamily((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };
    //#endregion

    //#region 更新生日
    const handleChangeBirthdayData = (value) => {
        setCreateFamily({ ...createFamily, ["birthday"]: value });
    };
    //#endregion

    //#region 更新縣市
    const handleChangeCityData = (value) => {
        setCreateFamily({ ...createFamily, ["cityId"]: parseInt(value) });
    };
    //#endregion

    //#region 更新地區
    const handleChangeDistData = (value) => {
        setCreateFamily({ ...createFamily, ["distId"]: parseInt(value) });
    };
    //#endregion

    return (
        <div className=" main-background normal">
            <div className="content">
                {/* 根據當前步驟狀態渲染不同的子元件 */}
                {currentStep === 1 && (
                    <RegisiterAccountStep1
                        onNextStep={handleNextStep}
                        setCheckTermsOfService={setCheckTermsOfService}
                        checkTermsOfService={checkTermsOfService} />
                )}
                {currentStep === 2 && (
                    <RegisiterAccountStep2
                        onNextStep={handleNextStep}
                        onPreviousStep={handlePreviousStep}
                        onChangeCreateFamily={handleChange}
                        onChangeBirthdayData={handleChangeBirthdayData}
                        onChangeCityData={handleChangeCityData}
                        onChangeDistData={handleChangeDistData}
                        createFamily={createFamily} />
                )}
                {currentStep === 3 && (
                    <RegisiterAccountStep3
                        onNextStep={handleNextStep}
                        onPreviousStep={handlePreviousStep}
                        onChangeCreateFamily={handleChange}
                        countdownSec={countdownSec}
                        setCountdownSec={setCountdownSec}
                        createFamily={createFamily} />
                )}
                {currentStep === 4 && (
                    <RegisiterVerifySuccess
                        createFamily={createFamily} />
                )}
                {currentStep === 5 && (
                    <RegisiterVerifyFailed
                        onPreviousStep={handlePreviousStep}
                    />
                )}
            </div>
        </div>
    );
};

export default RegisiterAccount;