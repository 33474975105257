import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import PatientInfoSure from "./PatientInfoSure";
import {
  CreateValidator,
  ValidateFields,
  ValidateField,
} from "../../utils/Validator";
import Select from "react-select";
import {
  apiGetAllSymptom,
  apiGetAllTube,
  apiGetAllLanguage,
  apiGetAllCareServiceItem,
  apiGetAllBedKind,
} from "../../utils/Api";

/**
 * 家屬找照服員-被照顧者基本資料(step3)
 */
const PatientInfo = ({
  onNextStep,
  onPreviousStep,
  onChangeCaseData,
  onChangeCaseSymptomsData,
  onChangeCaseTubesData,
  onChangeCaseLanguagesData,
  onChangeCaseBedKindData,
  onChangeCaseCareServiceItemsData,
  onChangeHeadshot,
  createCareCase,
  setParentCareServiceItems,
  type = "Hospital",
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const validator = CreateValidator();
  const [symptoms, setSymptoms] = useState([]); //病症列表
  const [tubes, setTubes] = useState([]); //管線列表
  // 初始化照服員性別選項
  const headshotRef = useRef(null);
  const [headshotImageUrl, setHeadshotImageUrl] = useState(null);
  const initialGenderOptions = [
    { value: 0, label: "男" },
    { value: 1, label: "女" },
    { value: 2, label: "不指定" },
  ];
  const [genderOptions, setGenderOptions] = useState(initialGenderOptions); // 指定照服員性別列表
  const [languages, setLanguages] = useState([]); //溝通語言列表
  const [bedKinds, setBedKinds] = useState([]);
  const [careServiceItems, setCareServiceItems] = useState([]); //服務需求列表
  const [errors, setErrors] = useState({
    caseName: "",
    gender: "",
    height: "",
    weight: "",
    age: "",
    symptoms: "",
    bedKind: "",
    tubes: "",
    languages: "",
    careServiceItems: "",
    nurseAidesGender: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isNotifiableSelected, setIsNotifiableSelected] = useState(false);

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      console.log(createCareCase);
      if (
        createCareCase.headshot &&
        Object.keys(createCareCase.headshot).length > 0
      ) {
        const tmpHeadshotImageUrl = URL.createObjectURL(
          createCareCase.headshot
        );
        setHeadshotImageUrl(tmpHeadshotImageUrl);
      }
      await refreshAllSymptom();
      await refreshAllTube();
      await refreshAllLanguage();
      await refreshAllCareServiceItem();
      await refreshAllBedKind();
    };
    fetchData();
    return () => {};
  }, []);
  //#endregion

  //#region 刷新床位種類列表
  const refreshAllBedKind = async () => {
    const response = await apiGetAllBedKind();
    if (response && response.code === 0) {
      const tmpBedKinds = response.data.result.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });

      setBedKinds(tmpBedKinds);
    } else {
      showToast("error", response ? response.message : t("toast.unKnownError"));
    }
  };
  //#endregion

  //#region 刷新病症列表
  const refreshAllSymptom = async () => {
    const getAllSymptomResponse = await apiGetAllSymptom();
    if (getAllSymptomResponse && getAllSymptomResponse.code === 0) {
      const tmpSymptoms = getAllSymptomResponse.data.result.map((data) => {
        return {
          value: data.id,
          label: data.name,
          isNotifiableInfectiousDiseases: data.isNotifiableInfectiousDiseases,
        };
      });
      console.log("getAllSymptomResponse", getAllSymptomResponse);
      setSymptoms(tmpSymptoms);
    } else {
      showToast(
        "error",
        getAllSymptomResponse
          ? getAllSymptomResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 刷新管線列表
  const refreshAllTube = async () => {
    const getAllTubeResponse = await apiGetAllTube();
    if (getAllTubeResponse && getAllTubeResponse.code === 0) {
      const tmpTubes = getAllTubeResponse.data.result.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setTubes(tmpTubes);
    } else {
      showToast(
        "error",
        getAllTubeResponse
          ? getAllTubeResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 刷新溝通語言列表
  const refreshAllLanguage = async () => {
    const getAllLanguageResponse = await apiGetAllLanguage();
    if (getAllLanguageResponse && getAllLanguageResponse.code === 0) {
      const tmpLanguages = getAllLanguageResponse.data.result.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setLanguages(tmpLanguages);
    } else {
      showToast(
        "error",
        getAllLanguageResponse
          ? getAllLanguageResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 刷新需求項目列表
  const refreshAllCareServiceItem = async () => {
    const getAllCareServiceItemResponse = await apiGetAllCareServiceItem();
    if (
      getAllCareServiceItemResponse &&
      getAllCareServiceItemResponse.code === 0
    ) {
      const tmpCareServiceItems = getAllCareServiceItemResponse.data.result.map(
        (data) => {
          return {
            value: data.id,
            label: `${data.name} ： 單次費用 ${data.fee} 元`,
          };
        }
      );
      console.log(getAllCareServiceItemResponse.data.result);
      setCareServiceItems(tmpCareServiceItems);
      setParentCareServiceItems(getAllCareServiceItemResponse.data.result);
    } else {
      showToast(
        "error",
        getAllCareServiceItemResponse
          ? getAllCareServiceItemResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  const handleGenderChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    console.log("selectedValue", selectedValue);
    onChangeCaseData({
      target: { name: "nurseAidesGender", value: selectedValue },
    });
  };

  // 語言是單選 配合樣式修改
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#ffb777",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#9e3223",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#9e3223" : "white", // 咖啡色
      "&:hover": {
        backgroundColor: state.isSelected ? "#9e3223" : "#ffb777", // 選中時保持咖啡色
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#9e3223",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.3)",
    }),
  };

  // 其他多選樣式修改
  const customStylesOther = {
    control: (provided) => ({
      ...provided,
      borderColor: "#ffb777",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#9e3223",
      },
    }),
    option: (provided, state) => {
      const isNotifiable = state.data.isNotifiableInfectiousDiseases;
      return {
        ...provided,
        color: state.isSelected ? "white" : isNotifiable ? "red" : "black",
        backgroundColor: state.isSelected ? "#ffb777" : "white",
        "&:hover": {
          backgroundColor: state.isSelected ? "#9e3223" : "#ffb777",
        },
      };
    },
    multiValue: (provided, state) => {
      const isNotifiable = state.data.isNotifiableInfectiousDiseases;
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? "#9E3223"
          : isNotifiable
          ? "red"
          : "#9E3223",
        borderRadius: "10px",
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white", // 已選擇選項的文字顏色
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white", // 移除按鈕的顏色
      "&:hover": {
        backgroundColor: "darkred",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#9e3223",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.3)",
    }),
  };

  //#region 失去焦點Input的欄位
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    let newErrors = { ...errors };

    // 將輸入的值轉換為數字
    const numericValue = Number(value);

    // 立即驗證空輸入並更新錯誤狀態
    if (value.trim() === "") {
      newErrors[name] = "此欄位為必填項目";
    } else {
      // 如果輸入不為空，則進行正常的驗證

      // 檢查身高和體重的範圍
      if (name === "height") {
        if (numericValue < 0 || numericValue > 200) {
          newErrors[name] = "請輸入有效的數字，範圍在0到200之間";
        } else {
          newErrors[name] = "";
        }
      } else if (name === "weight") {
        if (numericValue < 0 || numericValue > 150) {
          newErrors[name] = "請輸入有效的數字，範圍在0到150之間";
        } else {
          newErrors[name] = "";
        }
      } else if (name === "age") {
        if (numericValue < 0 || numericValue > 150) {
          newErrors[name] = "請輸入正確年齡";
        } else {
          newErrors[name] = "";
        }
      } else {
        newErrors[name] = "";
      }
    }

    // 將更新後的錯誤狀態設置為組件的狀態
    setErrors(newErrors);
  };

  // 目前病症 失去焦點錯誤訊息
  const onChangeCaseSymptomsDataErrors = (e) => {
    onChangeCaseSymptomsData(e);
    console.log("e", e);
    // 驗證症狀是否有選項
    let newErrors = { ...errors };
    if (e.length === 0) {
      newErrors.symptoms = "此欄位為必填項目";
    } else {
      newErrors.symptoms = "";
    }
    setErrors(newErrors);
    console.log(newErrors);
  };

  // 床物種類 失去焦點錯誤訊息
  const onChangeCaseBedKindErrors = (e) => {
    onChangeCaseBedKindData(e);
    // 驗證床位種類是否有選項
    let newErrors = { ...errors };
    if (e.length === 0) {
      newErrors.bedKind = "此欄位為必填項目";
    } else {
      newErrors.bedKind = "";
    }
    setErrors(newErrors);
  };

  // 管線 失去焦點錯誤訊息
  const onChangeCaseTubesDataErrors = (e) => {
    onChangeCaseTubesData(e);
    // 驗證症狀是否有選項
    let newErrors = { ...errors };
    if (e.length === 0) {
      newErrors.tubes = "此欄位為必填項目";
    } else {
      newErrors.tubes = "";
    }
    setErrors(newErrors);
    console.log(newErrors);
  };

  // 溝通語言 失去焦點錯誤訊息
  const onChangeCaseLanguagesDataErrors = (e) => {
    onChangeCaseLanguagesData(e);
    // 驗證症狀是否有選項
    let newErrors = { ...errors };
    if (e.length === 0) {
      newErrors.languages = "此欄位為必填項目";
    } else {
      newErrors.languages = "";
    }
    setErrors(newErrors);
    console.log(newErrors);
  };

  // 需求項目 失去焦點錯誤訊息
  const onChangeCaseCareServiceItemsDataErrors = (e) => {
    onChangeCaseCareServiceItemsData(e);
    // 驗證症狀是否有選項
    let newErrors = { ...errors };
    if (e.length === 0) {
      newErrors.careServiceItems = "此欄位為必填項目";
    } else {
      newErrors.careServiceItems = "";
    }
    setErrors(newErrors);
    console.log(newErrors);
  };

  //#region 欄位驗證
  const checkValidator = async () => {
    let newErrors = { ...errors };
    var excludes = [
      "forNurseAidesContent",
      "nurseAidesGender",
      "serviceDates",
      "serviceTime",
      "subFamilyMail",
      "subFamilyName",
      "subFamilyPhone",
    ];
    if (type === "inHome") {
      excludes.push("bedKind");
    }
    var validateFieldsResult = await ValidateFields(
      createCareCase,
      newErrors,
      excludes
    );

    if (!validateFieldsResult.result) {
      setErrors(newErrors);
    }

    // 檢查指定欄位是否有錯誤
    const fieldsToCheck = [
      "caseName",
      "bedKind",
      "gender",
      "height",
      "weight",
      "age",
      "symptoms",
      "tubes",
      "languages",
      "careServiceItems",
      "nurseAidesGender",
    ];
    let hasErrors = false;

    for (let field of fieldsToCheck) {
      if (newErrors[field]) {
        hasErrors = true;
        break;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
      return false;
    }

    return true;
  };
  //#endregion

  //#region 下一步 如果包含法定傳染疾病要跳提示訊息
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (await checkValidator()) {
      const hasNotifiableDisease = createCareCase.symptoms.some(
        (symptom) => symptom.isNotifiableInfectiousDiseases
      );
      if (hasNotifiableDisease) {
        setIsNotifiableSelected(true);
        setShowModal(true);
      } else {
        onNextStep();
      }
    } else {
      showToast("error", "有必填欄位未填寫或是格式錯誤");
    }
  };
  const handleConfirm = () => {
    setShowModal(false);
    onNextStep();
  };

  const handleClose = () => {
    setShowModal(false);
  };
  //#endregion

  // 處理Enter 鍵的按下 不要有任何動作
  const handleKeyDown = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  // #region 處理檔案輸入框變更事件
  const handleFileChange = async (e, previewId, type) => {
    const file = e.target.files[0]; // 取得第一個文件，可以根據需要處理多個文件上傳
    if (file) {
      // 檢查文件類型是否為 jpg、png、jpeg
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        // 如果文件類型不符合要求，顯示錯誤訊息並返回
        showToast("error", "僅能上傳 jpg、png、jpeg 檔");
        return;
      }
      onChangeHeadshot(file);
      // 創建一個Blob URL來顯示圖片預覽
      const imageUrl = URL.createObjectURL(file);
      setHeadshotImageUrl(imageUrl);

      // // 將圖片預覽設置到指定的區域
      // const previewArea = document.getElementById(previewId);
      // if (previewArea) {
      //   previewArea.innerHTML = `<img src="${imageUrl}" alt="File Preview" />`;
      // }
    }
  };
  //#endregion
  const handleRemoveFile = () => {
    setHeadshotImageUrl(null);
    onChangeHeadshot(null);
  };

  return (
    <div className="content">
      <div className="applyHead">
        <h1>找照服員</h1>
        <div>
          <div>
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                fill="white"
              />
            </svg>
          </div>
          <div>
            <svg
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                fill="#9E3223"
              />
            </svg>
          </div>
          <div className="active">
            <svg
              width="18"
              height="25"
              viewBox="0 0 18 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                fill="#9E3223"
              />
            </svg>
          </div>
        </div>
      </div>
      <form className="flex flex-col gap-[2.1875rem] mb-[10rem]">
        <div className="formBlock">
          <strong>被照顧者基本資料</strong>
          <div>
            <div className="grid grid-cols-2 gap-y-[1.5625rem] gap-x-[1.875rem]">
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="caseName"
                >
                  姓名*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="text"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] input-name"
                    name="caseName"
                    id="caseName"
                    value={createCareCase.caseName}
                    onChange={onChangeCaseData}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
            {errors.caseName !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "family.name"
              )}${t("helpWord.required")}`}</div>
            )}
            <div className="flex items-center gap-[0.5rem]">
              <label
                htmlFor="man"
                className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              >
                性別*
              </label>
              <div className="flex items-center gap-[0.75rem] grow">
                <div className="inline-flex items-center justify-center">
                  <label
                    className="relative flex items-center p-1 rounded-full cursor-pointer"
                    htmlFor="man"
                  >
                    <input
                      id="man"
                      type="radio"
                      name="gender"
                      className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                      value={0}
                      checked={
                        createCareCase.gender === 0 ||
                        createCareCase.gender === "0"
                      }
                      onChange={(e) => onChangeCaseData(e)}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="font-light text-primary text-[1.25rem] cursor-pointer select-none"
                    htmlFor="man"
                  >
                    男
                  </label>
                </div>
                <div className="inline-flex items-center justify-center">
                  <label
                    className="relative flex items-center p-1 rounded-full cursor-pointer"
                    htmlFor="woman"
                  >
                    <input
                      id="woman"
                      type="radio"
                      name="gender"
                      className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                      value={1}
                      checked={
                        createCareCase.gender === 1 ||
                        createCareCase.gender === "1"
                      }
                      onChange={(e) => onChangeCaseData(e)}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="font-light text-primary text-[1.25rem] cursor-pointer select-none"
                    htmlFor="woman"
                  >
                    女
                  </label>
                </div>
              </div>
            </div>
            {errors.gender !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "gender"
              )}${t("helpWord.required")}`}</div>
            )}
            <div className="grid grid-cols-2 gap-y-[1.5625rem] gap-x-[1.875rem]">
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="height"
                >
                  身高*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="number"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    name="height"
                    id="height"
                    min={0}
                    max={200}
                    value={createCareCase.height}
                    onChange={onChangeCaseData}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="weight"
                >
                  體重*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="number"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    name="weight"
                    id="weight"
                    min={0}
                    max={150}
                    value={createCareCase.weight}
                    onChange={onChangeCaseData}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="age"
                >
                  年齡*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="number"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    name="age"
                    id="age"
                    value={createCareCase.age}
                    onChange={onChangeCaseData}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    min={0}
                    max={150}
                    step="1" // 助防止小人輸入
                  />
                </div>
              </div>
            </div>
            {(errors.height || errors.weight || errors.age) && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "height"
              )}/${t("weight")}/${t("age")}${t("helpWord.range")}`}</div>
            )}
          </div>
          <div
            className="InspireDropzone"
            id="headshot"
            style={{
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => document.getElementById("upload-front")?.click()}
          >
            {headshotImageUrl ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "10px",
                    width: "36px",
                    height: "36px",
                  }}
                  onClick={handleRemoveFile}
                >
                  <img src="/img/times.png" alt="刪除身分證正面預覽" />
                </div>
                <img src={headshotImageUrl} alt="身分證正面預覽" />
              </>
            ) : (
              <>
                <div>
                  <span>點擊上傳被照顧者照片{headshotImageUrl}</span>
                  <span>僅能上傳jpg、png、jpeg檔</span>
                </div>
                <input
                  type="file"
                  id="upload-front"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      "identityCardFront",
                      "identityCardFront"
                    )
                  }
                  ref={headshotRef}
                />
              </>
            )}
          </div>
        </div>
        <div className="formBlock">
          <strong>被照顧者狀況</strong>
          <div>
            <div className="flex items-center gap-[0.5rem]">
              <div className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]">
                <label className="" htmlFor="status">
                  目前病症*
                </label>
                <p className="flex-none text-primary text-[0.8rem] font-medium leading-[1.5]">
                  紅字選項為法定傳染疾病
                </p>
              </div>

              <div className="flex items-center grow">
                <Select
                  name="symptoms"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  isSearchable={true}
                  isMulti={true}
                  value={createCareCase.symptoms}
                  onChange={onChangeCaseSymptomsDataErrors}
                  options={symptoms}
                  onKeyDown={handleKeyDown}
                  styles={customStylesOther}
                />
              </div>
            </div>
            {errors.symptoms !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "symptom.list"
              )}${t("helpWord.required")}`}</div>
            )}
            <div className="flex items-center gap-[0.5rem]">
              <label
                className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                htmlFor="department"
              >
                管線*
              </label>
              <div className="flex items-center grow">
                <Select
                  name="tubes"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  isSearchable={true}
                  isMulti={true}
                  value={createCareCase.tubes}
                  onChange={onChangeCaseTubesDataErrors}
                  options={tubes}
                  onKeyDown={handleKeyDown}
                  styles={customStylesOther}
                />
              </div>
            </div>
            {errors.tubes !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "tube.list"
              )}${t("helpWord.required")}`}</div>
            )}
          </div>
        </div>
        <div className="formBlock">
          <strong>案件需求</strong>
          <div>
            <div className="flex items-baseline gap-[0.5rem]">
              <label
                htmlFor="man1"
                className="w-[5.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              >
                指定照服員性別
              </label>
              <div className="flex flex-wrap items-center gap-[0.75rem] grow">
                <Select
                  name="nurseAidesGender"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  isSearchable={true}
                  isMulti={false}
                  value={genderOptions.find(
                    (option) => option.value === createCareCase.nurseAidesGender
                  )} // 根據 createCareCase.nurseAidesGender 找到對應的選項
                  onChange={handleGenderChange} // 使用 handleGenderChange 作為 onChange 的回調函式
                  options={genderOptions}
                  styles={customStylesOther}
                />
              </div>
            </div>
            {errors.nurseAidesGender !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "nurseAides"
              )}${t("gender")}${t("helpWord.required")}`}</div>
            )}
            {type == "Hospital" ? (
              <>
                <div className="flex items-center gap-[0.5rem]">
                  <label
                    className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                    htmlFor="lang"
                  >
                    床位種類*
                  </label>
                  <div className="flex items-center grow">
                    <Select
                      name="languages"
                      className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                      isSearchable={true}
                      isMulti={false}
                      value={createCareCase.bedKind}
                      onChange={onChangeCaseBedKindErrors}
                      options={bedKinds}
                      onKeyDown={handleKeyDown}
                      styles={customStyles}
                    />
                  </div>
                </div>
                {errors.bedKind !== "" && (
                  <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                    "bedKind.list"
                  )}${t("helpWord.required")}`}</div>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="flex items-center gap-[0.5rem]">
              <label
                className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                htmlFor="lang"
              >
                溝通語言*
              </label>
              <div className="flex items-center grow">
                <Select
                  name="languages"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  isSearchable={true}
                  isMulti={false}
                  value={createCareCase.languages}
                  onChange={onChangeCaseLanguagesDataErrors}
                  options={languages}
                  onKeyDown={handleKeyDown}
                  styles={customStyles}
                />
              </div>
            </div>
            {errors.languages !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "language.list"
              )}${t("helpWord.required")}`}</div>
            )}
            <div className="flex items-center gap-[0.5rem]">
              <label
                className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                htmlFor="require"
              >
                需求項目*
              </label>
              <div className="flex items-center grow">
                <Select
                  name="tubes"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  isSearchable={true}
                  isMulti={true}
                  value={createCareCase.careServiceItems}
                  onChange={onChangeCaseCareServiceItemsDataErrors}
                  options={careServiceItems}
                  onKeyDown={handleKeyDown}
                  styles={customStylesOther}
                />
              </div>
            </div>
            {errors.careServiceItems !== "" && (
              <div className="text-red-500 text-sm mt-1 ml-1 error-message">{`${t(
                "careServiceItem.list"
              )}${t("helpWord.required")}`}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-[0.75rem]">
          <button
            href="/FindCareStepChoose"
            className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
            title="上一步"
            onClick={onPreviousStep}
          >
            上一步
          </button>
          <button
            type="submit"
            className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            下一步
          </button>
          <PatientInfoSure
            isOpen={showModal}
            onClose={handleClose}
            onConfirm={handleConfirm}
          />
        </div>
      </form>
    </div>
  );
};

export default PatientInfo;
