import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import Line from "../../layouts/Line";
import { Link } from "react-router-dom";


/**
 * 資料顯示頁面
 */
const InfoPanel = ({ createFamily }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    return (
        <>
            <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">身分驗證</h1>
            <div className="flex items-center gap-[0.625rem] mt-[1.6875rem]">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_32_1078)">
                        <path d="M35 35H0V0H32.5864V4.82724H4.82724V30.1728H30.1728V18.5862H35V35Z" fill="#4CC764" />
                        <path
                            d="M11.3397 15.8931L7.96948 19.3491L16.9702 28.1263L20.3404 24.6703L11.3397 15.8931Z"
                            fill="#4CC764"
                        />
                        <path
                            d="M31.3705 6.97422L18.6639 19.5616L22.0612 22.991L34.7678 10.4036L31.3705 6.97422Z"
                            fill="#4CC764"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_32_1078">
                            <rect width="35" height="35" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span className="text-primary text-[1.25rem] leading-[1.5]">驗證成功</span>
            </div>
            <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
                Hi, {createFamily.name}，趕快點擊下方按鈕，立即開始使用服務吧！
            </p>
            <div className="flex flex-col items-center justify-center gap-[0.625rem] mt-[1.5625rem]">
                <Link to="/Choose">
                    <a
                        href={void 0}
                        className="min-w-[8.3125rem] mx-a py-1 px-[1.125rem] bg-primary rounded-full text-white text-[1.5rem] font-bold text-center leading-[1.5]"
                        title="進入首頁"
                    >
                        進入首頁
                    </a>
                </Link>
            </div>
            <Line />
        </>
    );
};

export default InfoPanel;
