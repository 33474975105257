import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import HamburgerFamilyModal from "../HamburgerModal";
import "./../../bootstrap.css";
import {
  apiDownloadFile,
  apiEvaluateToFamily,
  apiGetNurseAidesCaseDetail,
  apiWriteEvaluate,
} from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import HamburgerModal from "../HamburgerModal";

/**
 * 資料顯示頁面
 */
const Pairing3NureAides = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { navigate } = useNavigate();
  const [evaluate, setEvaluate] = useState("");
  const [rating, setRating] = useState(0); // 星星分數顯示
  const [selection, setSelection] = useState(0); //渲染滑鼠滑過的星星'
  const [careCaseDetail, setCareCaseDetail] = useState({
    careCaseId: 0,
    careCaseNo: "",
    hospitalName: "",
    mainFamily: {
      name: "",
      phone: "",
      address: "",
    },
    subFamily: {
      name: "",
      phone: "",
      address: "",
    },
    patient: {
      gender: 0,
      height: 0,
      weight: 0,
      age: 0,
      symptom: [],
      language: [],
      tube: [],
      plan: {
        name: "",
        hours: 0,
        unit: 0,
        price: 0,
        careCasePlanNurseAides: {
          careCasePlanNurseAidesTimes: [
            {
              startDate: "",
              startTime: 0,
            },
          ],
        },
      },
    },
    forNurseAidesContent: "",
  });
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal

  // #region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        careCaseId: parseInt(id),
      };
      const getNurseAidesCaseDetailResponse = await apiGetNurseAidesCaseDetail(
        params
      );
      if (
        getNurseAidesCaseDetailResponse &&
        getNurseAidesCaseDetailResponse.code === 0
      ) {
        const data = getNurseAidesCaseDetailResponse.data;
        const param = encodeURI(data.headshotFilePath);
        let fileURL = "";
        if (data.headshotFilePath) {
          const downloadFile = await apiDownloadFile(param);
          if (downloadFile) {
            fileURL = URL.createObjectURL(downloadFile.data);
          }
        }

        setCareCaseDetail({ ...data, fileURL: fileURL });
      }
    };
    fetchData();
  }, [id]);

  //#region  撰寫評論
  const handleWriteChange = (e) => {
    const { value } = e.target;
    console.log(value);
    setEvaluate(value);
  };
  // #end region

  //   #送出評價
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      careCaseId: parseInt(id),
      score: rating,
      //   toPersonnelId: completePair.nurseAidesId,
      description: evaluate,
    };
    const writeEvaluateResponse = await apiEvaluateToFamily(params);
    if (writeEvaluateResponse && writeEvaluateResponse.code === 0) {
      showToast("success", "送出成功");
      navigate("/NurseAides");
    } else {
      showToast(
        "error",
        writeEvaluateResponse
          ? writeEvaluateResponse.message
          : t("toast.unknownError") //網路連線錯誤
      );
    }
  };
  // #end region

  //#region 星星評分
  function Star({ marked, starId }) {
    return (
      <span
        star-id={starId}
        style={{ color: "#ff9933", fontSize: "2.5rem" }}
        role="button"
      >
        {/* 空星，實星 */}
        {marked ? "\u2605" : "\u2606"}
      </span>
    );
  }

  function StarRating() {
    // 鼠標移入效果
    const hoverOver = (event) => {
      let val = 0;
      if (event && event.target && event.target.getAttribute("star-id"))
        val = event.target.getAttribute("star-id");
      setSelection(val);
    };

    return (
      <div
        // 鼠標移入效果
        onMouseOut={() => hoverOver(null)}
        // 點擊選中分數
        onClick={(event) => setRating(event.target.getAttribute("star-id"))}
        onMouseOver={hoverOver}
      >
        {/* 創建5個组件 */}
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1} `}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
          />
        ))}
      </div>
    );
  }
  //#endregion
  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex flex-col gap-[2.1875rem]"
            action="./findCareStep6.html"
            onSubmit={handleSubmit}
          >
            <div className="flex items-center gap-[0.625rem] grow justify-between">
              <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
                已完成案件
              </strong>
              <span
                className="flex-none cursor-pointer"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>

            <input type="hidden" name="id" value="1" />
            <div className="flex flex-col items-center gap-[1.5625rem]">
              <div className="flex flex-col gap-[0.8125rem]">
                <div className="w-[8.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
                  <img
                    src={
                      careCaseDetail.fileURL
                        ? careCaseDetail.fileURL
                        : "./img/user.png"
                    }
                    width="130"
                    height="130"
                    alt="user avator"
                  />
                </div>

                {/* {completePair.nurseAidesName}({completePair.nurseAidesGender}/ */}
                {/* {completePair.nurseAidesAge}歲)  */}
                <ul className="min-w-[15.625rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
                  <li>
                    案件編號：
                    {careCaseDetail.careCaseNo}
                  </li>
                  <li>
                    {careCaseDetail.hospitalName
                      ? `醫院：${careCaseDetail.hospitalName}`
                      : ""}
                  </li>
                  {/* <li>費用： {careCases.plan.price}/ 天</li>
                                        <li>天數： {careCases.plan.careCasePlanNurseAides.careCasePlanNurseAidesTimes.length}天</li> */}
                  <li>
                    家屬：{careCaseDetail.mainFamily.name}|
                    {careCaseDetail.mainFamily.phone}
                  </li>
                  {careCaseDetail.subFamily.name && (
                    <li>
                      次要家屬：{careCaseDetail.subFamily.name}|
                      {careCaseDetail.subFamily.phone}
                    </li>
                  )}

                  <li>地址：{careCaseDetail.mainFamily.address}</li>
                </ul>
              </div>
              <div className="w-full flex flex-col items-center gap-[1.625rem]">
                <textarea
                  className="w-full p-[1rem] bg-white border-[0.125rem] border-primary text-primary text-base md:text-sm lg:text-base placeholder:text-xs placeholder:text-[rgba(0,0,0,0.3)]"
                  name="comment"
                  id="comment"
                  maxLength="50"
                  cols="30"
                  rows="6"
                  placeholder="家屬評價(0/50)"
                  aria-label="評論"
                  onChange={handleWriteChange}
                ></textarea>
                <div className="-mb-[1.0625rem]">
                  <StarRating />
                  <input type="hidden" name="rating" id="rating" value="0" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-[0.75rem]">
              <button
                type="submit"
                className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="送出評價"
                onClick={handleSubmit}
              >
                送出評價
              </button>
            </div>
          </form>
        </div>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default Pairing3NureAides;
