import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import {
  apiDownloadFile,
  apiNurseAidesCareCaseQueryByStatus,
} from "../../utils/Api";
import HamburgerModal from "../HamburgerModal";

/**
 * 資料顯示頁面
 */
const InfoPanel = () => {
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const iseEffectcalledRef = useRef(false);
  const { showToast } = useToast();
  const navigate = useNavigate(); //跳轉Router
  const navigateRef = useRef(navigate);
  const [careCases, setCareCases] = useState([]);
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const colorGreen = {
    "--color": "#9CC537",
    height: "3rem",
  };

  const colorPink = {
    "--color": "#F2307A",
    height: "3rem",
  };
  const colorOrange = {
    "--color": "#FF6200",
    height: "3rem",
  };
  const colorBlue = {
    "--color": "#3DA39E",
    height: "3rem",
  };

  const colorRed = {
    "--color": "#F70B0B",
    height: "3rem",
  };

  //#region 刷新案件列表
  const refreshAllCareCase = useCallback(
    async (status) => {
      const params = {
        status: status,
      };

      const response = await apiNurseAidesCareCaseQueryByStatus(params);
      if (response && response.code === 0) {
        if (response.data.result.length === 0 && status === 0) {
          navigateRef.current("/ProjectList");
        }
        const results = response.data.result;
        const apiDownloadFileArray = await Promise.all(
          results.map(async (result) => {
            if (result.headshotFilePath) {
              const param = encodeURI(result.headshotFilePath);
              return { ...result, headshotFile: await apiDownloadFile(param) };
            }
            return { ...result, headshotFile: "" };
          })
        );
        console.log(apiDownloadFileArray);
        // 排序 careCases 陣列
        const sortedCareCases = apiDownloadFileArray.sort((a, b) => {
          const statusOrder = {
            配對中: 0,
            請確認: 1,
            進行中: 2,
            已完成: 3,
            失效取消: 4,
          };
          const cleanStatus = (status) => status.replace(/\//g, "");
          return (
            statusOrder[cleanStatus(a.status)] -
            statusOrder[cleanStatus(b.status)]
          );
        });

        setCareCases(sortedCareCases);
      } else {
        showToast("error", response ? response.message : tRef.current); // [回傳的訊息] : 網路連線錯誤
      }
    },
    [showToast]
  );
  //#endregion

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      if (!window.showAContent) {
        await refreshAllCareCase(-1);
      }
    };
    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      fetchData();
    }

    return () => {};
  }, [refreshAllCareCase]);
  //#endregion

  // #region 根據選的狀態更新列表
  const handleStatusChange = (value) => {
    refreshAllCareCase(value);
  };
  //#endregion

  //#region跳轉頁面
  const handleNavigate = (status, id) => {
    switch (status) {
      case "請確認":
        navigate(`/Checking/${id}`);
        break;
      case "進行中":
        navigate(`/Checkin/${id}`);
        break;
      case "已完成":
        navigate(`/Pairing3NurseAides/${id}`);
        break;
      default:
        break;
    }
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };

  //#endregion
  useEffect(() => {
    console.log(careCases);
  }, [careCases]);

  return (
    <>
      {window.showAContent ? (
        <div className="main-background normal set">
          <div className="content">
            <p>照顧服務預約功能</p>
            <p>即將上線</p>
            <p>請耐心等候</p>
          </div>
        </div>
      ) : (
        <div className=" main-background normal">
          <div className="content">
            <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
              <form
                className="flex items-start justify-start gap-[0.625rem]"
                action="./projectList.html"
              >
                {/* <strong className="max-w-[6.5em] text-primary text-[1.5rem] leading-[1.5]">照服員 主畫面</strong> */}
                <div className="flex items-center gap-[0.625rem] grow">
                  <select
                    name="type"
                    id="type"
                    className="min-w-[9.125rem] min-w-0 grow p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs placeholder:text-[rgba(0,0,0,0.3)]"
                    aria-label="找案件"
                    onChange={(e) => handleStatusChange(e.target.value)}
                  >
                    <option value="" disabled>
                      請選擇
                    </option>
                    <option value="-1" selected>
                      全部
                    </option>
                    <option value="0">配對中</option>
                    <option value="1">請確認</option>
                    <option value="2">進行中</option>
                    <option value="3">已完成</option>
                    <option value="4">失效/取消</option>
                  </select>
                  <span
                    className="flex-none"
                    title="Sort Button"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowHamburgerModal}
                  >
                    <svg
                      width="28"
                      height="23"
                      viewBox="0 0 28 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_87_2023)">
                        <path
                          d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                          fill="#9E3223"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_2023">
                          <rect width="28" height="23" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
              </form>
              <form className="flex flex-col gap-[2.1875rem]">
                {careCases.length > 0 ? (
                  careCases.map((careCase, index) => {
                    console.log(careCase.headshotFile);
                    return (
                      <div
                        key={index}
                        className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden
                        hover:shadow-2xl"
                      >
                        <div className="flex-none bg-[#FFDA5E]">
                          <img
                            src={
                              careCase.headshotFile !== "" &&
                              careCase.headshotFile !== undefined
                                ? URL.createObjectURL(
                                    careCase.headshotFile.data
                                  )
                                : "./img/user.png"
                            }
                            className="h-full object-center object-cover"
                            width="110"
                            height="100"
                            alt="user avator"
                          />
                        </div>
                        <ul
                          style={{ cursor: "pointer" }}
                          className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6] "
                          onClick={() => {
                            handleNavigate(
                              careCase.status,
                              careCase.careCaseId
                            );
                          }}
                        >
                          <li>案件編號：{careCase.caseNo}</li>
                          <li>
                            家屬：{careCase.mainFamily.name} |{" "}
                            <a
                              className="hover:text-[#FFDA40] "
                              href={`tel:${careCase.mainFamily.phone}`}
                            >
                              {careCase.mainFamily.phone}
                            </a>
                          </li>
                          {careCase.subFamily.name !== "" ? (
                            <li>
                              次要家屬：{careCase.subFamily.name} |{" "}
                              <a href={`tel:${careCase.subFamily.phone}`}>
                                {careCase.subFamily.phone}
                              </a>
                            </li>
                          ) : (
                            <></>
                          )}
                          {careCase.hospitalName !== "" ? (
                            <li>醫院：{careCase.hospitalName}</li>
                          ) : (
                            <></>
                          )}
                          {careCase.homeAddress !== "" ? (
                            <li>住址：{careCase.homeAddress}</li>
                          ) : (
                            <></>
                          )}
                          {careCase.status === "進行中" ? (
                            <>
                              <li
                                className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                                style={colorOrange}
                              >
                                <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                                  進行中
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          {careCase.status === "配對中" ? (
                            <>
                              <li
                                className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                                style={colorPink}
                              >
                                <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                                  配對中
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          {careCase.status === "已完成" ? (
                            <>
                              <li
                                className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                                style={colorBlue}
                              >
                                <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                                  已完成
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          {careCase.status === "失敗/取消" ? (
                            <>
                              <li
                                className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-[1.25rem] bg-[var(--color)]"
                                style={colorRed}
                              >
                                <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                                  失敗取消
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          {careCase.status === "請確認" ? (
                            <>
                              <li
                                className="size-[5rem] flex items-center justify-center absolute top-[0.3125rem] right-[0.3125rem] z-3 rounded-full bg-[var(--color)]"
                                style={colorGreen}
                              >
                                <span className="w-[3em] text-white text-[1.2rem] leading-[1] text-center">
                                  確認中
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}

                <div className="flex items-center justify-end gap-[0.75rem]">
                  <Link to="/ProjectList">
                    <button
                      type="submit"
                      className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                      title="找案件"
                    >
                      找案件
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <HamburgerModal
            showHamburgerModal={showHamburgerModal}
            handleHamburgerClose={handleHamburgerClose}
          />
        </div>
      )}
    </>
  );
};

export default InfoPanel;
