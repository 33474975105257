import React, { useState, useEffect } from 'react';
import { addWindowClass, removeWindowClass } from '../utils/Helpers';
import { ToastProvider } from '../contexts/ToastContext';
import { useMyUserContext } from '../contexts/MyUserContext';
import {
    apiMyUserData,
} from "../utils/Api";
/**
 * 畫面樣板
 */
const _Layout = (props) => {
    const { myUser, setMyUser } = useMyUserContext();

    //#region 初始載入
    useEffect(() => {
        removeWindowClass('login-page');
        addWindowClass('sidebar-mini');
        const fetchData = async () => {
            await refreshMyUser();
        };

        fetchData();
        return () => {
            removeWindowClass('sidebar-mini');
        };
    }, []);
    //#endregion

    //#region 刷新MyUser
    const refreshMyUser = async () => {
        let myUserResponse = await apiMyUserData();
        if (myUserResponse && myUserResponse.code == 0) {
            setMyUser(myUserResponse.data);
        }
    }
    //#endregion

    return (
        <ToastProvider>
            <div className="relative min-h-screen main">
                {props.children}
            </div>
        </ToastProvider>
    );
}

export default _Layout;