import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import ChatCard from "./ChatCard";
import ChatForm from "./ChatForm";
import HamburgerFamilyModal from "../HamburgerModal";
import { useParams } from "react-router-dom";
import {
  apiCreateCareCasePlanQA,
  apiGetAllCareCasePlanQA,
} from "../../utils/Api";
import dayjs from "dayjs";

/**
 * 照服員QA資料顯示頁面
 */
const InfoPanel = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const { showToast } = useToast();
  const isEffectCalledRef = useRef(false);
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [chats, setChats] = useState([
    // {
    //   id: "0",
    //   name: "tex14",
    //   avatar: "img/user.png",
    //   message: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    //   date: "〇",
    //   replies: [
    //     {
    //       id: 1,
    //       name: "text15",
    //       avatar: "img/user.png",
    //       message: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    //       date: "〇",
    //     },
    //   ],
    // },
  ]);

  // #region 刷新聊天室
  const refreshChats = useCallback(async () => {
    if (id > 0) {
      const params = {
        CareCaseId: id,
      };
      const getAllCareCasePlanQAResponse = await apiGetAllCareCasePlanQA(
        params
      );
      if (
        getAllCareCasePlanQAResponse &&
        getAllCareCasePlanQAResponse.code === 0
      ) {
        const response = getAllCareCasePlanQAResponse.data.result;
        console.log("1213212", response);
        const tempCahts = transformDataRecursively(response);
        console.log("2222222", tempCahts);
        setChats(tempCahts);
      } else {
        showToast(
          "error",
          getAllCareCasePlanQAResponse
            ? getAllCareCasePlanQAResponse.message
            : tRef.current
        );
      }

      function transformDataRecursively(data) {
        const idMap = new Map();

        // 建立所有物件的map
        data.forEach((item) => {
          idMap.set(item.id, {
            id: item.id,
            name: item.personnelName,
            avatar: item.headshotFilePath,
            message: item.content,
            date: dayjs(new Date()).diff(dayjs(item.createdDateTime), "day"),
            replies: [],
          });
        });

        // 建立父子層結構的遞迴函數
        function addReplies(item) {
          const obj = idMap.get(item.id);
          if (item.parentId !== 0) {
            const parent = idMap.get(item.parentId);
            parent.replies.push(obj);
          }
          data
            .filter((child) => child.parentId === item.id)
            .forEach(addReplies);
        }

        // 開始遞迴
        data.filter((item) => item.parentId === 0).forEach(addReplies);

        // 返回父層物件

        return data
          .filter((item) => item.parentId === 0)
          .map((item) => idMap.get(item.id));
      }
    }
  }, [id, showToast]);
  // #endregion

  // #region 初始載入
  useEffect(() => {
    if (!isEffectCalledRef.current) {
      isEffectCalledRef.current = true;
      refreshChats();
    }
  }, [refreshChats]);
  // #endregion

  // #region 回覆訊息處理
  const addReply = async (parentId, reply) => {
    const tempChat = {
      careCaseId: parseInt(id),
      parentId: parseInt(parentId),
      content: reply.message,
    };
    const createNewCareCasePlanQA = await apiCreateCareCasePlanQA(tempChat);
    if (createNewCareCasePlanQA && createNewCareCasePlanQA.code === 0) {
      refreshChats();
    } else {
      showToast(
        "error",
        createNewCareCasePlanQA ? createNewCareCasePlanQA.message : tRef.current
      );
    }
  };
  // #endregion

  // #region  傳送新的訊息
  const addChat = async (chat) => {
    const tempChat = {
      careCaseId: parseInt(id),
      parentId: parseInt(chat.id),
      content: chat.message,
    };
    const createNewCareCasePlanQA = await apiCreateCareCasePlanQA(tempChat);
    if (createNewCareCasePlanQA && createNewCareCasePlanQA.code === 0) {
      refreshChats();
    } else {
      showToast(
        "error",
        createNewCareCasePlanQA ? createNewCareCasePlanQA.message : tRef.current
      );
    }
  };
  // #endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <div className="main-background normal">
      <div className="content h-[calc(100vh-1.25rem-9.875rem)]">
        <div className="applyHead">
          <h1>照服員提問</h1>
          <div className="flex items-center gap-[0.625rem] flex-none">
            <a
              data-bs-toggle="modal"
              href="#MenuModal"
              role="button"
              title="Sort Button"
              onClick={handleShowHamburgerModal}
            >
              <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_87_2023)">
                  <path
                    d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                    fill="#9E3223"
                  />
                  <path
                    d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                    fill="#9E3223"
                  />
                  <path
                    d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                    fill="#9E3223"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_87_2023">
                    <rect width="28" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div className="chatContainer h-[calc(100vh-1.25rem-9.875rem-5.625rem-3.75rem)] flex flex-col gap-[2.5rem] -mr-[8.14vw] pb-[6.25rem] pr-[calc(8.14vw-0.25rem)] scrollable md:mr-0 md:pr-[0.625rem]">
          {chats.map((chat, index) => (
            <Fragment key={chat.id}>
              <ChatCard chat={chat} addReply={addReply} />
            </Fragment>
          ))}
          <div className="chatFormContainer">
            <ChatForm addChat={addChat} />
          </div>
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default InfoPanel;
