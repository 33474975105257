import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import InfoPanel from "../../components/CaseSchedule/InfoPanel";

/**
 * 選擇身分
 * @returns
 */
const CaseSchedule = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <InfoPanel />
      </div>
    </>
  );
};

export default CaseSchedule;
