import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNoLink from "../../layouts/HeaderNoLink";
import ForgetAccount from "../../components/Login/ForgetAccount";

/**
 * 忘記帳號
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
    <div className="design">
      <HeaderNoLink/>
      <ForgetAccount/>
    </div>
    </>
  );
};

export default InfoPanel;
