import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; //语系
import { Modal, Button, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./../bootstrap.css";

const HamburgerModal = ({
  showHamburgerModal,
  handleHamburgerClose,
  title,
  id,
  delCallback,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let PersonnelType = localStorage.getItem("PersonnelType");
  // 如果頁面是在當前頁面則關閉選單
  const handleLinkClick = (path) => {
    console.log(location);
    navigate(path);
    // if (location.pathname === path) {
    handleHamburgerClose();
    // }
  };

  return (
    <Modal
      show={showHamburgerModal}
      // onHide={handleHamburgerClose}
      className="modal fade"
      id="MenuModal"
      tabIndex="-1"
      aria-labelledby="MenuModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-content hamburger">
        <div className="modal-body hamburger flex flex-col gap-[3.75rem] pb-[3.75rem]">
          <div className="flex items-center justify-end">
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleHamburgerClose}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_26_919)">
                  <path
                    d="M0.0210866 4.72536L4.49542 0L14.0205 9.33526L23.351 0.0263966L27.8665 4.71106L18.6671 13.8892L28 23.0361L23.5257 27.7615L14.0605 18.485L4.5155 28.0079L0 23.3233L9.41401 13.9311L0.0210866 4.72536Z"
                    fill="#9E3223"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_26_919">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <ul className="flex flex-col gap-[1.875rem]">
            <li
              className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
              onClick={() => {
                handleLinkClick("/UserProfile");
              }}
            >
              <a
                href="#"
                className="flex items-center gap-[1rem]"
                title="個人資料"
              >
                <svg
                  width="30"
                  height="41"
                  viewBox="0 0 30 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_596_10)">
                    <path
                      d="M2.08936 41.0002V30.4498C2.08936 23.5204 7.86791 17.9062 15.0002 17.9062C22.1325 17.9062 27.9111 23.5204 27.9111 30.4498V41.0002"
                      fill="#9E3223"
                    />
                    <path
                      d="M30 41.0001H25.8217V30.4497C25.8217 24.6529 20.9666 19.9359 15 19.9359C9.03343 19.9359 4.17827 24.6529 4.17827 30.4497V41.0001H0V30.4497C0 22.4121 6.7312 15.8765 15 15.8765C23.2688 15.8765 30 22.4162 30 30.4497V41.0001Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M14.9459 17.9021C19.4572 17.9021 23.1144 14.3489 23.1144 9.96592C23.1144 5.58292 19.4572 2.02979 14.9459 2.02979C10.4345 2.02979 6.77734 5.58292 6.77734 9.96592C6.77734 14.3489 10.4345 17.9021 14.9459 17.9021Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M14.9456 19.9357C9.28827 19.9357 4.68799 15.4623 4.68799 9.9699C4.68799 4.47752 9.28827 0 14.9456 0C20.603 0 25.2033 4.47346 25.2033 9.96584C25.2033 15.4582 20.5988 19.9317 14.9456 19.9317V19.9357ZM14.9456 4.05941C11.5905 4.05941 8.86626 6.7102 8.86626 9.96584C8.86626 13.2215 11.5947 15.8723 14.9456 15.8723C18.2966 15.8723 21.025 13.2215 21.025 9.96584C21.025 6.7102 18.2966 4.05941 14.9456 4.05941Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_596_10">
                      <rect width="30" height="41" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                個人資料
              </a>
            </li>

            {PersonnelType === "NurseAides" ? (
              <>
                <li
                  className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
                  onClick={() => {
                    handleLinkClick("/LicenseTracker");
                  }}
                >
                  <a
                    href="#"
                    className="flex items-center gap-[1rem]"
                    title="證照管理"
                  >
                    <svg
                      width="43"
                      height="47"
                      viewBox="0 0 43 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1701_50)">
                        <path
                          d="M40.7928 1.22607H10.5688V40.0522H40.7928V1.22607Z"
                          fill="white"
                        />
                        <path
                          d="M39.5745 2.45217V38.8261H11.787V2.45217H39.5745ZM42.0109 0H9.35059V41.2783H42.0109V0Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M31.4529 6.94775H1.229V45.7738H31.4529V6.94775Z"
                          fill="white"
                        />
                        <path
                          d="M30.2347 8.17385V44.5478H2.44717V8.17385H30.2347ZM32.6711 5.72168H0.0107422V46.9999H32.6711V5.72168Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M7.11719 22.6743V19.8788C7.11719 18.0438 8.7496 16.5562 10.7637 16.5562C12.7778 16.5562 14.4102 18.0438 14.4102 19.8788V22.6743"
                          fill="#9E3223"
                        />
                        <path
                          d="M15.3682 22.6746H13.4475V19.8791C13.4475 18.5304 12.2415 17.431 10.7593 17.431C9.27714 17.431 8.07111 18.5304 8.07111 19.8791V22.6746H6.15039V19.8791C6.15039 17.5618 8.2173 15.6777 10.7552 15.6777C13.2932 15.6777 15.3601 17.5618 15.3601 19.8791V22.6746H15.3682Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M10.7474 16.5562C12.0212 16.5562 13.0539 15.6139 13.0539 14.4515C13.0539 13.289 12.0212 12.3467 10.7474 12.3467C9.47357 12.3467 8.44092 13.289 8.44092 14.4515C8.44092 15.6139 9.47357 16.5562 10.7474 16.5562Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M10.7474 17.431C8.94444 17.431 7.47852 16.0945 7.47852 14.4516C7.47852 12.8086 8.94444 11.4722 10.7474 11.4722C12.5504 11.4722 14.0163 12.8086 14.0163 14.4516C14.0163 16.0945 12.5504 17.431 10.7474 17.431ZM10.7474 13.2214C10.0043 13.2214 9.39924 13.7731 9.39924 14.4516C9.39924 15.13 10.0043 15.6817 10.7474 15.6817C11.4905 15.6817 12.0956 15.13 12.0956 14.4516C12.0956 13.7731 11.4905 13.2214 10.7474 13.2214Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 11.4722H17.6504V13.2418H26.6895V11.4722Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 16.1392H17.6504V17.9088H26.6895V16.1392Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 20.8066H17.6504V22.5763H26.6895V20.8066Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 25.478H6.31689V27.2477H26.6895V25.478Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 30.1455H6.31689V31.9152H26.6895V30.1455Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 34.8125H6.31689V36.5822H26.6895V34.8125Z"
                          fill="#9E3223"
                        />
                        <path
                          d="M26.6895 39.48H6.31689V41.2496H26.6895V39.48Z"
                          fill="#9E3223"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1701_50">
                          <rect
                            width="42"
                            height="47"
                            fill="white"
                            transform="translate(0.0107422)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    證照管理
                  </a>
                </li>
                <li className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold">
                  <a
                    href="#"
                    className="flex items-center gap-[1rem]"
                    title="行程查看"
                    onClick={() => {
                      handleLinkClick("/CaseSchedule");
                    }}
                  >
                    <svg
                      width="33"
                      height="42"
                      viewBox="0 0 33 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.8545 3.71436H1.14453V40.9119H31.8545V3.71436Z"
                        fill="white"
                      />
                      <path
                        d="M33 2.62646H0V41.9999H33V2.62646Z"
                        fill="#9E3223"
                      />
                      <path d="M27 11H6V14H27V11Z" fill="white" />
                      <path d="M27 18H6V21H27V18Z" fill="white" />
                      <path d="M27 25H6V28H27V25Z" fill="white" />
                      <path d="M27 32H6V35H27V32Z" fill="white" />
                      <path
                        d="M9.48486 4C9.48486 1.792 12.7897 0 16.8618 0C20.9339 0 24.2388 1.792 24.2388 4"
                        fill="#9E3223"
                      />
                    </svg>
                    行程查看
                  </a>
                </li>
              </>
            ) : (
              <></>
            )}
            {PersonnelType === "NurseAides" ? (
              <>
                <li className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold">
                  <a
                    href="#"
                    className="flex items-center gap-[1rem]"
                    title="影音照顧"
                    onClick={() => {
                      handleLinkClick("/CareVideoList");
                    }}
                  >
                    <svg
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 6H39V36.4369C39 37.853 37.8168 39 36.3559 39H2.64407C1.18322 39 0 37.853 0 36.4369V6Z"
                        fill="#9E3223"
                      />
                      <path
                        d="M38.5792 -0.00275614L-0.000488281 2.72021L0.313872 6.7762L38.8936 4.05323L38.5792 -0.00275614Z"
                        fill="#9E3223"
                      />
                      <path d="M30 23L13 14V32L30 23Z" fill="white" />
                    </svg>
                    影音照顧
                  </a>
                </li>
              </>
            ) : (
              <></>
            )}

            <li className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold">
              <a
                href="#"
                className="flex items-center gap-[1rem]"
                title="收款紀錄"
              >
                <svg
                  width="33"
                  height="39"
                  viewBox="0 0 33 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_596_42)">
                    <path
                      d="M7.11337 39L0 32.8233V0H4.85223V30.6225L7.11337 32.5863L11.8055 28.5184L16.4976 32.5863L21.1848 28.5184L25.8769 32.5863L28.1478 30.6225V4.83691H8.176V0H33V32.8233L25.8769 39L21.1848 34.9273L16.4976 39L11.8055 34.9273L7.11337 39Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.062 9.021H7.93848V12.4068H25.062V9.021Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.062 15.1733H7.93848V18.5592H25.062V15.1733Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.062 21.3306H7.93848V24.7164H25.062V21.3306Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_596_42">
                      <rect width="33" height="39" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {PersonnelType === "NurseAides" ? "收款" : "付款"}紀錄
              </a>
            </li>
            <li
              className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
              onClick={() => {
                handleLinkClick("/Comment");
              }}
            >
              <a
                href="#"
                className="flex items-center gap-[1rem]"
                title="意見回饋"
              >
                <svg
                  width="39"
                  height="38"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_596_46)">
                    <path
                      d="M28.2674 -0.00116242L5.52002 22.3394L16.2528 32.8802L39.0002 10.5397L28.2674 -0.00116242Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M0 37.9998L14.567 34.2347L3.83365 23.6934L0 37.9998Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_596_46">
                      <rect width="39" height="38" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                意見回饋
              </a>
            </li>
          </ul>
          <ul className="flex flex-col gap-[1.875rem]">
            <li
              className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
              onClick={() => {
                handleLinkClick("/Choose");
              }}
            >
              <a
                href="#"
                className="flex items-center gap-[1rem]"
                title="切換身分"
              >
                <svg
                  width="42"
                  height="34"
                  viewBox="0 0 42 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_596_72)">
                    <path
                      d="M1.7251 33.9998V25.409C1.7251 19.7667 6.49611 15.1953 12.3848 15.1953C18.2736 15.1953 23.0446 19.7667 23.0446 25.409V33.9998"
                      fill="#9E3223"
                    />
                    <path
                      d="M24.7692 33.9996H21.3195V25.4089C21.3195 20.6888 17.3109 16.8479 12.3846 16.8479C7.45837 16.8479 3.44975 20.6888 3.44975 25.4089V33.9996H0V25.4089C0 18.8642 5.55755 13.5425 12.3846 13.5425C19.2117 13.5425 24.7692 18.8675 24.7692 25.4089V33.9996Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M12.34 15.1922C16.0647 15.1922 19.0842 12.299 19.0842 8.73014C19.0842 5.16123 16.0647 2.26807 12.34 2.26807C8.61522 2.26807 5.5957 5.16123 5.5957 8.73014C5.5957 12.299 8.61522 15.1922 12.34 15.1922Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M12.3397 16.8481C7.66878 16.8481 3.87061 13.2055 3.87061 8.73331C3.87061 4.2611 7.66878 0.615234 12.3397 0.615234C17.0107 0.615234 20.8089 4.25779 20.8089 8.73001C20.8089 13.2022 17.0073 16.8448 12.3397 16.8448V16.8481ZM12.3397 3.92064C9.5696 3.92064 7.32036 6.07907 7.32036 8.73001C7.32036 11.3809 9.57305 13.5394 12.3397 13.5394C15.1065 13.5394 17.3591 11.3809 17.3591 8.73001C17.3591 6.07907 15.1065 3.92064 12.3397 3.92064Z"
                      fill="#9E3223"
                    />
                  </g>
                  <g clipPath="url(#clip1_596_72)">
                    <path
                      d="M30.1109 2.92902C33.305 2.92902 35.902 5.53038 35.902 8.73001C35.902 10.386 35.1859 11.9693 33.9386 13.0766L31.3846 15.3375L34.427 16.8745C37.6706 18.514 39.6868 21.7831 39.6868 25.4091V31.6861H20.6175V25.4091C20.6175 21.8062 22.6171 18.5438 25.8344 16.8977L28.8669 15.3441L26.3029 13.0965C25.0424 11.9891 24.3198 10.3992 24.3198 8.73001C24.3198 5.53038 26.92 2.92902 30.1109 2.92902ZM30.1109 0.615234C25.643 0.615234 22.01 4.25779 22.01 8.73001C22.01 11.1628 23.0857 13.3477 24.7818 14.8351C20.9441 16.8018 18.3076 20.8014 18.3076 25.4091V33.9999H41.9999V25.4091C41.9999 20.7815 39.3403 16.7655 35.473 14.8087C37.1526 13.3212 38.2151 11.1463 38.2151 8.73001C38.2151 4.25779 34.5788 0.615234 30.1109 0.615234Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_596_72">
                      <rect
                        width="24.7692"
                        height="33.3846"
                        fill="white"
                        transform="translate(0 0.615234)"
                      />
                    </clipPath>
                    <clipPath id="clip1_596_72">
                      <rect
                        width="23.6923"
                        height="33.3846"
                        fill="white"
                        transform="translate(18.3076 0.615234)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                切換身分
              </a>
            </li>
            <li
              className="pb-[0.625rem] pl-[0.375rem] border-b-[0.1875rem] border-primary text-primary text-[1.5rem] font-bold"
              onClick={() => {
                handleLinkClick("/Login");
              }}
            >
              <a href="#" className="flex items-center gap-[1rem]" title="登出">
                <svg
                  width="36"
                  height="39"
                  viewBox="0 0 36 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_596_92)">
                    <path
                      d="M31.0107 38.1818H0V0H31.0107V5.26646H5.33333V32.9154H31.0107V38.1818Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M10.4265 16.3271L10.3716 21.5933L29.8799 21.7916L29.9348 16.5255L10.4265 16.3271Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M19.3761 10.125L28.3281 19.1438L19.1895 27.9809L26.8668 28.0573L36.0001 19.2228L27.0508 10.2014L19.3761 10.125Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_596_92">
                      <rect width="36" height="38.1818" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                登出
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default HamburgerModal;
