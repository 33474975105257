import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; //语系
import { useToast } from '../../contexts/ToastContext';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../bootstrap.css';
import {
    apiGetSigninAllRecord
} from "../../utils/Api";
const RecordModal = ({ showRecordModal, handleRecordClose, getId, ctitle, id, delCallback }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [recordDates, setRecordDates] = useState([]);
    const formatDate = (dateString) => {
        const options = { 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit' 
        };
        return new Date(dateString).toLocaleString('zh-TW', options);
    };
    useEffect(() => {
        const fetchData = async () => {
            handleGetSigninAllRecord(); 
        };
        fetchData();
        return () => { };
    }, []);
    const handleGetSigninAllRecord = () => {
        const fetchData = async () => {
            try {
                const param ={
                                CareCaseId : getId
                            }
                const response = await apiGetSigninAllRecord(param);
                setRecordDates(response.data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchData();
    };
    return (
        <Modal show={showRecordModal}  onHide={handleRecordClose} class="modal fade ProjectModal" id="checkinModal-1-1" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content accept">
                    <div class="modal-body">
                        <div class="bottomFormSection" >
                            <div class="flex items-center justify-center gap-[0.75rem] mt-[2.0625rem] pb-[8.9375rem]">
                            <div class="w-full flex flex-col items-center gap-[1.0625rem] mx-auto mt-[5.625rem] pb-[12.5rem]">
                                <strong class="text-primary text-[1.5rem] leading-[1.5] text-center">簽到紀錄</strong>
                                <ul class="flex flex-col items-center text-primary text-base leading-[1.5]">
                                {recordDates.map((date, index) => (
                                            <li key={index}>{formatDate(date.signinTime)} ~ {formatDate(date.signoutTime)}</li>
                                        ))}
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RecordModal;
