const MailVerificationPending = () => {
  return (
    <div className=" main-background normal">
      <div className="content">
        <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">信箱驗證</h1>
        <div className="flex items-center gap-[0.625rem] mt-[1.6875rem]">
          <span className="text-primary text-[1.25rem] leading-[1.5]">驗證中</span>
        </div>
        <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
          正在確認您的驗證連結...
        </p>
      </div>
    </div>
  )
}

export default MailVerificationPending;