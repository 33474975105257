import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { apiForgetAccount } from '../../utils/Api';
import Line from "../../layouts/Line";

/**
 * 忘記帳號頁面
 */
const InfoPanel = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }
  const submitForgetAccount = async (event) => {
    event.preventDefault();
    // 檢查是否有輸入電話號碼和Email
    if (!phoneNumber || !email) {
      showToast("error", "請輸入電話號碼和Email");
      return;
    }
    // 呼叫 apiForgetAccount 時傳遞 phoneNumber 和 email 參數
    const requestAccountResponse = await apiForgetAccount({ phone: phoneNumber, email: email });
    if (requestAccountResponse && requestAccountResponse.code === 0) {
      showToast(
        "success",
        `${t("login.account.placeholder")}${t("common.mailSent")}`
      ).then(() => {
        navigate("/Login");
      });
    } else {
      showToast(
        "error",
        requestAccountResponse
          ? requestAccountResponse.message
          : t("toast.unknownError")
      );
    }
  }


  return (
    <div className=" main-background normal">
      <form className="content mb-0 pb-[1.875rem]" action="">
        <div className="flex flex-col gap-[1.5rem]">
          <div className="flex items-center gap-[0.5625rem] pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_17_199)">
                <path
                  d="M36.8214 14.9667H33.2744V10.4079H35.0479C36.9243 10.4079 38.4531 8.87168 38.4531 6.98614C38.4531 5.10059 36.9243 3.56436 35.0479 3.56436C33.1716 3.56436 31.6428 5.10059 31.6428 6.98614H28.0958C28.0958 3.13307 31.2136 0 35.0479 0C38.8822 0 42.0001 3.13307 42.0001 6.98614C42.0001 10.2226 39.7974 12.9529 36.8214 13.7406V14.9632V14.9667Z"
                  fill="#9E3223"
                />
                <path d="M36.8214 17.4475H33.2744V21.7426H36.8214V17.4475Z" fill="#9E3223" />
                <path
                  d="M1.77356 36V26.7362C1.77356 20.6519 6.67906 15.7224 12.7338 15.7224C18.7885 15.7224 23.694 20.6519 23.694 26.7362V36"
                  fill="#9E3223"
                />
                <path
                  d="M25.4674 36H21.9204V26.7362C21.9204 21.6463 17.7988 17.5045 12.7337 17.5045C7.66861 17.5045 3.547 21.6463 3.547 26.7362V36H0V26.7362C0 19.6788 5.71421 13.9402 12.7337 13.9402C19.7532 13.9402 25.4674 19.6788 25.4674 26.7362V36Z"
                  fill="#9E3223"
                />
                <path
                  d="M12.6876 15.7188C16.5173 15.7188 19.6219 12.599 19.6219 8.75048C19.6219 4.90199 16.5173 1.78217 12.6876 1.78217C8.8578 1.78217 5.75317 4.90199 5.75317 8.75048C5.75317 12.599 8.8578 15.7188 12.6876 15.7188Z"
                  fill="#9E3223"
                />
                <path
                  d="M12.6876 17.5046C7.88498 17.5046 3.97974 13.5766 3.97974 8.75406C3.97974 3.93149 7.88498 0 12.6876 0C17.4902 0 21.3955 3.92792 21.3955 8.75049C21.3955 13.5731 17.4867 17.501 12.6876 17.501V17.5046ZM12.6876 3.56436C9.83938 3.56436 7.52673 5.89188 7.52673 8.75049C7.52673 11.6091 9.84292 13.9366 12.6876 13.9366C15.5323 13.9366 17.8485 11.6091 17.8485 8.75049C17.8485 5.89188 15.5323 3.56436 12.6876 3.56436Z"
                  fill="#9E3223"
                />
              </g>
              <defs>
                <clipPath id="clip0_17_199">
                  <rect width="42" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-[1.5rem] text-primary font-bold leading-[1.4]" for="phone">忘記帳號</span>
          </div>
          <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <input
              id="phone"
              type="tel"
              className="min-w-0 bg-transparent text-[20px] text-primary font-mormal leading-[1.4] placeholder:text-primary focus-visible:outline-none"
              placeholder="｜請輸入註冊手機號碼"
              aria-label="phone"
              inputMode="numeric"
              value={phoneNumber}
              onChange={onChangePhoneNumber}
            />
          </div>
          <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <input
              id="email"
              type="email"
              className="min-w-0 bg-transparent text-[20px] text-primary font-mormal leading-[1.4] placeholder:text-primary focus-visible:outline-none"
              placeholder="｜請輸入 Email"
              aria-label="email"
              inputMode="email"
              value={email}
              onChange={onChangeEmail}
            />
          </div>
        </div>
        <div className="flex items-center justify-start gap-[0.75rem] mt-[1.5625rem]">
          <button
            type="submit"
            onClick={submitForgetAccount}
            className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
            title="送出"
          >
            送出
          </button>
        </div>
      </form>
      <Line />
    </div>
  );
};

export default InfoPanel;
