import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { DebounceInput } from "react-debounce-input";
import { useMyUserContext } from "../../contexts/MyUserContext";
import {
  apiGetAllHospital,
  apiQueryHospitalByFilter,
  apiQueryHospitalByKeyword,
  GetDistsByCityId,
  apiGetAllCity,
} from "../../utils/Api";

/**
 * 家屬找照服員-選擇醫院(step2)
 */
const ChooseHospital = ({
  onNextStep,
  onPreviousStep,
  onChangeHospitalId,
  createCareCase,
}) => {
  const { myUser } = useMyUserContext();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [keyword, setKeyword] = useState(""); //關鍵字
  const [cities, setCities] = useState([]); //縣市
  const [towns, setTowns] = useState([]); //地區
  const [selectTown, setSelectTown] = useState(0); //選中的地區
  const [selectCity, setSelectCity] = useState(0); //選中的縣市
  const [hospitals, setHospitals] = useState([]); //醫院列表

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllCity();
      if (myUser) {
        setSelectCity(myUser.cityId);
        setSelectTown(myUser.distId);
      }
    };
    fetchData();
    return () => {};
  }, []);
  //#endregion

  //#region keyword、縣市地區搜尋
  useEffect(() => {
    const fetchData = async () => {
      if (selectTown !== 0) {
        const params = {
          keyword: keyword,
          distId: selectTown,
        };

        const queryHospitalByFilterResponse = await apiQueryHospitalByFilter(
          params
        );
        if (
          queryHospitalByFilterResponse &&
          queryHospitalByFilterResponse.code === 0
        ) {
          setHospitals(queryHospitalByFilterResponse.data.result);
        } else {
          showToast(
            "error",
            queryHospitalByFilterResponse
              ? queryHospitalByFilterResponse.message
              : t("toast.unknownError")
          ); // [回傳的訊息] : 網路連線錯誤
        }
      } else {
        refreshAllHospital();
      }
    };
    fetchData();
  }, [keyword, selectTown]);
  //#endregion

  //#region 刷新醫院列表
  const refreshAllHospital = async () => {
    const getAllHospitalResponse = await apiGetAllHospital();
    if (getAllHospitalResponse && getAllHospitalResponse.code === 0) {
      setHospitals(getAllHospitalResponse.data.result);
    } else {
      showToast(
        "error",
        getAllHospitalResponse
          ? getAllHospitalResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 刷新縣市
  const refreshAllCity = async () => {
    const getAllCityResponse = await apiGetAllCity();
    if (getAllCityResponse && getAllCityResponse.code === 0) {
      setCities(getAllCityResponse.data.result);
    } else {
      showToast(
        "error",
        getAllCityResponse ? getAllCityResponse.message : "取得縣市失敗"
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (cities.length > 0 && myUser && myUser.cityId != 0) {
        var params = {
          cityId: parseInt(myUser.cityId),
        };
        const getDistsResponse = await GetDistsByCityId(params);
        if (getDistsResponse && getDistsResponse.code === 0) {
          // 更新 towns
          setTowns(getDistsResponse.data.result);
          setSelectTown(myUser.distId);
        } else {
          showToast(
            "error",
            getDistsResponse ? getDistsResponse.message : "取得城市失敗"
          );
        }
      }
    };
    fetchData();
    return () => {};
  }, [cities]);
  //#endregion

  //#region 取得區域
  const handleCityChange = async (cityId) => {
    if (cityId === "") {
      // 清空地區的下拉選單
      setTowns([]);
      // 清空選中的地區
      setSelectTown(0);
      return;
    }
    var params = {
      cityId: parseInt(cityId),
    };
    const getDistsResponse = await GetDistsByCityId(params);
    if (getDistsResponse && getDistsResponse.code === 0) {
      // 更新 towns
      setSelectCity(parseInt(cityId));
      setTowns(getDistsResponse.data.result);
    } else {
      showToast(
        "error",
        getDistsResponse ? getDistsResponse.message : "取得城市失敗"
      );
    }
  };
  //#endregion

  //#region 修改keyword
  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };
  //#endregion

  //#region 下一步
  const handleSubmit = (e) => {
    if (createCareCase.hospitalId !== 0) {
      onNextStep();
    } else {
      e.preventDefault();
      showToast("error", "醫院為必選");
    }
  };
  //#endregion

  return (
    <div className="content">
      <div className="applyHead">
        <h1>找照服員</h1>
        <div>
          <div>
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="active">
            <svg
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                fill="#9E3223"
              />
            </svg>
          </div>
          <div>
            <svg
              width="18"
              height="25"
              viewBox="0 0 18 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                fill="#9E3223"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[2.1875rem]">
        <div className="flex items-center gap-[0.625rem]">
          <h1 className="grow text-primary text-[2rem] font-bold leading-none">
            選擇醫院
          </h1>
          <form className="w-1/2 block flex-none relative mt-[2px] mb-0">
            <DebounceInput
              debounceTimeout={300}
              id="search"
              name="search"
              className="w-full min-w-0 p-[0.4375rem] pr-[1.875rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
              type="search"
              placeholder="全部"
              value={keyword}
              onChange={handleKeywordChange}
            />
            <button
              type="btn"
              className="w-[1.75rem] md:w-[2.5rem] aspect-square h-full flex items-center justify-center absolute top-1/2 right-0 -translate-y-1/2"
              title="search button"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_87_2211)">
                  <path
                    d="M6.43032 12.7913C4.78369 12.7913 3.13707 12.1674 1.88316 10.9196C0.668293 9.7129 0 8.10627 0 6.3968C0 4.68733 0.668293 3.0807 1.88316 1.87173C4.39099 -0.623911 8.46964 -0.623911 10.9775 1.87173C12.1923 3.0807 12.8606 4.68733 12.8606 6.3968C12.8606 8.10627 12.1923 9.7129 10.9775 10.9219C9.72355 12.1697 8.07693 12.7936 6.43032 12.7936V12.7913ZM6.43032 2.2831C5.37161 2.2831 4.3129 2.68305 3.50682 3.48522C2.72599 4.26225 2.29654 5.29524 2.29654 6.39452C2.29654 7.49379 2.72599 8.52678 3.50682 9.30381C5.11899 10.9082 7.74164 10.9082 9.35381 9.30381C10.1346 8.52678 10.5641 7.49379 10.5641 6.39452C10.5641 5.29524 10.1346 4.26225 9.35381 3.48522C8.54773 2.68305 7.48902 2.2831 6.43032 2.2831Z"
                    fill="#9E3223"
                  />
                  <path
                    d="M12.5253 10.8807C12.3026 11.1778 12.0568 11.4612 11.7881 11.7286C11.508 12.0074 11.2094 12.2611 10.8948 12.4896L14.3603 15.9977L15.9977 14.3956L12.523 10.8784L12.5253 10.8807Z"
                    fill="#9E3223"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_87_2211">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </form>
        </div>
        <form className="flex flex-col gap-[2.1875rem]">
          <div className="flex items-start gap-[0.5rem]">
            <div className="flex items-center grow">
              <div className="w-full grid grid-cols-2 gap-[1.125rem]">
                <select
                  id="addressCity"
                  name="addressCity"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  aria-label="縣市"
                  onChange={(e) => handleCityChange(e.target.value)}
                  value={selectCity}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <select
                  id="addressTown"
                  name="addressTown"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  aria-label="地區"
                  onChange={(e) => setSelectTown(parseInt(e.target.value))}
                  value={selectTown}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  {towns.map((town) => (
                    <option key={town.id} value={town.id}>
                      {town.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {hospitals.length > 0 ? (
            hospitals.map((hospital, index) => {
              return (
                <div key={index} className="flex flex-col gap-[1.5625rem]">
                  <input
                    type="radio"
                    name="hospital"
                    id={`hospital-${hospital.id}`}
                    className="hidden peer"
                    onClick={(e) => {
                      onChangeHospitalId(hospital.id);
                    }}
                    checked={createCareCase.hospitalId === hospital.id}
                  />
                  <label
                    htmlFor={`hospital-${hospital.id}`}
                    className="flex flex-col items-start justify-center py-[1.75rem] pl-[2.0625rem] pr-[0.875rem] bg-[rgba(158,50,35,0.48)] peer-checked:bg-primary border-[0.125rem] border-[rgba(158,50,35,0.48)] peer-checked:border-primary rounded-[1.25rem] text-white text-[0.9375rem] lg:text-base leading-[1.5] overflow-hidden"
                  >
                    <span>{hospital.name}</span>
                    <span>{hospital.address}</span>
                  </label>
                </div>
              );
            })
          ) : (
            <></>
          )}
          <div className="flex items-center justify-end gap-[0.75rem]">
            <a
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="上一步"
              onClick={onPreviousStep}
            >
              上一步
            </a>
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="下一步"
              onClick={handleSubmit}
            >
              下一步
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChooseHospital;
