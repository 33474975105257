import React from 'react';

const PatientInfoSure = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg modal-PatientInfoSure-animation">
                <p>被照顧者病症包含法定傳染疾病，請確定資料填寫無誤</p>
                <div className="mt-4 flex justify-end space-x-2">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded">取消</button>
                    <button onClick={onConfirm} className="px-4 py-2 bg-primary text-white rounded">確定</button>
                </div>
            </div>
        </div>
    );
};

export default PatientInfoSure;

