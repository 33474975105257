import axios from "axios";
import { setAuthToken, getAuthToken, removeAuthToken } from "./JWToken";

let getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + getAuthToken(),
    Platform: "Frontend",
    PersonnelType: localStorage.getItem("PersonnelType"),
  };
};

//#region API
export const fetchDataCall = async (
  api,
  method,
  payload = null,
  download = false
) => {
  const config = {
    method: method,
    url: `${window.apiUrl}/api/${api}`,
    headers: getHeaders(),
    params: null,
    data: null,
  };

  if (method === "get") {
    if (payload != null) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "") {
          delete payload[key];
        }
      });
    }
    config.params = payload;
  } else {
    config.data = payload;
  }

  if (download) {
    config.responseType = "blob";
  }

  return axios
    .request(config)
    .then(async function (response) {
      if (response.data.token != null) {
        setAuthToken(response.data.token);
      }

      if (!download) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        //表示token驗證失敗
        // throw new Error("Token validation failed.");
        return error.response.data;
      } else if (error.response && error.response.status === 400) {
        return error.response.data;
      } else if (error.response && error.response.status === 404) {
        if (download) {
          return null;
        } else {
          return error.response.data;
        }
      } else if (error.response && error.response.status === 500) {
        return error.response.data;
      }
    });
};
//#endregion

//#region API multipart/form-data
export const fetchDataCallFile = async (
  api,
  method,
  payload = null,
  download = false
) => {
  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  const config = {
    method: method,
    url: `${window.apiUrl}/api/${api}`,
    headers: headers,
    params: null,
    data: null,
  };

  if (method === "get") {
    if (payload != null) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "") {
          delete payload[key];
        }
      });
    }
    config.params = payload;
  } else {
    config.data = payload;
  }

  if (download) {
    config.responseType = "blob";
  }

  return axios
    .request(config)
    .then(async function (response) {
      if (response.data.token != null) {
        setAuthToken(response.data.token);
      }

      if (!download) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        //表示token驗證失敗
        // throw new Error("Token validation failed.");
        return error.response.data;
      } else if (error.response && error.response.status === 400) {
        return error.response.data;
      } else if (error.response && error.response.status === 404) {
        if (download) {
          return null;
        } else {
          return error.response.data;
        }
      } else if (error.response && error.response.status === 500) {
        return error.response.data;
      }
    });
};
//#endregion

/*使用者設定 */
export const apiUserLogin = (data) =>
  fetchDataCall("UserInfo/Login", "post", data); //使用者登入
export const apiMyUserData = (data) =>
  fetchDataCall("UserInfo/GetNowUserInfoDetail", "get", data); //取得登入中的使用者資料
export const apiRequestResetPassword = (data) =>
  fetchDataCall("UserInfo/SendResetPasswordLink", "post", data);
export const apiVerifyResetPasswordToken = (data) =>
  fetchDataCall("UserInfo/VerifyResetPasswordToken", "get", data);
export const apiResetPassword = (data) =>
  fetchDataCall("UserInfo/ResetPassword", "post", data);
export const apiForgetAccount = (data) =>
  fetchDataCall("UserInfo/ForgetAccount", "post", data); //忘記帳號
export const apiCheckUserNurseAidesStatus = (
  data //檢查使用者是否有照服員身份
) => fetchDataCall("UserInfo/CheckUserNurseAidesStatus", "get", data);

/*城市*/
export const apiGetAllCity = (data) =>
  fetchDataCall("City/GetAll", "get", data); //取得城市列表
export const GetDistsByCityId = (data) =>
  fetchDataCall("City/GetDistsByCityId", "get", data); //取得城市列表

/*醫院*/
export const apiGetAllHospital = (data) =>
  fetchDataCall("Hospital/GetAll", "get", data); //取得醫院列表
export const apiQueryHospitalByKeyword = (data) =>
  fetchDataCall("Hospital/QueryByKeyword", "get", data); //依據keyword取得醫院列表

export const apiQueryHospitalByFilter = (data) =>
  fetchDataCall("Hospital/QueryByFilter", "get", data); //依據過濾條件取得醫院列表

/*病症*/
export const apiGetAllSymptom = (data) =>
  fetchDataCall("Symptom/GetAll", "get", data); //取得病症列表
export const apiQuerySymptomByKeyword = (data) =>
  fetchDataCall("Symptom/QueryByKeyword", "get", data); //依據keyword取得病症列表

/*管線*/
export const apiGetAllTube = (data) =>
  fetchDataCall("Tube/GetAll", "get", data); //取得管線列表
export const apiQueryTubeByKeyword = (data) =>
  fetchDataCall("Tube/QueryByKeyword", "get", data); //依據keyword取得管線列表

/*床位種類 */
export const apiGetAllBedKind = (data) =>
  fetchDataCall("BedKind/GetAll", "get", data); //取得床位列表

/*溝通語言*/
export const apiGetAllLanguage = (data) =>
  fetchDataCall("Language/GetAll", "get", data); //取得溝通語言列表
export const apiQueryLanguageByKeyword = (data) =>
  fetchDataCall("Language/QueryByKeyword", "get", data); //依據keyword取得溝通語言列表

/*服務需求項目*/
export const apiGetAllCareServiceItem = (data) =>
  fetchDataCall("CareServiceItem/GetAll", "get", data); //取得服務需求項目列表
export const apiQueryCareServiceItemByKeyword = (data) =>
  fetchDataCall("CareServiceItem/QueryByKeyword", "get", data); //依據keyword取得服務需求項目列表

/*照顧方案*/
export const apiGetAllPlan = (data) =>
  fetchDataCall("Plan/GetAll", "get", data); //取得照顧方案列表
export const apiQueryPlanByKeyword = (data) =>
  fetchDataCall("Plan/QueryByKeyword", "get", data); //依據keyword取得照顧方案列表

/*家屬主畫面*/
export const apiCareCaseFamilyMainQueryByStatus = (data) =>
  fetchDataCall("CareCaseFamilyMain/QueryByStatus", "get", data);
export const apiCareCaseFamilyMainCreate = (data) =>
  fetchDataCallFile("CareCaseFamilyMain/Create", "post", data); //找看護(醫院)
export const apiCareCaseFamilyMainCreateInHome = (data) =>
  fetchDataCallFile("CareCaseFamilyMain/CreateInHome", "post", data); //找看護(居家)
export const apiCareCaseFamilyMainGetWaitingPair = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetWaitingPair", "get", data); //取得等待配對資料
export const apiCareCaseFamilyMainReplyWaitingPair = (data) =>
  fetchDataCall("CareCaseFamilyMain/ReplyWaitingPair", "post", data); //回應等待配對的照服員
export const apiCareCaseFamilyMainGetProgressPair = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetProgressPair", "get", data); //取得進行中的照服員資料
export const apiCareCaseFamilyMainWriteEvaluate = (data) =>
  fetchDataCall("CareCaseFamilyMain/WriteEvaluate", "post", data); //撰寫評價
export const apiCareCaseFamilyMainGetPersonnelScore = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetPersonnelScore", "get", data); //取得評價分數
export const apiCareCaseFamilyMainGetPersonnelEvaluates = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetPersonnelEvaluates", "get", data); //取得最近五筆評價
export const apiGetAvailableFamilyDetail = (data) =>
  fetchDataCall("Family/CareCasePair/GetAvailableFamilyDetail", "get", data); //取得家屬查看案件的詳細資料
export const apiGetProgressPairNotYetTime = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetProgressPairNotYetTime", "get", data); //取得家屬查看進行中的案件未配對日期

export const apiGetProgressPairSigninTime = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetProgressPairSigninTime", "get", data); //取得照服員打卡時間資訊
export const apiGetCompletePair = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetCompletePair", "get", data); //取得完成案件的照服員
export const apiWriteEvaluate = (data) =>
  fetchDataCall("CareCaseFamilyMain/GetCompletePair", "post", data); //取得完成案件的照服員

/*註冊會員*/
export const apiSendPhoneSMS = (data) =>
  fetchDataCall("UserInfo/SendPhoneSMS", "post", data); //寄送手機驗證信
export const apiVerifyPhone = (data) =>
  fetchDataCall("UserInfo/VerifyPhone", "post", data); //驗證手機
export const apiCreateFamily = (data) =>
  fetchDataCall("UserInfo/CreateFamily", "post", data); //新增家屬
export const apiSendResetLink = (data) =>
  fetchDataCall("UserInfo/SendResetLink", "post", data); //忘記密碼(透過發送郵件置使用者手機以重設密碼)

/*註冊照服員*/
export const apiCreateNurseAides = (data) =>
  fetchDataCall("UserInfo/CreateNurseAides", "post", data); //新增照服員
export const apiUpdateNurseAides = (data) =>
  fetchDataCall("NurseAides/Update", "post", data); //更新照服員
export const apiUploadDocument = (data) =>
  fetchDataCallFile("NurseAides/UploadDocument", "post", data); //照服員上傳附件
export const apiGetDocument = (data) =>
  fetchDataCall("NurseAides/GetDocument", "get", data); //抓取照服員上傳附件
export const apiDownloadDocument = (data) =>
  fetchDataCall("NurseAides/DownloadDocument", "get", data, true); //檔案呈現
export const apiSendVerificationMail = () =>
  fetchDataCall("UserInfo/SendVerificationMail", "get");
export const apiVerifyMailToken = (data) =>
  fetchDataCall("UserInfo/RegisterVerify", "get", data);

export const apiGetCareCertificates = (data) =>
  fetchDataCall("NurseAides/GetCareCertificates", "post", data);

export const apiGetBankList = () => fetchDataCall("Bank/List", "get"); //取得銀行列表
export const apiGetBankBranchList = (data) =>
  fetchDataCall("Bank/ListBranch", "get", data); //取得分行列表
export const apiUpdateCareCertificates = (data) =>
  fetchDataCall("NurseAides/UpdateCareCertificates", "post", data); //上傳照服員證照

/*照服員*/
export const apiNurseAidesCareCaseQueryByStatus = (data) =>
  fetchDataCall("NurseAides/CareCase/QueryByStatus", "get", data); //依據狀態照服員主畫面取得案件
export const apiNurseAidesGetAvailableCases = (data) =>
  fetchDataCall("NurseAides/CareCasePair/GetAvailableCases", "get", data); //照服員取得可配對案子
export const apiNurseAidesGetAvailableCaseDetail = (data) =>
  fetchDataCall("NurseAides/CareCasePair/GetAvailableCaseDetail", "get", data); //照服員取得可配對案子的詳細資料
export const apiNurseAidesCareCasePairSubmit = (data) =>
  fetchDataCall("NurseAides/CareCasePair/Submit", "post", data); //照服員送出可配對案件
export const apiNurseAidesSimpleInfo = (data) =>
  fetchDataCall("NurseAides/GetNurseAidesSimpleInfo", "get", data); //取的照服員簡易資料
export const apiUpdateNurseAidesService = (data) =>
  fetchDataCall("NurseAides/UpdateNurseAidesService", "post", data); //更新照服員服務區域
export const apiUpdateCareCaseSignin = (data) =>
  fetchDataCall("NurseAides/CareCase/CareCaseSignin", "post", data); //更新照服員簽到
export const apiUpdateCareCaseSignoff = (data) =>
  fetchDataCall("NurseAides/CareCase/CareCaseSignoff", "post", data); //更新照服員簽退
export const apiGetSigninRecord = (data) =>
  fetchDataCall("NurseAides/CareCase/GetSigninRecord", "post", data); //簽到紀錄(判斷用)
export const apiGetNurseAidesCaseDetail = (data) =>
  fetchDataCall("NurseAides/CareCase/GetNurseAidesCaseDetail", "get", data); //照服員取得案件詳細資料(使用者只可以看到自己的)
export const apiGetSigninAllRecord = (data) =>
  fetchDataCall("NurseAides/CareCase/GetSigninAllRecord", "post", data); //簽到紀錄(該案子所有時間段)
export const apiChangeHeadshotFile = (data) =>
  fetchDataCallFile("NurseAides/ChangeHeadshotFile", "post", data); //更新、刪除、新增照服員大頭照
export const apiEvaluateToFamily = (data) =>
  fetchDataCall("CareCaseFamilyMain/CreateToFamily", "post", data); //照服員對案件的評價

/*意見回饋*/
export const apiCreateFeedback = (data) =>
  fetchDataCall("Feedback/Create", "post", data); //新建意見回饋列表

// 取消申請
export const apiGetAllCancelReason = (data) =>
  fetchDataCall("CancelReason/GetAll", "get", data); //取的第一層所有取消原因
export const apiGetAllSecondReason = (data) =>
  fetchDataCall(
    "CancelReason/GetCancelReasonSecsByCancelReasonId",
    "get",
    data
  ); //取的第二層所有取消原因(依第一層id)
export const apiCancelCareCasePlan = (data) =>
  fetchDataCall("CareCaseFamilyMain/CancelCareCasePlan", "post", data); //取消申請

// 照服員提問
export const apiGetAllCareCasePlanQA = (data) =>
  fetchDataCall("CareCasePlanQA/GetAllMessages", "get", data); //取得所有QA
export const apiCreateCareCasePlanQA = (data) =>
  fetchDataCall("CareCasePlanQA/Create", "post", data); //新增一筆訊息

// 行程查詢
export const apiCareCaseQueryByDate = (data) =>
  fetchDataCall("NurseAides/CareCase/QueryByDate", "get", data); //新增一筆訊息

// 家屬

//影音管理
export const apiGetCareVideoDetail = (data) =>
  fetchDataCall("CareVideo/GetDetail", "get", data); //取得單個影片
export const apiGetAuthEncrypto = (data) =>
  fetchDataCall("AuthCrypto/GetAuthEncrypto", "get", data);
export const apiGetCareVideoGetAll = () =>
  fetchDataCall("CareVideo/GetAll", "get");

// 檔案下載
export const apiDownloadFile = (data) =>
  fetchDataCall(`File/DownloadFile/${data}`, "get", null, true);

// 系統參數
export const apiSystemCode = (data) =>
  fetchDataCall("SystemCode/GetDetail", "post", data); //取消申請
