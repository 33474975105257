import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";


/**
 * 建立家屬帳號-服務條款(step1)
 */
const RegisiterAccountStep1 = ({ onNextStep, setCheckTermsOfService, checkTermsOfService }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    // 樣式設定
    const boldBiggerStyle = {
        fontWeight: '900',
        fontSize: '2rem',
        lineHeight: '3rem',
    };
    const biggerStyle = {
        fontSize: '1.5rem',
        lineHeight: '3rem',
    };
    const boldStyle = {
        fontWeight: '900',
        fontSize: '1.2rem',
        lineHeight: '3rem',
    };
    const redTextStyle = {
        color: 'red',
    };
    const twoEmptyWords = {
        paddingLeft: '2em',
    };

    return (
        <>
            <div className="applyHead">
                <h1>申請會員</h1>
                <div>
                    <div className="active">
                        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div>
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </div>
                    <div>
                        <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="h-[23.235rem] py-[0.5rem] px-[0.75rem] bg-white border border-primary overflow-scroll contract set">
                <b style={boldBiggerStyle}>馬上就來</b><br />
                <b style={boldBiggerStyle}>照顧服務員使用合約暨同意書</b><br />
                <p style={biggerStyle}>淞柏長青有限公司</p>
                <p>照顧服務員媒合平台使用合約</p>
                <p style={redTextStyle}>最新更新日期 : 2024年5月12日</p>
                <p>本服務合約（以下簡稱「本合約」）確立了您個人（以下簡稱「您」）與淞柏長青有限公司（統一編號94107413，以下簡稱「本公司」）之間的權利與義務。本公司透過「馬上就來媒合平台」（以下簡稱「本平台」）一個專為照顧服務員設計的媒合軟體。本公司賦予您權限，讓您能接收並滿足來自本平台用戶的照顧服務需求。</p>
                <p>您選擇簽署本合約，意味著您願意利用本平台及享受本公司提供的服務。您確認並同意，本公司僅作為技術服務提供方，不直接提供或參與任何實際的照顧服務。使用本平台，您需接受本合約所述的所有條款與條件。一旦您以電子或其他方式簽署本合約，您和本公司將共同受本合約的條款及條件約束。</p>

                <p style={boldStyle}>壹、名詞定義</p>
                <p>一、有關「成員」指任何實體，指本公司所屬之旗下各事業單位相關作業人員。</p>
                <p>二、「照服員ID」指本公司賦予您的一組識別碼，使您能使用及訪問本平台。</p>
                <p>三、「公司資料」指從本平台獲取及使用相關的所有資料。</p>
                <p>四、「馬上就來媒合平台」指本公司所提供的軟體，您能透過本平台獲取使用者的即時照顧服務請求，本平台將不定時更新或修改。</p>
                <p>五、「照顧費用」具有第肆條所載的定義。</p>
                <p>六、「服務費」具有第肆條所載的定義。</p>
                <p>七、「服務地區」指位於台灣各縣市的特定地區，在這些地區您可以接收到照顧服務請求。</p>
                <p>八、「照顧服務」指您透過本平台在服務地區內為使用者提供的照顧服務。</p>
                <p>九、「媒合服務」指本公司透過本平台提供的電子服務，這些服務包括為您與尋求照顧服務的使用者之間提供的即時仲介及相關服務，並根據本公司的許可使用本平台相關軟體、網站及支付服務和支援服務系統，這些服務將不定時更新或修改。</p>
                <p>十、「使用者」指被本平台授權，使用本平台以獲得照顧服務的終端使用者。</p>
                <p>十一、「使用者資訊」指使用者請求之照顧服務提供的資訊，可能包括姓名、所在地、聯絡方式及照片。</p>
                <p>十二、「您的裝置」指由您擁有或控制的所有裝置，並符合以下條件：經本公司授權，僅用於提供照顧服務的目的而註冊本平台。</p>

                <p style={boldStyle}>貳、本平台的使用</p>
                <p>一、您將被本平台指派一個照服員ID，這讓您可以根據本合約在您的裝置上存取及使用本平台。您必須確保自身ID的機密性，並且僅限於您個人使用，禁止與任何第三方共享。如您察覺有任何實際或潛在的違反安全性行為，或您的照服員ID、本平台使用權限被不當使用或洩露，您應立即通報本公司。</p>
                <p>二、照顧服務的提供，當本平台向您展示使用者的照顧服務請求時，您接受該請求後，本平台將提供相關使用者和受照顧需求者的資訊，包括使用者的姓名和受照顧需求者的位置。為了提升使用者對您所提供照顧服務的滿意度，建議您至少提前十分鐘到達指定地點。一旦您接受了照顧服務請求，您同意本平台可以向使用者提供您的相關資訊，如姓名、聯絡方式、照片、位置及您的照服員資格證明文件。您應避免使用本平台提供的聯絡方式與使用者進行任何非必要的聯繫，或使用使用者的個人資料進行非服務範圍內的活動。</p>
                <p style={twoEmptyWords}>您與使用者的關係，您提供照顧服務建立的是直接且合法的業務關係，與使用者之間。本公司並不是也不代表此業務關係的任何一方。若有任何關於您的行為（包括行為或不作為）的主張，本公司不承擔任何責任。您是對使用者或第三方可能產生的任何責任或債務的唯一負責人。您應採取所有合理且適當的預防措施來管理任何可能的風險。除非使用者明確同意，否則您在提供服務期間不得同時服務其他未經授權的人員。</p>
                <p style={twoEmptyWords}>(二)專業照顧服務的界限，如照顧服務涉及需醫生指示的醫療行為（如更換鼻胃管、氣切管、導尿管、抽痰技術等），應要求家屬在服務前提供相應的醫囑，並應通知受照顧對象或其家屬注意事項。如無法提供相應醫囑，您應依法拒絕執行該等特定照顧服務。</p>
                <p style={twoEmptyWords}>(三)您與本公司的合作關係。您認知並同意使用本平台提供之媒合服務，這在您與本公司之間建立了一個正式且直接的商業關係。然而，此關係不構成任何代理、承包、雇佣或其他形式的勞動關係，本公司亦不對您擁有任何形式的指揮或監督權力。您有全權自主決定何時使用本平台、使用的持續時間、是否以及如何提供照顧服務，以及在提供服務時的著裝。您有權接受、拒絕或忽略來自本平台的照顧服務請求，並且您可以取消已接受的請求，但需遵守本公司的取消政策，可能需要承擔相應的取消費用。您不得聲稱自己是本公司的員工或有權代表公司。您有權提供服務或從事其他業務活動，並且確認您有權使用其他軟體應用服務獲取工作機會，或從事與本公司無關的任何職業或業務。若您的行為損害了本公司或其成員的品牌或聲譽，本公司有權隨時限制或終止您對平台的存取權。</p>
                <p>三、評分與反饋</p>
                <p style={twoEmptyWords}>(一)您同意提供照顧服務後，本平台會要求使用者對您和您的服務進行評分和提供反饋，您也可以對使用者進行評分和反饋。您應真實地提供您對使用者的評分和反饋。</p>
                <p style={twoEmptyWords}>(二)為維持平台的服務品質，您必須確保您的用戶滿意度平均分數高於本公司為您所在地區設定的最低標準（此標準可能會調整）。如果您的平均分數低於標準，本公司會通知您，並可能要求您在一定時間內提升您的評分。如果您未能在規定時間內達到標準，本公司可能會暫停或取消您的平台使用權。</p>
                <p style={twoEmptyWords}>(三)您賦予本公司使用及必要時修改您對使用者的評分和評論的權限。本公司及其成員可為業務目的在任何形式上使用、分享及展示您和使用者的評分與評論，無需額外徵得您的許可。您承認本公司及其成員對您與使用者的評分和評論擁有發布權而非出版權，並保留在包括但不限於含有不雅內容、侵犯隱私或違反法律規定的情況下編輯或刪除評論的權利。</p>
                <p>四、地理位置信息服務。您同意並確認，在登入本平台時或提供照顧服務期間，必須通過您的裝置向本平台提供即時的地理位置信息。本平台將在您接收或提供照顧服務時監控和追蹤您的位置，並在服務提供前及期間向使用者展示您的大致位置。此外，本公司及其成員可能會出於安全、技術、營銷及商業目的，監控、追蹤及分享您的位置信息。</p>
                <p>五、服務履行義務。您接受承接的照顧服務訂單後，必須根據訂單和本合約的條款履行服務。若您在服務開始前取消或未能按時到達指定地點，導致訂單取消或失效，本公司可暫停或取消您的平台使用權，並可能要求您支付相應的取消費用。</p>
                <p>六、違反真實性規定。若您偽造或篡改上下班記錄，或未由您本人履行接受的案件，本公司有權扣留或不支付相應的照顧費用，並可對您提起刑事訴訟。</p>
                <p>七、禁止私下契約。您不得繞過本平台與使用者或照顧對象私下成立照顧服務合同或提供類似服務，也不得泄露任何個人資訊。如有違反，本公司有權向您收取新台幣捌萬元的罰金，並可追求其他相應的損害賠償。</p>

                <p style={boldStyle}>參、您和您的證照</p>
                <p>一、資格要求。您確認並同意，您應始終保持以下條件：（一）在服務地區內合法向第三方提供照顧服務所需的有效執照、許可、核准、資格或授權；（二）具備適當且認可的培訓、專業知識及經驗，並能以適當的技術、謹慎、勤勉及專業方式提供照顧服務；（三）維持高標準的專業性、服務品質及禮儀。您還需同意不時接受本公司進行的背景、資格及服務記錄檢查，以確保您持續符合提供照顧服務的資格。若您未能滿足這些要求，本公司有權隨時酌情限制或停止您存取或使用本平台的權利。</p>
                <p>二、文件提交。為了確保您符合第參條第一項的所有要求，您必須在提供任何照顧服務之前，向本公司提交所有相關的執照、許可、核准、授權或其他資格證明文件。此外，您必須定期提供書面證據，以證明這些文件已經更新並仍然有效。本公司有權隨時審查這些文件，若您未能提供或維持這些文件，將被視為嚴重違反本合約。本公司保留隨時獨立驗證您提交文件的權利。</p>
                <p>三、真實資訊保證。您承諾保證提供給本公司的個人資訊、文件及其他任何相關資訊均為真實且正確。如果因您提供的信息偽造、不實或誤導，導致本公司遭受損失或面對使用者索賠，您將承擔全部責任並賠償本公司因此遭受的所有損失。</p>

                <p style={boldStyle}>肆、財務條款</p>
                <p>一、您的照顧費用計算與付款。您有權在每次成功完成透過本平台匹配的照顧服務後，向使用者收取相應的照顧費用。照顧費用的計算方式是根據服務的日數或時數累計基本費用（具體費用以訂單所示為準），或依據所提供服務方案的定價。您委任本公司作為您的收款代理，目的包括：（一）利用本平台的支付處理功能代理您收取照顧費用；（二）認可使用者對本公司的付款等同於直接對您的付款。本公司承諾在每次提供服務完成後的下一個星期三（如果服務完成當天是星期三，則在下下個星期三）將以下款項統一匯入您的賬戶：A照顧費用；B取消費；及C其他附屬費用。您同意，在向您支付這些費用之前，本公司可以從中扣除應由您支付的其他款項（如平台服務費、相應的懲罰性或約定性違約金、匯款手續費等），扣款的具體順序由本公司根據需要決定。</p>
                <p>二、照顧費用計算的變更。本公司可能會根據當地市場情況隨時調整照顧費用的計算方式。您應留意並確認訂單上顯示的費用計算結果，以保障自己的權益。若在照顧費用計算方式變更後，您繼續使用本平台，將被視為您接受了這些變更。</p>
                <p>三、照顧費用調整。本公司有權在合理範圍內調整或取消您的照顧費用，具體情況包括但不限於：（一）因技術錯誤或您未能在本平台上正確結束特定照顧服務而調整費用；（二）因向使用者收取未實際提供的照顧服務、使用者投訴、身分冒用或詐欺等原因而取消費用。若照顧費用的數額或支付與否出現爭議，您同意本公司進行處理並暫緩支付，待爭議解決和責任明確後再行支付。</p>
                <p>四、服務費。您同意為使用本公司的媒合平台而支付服務費，該費用將按照每筆照顧服務交易的照顧費用按一定百分比計算（通常為15%，具體比例依本公司的公告決定）。服務費將根據含稅的照顧費用來計算。本公司保留根據當地市場變化隨時調整服務費的權利，變更情況會通過書面或電子方式提前通知您。如果本公司提高服務費，您有權立即終止本合約而無需預先通知。如果您在服務費變更後繼續使用本平台，將視為您接受了變更條款。</p>
                <p>五、取消費用。您確認並同意，使用者可以在您抵達前隨時透過本平台取消已接受的照顧服務請求。若使用者在配對後一小時之後因非您的原因取消服務，本公司有權代表您向該使用者收取取消費用。此類取消費用將被視為對應於被取消照顧服務的費用，您因此將不會獲得其他照顧費用。這些取消費用（無論是否有事先約定）將作為手續費處理。</p>
                <p>六、服務明細。本公司提供一個系統作為本平台的一部分，允許您對提供的照顧服務向使用者提交服務明細。當您完成照顧服務後，本公司將代表您製備並通過電子郵件或平台消息向該使用者發送適當的服務明細。這些服務明細也將通過電子郵件或平台消息提供給您，其中包括就照顧服務向使用者收取的款項細目，可能還包括您的個人信息，如姓名、聯絡方式及照片。任何對服務明細的更正需在收到後二個工作日內以以本公司指定型式提交給本公司。若未按時提出更正通知，本公司將不負責任何服務明細的錯誤，也不負責更正服務明細或重新計算、承擔因錯誤導致的照顧費用變動。</p>
                <p>七、無額外款項。您確認並同意，為了雙方的共同利益，本公司及其成員可能會透過廣告和營銷活動來尋求吸引新的使用者及增加現有使用者對本平台的使用。您理解並同意，這些廣告或營銷活動不會為您帶來除本合約已明確列出的款項之外的任何額外收入。</p>
                <p>八、稅金責任。您確認並同意，您負有自行處理所有稅務登記的義務，並根據相關法律規定計算、申報及繳納所有與您提供的照顧服務相關的稅捐。您也需要履行所有相關的稅法義務，並向本公司提供所有必要的稅務資料，包括有效的稅籍編號（如果適用）。您進一步確認，您將負責支付因提供照顧服務而對您收入產生的所有稅賦。即使本合約有相反的規定，本公司也可能按照合理的判斷，基於相關的稅收和監管考慮，從應支付給您的款項中扣除相應的稅款，並可能代表您或以其他方式直接向政府稅務機關提供您根據本條款提供的稅務資料。</p>

                <p style={boldStyle}>伍、權利與限制</p>
                <p>一、限制。您不得允許任何其他第三人：</p>
                <p>（一）以任何方式向任何其他第三人許可、轉授權、銷售、轉售、轉讓、出讓、分銷或另行提供或給予本平台或任何本公司裝置；（二）不當使用本平台，包括創建鏈接至本平台的任何部份的「連結」，在任何其他網站或系統上對本平台的任何部份進行不當方式獲取本平台的資料</p>
                <p>二、所有權。本平台及本公司資料（包括其所有智慧財產權）以及本公司裝置均應為且始終為本公司的財產。</p>

                <p style={boldStyle}>陸、機密</p>
                <p>於使用或接觸本公司任何產品及服務時，其可能會直接或間接存取或接觸到另一方的機密資訊（以下簡稱「機密資訊」）。機密資訊包括但不限於本公司資料、照服員ID、使用者資訊以及任本公司的交易量、營銷及業務計劃、業務、財務、技術、營運及任一方指定為專屬或機密資訊或另一方應合理知曉應將其視為機密的其他非公開資訊（包括但不限以書面或口頭方式揭露）。</p>

                <p style={boldStyle}>柒、隱私權</p>
                <p>一、在不抵觸所有適用法律規定的前提下，若出現以下情況，您同意本公司可向第三方提供有關您的任何資訊（包括個人資料及任何本公司資料）：（一）您與使用者之間出現投訴、爭議或衝突，包括事故；（二）揭露為強制執行本合約條款所需；（三）本公司或任何成員按其全權酌情要求或適用法律法規要求揭露；（四）本公司或任何成員按其全權酌情確定，揭露為保護本公司、本平台或任何第三方的安全、權利或財產，及檢測、防止或另行處理詐欺、安全或技術問題，及防止或制止本公司或任何成員按其全權酌情確定為非法、不道德或會引致法律行動或產生相關風險的活動所需；或為了保險或其他與照服員使用本平台之資格能力或保留資格有關的目的而要求或必須按本公司或任何成員的全權酌情決定作出揭露。您知悉，在本合約終止後，本公司可因法律、監管、安全及其他必要的目的保留您的個人資料。您的個人資料將直接傳輸至本平台，由本平台以其作為該等個人資料的資料控制者處理。</p>
                <p>二、由於個人資料之蒐集，涉及您的隱私權益，茲依個人資料保護法（以下稱個資法）規定，告知您下列事項：</p>
                <p style={twoEmptyWords}>(一)蒐集之目的您瞭解並同意本公司蒐集您的個人資料係包括但不限於供下列特定目的之利用：〇四◎：行銷（包含金控共同行銷業務）；〇六三：非公務機關依法定義務所進行個人資料之蒐集處理及利用；〇六七：信用卡、現金卡、轉帳卡或電子票證業務；〇六九：契約、類似契約或其他法律關係事務；〇九〇：消費者、客戶管理與服務；〇九一：消費者保護；一四八：網路購物及其他電子商務服務；一五二：廣告或商業行為管理（例如：提供相關優惠行銷活動）；一五七：調查、統計與研究分析；一八一：其他經營合於營業登記項目或組織章程所定之業務；及一八二：其他諮詢與顧問服務。</p>
                <p style={twoEmptyWords}>(二)蒐集個人資料之類別 您瞭解並同意本公司所蒐集、處理、利用或國際傳輸您的個人資料，包括但不限於：COO一：辨識個人者（例如：姓名、住址、電子郵件信箱地址、行動電話、網路平臺申請之帳號、相片等）；C〇〇二：辨識財務者（例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、保險單號碼、個人之其他號碼或帳戶等）；C〇〇三：政府資料中之辨識者（例如：身分證統一編號、證照號碼等）；CO一一：個人描述（例如：出生年月日、性別、年齡等）；CO一二：身體描述（例如：身高、體重、血型等）；CO一三：習慣（例如：抽煙、喝酒等）；CO一四：個性（例如：個性等之評述意見）；CO三八：職業；C一一一：健康紀錄（例如：醫療報告、治療與診斷紀錄、檢驗結果、身心障礙種類、等級、有效期間、身心障礙手冊證號及聯絡人等）。</p>
                <p style={twoEmptyWords}>(三)個人資料利用之期間：除另有約定者外，本公司將於您本合約有效存續期間內，處理、利用或國際傳輸您的個人資料。</p>
                <p style={twoEmptyWords}>(四)個人資料利用之地區：本公司及本服務個人資料主機、相關網路伺服器主機所在地及本平台相關服務提供或行銷地區，為您個人資料利用之地區。</p>
                <p style={twoEmptyWords}>(五)個人資料利用之對象：本公司、本公司關係企業、相關委外之合作夥伴及瀏覽本服務客戶，為您個人資料利用之對象。</p>
                <p style={twoEmptyWords}>(六)個人資料利用之方式：您提供予本公司之個人資料，將由本公司於本服務個人資料主機、相關網路伺服器主機中妥善保存，並以符合個人資料保護相關法令以自動化機器或其他非自動化之利用方式利用之，包括但不限於針對您提供予本公司之個人資料進行分析，並就分析結果向您提供或推薦經優化的相關互動性資訊。</p>
                <p style={twoEmptyWords}>(七)個人資料權利及行使方式告知：對於您的個人資料，您可以透過電子郵件care520@sungreen.net.tw行使下列之權利：A. 查詢或、請求閱覽、補充或更正您的個人資料；B. 請求停止蒐集、處理或利用您的個人資料；C.請求刪除您的個人資料；及D. 請求製給您個人資料的複製本。</p>
                <p style={twoEmptyWords}>(八)為確保您之個人資料、隱私及權益之保護，當您於本平台輸入個人資料時，即表示您願意以本條規定行使法律所賦予同意之權利。如您不同意註冊並輸入您個人資料時，可能會無法使用本平台所提供之特定服務。</p>
                <p style={twoEmptyWords}>(九)個人資料之法定揭露：當使用者可能違反本協議或是損害本公司或第三人權益，或因法律或司法單位要求本公司提供特定使用者之個人資料時，本公司將在合法的程序下進行讀取、使用或揭露。</p>

                <p style={boldStyle}>捌、訂約方之間的關係</p>
                <p>一、除非本合約另有明文規定，否則，本合約訂約方之間為居間之法律關係，雙方分別為獨立、互不隸屬之服務提供者。雙方明確同意：本合約並非僱傭合約，除本合約另有明文規定外，亦不會在本公司與您之間形成委任、承攬、僱傭或任何勞動關係（包括從勞動法、稅法或其他法律角度）；及本公司與您之間並無形成任何合資、合夥或代理關係。</p>
                <p>二、您無權約束本公司或其成員，您承諾不會聲稱自己為本公司或其成員的員工，或者聲稱有權代理或代表本公司或其成員。若有任何人士、實體、主管機關或政府機構將您認定為本公司之受僱人、代理人或代表，並對本公司提出主張或以任何方式造成本公司可能之損害或義務負擔，您承諾及同意為本公司辯護或提供訴訟、非訴訟途徑上之協助（按本公司的選擇），使其免受損害。</p>
                <p>三、您明確確認並同意，透過同意本合約的條款和條件，您意圖以非偶然方式履行照顧服務，您應自行依適用之所得稅法、加值型及非加值型營業稅法及其他法令申報、繳納稅捐，與本公司無涉。</p>

                <p style={boldStyle}>玖、適用法律</p>
                <p>本合約之準據法為中華民國法律，應依中華民國法律解釋適用，不考慮其衝突法規定。由本合約所生或有關於本合約之任何糾紛、爭議或主張，如雙方無法和平地達成共識時，雙方同意應以臺灣彰化地方法院為第一審管轄法院。透過按下「我同意上述之合約」按鈕，代表您明確確認，您已閱讀、理解及充分考慮本合約之內容，您同意受本合約的條款和條件之拘束，您在法律上有與本公司簽署本合約之完全行為能力。</p>

            </div>
            <div className="inline-flex items-center justify-center mt-[2rem]">
                <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                    <input
                        id="check"
                        type="checkbox"
                        className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none border border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                        onChange={() => setCheckTermsOfService(!checkTermsOfService)}
                        checked={checkTermsOfService}
                    />
                    <span
                        className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </span>
                </label>
                <label
                    className="font-light text-primary text-[0.9375rem] lg:text-base cursor-pointer select-none"
                    htmlFor="check"
                >
                    我已閱讀並同意上述之合約
                </label>
            </div>
            <div className="flex items-center justify-center gap-[0.75rem] mt-[1.25rem]">
                <Link to="/Login">
                    <a
                        href={void (0)}
                        className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                        title="取消"
                    >
                        取消
                    </a>
                </Link>
                <button
                    type="button"
                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                    title="下一步"
                    onClick={() => {
                        if (!checkTermsOfService) {
                            showToast('warning', "請閱讀並同意服務條款");
                        } else {
                            onNextStep();
                        }
                    }}
                >
                    下一步
                </button>
            </div>
        </>
    );
};

export default RegisiterAccountStep1;
