import React, { useState, useEffect, useRef, useCallback } from "react";
// import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { CreateValidator, ValidateFields } from "../../utils/Validator";
import { useMyUserContext } from "../../contexts/MyUserContext";

import {
  apiGetAllCity,
  apiNurseAidesSimpleInfo,
  apiUpdateNurseAidesService,
  GetDistsByCityId,
} from "../../utils/Api";
import HamburgerModal from "../HamburgerModal";

/**
 * 個人資料
 */
const InforPanel = () => {
  const { myUser } = useMyUserContext();
  // const { t } = useTranslation();
  const { showToast } = useToast();
  const validator = CreateValidator();
  const [cities, setCities] = useState([]); //縣市
  const [towns, setTowns] = useState([]); //地區
  const [mainServiceTowns, setMainServiceTowns] = useState([]); //主要服務地區
  const [subServiceTowns, setSubServiceTowns] = useState([]); //次要服務地區
  const [userInfo, setUserInfo] = useState({
    id: 0,
    account: "",
    personnelName: "",
    gender: 0,
    cityId: 0,
    distId: 0,
    address: "",
    phone: "",
    mail: "",
    height: 0,
    weight: 0,
    IdNumber: 0,
    PersonnelType: "",
  });
  const PersonnelType = localStorage.getItem("PersonnelType"); //取的選擇的身分
  const isInfoResponseRef = useRef(true);
  // 拿到現在的時間
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const [birthdayYear, setBirthdayYear] = useState(currentyear.toString()); //生日
  const [birthdayMonth, setBirthdayMonth] = useState(currentMonth.toString()); //生日
  const [birthdayDay, setBirthdayDay] = useState(currentDay.toString()); //生日
  const [dayOptions, setDayOptions] = useState([]);

  const [errors, setErrors] = useState({
    height: "",
    weight: "",
    mail: "",
    identityNumber: "",
  });
  const [showHamburgerModal, setShowHamburgerModal] = useState();

  //#region 初始載入 獲取家屬註冊填寫的資訊
  useEffect(() => {
    const fetchData = async () => {
      const getAllCityResponse = await apiGetAllCity();
      if (getAllCityResponse && getAllCityResponse.code === 0) {
        console.log(getAllCityResponse.data.result);
        setCities(getAllCityResponse.data.result);
      } else {
        showToast(
          "error",
          getAllCityResponse ? getAllCityResponse.message : "取得縣市失敗"
        );
      }

      if (PersonnelType === "Family") {
        if (myUser) {
          console.log(myUser);
          const splitBirthday = myUser.birthday && myUser.birthday.split("/");
          setBirthdayYear(splitBirthday[0]);
          setBirthdayMonth(parseInt(splitBirthday[1]));
          setBirthdayDay(parseInt(splitBirthday[2]));
          setUserInfo({
            id: myUser.id,
            personnelName: myUser.personnelName,
            gender: myUser.gender,
            cityId: myUser.cityId,
            distId: myUser.distId,
            address: myUser.address,
            phone: myUser.phone,
            mail: myUser.mail,
            IdNumber: myUser.identityNumber ? myUser.identityNumber : "",
          });
        }
      } else {
        if (isInfoResponseRef.current) {
          const getNurseAidesSimpleInfoResponse =
            await apiNurseAidesSimpleInfo();
          if (
            getNurseAidesSimpleInfoResponse &&
            getNurseAidesSimpleInfoResponse.code === 0
          ) {
            const info = getNurseAidesSimpleInfoResponse.data;
            console.log(info);
            const splitBirthday = info.birthday.split("/");
            setBirthdayYear(splitBirthday[0]);
            setBirthdayMonth(parseInt(splitBirthday[1]));
            setBirthdayDay(parseInt(splitBirthday[2]));
            setUserInfo({
              id: info.id,
              personnelName: info.name,
              gender: info.gender,
              cityId: info.cityId,
              distId: info.distId,
              address: info.address,
              phone: info.phone,
              mail: info.mail,
              weight: info.weight,
              height: info.height,
              IdNumber: info.identityNumber,
              mainServiceCityId: info.mainServiceCityId,
              mainServiceDistId: info.mainServiceDistId,
              subServiceCityId: info.subServiceCityId,
              subServiceDistId: info.subServiceDistId,
              recommendCode: info.recommendCode,
            });
          } else {
            isInfoResponseRef.current = false;
            showToast(
              "error",
              getNurseAidesSimpleInfoResponse
                ? getNurseAidesSimpleInfoResponse.message
                : "取得照服員個資失敗"
            );
          }
        }
      }
    };

    fetchData();
    return () => {};
  }, [PersonnelType, myUser, showToast]);
  //#endregion

  //#region 打開漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };
  //#endregion

  //#region 關漢堡modal
  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  //#region 主要服務縣市切換
  const handleMainServiceCityChange = useCallback(
    async (cityId) => {
      // onChangeMainServiceCityData(parseInt(cityId));
      // 如果 cityId 是空字串，則不進行 API 請求並清空地區下拉選單
      if (!cityId) {
        setMainServiceTowns([]);
        // onChangeMainServiceDistData("");
        return;
      }

      var params = {
        cityId: parseInt(cityId),
      };
      const getDistsResponse = await GetDistsByCityId(params);
      if (getDistsResponse && getDistsResponse.code === 0) {
        // 更新 towns
        setMainServiceTowns(getDistsResponse.data.result);
        setUserInfo((prevInfo) => ({ ...prevInfo, mainServiceCityId: cityId }));
      } else {
        showToast(
          "error",
          getDistsResponse ? getDistsResponse.message : "取得城市失敗"
        );
      }
    },
    [showToast]
  );
  //#endregion

  //#region 次要服務縣市切換
  const handleSubServiceCityChange = useCallback(
    async (cityId) => {
      if (!cityId) {
        setSubServiceTowns([]);
        return;
      }
      var params = {
        cityId: parseInt(cityId),
      };
      const getDistsResponse = await GetDistsByCityId(params);
      if (getDistsResponse && getDistsResponse.code === 0) {
        setSubServiceTowns(getDistsResponse.data.result);
        setUserInfo((prevInfo) => ({ ...prevInfo, subServiceCityId: cityId }));
      } else {
        showToast(
          "error",
          getDistsResponse ? getDistsResponse.message : "取得城市失敗"
        );
      }
    },
    [showToast]
  );
  //#endregion

  // #region 次要城市更換
  const SubServiceDistChange = (id) => {
    setUserInfo({ ...userInfo, subServiceDistId: id });
  };
  //#endregion

  //#變更推薦馬
  const CreateNurseAidesChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  //#endregion

  // #region 主要城市更換
  const mainServiceDistChange = (id) => {
    setUserInfo({ ...userInfo, mainServiceDistId: id });
  };
  //#endregion

  //#region 生日相關
  const currentYear = new Date().getFullYear();
  const startYear = 1911;

  const yearOptions = [];
  yearOptions.push(
    <option key="" value="">
      請選擇年份
    </option>
  );
  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const monthOptions = [];
  monthOptions.push(
    <option key="" value="">
      請選擇月份
    </option>
  );
  for (let month = 1; month <= 12; month++) {
    monthOptions.push(
      <option key={month} value={month}>
        {month}
      </option>
    );
  }

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const updateDayOptions = useCallback(() => {
    if (birthdayYear && birthdayMonth) {
      const daysInSelectedMonth = getDaysInMonth(birthdayYear, birthdayMonth);
      const updatedDayOptions = [];
      updatedDayOptions.push(
        <option key="" value="">
          請選擇日期
        </option>
      );
      for (let day = 1; day <= daysInSelectedMonth; day++) {
        updatedDayOptions.push(
          <option key={day} value={day}>
            {day}
          </option>
        );
      }
      setDayOptions(updatedDayOptions);
    }
  }, [birthdayMonth, birthdayYear]);

  useEffect(() => {
    updateDayOptions();
  }, [updateDayOptions]);
  //#endregion

  //#region 渲染服務區域localStorage資料的下拉式選單
  // 組件初次加載時還未取得 mainServiceTowns 的數據，導致下拉菜單沒有選項。 所以用useEffect 鉤子中預先加載相應的數據來解決這個問題。
  useEffect(() => {
    if (userInfo.mainServiceCityId) {
      handleMainServiceCityChange(userInfo.mainServiceCityId);
    }
  }, [handleMainServiceCityChange, userInfo.mainServiceCityId]);

  useEffect(() => {
    if (userInfo.subServiceCityId) {
      handleSubServiceCityChange(userInfo.subServiceCityId);
    }
  }, [handleSubServiceCityChange, userInfo.subServiceCityId]);
  //#endregion

  // 處理按鍵事件並阻止小數點輸入
  const handleKeyDown = (e) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      handleMainServiceCityChange(userInfo.mainServiceCityId);

      if (userInfo.cityId) {
        var params = {
          cityId: parseInt(userInfo.cityId),
        };
        const getDistsResponse = await GetDistsByCityId(params);
        if (getDistsResponse && getDistsResponse.code === 0) {
          // 更新 towns
          setTowns(getDistsResponse.data.result);
        } else {
          showToast(
            "error",
            getDistsResponse ? getDistsResponse.message : "取得城市失敗"
          );
        }
      }
    };
    fetchData();
  }, [
    handleMainServiceCityChange,
    showToast,
    userInfo.cityId,
    userInfo.mainServiceCityId,
  ]);

  //#region 失去焦點Input的欄位
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    let newErrors = { ...errors };

    // 特別檢查主要服務區域的必填項目
    if (name === "mainServiceCityId" || name === "mainServiceDistId") {
      if (!value.trim()) {
        newErrors[name] = "此欄位為必填項目";
      } else {
        newErrors[name] = "";
      }
    }
    // 將更新後的錯誤狀態設置為組件的狀態
    setErrors(newErrors);
  };
  //#endregion

  //#region 欄位驗證
  const checkValidator = async () => {
    let newErrors = { ...errors };
    var validateFieldsResult = await ValidateFields(userInfo, newErrors, [
      "name",
      "gender",
      "birthday",
      "cityId",
      "distId",
      "address",
      "phone",
      "subServiceCityId",
      "subServiceDistId",
      "recommendCode",
      "IdNumber",
    ]);

    if (!validator.check(userInfo.mail, "required|mail")) {
      newErrors.mail = "請輸入有效的信箱";
      showToast("error", "信箱格式錯誤");
    }

    if (!validateFieldsResult.result) {
      setErrors(newErrors);
      // 打印錯誤信息
      console.log("欄位驗證錯誤:", validateFieldsResult);
    }

    return validateFieldsResult.result && !newErrors.mail;
  };
  //#endregion

  //#region 下一步
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkValidator()) {
      const updateNurseAidesInfo = {
        id: parseInt(userInfo.id),
        mainServiceCityId: parseInt(userInfo.mainServiceCityId),
        mainServiceDistId: parseInt(userInfo.mainServiceDistId),
        subServiceCityId: parseInt(userInfo.subServiceCityId),
        subServiceDistId: parseInt(userInfo.subServiceDistId),
      };
      const getUpdateNurseAidesServiceResponse =
        await apiUpdateNurseAidesService(updateNurseAidesInfo);
      if (
        getUpdateNurseAidesServiceResponse &&
        getUpdateNurseAidesServiceResponse.code === 0
      ) {
        showToast("success", "更新成功");
        // window.location.reload();
      } else {
        showToast(
          "error",
          getUpdateNurseAidesServiceResponse
            ? getUpdateNurseAidesServiceResponse.message
            : "照服員服務區務更新失敗"
        );
      }
    }
  };

  //#endregion

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  return (
    <>
      <div className="content">
        <div className="applyHead justify-between">
          <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
            個人資料
          </strong>
          <div className="flex items-center gap-[0.625rem] flex-none">
            <a
              data-bs-toggle="modal"
              href="#MenuModal"
              role="button"
              title="Sort Button"
              onClick={handleShowHamburgerModal}
            >
              <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_87_2023)">
                  <path
                    d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                    fill="#9E3223"
                  />
                  <path
                    d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                    fill="#9E3223"
                  />
                  <path
                    d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                    fill="#9E3223"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_87_2023">
                    <rect width="28" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <form
          className="flex flex-col gap-[1.875rem] mb-[6.5625rem] regisiterEnlarge"
          action="./careStep2.html"
        >
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="name"
            >
              姓名*
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem]  border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                name="personnelName"
                id="personnelName"
                placeholder="｜請輸入姓名"
                value={userInfo.personnelName}
                readOnly={true}
              />
            </div>
          </div>
          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="man"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              性別*
            </label>
            <div className="flex items-center gap-[0.75rem] grow">
              <div className="inline-flex items-center justify-center">
                <label
                  className="relative flex items-center p-1 rounded-full cursor-pointer"
                  htmlFor="man"
                >
                  <input
                    id="man"
                    type="radio"
                    name="gender"
                    value="1"
                    checked={userInfo.gender === 1}
                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10 "
                    disabled={true}
                    readOnly
                  />
                  <span className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-primary text-[1.25rem] cursor-pointer select-none "
                  htmlFor="man"
                >
                  男
                </label>
              </div>
              <div className="inline-flex items-center justify-center">
                <label
                  className="relative flex items-center p-1 rounded-full cursor-pointer"
                  htmlFor="woman"
                >
                  <input
                    id="woman"
                    type="radio"
                    name="gender"
                    value="2"
                    checked={userInfo.gender === 2}
                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10 "
                    disabled={true}
                  />
                  <span className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-primary text-[1.25rem] cursor-pointer select-none"
                  htmlFor="woman"
                >
                  女
                </label>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="year"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              生日*
            </label>
            <div className="flex items-center grow">
              <div className="grid grid-cols-3 gap-[1.125rem]">
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayYear"
                    value={birthdayYear}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    disabled={true}
                  >
                    {yearOptions}
                  </select>
                </div>
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayMonth"
                    value={birthdayMonth}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    disabled={true}
                  >
                    {monthOptions}
                  </select>
                </div>
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayDay"
                    value={birthdayDay}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]  "
                    disabled={true}
                  >
                    {dayOptions}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="address"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              住址*
            </label>
            <div className="flex items-center grow">
              <div className="w-full grid grid-cols-2 gap-[1.125rem]">
                <select
                  id="addressCity"
                  name="addressCity"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                  aria-label="縣市"
                  value={userInfo.cityId}
                  disabled={true}
                >
                  <option value="">請選擇</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <select
                  id="addressTown"
                  name="addressTown"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                  aria-label="地區"
                  value={userInfo.distId}
                  disabled={true}
                >
                  <option value="">請選擇</option>
                  {towns.map((town) => (
                    <option key={town.id} value={town.id}>
                      {town.name}
                    </option>
                  ))}
                </select>

                <div className="col-span-2">
                  <input
                    type="text"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    name="address"
                    id="address"
                    placeholder="Ex:河南路2段"
                    value={userInfo.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {PersonnelType === "Family" ? (
            <></>
          ) : (
            <>
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="name"
                >
                  身高*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="number"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    name="height"
                    id="height"
                    placeholder="｜單位為公分，請輸入130~200的整數"
                    value={userInfo.height}
                    min={130}
                    max={200}
                    step="1" // 助防止小數點輸入
                    onKeyDown={handleKeyDown}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="name"
                >
                  體重*
                </label>
                <div className="flex items-center grow">
                  <input
                    type="number"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    name="weight"
                    id="weight"
                    placeholder="｜單位為公斤，請輸入30~150的整數"
                    value={userInfo.weight}
                    min={30}
                    max={150}
                    step="1" // 防止小數點輸入
                    onKeyDown={handleKeyDown}
                    disabled={true}
                    readOnly
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="phone"
            >
              手機號碼*
            </label>
            <div className="flex items-center grow">
              <input
                type="tel"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                name="phone"
                id="phone"
                placeholder="Ex:0987654321"
                value={userInfo.phone}
                disabled={true}
                readOnly
              />
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="mail"
            >
              信箱*
            </label>
            <div className="flex items-center grow">
              <input
                type="mail"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                name="mail"
                id="mail"
                placeholder="｜請輸入信箱"
                value={userInfo.mail}
                disabled={true}
                readOnly
              />
            </div>
          </div>
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="IDNumber"
            >
              身分證號*
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                name="identityNumber"
                id="identityNumber"
                placeholder="Ex:A123456789"
                value={userInfo.IdNumber}
                onKeyDown={handleKeyDown}
                disabled={true}
                readOnly
              />
            </div>
          </div>
          {PersonnelType === "Family" ? (
            <></>
          ) : (
            <>
              {" "}
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="otherServiceCountry"
                >
                  主要服務區域*
                </label>
                <div className="flex items-center grow">
                  <div className="flex items-center grow">
                    <div className="w-full grid grid-cols-1 gap-[0.5rem]">
                      <select
                        id="mainServiceCityId"
                        name="mainServiceCityId"
                        className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                        aria-label="縣市"
                        onChange={(e) => {
                          handleMainServiceCityChange(e.target.value); // 這裡觸發主要服務區域城市的變動
                          // onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                        }}
                        value={userInfo.mainServiceCityId}
                        onBlur={handleBlur}
                      >
                        <option value="">請選擇</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      <select
                        id="mainServiceDistId"
                        name="mainServiceDistId"
                        className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                        aria-label="地區"
                        onChange={(e) => {
                          mainServiceDistChange(e.target.value);
                        }}
                        value={userInfo.mainServiceDistId}
                        onBlur={handleBlur}
                      >
                        <option value="">請選擇</option>
                        {mainServiceTowns.map((town) => (
                          <option key={town.id} value={town.id}>
                            {town.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {(errors.mainServiceCityId || errors.mainServiceDistId) && (
                <div className="text-red-500 text-sm mt-1 ml-1 error-message">
                  請填寫完整的地址
                </div>
              )}
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="otherServiceCountry"
                >
                  其他服務區域
                </label>
                <div className="flex items-center grow">
                  <div className="flex items-center grow">
                    <div className="w-full grid grid-cols-1 gap-[0.5rem]">
                      <select
                        id="subServiceCityId"
                        name="subServiceCityId"
                        className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                        aria-label="縣市"
                        onChange={(e) => {
                          handleSubServiceCityChange(e.target.value);
                          // onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                        }}
                        value={userInfo.subServiceCityId || 0} // 確保初始值為0
                      >
                        <option value="0">請選擇</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      <select
                        id="subServiceDistId"
                        name="subServiceDistId"
                        className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                        aria-label="地區"
                        onChange={(e) => {
                          SubServiceDistChange(parseInt(e.target.value));
                          // onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                        }}
                        value={userInfo.subServiceDistId || 0} // 確保初始值為0
                      >
                        <option value="0">請選擇</option>
                        {subServiceTowns.map((town) => (
                          <option key={town.id} value={town.id}>
                            {town.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-[0.5rem]">
                <label
                  className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
                  htmlFor="name"
                >
                  推薦碼
                </label>
                <div className="flex items-center grow">
                  <input
                    type="text"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    name="recommendCode"
                    id="recommendCode"
                    placeholder="請輸入您的推薦碼(非必填)"
                    value={userInfo.recommendCode}
                    onChange={CreateNurseAidesChange}
                  />
                </div>
              </div>
            </>
          )}
          {PersonnelType === "Family" ? (
            <></>
          ) : (
            <>
              <div className="flex items-center justify-end gap-[0.75rem]">
                <button
                  className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                  onClick={handleSubmit}
                >
                  儲存
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <HamburgerModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </>
  );
};

export default InforPanel;
