import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { apiGetAllCancelReason } from "../../utils/Api";

/**
 * 照服員接受案件-可服務方式(step1)
 */
const CancelReqStep1 = ({
  onNextStep,
  handleClose,
  step1DataCallback,
  firstReasons,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [selectedRes, setSelectedRes] = useState(0); //選擇的原因

  // region 初始載入
  useEffect(() => {
    const firstCancelReason = localStorage.getItem("firstCancelReason");
    setSelectedRes(firstCancelReason);
  }, []);
  //#endregion

  //#region 選擇取消原因
  const handleSelectCancelRes = (e) => {
    const { name, value } = e.target;
    const tmpValue = parseInt(value);
    localStorage.setItem("firstCancelReason", tmpValue);
    setSelectedRes(tmpValue);
  };
  //#endregion

  //#region 下一步
  const handleSubmit = (e) => {
    console.log(selectedRes);
    if (selectedRes !== 0) {
      step1DataCallback(selectedRes);
      onNextStep();
    } else {
      e.preventDefault();
      showToast("error", "請選擇取消原因");
    }
  };
  //#endregion

  return (
    <div
      className="modal-body accept"
      style={{ width: "100%", height: "100%" }}
    >
      <div
        className="bottomFormSection"
        style={{
          height: "33.3333%",
          width: "100%",
          alignItems: "center",
        }}
      >
        <strong className="title">請選擇取消原因</strong>
        <strong
          className="text-center"
          style={{ color: "var(--color-primary)" }}
        >
          *僅提供平台，照服員不會收到此消息
        </strong>
        <div className="flex items-center justify-between gap-[0.75rem] mt-[2.25rem] flex-wrap cancelRWD ">
          {firstReasons &&
            firstReasons.map((reason) => {
              return (
                <div
                  key={reason.id}
                  style={{ width: "34%" }}
                  className="w-[100%] min-w-[7.5rem] flex items-center justify-center  "
                >
                  <input
                    type="checkbox"
                    className="hidden peer "
                    name="firstReason"
                    id={`reason-${reason.id}`}
                    value={reason.id}
                    checked={selectedRes === reason.id}
                    onChange={handleSelectCancelRes}
                  />
                  <label
                    htmlFor={`reason-${reason.id}`}
                    className=" min-h-[5rem] rounded-2xl block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] text-white text-[1.5rem] flex    items-center font-bold leading-[1.5] peer-checked:bg-primary"
                  >
                    {reason.name}
                  </label>
                </div>
              );
            })}

          {/* <div
            style={{ width: "34%" }}
            className="rounded-2xl  overflow-hidden min-w-[9rem]"
          >
            <input
              type="checkbox"
              className="hidden peer w-2/4"
              name="date"
              id="day-model-1"
              value="1"
              checked={selectedRes === 1}
              onChange={handleSelectCancelRes}
            />
            <label
              htmlFor="day-model-1"
              className="block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)]  text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
            >
              我只配配配配配配配配配配配配配配配配配配配配配配配配配配配配
            </label>
          </div> */}
        </div>
        <div className="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] pb-[5.25rem]">
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="取消"
            onClick={handleClose}
          >
            取消
          </button>
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelReqStep1;
