import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNoLink from "../../layouts/HeaderNoLink";
import Login from "../../components/Login/Login";

/**
 * 登入
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div class="design  min-h-screen  bg-[#ffb777] ">
        <HeaderNoLink />
        <Login />
      </div>
    </>
  );
};

export default InfoPanel;
