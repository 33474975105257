import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";
import HamburgerNurseAidesModal from "./../HamburgerNurseAidesModal";



/**
 * 資料顯示頁面
 */
const InfoPanel = ({ keyword, CareServiceItemLists, refreshAllCareServiceItemList, name, creatorNum }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal

    //#region 漢堡modal
    const handleShowHamburgerModal = () => {
        setShowHamburgerModal(true);
    };

    const handleHamburgerClose = () => {
        setShowHamburgerModal(false);
    };
    //#endregion

    return (
        <div className=" main-background normal">
            <div className="content">
                <form className="flex flex-col gap-[2.1875rem] mb-[10rem]" action="./projectListChoose-success.html">
                    <input type="hidden" name="id" value="1" />
                    <div className="flex items-center justify-between gap-[0.625rem]">
                        <strong className="max-w-[6em] grow text-primary text-[1.5rem] leading-[1.5]">上傳證照</strong>
                        <div className="flex items-center gap-[0.625rem] flex-none">
                            <a data-bs-toggle="modal" href="#MenuModal" role="button" title="Sort Button" onClick={handleShowHamburgerModal}>
                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_87_2023)">
                                        <path
                                            d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                                            fill="#9E3223"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_87_2023">
                                            <rect width="28" height="23" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[2.5rem]">
                        <div className="InspireDropzone">
                            <div>
                                <span>點擊上傳身份證正面</span>
                                <span>僅能上傳jpg、png、jpeg檔</span>
                            </div>
                            <input type="hidden" name="IDcard-01" />
                        </div>
                        <div className="InspireDropzone">
                            <div>
                                <span>點擊上傳身份證背面</span>
                                <span>僅能上傳jpg、png、jpeg檔</span>
                            </div>
                            <input type="hidden" name="IDcard-02" />
                        </div>
                        <div className="InspireDropzone">
                            <div>
                                <span>點擊上傳照服員證書</span>
                                <span>僅能上傳jpg、png、jpeg檔</span>
                            </div>
                            <input type="hidden" name="IDcard-03" />
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-[0.75rem] mt-[0.625rem]">
                        <Link to="/ProjectListChooseSuccess">
                            <button
                                type="submit"
                                className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                title="上傳"
                            >
                                上傳
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
            <HamburgerNurseAidesModal
                showHamburgerModal={showHamburgerModal}
                handleHamburgerClose={handleHamburgerClose}
            />
        </div>
    );
};

export default InfoPanel;
