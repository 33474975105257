import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import HamburgerFamilyModal from "../HamburgerModal";
import "./../../bootstrap.css";
import { apiWriteEvaluate } from "../../utils/Api";
import { useNavigate } from "react-router-dom";

/**
 * 資料顯示頁面
 */
const Pairing3Detail = ({ id, completePair }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { navigate } = useNavigate();
  const [evaluate, setEvaluate] = useState("");
  const [rating, setRating] = useState(0); // 星星分數顯示
  const [selection, setSelection] = useState(0); //渲染滑鼠滑過的星星

  //#region  撰寫評論
  const handleWriteChange = (e) => {
    const { value } = e.target;
    console.log(value);
    setEvaluate(value);
  };
  // #end region

  //   #送出評價
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      careCaseId: parseInt(id),
      score: rating,
      toPersonnelId: completePair.nurseAidesId,
      description: evaluate,
    };
    const writeEvaluateResponse = await apiWriteEvaluate(params);
    if (writeEvaluateResponse && writeEvaluateResponse.code === 0) {
      showToast("success", "送出成功");
      navigate("/FindCareStep");
    } else {
      showToast(
        "error",
        writeEvaluateResponse
          ? writeEvaluateResponse.message
          : t("toast.unknownError") //網路連線錯誤
      );
    }
  };
  // #end region

  //#region 星星評分
  function Star({ marked, starId }) {
    return (
      <span
        star-id={starId}
        style={{ color: "#ff9933", fontSize: "2.5rem" }}
        role="button"
      >
        {/* 空星，實星 */}
        {marked ? "\u2605" : "\u2606"}
      </span>
    );
  }

  function StarRating() {
    // 鼠標移入效果
    const hoverOver = (event) => {
      let val = 0;
      if (event && event.target && event.target.getAttribute("star-id"))
        val = event.target.getAttribute("star-id");
      setSelection(val);
    };

    return (
      <div
        // 鼠標移入效果
        onMouseOut={() => hoverOver(null)}
        // 點擊選中分數
        onClick={(event) => setRating(event.target.getAttribute("star-id"))}
        onMouseOver={hoverOver}
      >
        {/* 創建5個组件 */}
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1} `}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
          />
        ))}
      </div>
    );
  }
  //#endregion

  return (
    // <div className=" main-background normal">
    //   <div className="content">
    //     <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
    <form
      className="flex flex-col gap-[2.1875rem]"
      action="./findCareStep6.html"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="id" value="1" />
      <div className="flex flex-col items-center gap-[1.5625rem]">
        <div className="flex flex-col gap-[0.8125rem]">
          <div className="w-[8.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
            <img
              src={
                completePair.headshotFile
                  ? URL.createObjectURL(completePair.headshotFile.data)
                  : "./img/user.png"
              }
              width="130"
              height="130"
              alt="user avator"
              className="h-full w-full object-center object-cover"
            />
          </div>
          <span className="text-primary text-[1.25rem] text-center leading-[1.5]">
            {/* {completePair.nurseAidesName}({completePair.nurseAidesGender}/ */}
            {/* {completePair.nurseAidesAge}歲)  */}
            李XX(男 / 26歲)
          </span>
        </div>
        <div className="w-full flex flex-col items-center gap-[1.625rem]">
          <textarea
            className="w-full p-[1rem] bg-white border-[0.125rem] border-primary text-primary text-base md:text-sm lg:text-base placeholder:text-xs placeholder:text-[rgba(0,0,0,0.3)]"
            name="comment"
            id="comment"
            maxLength="50"
            cols="30"
            rows="6"
            placeholder="照服員評價(0/50)"
            aria-label="評論"
            onChange={handleWriteChange}
          ></textarea>
          <div className="-mb-[1.0625rem]">
            <StarRating />
            <input type="hidden" name="rating" id="rating" value="0" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-[0.75rem]">
        <button
          type="submit"
          className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
          title="送出評價"
        >
          送出評價
        </button>
      </div>
    </form>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Pairing3Detail;
