import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; //语系
import { useToast } from "../../contexts/ToastContext";
import { Modal, Button, Form } from "react-bootstrap";
import "../../bootstrap.css";

/**
 * 意見回饋 - 填寫email
 */
const TextEmailModal = ({ showModal, handleClose, setEmail, email }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [error, setError] = useState("");
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  //#region 失去焦點驗證
  const handleBlure = (e) => {
    const { name, value } = e.target;
    const valueTrim = value.trim();
    if (valueTrim === "") {
      setError("請填寫email");
      return;
    }
    if (!validateEmail(valueTrim)) {
      setError("請填寫正確的email格式");
      return;
    } else {
      setError("");
    }
  };
  //#endregion

  //  #region 驗證信箱格式
  const validateEmail = (email) => {
    // 這段不懂的話，可以去查正規表達示
    // 參考網址:https://5xcampus.com/posts/15min-regular-expression.html
    const re = /^[^\s@]+@[^\s@\d]+\.[^\s@\d]+$/;
    return re.test(email);
  };
  //#endregion

  //   #region 確認
  const handleConfirm = () => {
    if (email === "") {
      setError("請填寫email");
      return;
    }
    if (!validateEmail(email)) {
      setError("請填寫正確的email格式");
      return;
    } else {
      handleClose();
      setError("");
    }
  };
  //#endregion

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <Modal
      show={showModal}
      className="modal fade ProjectModal"
      id="checkinModal-1-1"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content accept">
          <div className="modal-body">
            <div className="bottomFormSection">
              <div className="w-full flex flex-col items-center gap-[1rem] mx-auto mt-[1.75rem]">
                <svg
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_787_333)">
                    <path
                      d="M40.2098 16.8154H30.7922V41.1752H40.2098V16.8154Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M40.2098 45.8457H30.7922V54.1847H40.2098V45.8457Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M9.5165 31.0627C11.6484 18.7455 22.484 9.34138 35.501 9.34138C48.518 9.34138 59.3536 18.7455 61.4855 31.0627H71.002C68.791 13.5766 53.7192 0 35.501 0C17.2828 0 2.20905 13.5766 0 31.0627H9.5165Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M61.4004 40.4043C59.0787 52.49 48.3518 61.6568 35.501 61.6568C22.6501 61.6568 11.9233 52.49 9.60154 40.4043H0.0593262C2.47603 57.6649 17.443 71.0002 35.499 71.0002C53.555 71.0002 68.522 57.6649 70.9387 40.4043H61.3984H61.4004Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_787_333">
                      <rect width="71" height="71" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <strong className="text-primary text-[1.5rem] leading-[1.5] text-center">
                  請填寫信箱
                </strong>
                <div className="flex items-center flex-col grow">
                  <input
                    type="email"
                    className="w-full min-w-0 p-[0.4375rem]  border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] "
                    name="email"
                    id="email"
                    placeholder="｜請輸入信箱"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleBlure}
                  />
                  {error !== "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center gap-[0.75rem] mt-[2.0625rem] pb-[2.9375rem]">
                <button
                  className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                  title="取消"
                  onClick={handleClose}
                >
                  取消
                </button>
                <button
                  type="button"
                  className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                  title="確認"
                  data-bs-toggle="modal"
                  data-bs-target="#checkinModal-1-2"
                  data-submit
                  onClick={handleConfirm}
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TextEmailModal;
