import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import Pairing3CaregiverList from "../../components/Pairing/Pairing3CaregiverList";

/**
 * 選擇身分
 * @returns
 */
const Pairing3CaregiverListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <Pairing3CaregiverList />
      </div>
    </>
  );
};

export default Pairing3CaregiverListPage;
