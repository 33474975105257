import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { Link } from "react-router-dom";
import HamburgerNurseAidesModal from "./../HamburgerNurseAidesModal";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

/**
 * 資料顯示頁面
 */
const InfoPanel = ({ keyword, CareServiceItemLists, refreshAllCareServiceItemList, name, creatorNum }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
    const [selectedDate, setSelectedDate] = useState(new Date());

    // 定義一個函數來處理日期變更事件
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    //#region 漢堡modal
    const handleShowHamburgerModal = () => {
        setShowHamburgerModal(true);
    };

    const handleHamburgerClose = () => {
        setShowHamburgerModal(false);
    };
    //#endregion


    return (
        <div className=" main-background normal">
            <div className="content">
                <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
                    <form className="flex items-start justify-start gap-[0.625rem]" action="./projectListChoose-success.html">
                        <strong className="max-w-[6.5em] text-primary text-[1.5rem] leading-[1.5]">日期顯示</strong>
                        <div className="flex items-center justify-end gap-[0.625rem] grow">
                            <select
                                name="type"
                                id="type"
                                className="min-w-[9.125rem] max-w-[9.125rem] min-w-0 grow p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs placeholder:text-[rgba(0,0,0,0.3)]"
                                aria-label="日期顯示"
                            >
                                <option value="" disabled>請選擇</option>
                                <option value="all" selected>全部</option>
                                <option value="1">ＸＸＸ</option>
                            </select>
                            <a data-bs-toggle="modal" href="#MenuModal" class="flex-none" role="button" title="Sort Button" onClick={handleShowHamburgerModal}>
                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_87_2023)">
                                        <path
                                            d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                                            fill="#9E3223"
                                        />
                                        <path
                                            d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                                            fill="#9E3223"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_87_2023">
                                            <rect width="28" height="23" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </form>
                    <form className="flex flex-col gap-[2.1875rem]" action="./checkin.html">
                        <div className="w-full flex items-center justify-center p-[0.625rem]">
                            <div style={{ border: '2px solid rgb(158, 50, 35)' }}>
                                <Calendar
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    minDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[1.5625rem] mt-[1.25rem]">
                            <div>
                                <input type="checkbox" name="nurse" id="nurse-01" class="hidden peer" checked disabled />
                                <label
                                    for="nurse-01"
                                    className="flex items-stretch bg-[rgba(158,50,35,0.48)] peer-checked:bg-primary border-[0.125rem] border-[rgba(158,50,35,0.48)] peer-checked:border-primary rounded-[1.25rem] overflow-hidden"
                                >
                                    <div className="flex items-center flex-none bg-[#FFDA5E]">
                                        <img
                                            src="./img/user.png"
                                            className="h-full object-center object-cover"
                                            width="110"
                                            height="100"
                                            alt="user avator"
                                        />
                                    </div>
                                    <ul
                                        className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] text-white text-xs md:text-sm lg:text-base leading-[1.6]"
                                    >
                                        <li>案件編號：4bc523</li>
                                        <li>家屬：林ＸＸ | 0987654321</li>
                                        <li>次要家屬：林12 | 0987654321</li>
                                        <li>醫院：新北市土城醫院</li>
                                    </ul>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-[0.75rem]">
                            <Link to="/ProjectList">
                                <button
                                    type="submit"
                                    className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                                    title="下一頁"
                                >
                                    下一頁
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <HamburgerNurseAidesModal
                showHamburgerModal={showHamburgerModal}
                handleHamburgerClose={handleHamburgerClose}
            />
        </div>
    );
};

export default InfoPanel;
