import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import moment from "moment";

/**
 * 照服員接受案件-確認申請(step4)
 */
const ConfirmStep4 = ({
  onNextStep,
  onPreviousStep,
  confirmData,
  availableCaseDetail,
  isResponse,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  //#region 初始載入
  useEffect(() => {
    console.log("ConfirmStep4的", availableCaseDetail);
  }, [availableCaseDetail]);
  //#endregion

  const formatDateTime = (dateTime) => {
    // 試著解析不同的日期格式
    const momentDateTime = moment.parseZone(dateTime, [
      "M/D/YYYY h:mm:ss A",
      "YYYY/M/D A h:mm:ss",
    ]);
    return momentDateTime.isValid() ? momentDateTime.format("MM/DD") : dateTime;
  };

  // #region 計算總金額
  const caculationAmount = (expectedPrice) => {
    const itemsTotalAmount =
      availableCaseDetail.lCareServiceItemDataDTO &&
      availableCaseDetail.lCareServiceItemDataDTO.reduce(
        (total, item) => total + Number(item.fee),
        0
      );

    const totalDays = confirmData.selectedDays.length;
    if (availableCaseDetail.plan.unit === 1) {
      // 全天候算法
      const totalAmount =
        (Number(expectedPrice) + itemsTotalAmount) * totalDays;
      return totalAmount;
    }
    if (availableCaseDetail.plan.unit === 0) {
      const hours = Number(availableCaseDetail.plan.hours);
      // 喘息照顧算法
      const totalAmount =
        (Number(expectedPrice) * hours + itemsTotalAmount) * totalDays;
      return totalAmount;
    }
  };
  //#endregion

  return (
    <div class="modal-body accept">
      <div class="bottomFormSection" style={{ height: "100%" }}>
        <strong class="title">確認申請</strong>
        <div class="w-[12.5rem] min-h-[10.875rem] flex flex-col mt-[2.5rem] mx-auto text-primary text-[0.9375rem] lg:text-base leading-[1.6]">
          <span>
            服務時間：
            <ul style={{ listStyle: "disc", listStylePosition: "inside" }}>
              {confirmData && confirmData.selectedDays.length > 0 ? (
                confirmData.selectedDays.map((selectedDay, index) => {
                  return (
                    <li key={index} className="ml-3">
                      {selectedDay}
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </span>
          <span className="mt-3">
            服務項目 (1天的金額)：
            <ul style={{ listStyle: "disc", listStylePosition: "inside" }}>
              {availableCaseDetail &&
              availableCaseDetail.lCareServiceItemDataDTO.length > 0 ? (
                availableCaseDetail.lCareServiceItemDataDTO.map((item) => {
                  return (
                    <li key={item.id} className="ml-3">
                      {item.name} $ {item.fee}
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </span>
          <span className="mt-3">
            期望方案金額 ：
            <span class="whitespace-nowrap" data-receipt="price">
              $ {confirmData.expectedPrice.toLocaleString()} /{" "}
              {availableCaseDetail.plan.unit === 0 ? "小時" : "天"}
            </span>
          </span>
          {availableCaseDetail.plan.unit === 0 ? (
            <>
              <span className="mt-3">
                時數 ：
                <span class="whitespace-nowrap" data-receipt="price">
                  {availableCaseDetail.plan.hours} 小時
                </span>
              </span>
            </>
          ) : (
            <></>
          )}

          <strong className="mt-3">
            總金額 ：
            <span class="whitespace-nowrap " data-receipt="price">
              $ {caculationAmount(confirmData.expectedPrice).toLocaleString()}
            </span>
          </strong>
        </div>
        <div class="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] pb-[5.25rem]">
          <button
            type="button"
            class="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="上一步"
            onClick={onPreviousStep}
          >
            上一步
          </button>
          <button
            type="button"
            class="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="送出"
            onClick={onNextStep}
          >
            送出
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStep4;
