import React from "react";
import HeaderNoLink from "../../layouts/HeaderNoLink";
import ReviewFail from "../../components/Review/ReviewFail";

/**
 * 審核不通過
 * @returns
 */
const InfoPanel = () => {

    return (
        <>
            <div className="design">
                <HeaderNoLink />
                <ReviewFail />
            </div>
        </>
    );
};

export default InfoPanel;
