import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Header = () => {
    const navigate = useNavigate(); //跳轉Router
    const [isActive, setIsActive] = useState(false);

    const handleToggle = (event) => {
        // 阻止事件冒泡到下面的按鈕
        event.stopPropagation();
        setIsActive(!isActive);
    };

    // 按鈕一 撥打電話
    const handleCall = () => {
        // 阻止事件冒泡到下面的按鈕

        // 執行撥打電話的操作
        window.location.href = 'tel:+886-4-7055081';
    };

    // 按鈕二 寄送郵件
    const handleEmail = () => {
        window.location.href = 'mailto:care520@sungreen.net.tw';
    };

    // 按鈕三 加入line
    const handleLine = () => {
        window.location.href = 'https://line.me/R/ti/p/@care520'; // 記得替換LINE帳號ID
    };

    return (
        <>
            <ul className={`ContactusList ${isActive ? 'active' : ''}`}>
                <li>
                    <a href={void 0} onClick={handleCall}
                        ><svg width="32" height="47" viewBox="0 0 32 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.61754 38.5416C3.51412 36.9459 5.43705 35.3843 7.34493 33.8C8.36472 32.953 9.47859 32.7791 10.7016 33.2782C11.4241 33.5769 12.1428 33.8907 12.8804 34.1592C14.2502 34.6583 15.3603 34.3369 16.3123 33.2215C17.4902 31.8413 18.4234 30.2948 19.2588 28.6954C21.0463 25.281 22.6757 21.7985 23.4471 17.9833C23.7481 16.4973 23.2702 15.4348 21.9833 14.6596C21.2908 14.2437 20.5683 13.8732 19.8646 13.4723C18.8524 12.8976 18.2842 12.0128 18.2804 10.852C18.2729 8.156 18.2653 5.46002 18.3557 2.76781C18.4234 0.722189 20.3125 -0.480228 22.2504 0.18148C22.7923 0.366758 23.338 0.552036 23.8723 0.752439C27.3833 2.06073 29.8518 4.34457 30.898 8.0766C31.771 11.1923 31.8801 14.3345 31.3232 17.4842C30.0099 24.9104 27.0295 31.6409 22.5816 37.6946C20.2823 40.8292 17.46 43.408 12.3009 45.7032C10.3742 46.5691 8.04862 46.5955 4.66939 45.102C3.73615 44.6898 2.87817 44.1378 2.01642 43.6008C-0.0607876 42.3039 -0.245178 40.1108 1.61754 38.5454V38.5416Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </a>
                </li>
                <li>
                    <a href={void 0} onClick={handleEmail}
                        ><svg width="54" height="34" viewBox="0 0 54 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M26.9812 21.6806C26.5146 21.6617 26.2324 21.3289 26.2211 21.3138L3.77063 0.687448L50.1391 0.471924L27.9709 21.1437C27.8844 21.242 27.5457 21.6768 27.0264 21.6768H26.985L26.9812 21.6806Z"
                                fill="#9E3223"
                            />
                            <path
                                d="M2.94653 33.5687L3.43197 32.9448L9.4604 28.604C12.9601 26.097 16.4522 23.5825 19.9217 21.0416L20.8437 21.1172C21.6603 21.9302 22.522 22.6977 23.38 23.4615C24.3847 24.3501 25.6717 24.8379 27.0076 24.8379C28.3435 24.8379 29.6568 24.3388 30.654 23.4275L31.2787 22.8528C31.9974 22.1873 32.7162 21.5256 33.465 20.8941L50.9369 33.3456L50.8654 33.5649H2.94653V33.5687Z"
                                fill="#9E3223"
                            />
                            <path
                                d="M0.66603 30.608C0.639689 30.608 0.538086 30.5891 0.538086 30.487V2.96751L17.6375 18.2322L0.737528 30.5815L0.66603 30.608Z"
                                fill="#9E3223"
                            />
                            <path
                                d="M53.2624 30.5702L36.3888 18.425L53.2549 2.89568L53.4618 30.4756L53.3339 30.5966L53.2624 30.5702Z"
                                fill="#9E3223"
                            />
                        </svg>
                    </a>
                </li>
                <li>
                    <a href={void 0} onClick={handleLine}
                        ><svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M27 55C41.9117 55 54 42.8535 54 27.87C54 12.8865 41.9117 0.73999 27 0.73999C12.0883 0.73999 0 12.8865 0 27.87C0 42.8535 12.0883 55 27 55Z"
                                fill="#4CC764"
                            />
                            <path
                                d="M45.0062 26.2932C45.0062 18.1902 36.9194 11.5958 26.9811 11.5958C17.0429 11.5958 8.95605 18.1902 8.95605 26.2932C8.95605 33.5569 15.3683 39.6446 24.0309 40.7941C24.6179 40.9226 25.4157 41.1835 25.6189 41.6864C25.7995 42.144 25.7393 42.8624 25.6791 43.3237C25.6791 43.3237 25.4684 44.6017 25.4232 44.874C25.3442 45.3315 25.062 46.6663 26.9849 45.8495C28.9078 45.0366 37.3634 39.7089 41.1453 35.334C43.7569 32.4566 45.01 29.5337 45.01 26.2894L45.0062 26.2932Z"
                                fill="white"
                            />
                            <path
                                d="M39.008 30.9706C39.1999 30.9706 39.3504 30.8155 39.3504 30.6265V29.3409C39.3504 29.1518 39.1961 28.9968 39.008 28.9968H35.5648V27.662H39.008C39.1999 27.662 39.3504 27.507 39.3504 27.3179V26.0323C39.3504 25.8433 39.1961 25.6882 39.008 25.6882H35.5648V24.3535H39.008C39.1999 24.3535 39.3504 24.1984 39.3504 24.0094V22.7238C39.3504 22.5347 39.1961 22.3797 39.008 22.3797H33.9429C33.751 22.3797 33.6005 22.5347 33.6005 22.7238V22.7313V30.6227V30.6302C33.6005 30.8231 33.7547 30.9743 33.9429 30.9743H39.008V30.9706Z"
                                fill="#4CC764"
                            />
                            <path
                                d="M20.2791 30.9706C20.4673 30.9706 20.6215 30.8155 20.6215 30.6265V29.3409C20.6215 29.1518 20.4673 28.9968 20.2791 28.9968H16.8359V22.7238C16.8359 22.5347 16.6816 22.3797 16.4935 22.3797H15.214C15.0221 22.3797 14.8716 22.5347 14.8716 22.7238V30.6227V30.6302C14.8716 30.8231 15.0259 30.9743 15.214 30.9743H20.2791V30.9706Z"
                                fill="#4CC764"
                            />
                            <path
                                d="M23.331 22.3759H22.0478C21.8586 22.3759 21.7053 22.53 21.7053 22.72V30.6265C21.7053 30.8165 21.8586 30.9706 22.0478 30.9706H23.331C23.5201 30.9706 23.6734 30.8165 23.6734 30.6265V22.72C23.6734 22.53 23.5201 22.3759 23.331 22.3759Z"
                                fill="#4CC764"
                            />
                            <path
                                d="M32.0274 22.3759H30.748C30.5561 22.3759 30.4055 22.5309 30.4055 22.72V27.4162L26.808 22.5309C26.808 22.5309 26.7892 22.5083 26.7817 22.4969C26.7742 22.4894 26.7666 22.4818 26.7591 22.4742C26.7591 22.4742 26.7554 22.4705 26.7516 22.4667C26.7441 22.4629 26.7403 22.4553 26.7328 22.4515C26.7328 22.4515 26.7253 22.4478 26.7253 22.444C26.7177 22.4402 26.714 22.4364 26.7064 22.4326C26.7064 22.4326 26.6989 22.4289 26.6952 22.4251C26.6876 22.4251 26.6839 22.4175 26.6763 22.4137C26.6763 22.4137 26.6688 22.4137 26.665 22.4099C26.6575 22.4099 26.65 22.4024 26.6462 22.4024C26.6462 22.4024 26.6387 22.4024 26.6349 22.3986C26.6274 22.3986 26.6199 22.3948 26.6124 22.391C26.6086 22.391 26.6048 22.391 26.6011 22.391C26.5935 22.391 26.586 22.391 26.5823 22.3873C26.5785 22.3873 26.571 22.3873 26.5672 22.3873C26.5597 22.3873 26.5559 22.3873 26.5484 22.3873C26.5409 22.3873 26.5371 22.3873 26.5296 22.3873C26.5258 22.3873 26.522 22.3873 26.5183 22.3873H25.2464C25.0582 22.3873 24.9039 22.5423 24.9039 22.7313V30.634C24.9039 30.8231 25.0582 30.9781 25.2464 30.9781H26.5258C26.7177 30.9781 26.8683 30.8231 26.8683 30.634V25.9416L30.4733 30.8307C30.4996 30.8647 30.5297 30.8949 30.5636 30.9176C30.5636 30.9176 30.5636 30.9176 30.5673 30.9176C30.5749 30.9214 30.5824 30.9252 30.5899 30.929C30.5899 30.929 30.5974 30.929 30.6012 30.9327C30.605 30.9327 30.6125 30.9365 30.6163 30.9403C30.62 30.9403 30.6276 30.9441 30.6313 30.9479C30.6313 30.9479 30.6388 30.9479 30.6426 30.9516C30.6501 30.9516 30.6577 30.9554 30.6652 30.9592C30.6652 30.9592 30.6652 30.9592 30.6689 30.9592C30.6991 30.9668 30.7292 30.9706 30.7593 30.9706H32.0312C32.2193 30.9706 32.3736 30.8155 32.3736 30.6265V22.7238C32.3736 22.5347 32.2193 22.3797 32.0312 22.3797L32.0274 22.3759Z"
                                fill="#4CC764"
                            />
                        </svg>
                    </a>
                </li>
                <a href="javascript:void(0)" target="_self" className={`navBtn ${isActive ? 'active' : ''}`} onClick={handleToggle}>
                    <span class="line1"></span>
                    <span class="line2"></span>
                    <span class="line3"></span>
                </a>
            </ul>

        </>
    );
}

export default Header;