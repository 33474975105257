import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import moment from "moment";
import {
  convertDoubleToDate,
  convertTimeToDouble,
  formatTimeToHHmm,
  formatToMMDD,
} from "../../utils/Helpers";

/**
 * 照服員接受案件-選擇時段(step2)
 */
const ConfirmStep2 = ({
  onNextStep,
  onPreviousStep,
  onHandleChangeConfirmDataStep2,
  confirmData,
  availableCaseDetail,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [selectedDays, setSelectedDays] = useState([]);
  const [serviceTimeRange, setServiceTimeRange] = useState();

  //#region 初始載入
  useEffect(() => {
    if (confirmData.selectedDayModel == 0) {
      //幫他寫好全部的日期阿
      let tmpSelectedDays = [];
      availableCaseDetail.plan.careCasePlanTimes.map(
        (careCasePlanTime, index) => {
          tmpSelectedDays.push(careCasePlanTime.startDate.substring(0, 10));
        }
      );
      onHandleChangeConfirmDataStep2(tmpSelectedDays);
      onNextStep();
    }

    //顯示服務時段
    var startTime = availableCaseDetail.plan.careCasePlanTimes[0].startTime;
    var startTimeDate = convertDoubleToDate(startTime);
    var endTimeDate = new Date(startTimeDate);
    endTimeDate.setHours(
      endTimeDate.getHours() + availableCaseDetail.plan.hours
    );

    setServiceTimeRange(
      `${formatTimeToHHmm(startTimeDate)} ~ ${formatTimeToHHmm(endTimeDate)}`
    );

    setSelectedDays(confirmData.selectedDays);
  }, [confirmData]);
  //#endregion

  const formatDateTime = (dateTime) => {
    // 試著解析不同的日期格式
    const momentDateTime = moment.parseZone(dateTime, [
      "M/D/YYYY h:mm:ss A",
      "YYYY/M/D A h:mm:ss",
    ]);
    return momentDateTime.isValid() ? momentDateTime.format("MM/DD") : dateTime;
  };

  //#region 選擇日期
  const handleChangeSelectDay = (e) => {
    let tmpSelectedDays = [...selectedDays];
    const { value } = e.target;

    let tmpDate = value.substring(0, 10);
    console.log(confirmData.serviceDay);
    let findIndex = tmpSelectedDays.findIndex((x) => x === tmpDate);
    if (findIndex >= 0) {
      tmpSelectedDays.splice(findIndex, 1);
    } else if (tmpSelectedDays.length < confirmData.serviceDay) {
      tmpSelectedDays.push(tmpDate);
    } else {
      showToast("error", "選擇天數請小於服務天數");
    }

    setSelectedDays(tmpSelectedDays);
  };
  //#endregion

  //#region 下一步
  const handleSubmit = (e) => {
    if (
      selectedDays.length > 0 &&
      selectedDays.length === confirmData.serviceDay
    ) {
      onHandleChangeConfirmDataStep2(selectedDays);
      onNextStep();
    } else {
      e.preventDefault();
      showToast("error", `請選滿${confirmData.serviceDay}天`);
    }
  };
  //#endregion

  return (
    <div className="modal-body accept">
      <div className="bottomFormSection" style={{ height: "33.3333%" }}>
        <strong className="title">選擇時段</strong>
        <div className="flex flex-col mx-auto text-primary text-[1.25rem] text-center leading-[1.6] mt-[0.75rem]">
          <p>需求時間 {availableCaseDetail.plan.hours}小時</p>
          <p>{serviceTimeRange}</p>
        </div>
        <div className="w-full flex flex-col items-center gap-[1.25rem] mt-[1.5625rem] mx-auto">
          {availableCaseDetail.plan &&
          availableCaseDetail.plan.careCasePlanTimes.length > 0 ? (
            availableCaseDetail.plan.careCasePlanTimes.map(
              (careCasePlanTime, index) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      className="hidden peer"
                      name="date"
                      id={`careCasePlanTime-${index}`}
                      value={careCasePlanTime.startDate}
                      checked={selectedDays.includes(
                        careCasePlanTime.startDate.substring(0, 10)
                      )}
                      onChange={handleChangeSelectDay}
                    />
                    <label
                      htmlFor={`careCasePlanTime-${index}`}
                      className="min-w-[14rem] block py-1 px-[0.75rem] bg-[rgba(158,50,35,0.48)] rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] peer-checked:bg-primary"
                    >
                      {formatDateTime(careCasePlanTime.startDate)}
                    </label>
                  </div>
                );
              }
            )
          ) : (
            <></>
          )}
        </div>
        <div className="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] pb-[5.25rem]">
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="上一步"
            onClick={onPreviousStep}
          >
            上一步
          </button>
          <button
            type="button"
            className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
            title="下一步"
            onClick={handleSubmit}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStep2;
