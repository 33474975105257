import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import { Modal, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * 通知元件
 *
 * @param {string} type 通知類型
 * @param {string} message 通知訊息
 * @param {boolean} visible 是否顯示
 * @returns
 */
const Toast = ({ type, message, visible, handleConfirm }) => {
  // 限制訊息寬度，超過30個字元則截斷
  // const truncatedMessage =
  //   message.length > 30 ? `${message.substring(0, 30)}...` : message;
  return (
    <Modal
      show={visible}
      backdrop="static"
      className="toast"
      keyboard={false}
      centered
    >
      <Modal.Body style={{ padding: "35px" }} className="toast">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {
            {
              success: (
                <div className="text-success" style={{ fontSize: "4rem" }}>
                  <img
                    src="/img/Ok.png"
                    alt="Success Icon"
                    style={{ width: "2em", height: "2em" }}
                  />
                </div>
              ),
              warning: (
                <div className="fs-1 text-warning" style={{ fontSize: "4rem" }}>
                  <img
                    src="/img/warning.png"
                    alt="Success Icon"
                    style={{ width: "1em", height: "1em" }}
                  />
                </div>
              ),
              error: (
                <div style={{ color: "#dc3545", fontSize: "3rem" }}>
                  <img
                    src="/img/No.png"
                    alt="Success Icon"
                    style={{ width: "1em", height: "1em" }}
                  />
                </div>
              ),
            }[type]
          }
          <p className="m-5" style={{ fontSize: "1.25rem" }}>
            {/* {truncatedMessage} */}
            {message}
          </p>
        </div>
        {type === "error" ? (
          <div className="flex items-center justify-center gap-[0.75rem] mt-[2.25rem] ">
            <button
              type="button"
              className="w-[7.55rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5] pointer-events-auto"
              title="確認"
              onClick={handleConfirm}
            >
              確認
            </button>
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Toast;
