import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import HeaderNurseAides from "../../layouts/HeaderNurseAides";
import Pairing3NureAides from "../../components/Pairing/Pairing3NureAides";

/**
 * 選擇身分
 * @returns
 */
const Pairing3NureAidePanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <HeaderNurseAides />
        <Pairing3NureAides />
      </div>
    </>
  );
};

export default Pairing3NureAidePanel;
