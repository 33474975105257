import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next"; //語系
import { useNavigate } from "react-router-dom";
import { useToast } from "../contexts/ToastContext";
import { useMyUserContext } from "../contexts/MyUserContext";
import { apiChangeHeadshotFile, apiDownloadFile } from "../utils/Api";

const HeadshotModule = ({
  showModule,
  handleClose,
  dataCallback,
  headshotURL,
}) => {
  const { showToast } = useToast();
  const oldHeadshotRef = useRef({ file: null, fileURL: "" });
  const [headShotFile, setHeadShotFile] = useState({
    file: null,
    fileURL: "",
  });

  // # region 初始載入
  useEffect(() => {
    if (headshotURL !== "" && headshotURL !== null) {
      setHeadShotFile((prev) => ({ ...prev, fileURL: headshotURL }));
      oldHeadshotRef.current = { file: null, fileURL: headshotURL };
    }
  }, [headshotURL]);
  //   #endregion

  // #region 更換大頭照
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      // 檢查文件類型是否為 jpg、png、jpeg
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        showToast("error", "僅能上傳 jpg、png、jpeg 檔");
        return;
      }
    }

    const imageUrl = URL.createObjectURL(file);
    console.log(imageUrl);
    if (imageUrl) {
      oldHeadshotRef.current = {
        file: file,
        fileURL: imageUrl,
      };
      setHeadShotFile({
        file: file,
        fileURL: imageUrl,
      });
    }
  };
  // #end region

  //   取消
  const handleCloseClickd = () => {
    const { file, fileURL } = oldHeadshotRef.current;
    handleClose();
    setHeadShotFile({ file: file, fileURL: fileURL });
  };
  // #end region

  //   #region 移除檔案
  const handleRemoveFile = async () => {
    setHeadShotFile({ file: null, fileURL: "" });
  };
  // #end region

  //   #region確認
  const handleConfirm = async () => {
    const formData = new FormData();
    console.log(headShotFile);
    formData.append("Headshot", headShotFile.file);

    const downloadFileResponse = await apiChangeHeadshotFile(formData);
    console.log(downloadFileResponse);

    if (downloadFileResponse && downloadFileResponse.code === 0) {
      dataCallback(downloadFileResponse.data);
      console.log("更換大頭貼確認後有進去");
    } else {
      showToast(
        "error",
        downloadFileResponse ? downloadFileResponse.message : "網路連線錯誤"
      );
    }
  };
  // #end region

  return (
    <Modal
      show={showModule}
      className="modal fade ProjectModal"
      id="MenuModal"
      tabIndex="-1"
      aria-labelledby="MenuModalLabel"
      aria-hidden="true"
      backdrop="static"
    >
      <div className="modal-body accept">
        <div className="modal-content accept ">
          <div
            className="bottomFormSection items-center"
            style={{ height: "33.3333%" }}
          >
            <div
              className="InspireDropzone !w-[100%] !h-[300px] mb-6 max-w-[300px] "
              id="identityCardFront"
              style={{
                overflow: "hidden",
                position: "relative",
              }}
              onClick={() => document.getElementById("upload-front")?.click()}
            >
              {headShotFile.fileURL ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "10px",
                      width: "36px",
                      height: "36px",
                    }}
                    onClick={(e) => handleRemoveFile("identityCardFront")}
                  >
                    <img src="/img/times.png" alt="刪除大頭照預覽" />
                  </div>
                  <img src={headShotFile.fileURL} alt="大頭照預覽" />
                </>
              ) : (
                <>
                  <div>
                    <span>點擊上傳大頭照{headShotFile.fileURL}</span>
                    <span>僅能上傳jpg、png、jpeg檔</span>
                    <span>大頭照尺寸請以400x400大小</span>
                  </div>
                  <input
                    type="file"
                    id="upload-front"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)}
                  />
                </>
              )}
            </div>
            <div className="flex items-center justify-center gap-[4rem] mt-[2.25rem] pb-[3.25rem]">
              <button
                type="button"
                className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                title="取消"
                onClick={handleCloseClickd}
              >
                取消
              </button>
              <button
                type="button"
                className="w-[6.25rem] py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] text-center font-bold leading-[1.5]"
                title="確定"
                onClick={handleConfirm}
              >
                確定
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Header = () => {
  const { showToast } = useToast();
  const iseEffectcalledRef = useRef(0);
  const navigate = useNavigate(); //跳轉Router
  const { myUser } = useMyUserContext();
  const [showModule, setshowModule] = useState(false);
  const [headshotURL, setHeadshotURL] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          myUser &&
          myUser.headshotFilePath !== null &&
          myUser.headshotFilePath !== undefined
        ) {
          console.log(myUser.headshotFilePath);

          const params = encodeURI(myUser.headshotFilePath);
          const downloadFileResponse = await apiDownloadFile(params);
          if (downloadFileResponse !== null) {
            const url = URL.createObjectURL(downloadFileResponse.data);
            setHeadshotURL(url);
            console.log("初始進入沒問題");
          } else {
            showToast(
              "error",
              downloadFileResponse
                ? downloadFileResponse.message
                : "網路連線錯誤"
            );
          }
        }
      } catch (e) {
        console.log(e);
        showToast("error", e.message);
      }
    };
    // 因為myUser會比較慢拿到資料，所以才限制兩次而非一次
    if (iseEffectcalledRef.current < 2) {
      iseEffectcalledRef.current += 1;
      fetchData();
    }
  }, [myUser, showToast]);

  const handleGotoChoose = () => {
    localStorage.removeItem("PersonnelType");
    navigate("/Choose");
  };

  // #region  開啟module
  const headShotModaleClick = () => {
    setshowModule(true);
  };
  //   #end region

  // #region  關閉module
  const handleClose = () => {
    setshowModule(false);
  };
  //   #end region

  //   #region 大頭照資料回傳處理
  const headShotDataCallback = async (filePath) => {
    console.log(filePath);
    const params = encodeURI(filePath);
    if (filePath !== null) {
      const downloadFileResponse = await apiDownloadFile(params);
      if (downloadFileResponse !== null) {
        // console.log(encodeURI(downloadFileResponse));
        const url = URL.createObjectURL(downloadFileResponse.data);
        console.log(url);
        setHeadshotURL(url);
        handleClose();
      } else {
        showToast(
          "error",
          downloadFileResponse ? downloadFileResponse.message : "網路連線錯誤"
        );
      }
    } else {
      setHeadshotURL(null);
    }
  };
  //   #end region

  return (
    <>
      <header style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <div style={{ display: "inline-block" }}>
          <a
            href={void 0}
            className="logo"
            title="馬上就來"
            onClick={handleGotoChoose}
            style={{ display: "inline-block", cursor: "pointer" }}
          ></a>
          {/* <a href="#" style={{ display: "inline-block" }} className="m-5 flex flex-col justify-center gap-[0.375rem] md:pr-[calc(((100vw-37.5rem)/2)-2.0625rem-1.25rem)]" title="Nurse Profile"> */}
          <div
            style={{ display: "inline-block" }}
            className="m-5 flex flex-col justify-center gap-[0.375rem]"
            title="Nurse Profile"
          >
            <div
              className="w-[3.75rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden cursor-pointer"
              onClick={headShotModaleClick}
            >
              <span className="img-container">
                <img
                  style={{ objectFit: "cover" }}
                  src={headshotURL ? headshotURL : "./img/user.png"}
                  width="60"
                  height="60"
                  alt="user avator"
                />
              </span>
            </div>
            <p className="text-primary text-[1.125rem] font-bold tracking-[0] text-center">
              照服員，您好！
            </p>
          </div>
        </div>
        <HeadshotModule
          showModule={showModule}
          headshotURL={headshotURL}
          handleClose={handleClose}
          dataCallback={headShotDataCallback}
        />
      </header>
    </>
  );
};

export default Header;
