import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import ProgressPairPanel from "../../components/Pairing/ProgressPairPanel";

/**
 * 進行中案件
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <ProgressPairPanel />
      </div>
    </>
  );
};

export default InfoPanel;
