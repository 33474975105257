import React, { createContext, useContext, useState } from 'react';

export const MyUserContext = createContext();

export const MyUserProvider = ({ children }) => {
    const [myUser, setMyUser] = useState(null);

    return (
        <MyUserContext.Provider value={{ myUser, setMyUser }}>
            {children}
        </MyUserContext.Provider>
    );
};

export const useMyUserContext = () => useContext(MyUserContext);