import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useParams } from "react-router-dom";
import HamburgerFamilyModal from "../HamburgerModal";
import { apiDownloadFile, apiGetProgressPairSigninTime } from "../../utils/Api";
import dayjs from "dayjs";

/**
 * 進行中的詳細資料
 */
const ProgressPairDetail = () => {
  const { careCaseId, id } = useParams();
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError"));
  const iseEffectcalledRef = useRef(false);
  const { showToast } = useToast();
  const [nurseAidesDetail, setNurseAidesDetail] = useState({
    nurseAidesId: 0,
    nurseAidesGender: "",
    nurseAidesAge: 0,
    nurseAidesName: "",
    signinRecordTimeAll: [
      {
        signinTime: "",
        signOutTime: "",
      },
    ],
  });
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          CareCaseId: parseInt(careCaseId),
          nurseAidesId: parseInt(id),
        };
        const progressPairSigninTimeResponse =
          await apiGetProgressPairSigninTime(params);

        if (
          progressPairSigninTimeResponse &&
          progressPairSigninTimeResponse.code === 0
        ) {
          console.log(progressPairSigninTimeResponse.data);
          const data = progressPairSigninTimeResponse.data;
          let signinTime = "";
          let signOutTime = "";
          if (data.signinRecordTimeAll) {
            signinTime = dayjs(data.signinRecordTimeAll.signinTime).format(
              "YYYY/MM/DD HH:mm"
            );
            signOutTime = dayjs(data.signinRecordTimeAll.signOutTime).format(
              "YYYY/MM/DD HH:mm"
            );
          }

          const param = encodeURI(data.headshotFilePath);
          const downloadFile = await apiDownloadFile(param);
          let fileURL = "";
          if (downloadFile) {
            fileURL = URL.createObjectURL(downloadFile.data);
          }
          setNurseAidesDetail({
            ...data,
            fileURL: fileURL,
            signinRecordTimeAll: {
              signinTime: signinTime,
              signOutTime: signOutTime,
            },
          });
        } else {
          showToast(
            "error",
            progressPairSigninTimeResponse
              ? progressPairSigninTimeResponse.message
              : tRef.current
          );
        }
      } catch (e) {
        console.log(e);
        showToast("error", e.message);
      }
    };

    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      fetchData();
    }
  }, [careCaseId, id, showToast]);

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion
  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-start justify-between gap-[0.625rem]"
            action="./pairing2.html"
          >
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              進行中案件
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <div className="flex flex-col items-center gap-[1.5625rem] mt-[2.6875rem]">
            <div className="flex flex-col gap-[1.875rem]">
              <div className="w-[11.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
                <img
                  src={
                    nurseAidesDetail.fileURL
                      ? nurseAidesDetail.fileURL
                      : "./img/user.png"
                  }
                  width="178"
                  height="178"
                  alt="user avator"
                  className="img-pairing-case"
                />
              </div>
              <span className="text-primary text-[1.25rem] text-center leading-[1.5]">
                {nurseAidesDetail.nurseAidesName}(
                {nurseAidesDetail.nurseAidesGender}/
                {nurseAidesDetail.nurseAidesAge}歲)
              </span>
            </div>
            <div className="flex flex-col items-center gap-[0.8125rem]">
              <b className="pl-[0.625rem] text-primary text-[1.5rem] text-center leading-[1.5]">
                照服員打卡時間
              </b>
              <ul
                className="text-primary text-[0.9375rem] lg:text-base leading-[1.5]"
                style={{ listStyleType: "disc", listStylePosition: "inside" }}
              >
                <li>
                  上班:{" "}
                  {nurseAidesDetail.signinRecordTimeAll.signinTime
                    ? nurseAidesDetail.signinRecordTimeAll.signinTime
                    : "尚未打卡"}
                  ， 下班:{" "}
                  {nurseAidesDetail.signinRecordTimeAll.signOutTime
                    ? nurseAidesDetail.signinRecordTimeAll.signOutTime
                    : "尚未打卡"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default ProgressPairDetail;
