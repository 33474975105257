import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import {
  CreateValidator,
  ValidateFields,
  ValidateField,
} from "../../utils/Validator";
import { useMyUserContext } from "../../contexts/MyUserContext";

import { apiGetAllCity, GetDistsByCityId } from "../../utils/Api";

/**
 * 申請照服員-主要資料(step1)
 */
const InfoPanel = ({
  onNextStep,
  onChangeCreateNurseAides,
  onChangeMainServiceCityData,
  onChangeMainServiceDistData,
  onChangeSubServiceCityData,
  onChangeSubServiceDistData,
  createNurseAides,
}) => {
  const { myUser } = useMyUserContext();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const validator = CreateValidator();
  const [cities, setCities] = useState([]); //縣市
  const [towns, setTowns] = useState([]); //地區
  const [mainServiceTowns, setMainServiceTowns] = useState([]); //主要服務地區
  const [subServiceTowns, setSubServiceTowns] = useState([]); //次要服務地區

  // 拿到現在的時間
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const [birthdayYear, setBirthdayYear] = useState(currentyear.toString()); //生日
  const [birthdayMonth, setBirthdayMonth] = useState(currentMonth.toString()); //生日
  const [birthdayDay, setBirthdayDay] = useState(currentDay.toString()); //生日
  const [dayOptions, setDayOptions] = useState([]);

  const [errors, setErrors] = useState({
    height: "",
    weight: "",
    mail: "",
    identityNumber: "",
  });

  //#region 初始載入 獲取家屬註冊填寫的資訊
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllCity();

      // 從 myUser 中取得生日並設置到生日欄位中
      if (myUser && myUser.birthday) {
        const splitBirthday = myUser.birthday.split("/");
        setBirthdayYear(splitBirthday[0]);
        setBirthdayMonth(parseInt(splitBirthday[1]));
        setBirthdayDay(parseInt(splitBirthday[2]));
      }
    };

    fetchData();
    return () => {};
  }, [myUser, createNurseAides]);
  //#endregion

  //#region 取得縣市
  const refreshAllCity = async () => {
    const getAllCityResponse = await apiGetAllCity();
    if (getAllCityResponse && getAllCityResponse.code === 0) {
      setCities(getAllCityResponse.data.result);
    } else {
      showToast(
        "error",
        getAllCityResponse ? getAllCityResponse.message : "取得縣市失敗"
      );
    }
  };
  //#endregion

  useEffect(() => {
    const fetchData = async () => {
      if (cities.length > 0 && myUser) {
        var params = {
          cityId: parseInt(myUser.cityId),
        };
        const getDistsResponse = await GetDistsByCityId(params);
        if (getDistsResponse && getDistsResponse.code === 0) {
          // 更新 towns
          setTowns(getDistsResponse.data.result);
        } else {
          showToast(
            "error",
            getDistsResponse ? getDistsResponse.message : "取得城市失敗"
          );
        }
      }
    };
    fetchData();
    return () => {};
  }, [cities, myUser]);

  //#region 生日相關
  const currentYear = new Date().getFullYear();
  const startYear = 1911;

  const yearOptions = [];
  yearOptions.push(
    <option key="" value="">
      請選擇年份
    </option>
  );
  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const monthOptions = [];
  monthOptions.push(
    <option key="" value="">
      請選擇月份
    </option>
  );
  for (let month = 1; month <= 12; month++) {
    monthOptions.push(
      <option key={month} value={month}>
        {month}
      </option>
    );
  }

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const updateDayOptions = () => {
    if (birthdayYear && birthdayMonth) {
      const daysInSelectedMonth = getDaysInMonth(birthdayYear, birthdayMonth);
      const updatedDayOptions = [];
      updatedDayOptions.push(
        <option key="" value="">
          請選擇日期
        </option>
      );
      for (let day = 1; day <= daysInSelectedMonth; day++) {
        updatedDayOptions.push(
          <option key={day} value={day}>
            {day}
          </option>
        );
      }
      setDayOptions(updatedDayOptions);
    }
  };

  useEffect(() => {
    updateDayOptions();
  }, [birthdayYear, birthdayMonth]);
  //#endregion

  //#region 主要服務縣市切換
  const handleMainServiceCityChange = async (cityId) => {
    onChangeMainServiceCityData(parseInt(cityId));
    // 如果 cityId 是空字串，則不進行 API 請求並清空地區下拉選單
    if (!cityId) {
      setMainServiceTowns([]);
      onChangeMainServiceDistData("");
      return;
    }
    var params = {
      cityId: parseInt(cityId),
    };
    const getDistsResponse = await GetDistsByCityId(params);
    if (getDistsResponse && getDistsResponse.code === 0) {
      // 更新 towns
      setMainServiceTowns(getDistsResponse.data.result);
    } else {
      showToast(
        "error",
        getDistsResponse ? getDistsResponse.message : "取得城市失敗"
      );
    }
  };
  //#endregion

  //#region 次要服務縣市切換
  const handleSubServiceCityChange = async (cityId) => {
    if (!cityId) {
      onChangeSubServiceCityData(0);
      setSubServiceTowns([]);
      onChangeSubServiceDistData(0);
      return;
    }
    onChangeSubServiceCityData(parseInt(cityId));
    var params = {
      cityId: parseInt(cityId),
    };
    const getDistsResponse = await GetDistsByCityId(params);
    if (getDistsResponse && getDistsResponse.code === 0) {
      setSubServiceTowns(getDistsResponse.data.result);
    } else {
      // showToast(
      //     "error",
      //     getDistsResponse ? getDistsResponse.message : "取得城市失敗"
      // );
    }
  };
  //#endregion

  //#region 渲染服務區域localStorage資料的下拉式選單
  // 組件初次加載時還未取得 mainServiceTowns 的數據，導致下拉菜單沒有選項。 所以用useEffect 鉤子中預先加載相應的數據來解決這個問題。
  useEffect(() => {
    if (createNurseAides.mainServiceCityId) {
      handleMainServiceCityChange(createNurseAides.mainServiceCityId);
    }
  }, [createNurseAides.mainServiceCityId]);
  useEffect(() => {
    if (createNurseAides.subServiceCityId) {
      handleSubServiceCityChange(createNurseAides.subServiceCityId);
    }
  }, [createNurseAides.subServiceCityId]);
  //#endregion

  // 處理按鍵事件並阻止小數點輸入
  const handleKeyDown = (e) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  //#region 失去焦點Input的欄位
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    let newErrors = { ...errors };

    // 如果值包含小數點，移除小數點
    if (value.includes(".") && (name === "weight" || name === "height")) {
      const correctedValue = parseInt(value, 10);
      onChangeCreateNurseAides({
        ...createNurseAides,
        [name]: correctedValue,
      });
    }

    // 立即驗證空輸入並更新錯誤狀態
    if (value.trim() === "") {
      newErrors[name] = "此欄位為必填項目";
    } else {
      // 如果輸入不為空，則進行正常的驗證
      const validationResult = await ValidateField(
        name,
        value,
        newErrors,
        validator
      );

      // 檢查特定欄位的額外驗證規則，此為身分證號碼的格式驗證
      if (name === "identityNumber" && !validationResult.result) {
        // 如果驗證結果為 false，表示身分證號碼格式不正確
        newErrors[name] = "請輸入有效的身分證號碼";
      } else {
        // 驗證通過，清除錯誤訊息
        newErrors[name] = "";
      }
      // 檢查特定欄位的額外驗證規則，此為信箱格式驗證
      if (name === "mail" && !validator.check(value, "required|mail")) {
        // 如果驗證結果為 false，表示信箱格式不正確
        newErrors[name] = "請輸入有效的信箱";
      } else {
        // 驗證通過，清除錯誤訊息
        newErrors[name] = "";
      }

      // 檢查身高和體重的範圍
      if (name === "height") {
        if (value < 130 || value > 200) {
          newErrors[name] = "請輸入有效的數字，範圍在130到200之間";
        }
      } else if (name === "weight") {
        if (value < 30 || value > 150) {
          newErrors[name] = "請輸入有效的數字，範圍在30到150之間";
        }
      }
    }
    // 特別檢查主要服務區域的必填項目
    if (name === "mainServiceCityId" || name === "mainServiceDistId") {
      if (!value.trim()) {
        newErrors[name] = "此欄位為必填項目";
      } else {
        newErrors[name] = "";
      }
    }
    // 將更新後的錯誤狀態設置為組件的狀態
    setErrors(newErrors);
  };
  //#endregion

  //#region 欄位驗證
  const checkValidator = async () => {
    let newErrors = { ...errors };
    var validateFieldsResult = await ValidateFields(
      createNurseAides,
      newErrors,
      [
        "name",
        "gender",
        "birthday",
        "cityId",
        "distId",
        "address",
        "phone",
        "mainServiceCityId",
        "mainServiceDistId",
        "subServiceCityId",
        "subServiceDistId",
        "recommendCode",
        "bankCode",
        "bankBranchCode",
        "bankAccount",
        "bankBookFileId",
        "careCertificates",
        "identityCard",
      ]
    );

    if (!validator.check(createNurseAides.mail, "required|mail")) {
      newErrors.mail = "請輸入有效的信箱";
      showToast("error", "信箱格式錯誤");
    }

    if (!validateFieldsResult.result) {
      setErrors(newErrors);
      // 打印錯誤信息
      console.log("欄位驗證錯誤:", validateFieldsResult);
    }

    return validateFieldsResult.result && !newErrors.mail;
  };
  //#endregion

  //#region 下一步
  const handleSubmit = async (e) => {
    e.preventDefault();
    // 將 mainServiceDistId 和 subServiceDistId 轉成數字
    createNurseAides.mainServiceDistId = Number(
      createNurseAides.mainServiceDistId
    );
    createNurseAides.subServiceDistId = Number(
      createNurseAides.subServiceDistId
    );
    console.log("mainServiceCityId:", createNurseAides.mainServiceCityId);
    console.log("mainServiceDistId:", createNurseAides.mainServiceDistId);
    console.log("其他必填欄位:", createNurseAides);
    if (await checkValidator()) {
      // 檢查地址下拉框的值是否為空
      console.log("到這了", createNurseAides);
      const addressCity = createNurseAides.mainServiceCityId;
      const addressTown = createNurseAides.mainServiceDistId;
      if (!addressCity || !addressTown) {
        showToast("error", "請選擇完整地址");
        return; // 阻止表單提交
      }
      onNextStep();
    } else {
      console.log(createNurseAides);
      showToast("error", "有必填欄位未填寫或是格式錯誤");
    }
    console.log("身高", createNurseAides.height);
  };

  //#endregion

  return (
    <>
      <div className="content">
        <div className="applyHead">
          <h1>申請照服員</h1>
          <div>
            <div className="active">
              <svg
                width="15"
                height="19"
                viewBox="0 0 15 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5687 14.1364V18.9016H0.64416V14.1364H5.22108V5.69534C5.02735 5.95896 4.72707 6.22259 4.32023 6.49119C3.91339 6.75482 3.45328 7.0085 2.94957 7.25223C2.44587 7.49596 1.93248 7.68995 1.41909 7.82922C0.905699 7.97347 0.431054 8.04311 0 8.04311V3.14363C0.363248 3.14363 0.818518 3.02922 1.35128 2.79544C1.88889 2.56166 2.43134 2.27316 2.97863 1.92C3.52593 1.56684 4.01026 1.21368 4.42678 0.860517C4.84815 0.507356 5.10969 0.21886 5.21624 0H10.4712V14.1364H14.5638H14.5687Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.00484064 19.4686C0.00484064 18.4191 0.0871768 17.4839 0.247006 16.6533C0.406835 15.8276 0.678062 15.0715 1.051 14.3901C1.42877 13.7086 1.93248 13.0719 2.56695 12.48C3.20143 11.8881 4.00057 11.326 4.96923 10.7888C5.97663 10.2317 6.82421 9.7542 7.49743 9.36622C8.17065 8.97824 8.71795 8.64497 9.12478 8.37637C9.53162 8.10777 9.81737 7.84415 9.9772 7.59047C10.137 7.33181 10.2194 7.04829 10.2194 6.73492C10.2194 6.2972 10.1225 5.91917 9.92877 5.60083C9.73504 5.28249 9.45413 5.03378 9.09088 4.85969C8.72764 4.6856 8.27236 4.60104 7.7396 4.60104C7.30854 4.60104 6.90171 4.65575 6.51424 4.76518C6.12678 4.87461 5.75869 5.04373 5.40028 5.26259C5.04672 5.48145 4.70769 5.7401 4.38319 6.03855C4.05869 6.33699 3.73903 6.67523 3.41453 7.04829L0.15983 3.14363C0.523078 2.77057 0.992875 2.39254 1.56439 2.01948C2.13105 1.64643 2.7849 1.30819 3.51624 1.00974C4.24758 0.711295 5.04672 0.467565 5.91852 0.283523C6.79031 0.0945076 7.69601 0 8.64045 0C10.1855 0 11.4932 0.238757 12.5587 0.721244C13.6242 1.20373 14.433 1.87523 14.99 2.7457C15.547 3.61617 15.8279 4.6458 15.8279 5.82964C15.8279 6.59565 15.7165 7.26218 15.4889 7.8342C15.2613 8.40622 14.9658 8.91855 14.6026 9.36622C14.2393 9.81389 13.8228 10.2218 13.3627 10.5799C12.9026 10.938 12.4424 11.2663 11.992 11.5598C11.4786 11.8284 10.9943 12.092 10.5439 12.3507C10.0934 12.6093 9.67208 12.858 9.28461 13.0918C8.89715 13.3256 8.53875 13.5743 8.20456 13.833C7.87037 14.0916 7.55555 14.3801 7.25527 14.6935H16.1815V19.4587H0L0.00484064 19.4686Z"
                  fill="#9E3223"
                />
              </svg>
            </div>

            <div>
              <svg
                width="18"
                height="25"
                viewBox="0 0 18 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6379 11.3956C13.5387 11.5548 14.3282 11.9179 15.0063 12.4899C15.6843 13.062 16.2074 13.7733 16.5852 14.6139C16.963 15.4545 17.147 16.4046 17.147 17.464C17.147 18.8568 16.7934 20.0705 16.0815 21.1051C15.3743 22.1397 14.3621 22.9355 13.0496 23.4976C11.737 24.0597 10.192 24.3382 8.40968 24.3382C6.77748 24.3382 5.31965 24.0895 4.04102 23.5921C2.76239 23.0947 1.66296 22.3635 0.737885 21.3936L3.50826 17.6182C4.08945 18.2151 4.75299 18.6777 5.50854 19.006C6.25926 19.3393 7.14073 19.5034 8.15298 19.5034C9.37834 19.5034 10.2889 19.2696 10.8943 18.7971C11.4949 18.3246 11.7951 17.6083 11.7951 16.6433C11.7951 15.6087 11.4367 14.8278 10.7151 14.3005C9.99344 13.7733 8.8601 13.5096 7.31509 13.5096H6.34643V9.70446H7.41196C8.59373 9.70446 9.52849 9.43585 10.2162 8.89865C10.904 8.36145 11.2478 7.67503 11.2478 6.82943C11.2478 6.32705 11.1365 5.90425 10.9088 5.56601C10.6812 5.2228 10.3712 4.96912 9.97407 4.79503C9.57692 4.62591 9.09742 4.53637 8.54044 4.53637C7.68318 4.53637 6.88404 4.74528 6.15754 5.16311C5.42621 5.58093 4.81594 6.2027 4.32193 7.01845L1.06723 3.17845C1.54187 2.53679 2.19087 1.97969 3.01908 1.50715C3.84728 1.03461 4.78689 0.661555 5.83788 0.397928C6.88888 0.134301 7.98832 0 9.12649 0C10.6521 0 11.9889 0.248705 13.1367 0.741141C14.2846 1.23358 15.1758 1.92497 15.8102 2.80539C16.4447 3.69078 16.7595 4.73534 16.7595 5.94404C16.7595 6.81948 16.5852 7.64021 16.2461 8.41119C15.9023 9.18218 15.4179 9.83378 14.798 10.361C14.1732 10.8883 13.4564 11.2265 12.6379 11.3857V11.3956Z"
                  fill="#9E3223"
                />
              </svg>
            </div>
          </div>
        </div>
        <form
          className="flex flex-col gap-[1.875rem] mb-[6.5625rem] regisiterEnlarge"
          action="./careStep2.html"
        >
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="name"
            >
              姓名*
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="name"
                id="name"
                placeholder="｜請輸入姓名"
                value={createNurseAides.name}
                disabled
              />
            </div>
          </div>
          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="man"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              性別*
            </label>
            <div className="flex items-center gap-[0.75rem] grow">
              <div className="inline-flex items-center justify-center">
                <label
                  className="relative flex items-center p-1 rounded-full cursor-pointer"
                  htmlFor="man"
                >
                  <input
                    id="man"
                    type="radio"
                    name="gender"
                    value="1"
                    checked={createNurseAides.gender == 1}
                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                    disabled
                  />
                  <span className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-primary text-[1.25rem] cursor-pointer select-none"
                  htmlFor="man"
                >
                  男
                </label>
              </div>
              <div className="inline-flex items-center justify-center">
                <label
                  className="relative flex items-center p-1 rounded-full cursor-pointer"
                  htmlFor="woman"
                >
                  <input
                    id="woman"
                    type="radio"
                    name="gender"
                    value="2"
                    checked={createNurseAides.gender == 2}
                    className="before:content[''] peer relative h-4 w-4 bg-white cursor-pointer appearance-none border-[0.125rem] border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                    disabled
                  />
                  <span className="absolute text-white opacity-0 pointer-events-none transition-opacity top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-primary text-[1.25rem] cursor-pointer select-none"
                  htmlFor="woman"
                >
                  女
                </label>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="year"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              生日*
            </label>
            <div className="flex items-center grow">
              <div className="grid grid-cols-3 gap-[1.125rem]">
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayYear"
                    value={birthdayYear}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    disabled
                  >
                    {yearOptions}
                  </select>
                </div>
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayMonth"
                    value={birthdayMonth}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    disabled
                  >
                    {monthOptions}
                  </select>
                </div>
                <div className="form-group col-md-4 col-4">
                  <select
                    name="birthdayDay"
                    value={birthdayDay}
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    disabled
                  >
                    {dayOptions}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              htmlFor="address"
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
            >
              住址*
            </label>
            <div className="flex items-center grow">
              <div className="w-full grid grid-cols-2 gap-[1.125rem]">
                <select
                  id="addressCity"
                  name="addressCity"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  aria-label="縣市"
                  value={createNurseAides.cityId}
                  disabled
                >
                  <option value="">請選擇</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <select
                  id="addressTown"
                  name="addressTown"
                  className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  aria-label="地區"
                  value={createNurseAides.distId}
                  disabled
                >
                  <option value="">請選擇</option>
                  {towns.map((town) => (
                    <option key={town.id} value={town.id}>
                      {town.name}
                    </option>
                  ))}
                </select>

                <div className="col-span-2">
                  <input
                    type="text"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    name="address"
                    id="address"
                    placeholder="Ex:河南路2段"
                    value={createNurseAides.address}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="name"
            >
              身高*
            </label>
            <div className="flex items-center grow">
              <input
                type="number"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="height"
                id="height"
                placeholder="｜單位為公分，請輸入130~200的整數"
                value={createNurseAides.height}
                onChange={onChangeCreateNurseAides}
                min={130}
                max={200}
                step="1" // 助防止小數點輸入
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {errors.height && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              請輸入正確的身高
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[2.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="name"
            >
              體重*
            </label>
            <div className="flex items-center grow">
              <input
                type="number"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="weight"
                id="weight"
                placeholder="｜單位為公斤，請輸入30~150的整數"
                value={createNurseAides.weight}
                onChange={onChangeCreateNurseAides}
                min={30}
                max={150}
                step="1" // 防止小數點輸入
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {errors.weight && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              請輸入正確的體重
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="phone"
            >
              手機號碼*
            </label>
            <div className="flex items-center grow">
              <input
                type="tel"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] disabled:bg-[#e3e3e3]"
                name="phone"
                id="phone"
                placeholder="Ex:0987654321"
                value={createNurseAides.phone}
                disabled
              />
            </div>
          </div>

          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="mail"
            >
              信箱*
            </label>
            <div className="flex items-center grow">
              <input
                type="mail"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="mail"
                id="mail"
                placeholder="｜請輸入信箱"
                value={createNurseAides.mail}
                onChange={onChangeCreateNurseAides}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {errors.mail && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              請輸入有效的信箱格式
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="IDNumber"
            >
              身分證號*
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                name="identityNumber"
                id="identityNumber"
                placeholder="Ex:A123456789"
                value={createNurseAides.identityNumber}
                onChange={onChangeCreateNurseAides}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          {errors.identityNumber && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              請輸入有效的身分證號碼
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="otherServiceCountry"
            >
              主要服務區域*
            </label>
            <div className="flex items-center grow">
              <div className="flex items-center grow">
                <div className="w-full grid grid-cols-1 gap-[0.5rem]">
                  <select
                    id="mainServiceCityId"
                    name="mainServiceCityId"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    aria-label="縣市"
                    onChange={(e) => {
                      handleMainServiceCityChange(e.target.value); // 這裡觸發主要服務區域城市的變動
                      onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                    }}
                    value={createNurseAides.mainServiceCityId}
                    onBlur={handleBlur}
                  >
                    <option value="">請選擇</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <select
                    id="mainServiceDistId"
                    name="mainServiceDistId"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    aria-label="地區"
                    onChange={(e) => {
                      onChangeMainServiceDistData(parseInt(e.target.value));
                      onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                    }}
                    value={createNurseAides.mainServiceDistId}
                    onBlur={handleBlur}
                  >
                    <option value="">請選擇</option>
                    {mainServiceTowns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {(errors.mainServiceCityId || errors.mainServiceDistId) && (
            <div className="text-red-500 text-sm mt-1 ml-1 error-message">
              請填寫完整的地址
            </div>
          )}
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="otherServiceCountry"
            >
              其他服務區域
            </label>
            <div className="flex items-center grow">
              <div className="flex items-center grow">
                <div className="w-full grid grid-cols-1 gap-[0.5rem]">
                  <select
                    id="subServiceCityId"
                    name="subServiceCityId"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    aria-label="縣市"
                    onChange={(e) => {
                      handleSubServiceCityChange(e.target.value);
                      onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                    }}
                    value={createNurseAides.subServiceCityId || 0} // 確保初始值為0
                  >
                    <option value="0">請選擇</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <select
                    id="subServiceDistId"
                    name="subServiceDistId"
                    className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                    aria-label="地區"
                    onChange={(e) => {
                      onChangeSubServiceDistData(parseInt(e.target.value));
                      onChangeCreateNurseAides(e); // 這裡觸發將值存入localStorage
                    }}
                    value={createNurseAides.subServiceDistId || 0} // 確保初始值為0
                  >
                    <option value="0">請選擇</option>
                    {subServiceTowns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[0.5rem]">
            <label
              className="w-[4.5em] flex-none text-primary text-[1.25rem] font-medium leading-[1.5]"
              htmlFor="name"
            >
              推薦碼
            </label>
            <div className="flex items-center grow">
              <input
                type="text"
                className="w-full min-w-0 p-[0.4375rem] bg-white border-[0.125rem] border-primary text-primary text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)] disabled:bg-[#e3e3e3]"
                name="recommendCode"
                id="recommendCode"
                placeholder="請輸入您的推薦碼(非必填)"
                value={createNurseAides.recommendCode}
                onChange={onChangeCreateNurseAides}
              />
            </div>
          </div>

          <div className="flex items-center justify-end gap-[0.75rem]">
            <button
              type="submit"
              className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
              title="下一步"
              onClick={handleSubmit}
            >
              下一步
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default InfoPanel;
