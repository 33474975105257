import React, { useState, useEffect } from "react";
import ChooseHospital from "./ChooseHospital"
import PatientInfo from "./PatientInfo"
import FamilyInfo from "./FamilyInfo"
import ChoosePlan from "./ChoosePlan"
import CaseConfirm from "./CaseConfirm"

/**
 * 找照服員(醫院看護) - 資料顯示頁面
 */
const FindCareStepAll = () => {
    const [currentStep, setCurrentStep] = useState(1); //步驟
    const initialData = {
        caseName: "", //被照顧者姓名
        cityId: 0,
        distId: 0,
        address: "",
        gender: 0,
        height: 0,
        weight: 0,
        age: 0,
        symptoms: [],
        tubes: [],
        nurseAidesGender: 2,
        languages: [], //溝通語言
        careServiceItems: [], //需求項目
        bedKind: [], //床位種類
        subFamilyName: "",
        subFamilyGender: -1,
        subFamilyPhone: "",
        subFamilyMail: "",
        forNurseAidesContent: "",
        planId: 0,
        serviceDates: [],
        serviceTime: "",
        headshot:null
    };
    // 初始化資料，如果本身就有申請過則直接取得
    const [createCareCase, setCreateCareCase] = useState(() => {
        const savedData = localStorage.getItem('createCareCase');
        return savedData ? JSON.parse(savedData) : initialData;
    });

    //有變更資料就存到localStorage
    useEffect(() => {
        localStorage.setItem('createCareCase', JSON.stringify(createCareCase));
    }, [createCareCase]);

    const [careServiceItems, setCareServiceItems] = useState([]); //服務需求列表(為了共用)
    const [plans, setPlans] = useState([]); //照顧方案列表(為了共用)

    //#region 下一頁
    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    //#endregion

    //#region 上一頁
    const handlePreviousStep = (e) => {
        e.preventDefault();
        setCurrentStep(currentStep - 1);
    };

    const handlePreviousStepNoE = () => {
        setCurrentStep(currentStep - 1);
    };
    //#endregion

    //#region 選擇醫院
    const handleChangeHospitalId = (hospitalId) => {
        setCreateCareCase({ ...createCareCase, "hospitalId": hospitalId });
    }
    //#endregion

    //#region 被照顧者基本資料
    const handleChangeCaseData = (event) => {
        const { name, value } = event.target;
        setCreateCareCase({ ...createCareCase, [name]: value });
    };
    //#endregion

    //#region 被照顧者病症Select2
    const handleChangeCaseSymptomsData = (value) => {
        setCreateCareCase({ ...createCareCase, "symptoms": value });
    };
    //#endregion

    //#region 被照顧者管線Select2
    const handleChangeCaseTubesData = (value) => {
        setCreateCareCase({ ...createCareCase, "tubes": value });
    };
    //#endregion

    //#region 被照顧者語言Select2
    const handleChangeCaseLanguagesData = (value) => {
        setCreateCareCase({ ...createCareCase, "languages": value });
    };
    //#endregion

    //#region 被照顧者床位種類Select2
    const handleChangeBedKindData = (value) => {
        setCreateCareCase({ ...createCareCase, "bedKind": value });
    }
    //#endregion

    //#region 被照顧者需求項目Select2
    const handleChangeCaseCareServiceItemsData = (value) => {
        setCreateCareCase({ ...createCareCase, "careServiceItems": value });
    };
    //#endregion
    //#region 修改被照顧者照片
    const handleChangeCaseHeadshot = (value)=>{
        setCreateCareCase({ ...createCareCase, "headshot":value});
    }
    //#endregion

    //#region 選擇照顧方案
    const handleChangePlan = (planId, behaviorMode, serviceDates, serviceTime) => {
        // 更新 createCareCase 对象
        setCreateCareCase({
            ...createCareCase,
            "planId": planId,
            "serviceDates": serviceDates,
            "serviceTime": serviceTime,
            "behaviorMode": behaviorMode
        });
    }
    //#endregion

    return (
        <div className=" main-background normal">
            {/* 根據當前步驟狀態渲染不同的子元件 */}
            {currentStep === 1 && (
                <FamilyInfo
                    onNextStep={handleNextStep}
                    onChangeCaseData={handleChangeCaseData}
                    createCareCase={createCareCase} />
            )}
            {currentStep === 2 && (
                <ChooseHospital
                    onNextStep={handleNextStep}
                    onPreviousStep={handlePreviousStep}
                    onChangeHospitalId={handleChangeHospitalId}
                    createCareCase={createCareCase}
                />
            )}
            {currentStep === 3 && (
                <PatientInfo
                    onNextStep={handleNextStep}
                    onPreviousStep={handlePreviousStep}
                    onChangeCaseData={handleChangeCaseData}
                    onChangeCaseSymptomsData={handleChangeCaseSymptomsData}
                    onChangeCaseTubesData={handleChangeCaseTubesData}
                    onChangeCaseBedKindData={handleChangeBedKindData}
                    onChangeCaseLanguagesData={handleChangeCaseLanguagesData}
                    onChangeCaseCareServiceItemsData={handleChangeCaseCareServiceItemsData}
                    createCareCase={createCareCase}
                    onChangeHeadshot = {handleChangeCaseHeadshot}
                    setParentCareServiceItems={setCareServiceItems} />
            )}
            {currentStep === 4 && (
                <ChoosePlan
                    onNextStep={handleNextStep}
                    onPreviousStep={handlePreviousStep}
                    onChangePlan={handleChangePlan}
                    createCareCase={createCareCase}
                    setParentPlans={setPlans} />
            )}
            {currentStep === 5 && (
                <CaseConfirm
                    onPreviousStep={handlePreviousStep}
                    onPreviousStepNoE={handlePreviousStepNoE}
                    createCareCase={createCareCase}
                    careServiceItems={careServiceItems}
                    plans={plans}
                    type={'hospital'}
                />
            )}
        </div>
    );
};

export default FindCareStepAll;
