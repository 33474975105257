import { Link } from "react-router-dom";
import Line from "../../layouts/Line";

const MailVerificationFail = () => {
  return (
    <>
      <div className=" main-background normal">
        <div className="content">
          <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">照服員審核</h1>
          <div className="flex items-center gap-[0.625rem] mt-[1.6875rem]">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_32_1151)">
                <path d="M35 35H0V0H32.5864V4.82724H4.82724V30.1728H30.1728V8.93069H35V35Z" fill="#FF0000" />
                <path
                  d="M24.6208 7.20861L7.14258 24.3484L10.5224 27.795L28.0006 10.6552L24.6208 7.20861Z"
                  fill="#FF0000"
                />
                <path
                  d="M10.5074 7.18913L7.15833 10.6656L12.3746 15.6906L15.7236 12.2141L10.5074 7.18913Z"
                  fill="#FF0000"
                />
                <path
                  d="M22.6261 19.3072L19.2771 22.7838L24.4933 27.8087L27.8424 24.3322L22.6261 19.3072Z"
                  fill="#FF0000"
                />
              </g>
              <defs>
                <clipPath id="clip0_32_1151">
                  <rect width="35" height="35" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-primary text-[1.25rem] leading-[1.5]">審核不通過</span>
          </div>
          <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
            您的照服員審核未通過！您可以修正資訊後再次送件！
          </p>
          <div className="flex flex-col items-center justify-center gap-[0.625rem] mt-[1.5625rem]">
            <Link to={'/CareStep'} state={{ "reApply": true }}>
              <butom
                className="min-w-[8.3125rem] mx-a py-1 px-[1.125rem] bg-primary rounded-full text-white text-[1.5rem] font-bold text-center leading-[1.5]"
                title="重新申請照服員"
              >
                重新申請照服員
              </butom>
            </Link>
          </div>
        </div>
      </div>
      <Line />
    </>
  )
}

export default MailVerificationFail;