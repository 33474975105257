import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; //語系
import { apiCheckUserNurseAidesStatus } from "../../utils/Api";
import { useToast } from "../../contexts/ToastContext";

const IconComponent = () => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const navigate = useNavigate(); //跳轉Router

    //#region 初始載入
    useEffect(() => {
        const fetchData = async () => {

        };
        fetchData();
        return () => { };
    }, []);
    //#endregion

    const ChoosePersonnelType = async () => {
        //判斷是否有照服員身份
        var checkUserNurseAidesStatusResponse = await apiCheckUserNurseAidesStatus();
        if (checkUserNurseAidesStatusResponse && checkUserNurseAidesStatusResponse.code == 0) {
            switch (checkUserNurseAidesStatusResponse.data.userNurseAidesStatus) {
                case 0: //未申請
                    localStorage.setItem("PersonnelType", "Family");
                    navigate("/CareStep");
                    break;
                case 1: //未驗證信箱
                    navigate("/CareStep", {
                        state: {
                            currentStep: 4
                        }
                    });
                    break;
                case 2: //審核中
                    showToast(
                        "warning",
                        "管理員審核中"
                    );
                    break;
                case 3: //未通過
                    navigate("/ReviewFail");
                    break;
                case 4: //審核通過
                    localStorage.setItem("PersonnelType", "NurseAides");
                    navigate("/NurseAides");
                    break;
                default:
                    showToast(
                        "error",
                        "照服員狀態異常"
                    );
                    break;
            }
        } else {
            showToast(
                "error",
                checkUserNurseAidesStatusResponse
                    ? checkUserNurseAidesStatusResponse.message
                    : t("toast.unknownError")
            ); // [回傳的訊息] : 網路連線錯誤
        }



    }

    return (
        // 照服員的首頁還沒做出來，先連結找案件
        <a href={void (0)} onClick={ChoosePersonnelType} className="block w-full max-w-[16.5625rem] md:flex-1 md:max-w-none" title="我是照護員">
            <svg
                className="w-full lg:h-[24.0625rem]"
                width="265"
                height="253"
                viewBox="0 0 265 253"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M227.241 252.15H37.759C16.938 252.15 0 235.115 0 214.174V69.3471C0 54.314 8.8357 40.6705 22.5108 34.5952L24.2291 38.5076C12.0915 43.9023 4.25134 56.0065 4.25134 69.3507V214.177C4.25134 232.759 19.2834 247.878 37.759 247.878H227.238C245.713 247.878 260.745 232.759 260.745 214.177V69.3471C260.745 50.765 245.713 35.6463 227.238 35.6463H119.82V31.3705H227.238C248.058 31.3705 264.996 48.4062 264.996 69.3471V214.174C264.996 235.115 248.058 252.15 227.238 252.15H227.241Z"
                    fill="#9E3223"
                />
                <path
                    d="M35.0841 242.337L2.12561 200.523L18.2771 187.646L33.9114 207.482L73.7004 149.32L90.681 161.075L35.0841 242.337Z"
                    fill="#9E3223"
                />
                <path
                    d="M83.5601 41.433C81.1971 44.7111 77.3709 46.8562 73.0487 46.8562C65.8888 46.8562 60.0609 40.9947 60.0609 33.7934C60.0609 26.5922 65.8888 20.7307 73.0487 20.7307C77.3744 20.7307 81.2007 22.8758 83.5601 26.1539H105.749C102.294 11.192 88.9593 0 73.0487 0C54.52 0 39.449 15.1579 39.449 33.7934C39.449 52.429 54.52 67.5869 73.0487 67.5869C88.9593 67.5869 102.294 56.3949 105.749 41.433H83.5601Z"
                    fill="#9E3223"
                />
                <path
                    d="M110.067 97.949C104.838 97.949 104.544 97.4038 102.259 93.1565C99.1093 87.3021 93.8341 77.5033 76.3257 77.5033C50.6441 77.5033 29.6035 88.845 20.0451 107.84C13.498 120.85 13.9692 135.142 21.2744 145.137C35.1125 164.071 53.1488 165.643 71.968 165.643C104.742 165.643 120.008 186.73 120.008 232.011V242.337H153.686V221.646H140.383C139.791 209.748 137.839 195.324 131.873 181.987C121.025 157.732 100.31 144.912 71.968 144.912C54.488 144.912 45.4184 143.173 37.883 132.861C35.2471 129.255 35.4668 123.109 38.4392 117.201C44.4158 105.321 58.5799 98.2305 76.3292 98.2305C81.5584 98.2305 81.8524 98.7756 84.1375 103.023C87.287 108.877 92.5622 118.676 110.071 118.676C118.403 118.676 147.5 109.405 153.76 106.536V83.0655C146.537 87.3235 117.992 97.949 110.071 97.949H110.067Z"
                    fill="#9E3223"
                />
                <path
                    d="M207.781 75.3832C206.817 77.1291 205.616 78.7005 204.224 80.1044C204.801 81.7649 205.467 82.7554 206.108 82.7554C206.558 82.7554 206.817 81.7221 206.923 79.178C207.48 79.7802 208.337 80.3645 209.024 80.6425C208.617 84.4123 207.823 85.3637 205.875 85.3637C204.309 85.3637 203.111 84.0703 202.19 81.9573C200.947 82.9906 199.597 83.8743 198.226 84.5869C197.861 83.9848 197.156 83.1011 196.532 82.5167C198.183 81.7613 199.834 80.7066 201.312 79.4773C200.84 77.7741 200.475 75.8143 200.22 73.7013H195.87V76.16C196.961 75.9675 198.077 75.7502 199.172 75.5578L199.363 77.8845L195.87 78.5972V82.2815C195.87 83.6819 195.569 84.3731 194.711 84.7829C193.875 85.1927 192.589 85.2996 190.853 85.2782C190.747 84.5656 190.318 83.404 189.953 82.6913C191.154 82.7554 192.398 82.7341 192.781 82.7127C193.167 82.7127 193.294 82.6058 193.294 82.2602V79.1352C191.923 79.4167 190.595 79.6947 189.457 79.912L188.837 77.3251C190.06 77.1327 191.601 76.894 193.294 76.5911V73.7049H189.245V71.2712H193.294V68.8126C192.136 69.005 190.96 69.1582 189.865 69.2865C189.759 68.663 189.415 67.7116 189.135 67.1308C192.136 66.721 195.455 66.0333 197.641 65.3207L199.356 67.4978C198.286 67.822 197.106 68.1 195.863 68.3601V71.2712H199.976C199.806 69.3328 199.739 67.3268 199.717 65.367H202.396C202.353 67.3695 202.438 69.3756 202.612 71.2712H208.44V73.7049H202.867C203.037 74.9983 203.253 76.249 203.487 77.3465C204.259 76.4201 204.964 75.4509 205.524 74.414L207.774 75.3832H207.781ZM206.215 70.6405C205.659 69.65 204.33 68.1178 203.171 67.0845L205.099 65.7696C206.236 66.7602 207.65 68.1819 208.27 69.1974L206.211 70.6405H206.215Z"
                    fill="#9E3223"
                />
                <path
                    d="M221.775 82.5167C222.331 82.5595 222.912 82.5809 223.489 82.5809C224.69 82.5809 228.803 82.5809 230.323 82.5167C229.98 83.0975 229.593 84.22 229.466 84.9754H223.358C219.436 84.9754 216.779 84.4159 214.98 81.9146C214.207 83.3363 213.222 84.4801 211.979 85.3424C211.614 84.8685 210.629 83.9206 210.052 83.5536C212.237 82.1961 213.545 79.912 214.037 77.0223L216.588 77.4107C216.439 78.1019 216.266 78.7469 216.095 79.3704C216.762 80.8349 217.81 81.6331 219.181 82.0642V76.5911H210.824V74.3285H229.725V76.5911H221.775V78.2088H228.375V80.4287H221.775V82.5203V82.5167ZM227.687 73.3771H212.794V65.8979H227.687V73.3771ZM225.094 67.6867H215.281V68.7841H225.094V67.6867ZM225.094 70.466H215.281V71.5884H225.094V70.466Z"
                    fill="#9E3223"
                />
                <path
                    d="M190.247 132.295C191.948 130.249 194.066 126.825 195.352 123.693L200.128 125.029C198.757 128.204 196.766 131.921 195.023 134.34L190.247 132.295ZM196.766 123.315H192.118V96.7589H205.322V107.074C210.012 105.781 211.674 103.775 212.422 100.978H206.526V96.6342H227.907C227.907 96.6342 227.865 97.7209 227.826 98.3053C227.493 103.985 227.121 106.615 226.164 107.659C225.374 108.535 224.421 108.87 223.298 108.995C222.26 109.12 220.393 109.162 218.522 109.077C218.441 107.823 217.984 106.194 217.318 105.111C218.894 105.236 220.265 105.278 220.931 105.278C221.512 105.278 221.845 105.197 222.178 104.862C222.55 104.487 222.802 103.358 223.007 100.978H217.279C216.407 105.321 214.413 108.411 208.85 110.374H226.621V122.817H207.773V110.709L207.692 110.752C207.277 109.708 206.282 108.204 205.325 107.285V121.356H196.773V123.319L196.766 123.315ZM196.766 101.102V106.529H200.794V101.102H196.766ZM200.794 116.927V110.873H196.766V116.927H200.794ZM206.813 124.734C207.352 127.449 207.851 131.04 207.894 133.253L203.037 134.005C203.037 131.835 202.704 128.158 202.247 125.279L206.813 124.737V124.734ZM215.656 124.359C216.694 126.989 217.732 130.538 218.104 132.836L213.162 133.838C212.914 131.668 211.957 127.99 211.004 125.193L215.653 124.359H215.656ZM212.418 118.804H221.718V114.336H212.418V118.804ZM224.042 123.397C225.742 126.07 227.904 129.743 228.857 132.124L224.081 134.169C223.252 131.832 221.257 127.99 219.557 125.151L224.042 123.397Z"
                    fill="#9E3223"
                />
                <path
                    d="M205.733 170.503C205.733 172.801 205.4 174.177 204.032 175.014C202.746 175.809 200.961 175.933 198.261 175.933C198.137 174.64 197.556 172.384 196.932 171.13C198.427 171.212 199.962 171.173 200.461 171.173C200.961 171.173 201.166 171.005 201.166 170.421V162.154H196.808C196.436 167.04 195.604 172.174 193.903 175.976C193.032 175.267 190.998 174.098 189.918 173.678C192.493 167.998 192.656 159.646 192.656 153.553V138.395H205.733V170.507V170.503ZM201.166 157.476V152.423H197.099V153.549C197.099 154.804 197.099 156.097 197.056 157.476H201.166ZM197.099 142.988V147.83H201.166V142.988H197.099ZM228.403 153.72C227.446 159.357 225.746 163.95 223.422 167.709C225.207 169.548 227.283 171.091 229.565 172.092C228.569 173.012 227.198 174.765 226.493 175.976C224.336 174.765 222.341 173.222 220.598 171.426C219.022 173.179 217.194 174.683 215.16 175.894C214.661 174.932 213.622 173.471 212.712 172.552V175.976H208.021V138.438H227.12V146.498C227.12 148.668 226.663 149.84 224.92 150.506C223.301 151.133 220.935 151.133 217.778 151.133C217.569 149.84 216.907 148.169 216.326 146.957C218.402 147.082 220.85 147.082 221.516 147.039C222.182 146.997 222.387 146.872 222.387 146.369V143.027H212.716V152.922H224.715L225.462 152.755L228.41 153.717L228.403 153.72ZM212.708 172.01C214.575 170.966 216.237 169.505 217.775 167.752C215.741 164.706 214.203 161.196 212.96 157.398H212.712V172.014L212.708 172.01ZM217.151 157.394C217.98 159.692 219.061 161.905 220.347 163.908C221.466 161.944 222.38 159.731 223.046 157.394H217.151Z"
                    fill="#9E3223"
                />
                <path
                    d="M218.186 210.464C222.047 211.633 226.281 213.137 229.147 214.263L224.789 217.769C221.594 216.265 215.862 214.22 211.462 212.927L214.909 210.464H203.657L206.813 212.677C203.451 214.641 198.137 216.643 193.942 217.687C193.028 216.643 191.328 215.097 190.205 214.138C193.776 213.344 198.01 211.883 200.833 210.464H194.399V191.968H224.747V210.464H218.186ZM223.291 190.421H196.263V179.981H223.291V190.421ZM199.296 197.145H219.638V195.349H199.296V197.145ZM199.296 202.073H219.638V200.277H199.296V202.073ZM199.296 207.083H219.638V205.244H199.296V207.083ZM218.019 183.865H201.248V186.58H218.019V183.865Z"
                    fill="#9E3223"
                />
            </svg>
        </a>
    );
};

export default IconComponent;