import React, { useState } from "react";
import ChatForm from "./ChatForm";

function ChatCard({ chat, addReply }) {
  const [showReplyForm, setShowReplyForm] = useState(false);

  return (
    <div className="chatCardBlocks hasChildren" data-id={chat.id}>
      <div className="chatCard" data-id={chat.id} data-main>
        <div className="chatCardAvator">
          <img src={chat.avatar} width="30" height="30" alt="avatar" />
        </div>
        <div className="chatCardBody">
          <div className="chatCardBodyTop">
            <p className="chatCardHead">
              <strong className="chatCardTitle">{chat.name}</strong>
            </p>
            <div className="chatCardText">{chat.message}</div>
          </div>
          <div className="chatCardBodyBottom">
            <span>{chat.date}天</span>
            <button
              type="button"
              className="chatCardOpenReplyButton"
              onClick={() => setShowReplyForm(!showReplyForm)}
            >
              回覆
            </button>
          </div>
        </div>
      </div>

      {chat.replies.map((reply) => (
        <div
          key={reply.id}
          className={`chatCardBlocksChildrens ${
            chat.replies.length > 0 ? "" : "hide"
          } `}
        >
          <div className="chatCard" data-id={reply.id} key={reply.id}>
            <div className="chatCardAvator">
              <img
                src={reply.avatar ? reply.avatar : "img/user.png"}
                width="30"
                height="30"
                alt="avatar"
              />
            </div>
            <div className="chatCardBody">
              <div className="chatCardBodyTop">
                <p className="chatCardHead">
                  <strong className="chatCardTitle">{reply.name}</strong>
                </p>
                <div className="chatCardText">{reply.message}</div>
              </div>
              <div className="chatCardBodyBottom">
                <span>{reply.date}天</span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div>
        {showReplyForm && (
          <>
            <div className="replyForm"></div>
            <ChatForm parentId={chat.id} addReply={addReply} />
          </>
        )}
      </div>
    </div>
  );
}

export default ChatCard;
