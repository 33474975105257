import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { apiRequestResetPassword } from '../../utils/Api';
import Line from "../../layouts/Line";

/**
 * 資料顯示頁面
 */
const InfoPanel = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [account, setAccount] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const onChangeAccount = (event) => {
    setAccount(event.target.value);
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const submitForgetPassword = (event) => {
    event.preventDefault();

    // 檢查帳號和信箱是否有效
    if (!account || !email || !phone) {
      showToast("error", "帳號、信箱及手機號碼都是必填的");
      return;
    }

    // 發送請求重設密碼
    apiRequestResetPassword({
      Account: account,
      Email: email,
      Phone: phone
    })
      .then((sendResult) => {
        if (sendResult.code === 0) {
          // 顯示成功提示訊息，導航到登入頁面
          showToast(
            "success",
            `${t("common.resetPassword")}${t("common.mailSent")}`
          );
          navigate("/Login", {
            replace: true
          });
        } else {
          // 顯示失敗提示訊息
          showToast(
            "error",
            `${t("common.resetPassword")}${t("common.mailSentFail")}，請檢查輸入內容`
          );
        }
      })
      .catch(() => {
        // 顯示失敗提示訊息
        showToast(
          "error",
          `${t("common.resetPassword")}${t("common.mailSentFail")}`
        );
      });
  };


  return (
    <div className="main-background normal">
      <form className="content mb-0 pb-[1.875rem]" action="">
        <div className="flex flex-col gap-[1.5rem]">
          <div className="flex items-center gap-[0.5625rem] pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_15_96)">
                <path
                  d="M37.7917 15.0239H34.2245V10.4477H36.0081C37.8952 10.4477 39.4327 8.9056 39.4327 7.01284C39.4327 5.12009 37.8952 3.57798 36.0081 3.57798C34.121 3.57798 32.5835 5.12009 32.5835 7.01284H29.0162C29.0162 3.14505 32.1519 0 36.0081 0C39.8643 0 43 3.14505 43 7.01284C43 10.2617 40.7847 13.0024 37.7917 13.7931V15.0204V15.0239Z"
                  fill="#9E3223"
                />
                <path d="M37.7918 17.5142H34.2245V21.8257H37.7918V17.5142Z" fill="#9E3223" />
                <path
                  d="M0 11.7823V39H27.1363V11.7823H0ZM15.0147 27.2249V31.5578H12.1216V27.2249C10.5591 26.6381 9.44616 25.1282 9.44616 23.3571C9.44616 21.0743 11.2904 19.2209 13.5699 19.2209C15.8494 19.2209 17.6937 21.0707 17.6937 23.3571C17.6937 25.1282 16.5807 26.6381 15.0183 27.2249H15.0147Z"
                  fill="#9E3223"
                />
                <path
                  d="M25.3134 11.7823H21.7461C21.7461 7.25972 18.079 3.57798 13.5664 3.57798C9.05375 3.57798 5.38659 7.25615 5.38659 11.7823H1.82288C1.82288 5.28468 7.09175 0 13.5699 0C20.0481 0 25.317 5.28468 25.317 11.7823H25.3134Z"
                  fill="#9E3223"
                />
              </g>
              <defs>
                <clipPath id="clip0_15_96">
                  <rect width="43" height="39" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-[1.5rem] text-primary font-bold leading-[1.4]" for="account">忘記密碼</span>
          </div>
          <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <input
              id="account"
              type="text"
              className="min-w-0 bg-transparent text-[20px] text-primary font-mormal leading-[1.4] placeholder:text-primary focus-visible:outline-none"
              placeholder="｜請輸入帳號"
              aria-label="account"
              value={account}
              onChange={onChangeAccount}
            />
          </div>
          <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <input
              id="email"
              type="email"
              className="min-w-0 bg-transparent text-[20px] text-primary font-mormal leading-[1.4] placeholder:text-primary focus-visible:outline-none"
              placeholder="｜請輸入 Email"
              aria-label="email"
              inputMode={"email"}
              value={email}
              onChange={onChangeEmail}
            />
          </div>
          <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
            <input
              id="phone"
              type="text"
              className="min-w-0 bg-transparent text-[20px] text-primary font-mormal leading-[1.4] placeholder:text-primary focus-visible:outline-none"
              placeholder="｜請輸入 電話號碼"
              aria-label="phone"
              value={phone}
              onChange={onChangePhone}
            />
          </div>
        </div>
        <div className="flex items-center justify-start gap-[0.75rem] mt-[1.5625rem]">
          <button
            type="submit"
            onClick={submitForgetPassword}
            className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
            title="送出"
          >
            送出
          </button>
        </div>
      </form>
      <Line />
    </div>
  );
};

export default InfoPanel;
