import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; //语系
import { Modal, Button, Form } from "react-bootstrap";
import { useToast } from "../../contexts/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import "./../../bootstrap.css";
import {
  apiCancelCareCasePlan,
  apiGetAllCancelReason,
  apiNurseAidesCareCasePairSubmit,
} from "../../utils/Api";
import CancelReqStep1 from "./CancelReqStep1";
import CancelReqStep2 from "./CancelReqStep2";

/**
 * 按下取消申請跳出的視窗
 * @param {*} param0
 * @returns
 */
const CancelReqModule = ({ showModule, handleClose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [firstReasons, setFirstReasons] = useState([
    {
      id: 0,
      name: "",
      isRemark: "",
    },
  ]);
  const [cancelReason, setCancelReason] = useState({
    careCaseId: 0, //案件id
    cancelReasonId: 0, //第一層取消原因
    cancelReasonSecId: 0, //第二層取消原因
    cancelRemark: "", //有需要額外填寫的取消原因
  });
  const [isRemarke, setIsRemark] = useState(false); //是否需要填寫額外原因

  //#region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      try {
        localStorage.setItem("firstCancelReason", 0);
        const apiGetAllReason = await apiGetAllCancelReason();
        console.log(apiGetAllReason.data.result);
        setFirstReasons(apiGetAllReason.data.result);
      } catch (e) {
        console.log(e);
        alert(e);
        // showToast("error", e);
      }
    };
    fetchData();
    return () => {};
  }, []);
  //#endregion
  //#region 下一頁
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  //#endregion

  //#region 上一頁
  const handlePreviousStep = (e) => {
    e.preventDefault();
    setCurrentStep(1);
  };
  //#endregion

  //#region 步驟1回傳的資料
  const handleCancelResDataStep1Callback = (selectedRes) => {
    const tempStep1Res = firstReasons.find(
      (reason) => reason.id === selectedRes
    );
    console.log("tempStep1Res", tempStep1Res);
    if (tempStep1Res.isRemark) {
      setCancelReason({ ...cancelReason, cancelReasonId: selectedRes });
      setIsRemark(true);
    } else {
      setCancelReason({ ...cancelReason, cancelReasonId: selectedRes });
      setIsRemark(false);
    }
  };
  //#endregion

  //#region 步驟2回傳的資料
  const handleCancelResDataStep2Callback = async (selectedRes, remark) => {
    const tempCancelReason = {
      careCaseId: parseInt(id),
      cancelReasonId: cancelReason.cancelReasonId,
      cancelReasonSecId: selectedRes,
      cancelRemark: remark,
    };
    const apiCancelApplyResponse = await apiCancelCareCasePlan(
      tempCancelReason
    );
    if (apiCancelApplyResponse && apiCancelApplyResponse.code === 0) {
      showToast("success", "取消申請成功");
      handleClose();
      navigate("/FindCareStep");
    } else {
      showToast(
        "error",
        apiCancelApplyResponse
          ? apiCancelApplyResponse.message
          : t("toast.unknownError")
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 欄位驗證
  // const checkValidator = async () => {
  //   let result = true;
  //   //檢查step1.
  //   if (cancelRes.selectedDayModel > 1 || cancelRes.selectedDayModel < 0) {
  //     result = false;
  //     showToast("error", "服務方式資料錯誤");
  //   }

  //   if (cancelRes.selectedDayModel === 1) {
  //     if (cancelRes.serviceDay < 0) {
  //       result = false;
  //       showToast("error", "服務方式資料錯誤");
  //     }
  //   }

  //   //檢查step2.
  //   if (cancelRes.selectedDays.length === 0) {
  //     result = false;
  //     showToast("error", "時段尚未選擇");
  //   }

  //   //檢查step3.
  //   if (cancelRes.expectedPrice === 0) {
  //     result = false;
  //     showToast("error", "期望金額金額尚未選擇");
  //   }

  //   return result;
  // };
  //#endregion

  return (
    <Modal
      show={showModule === "cancelApply"}
      onHide={handleClose}
      // size="lg"
      className="modal fade ProjectModal"
      id="MenuModal"
      tabIndex="-1"
      centered={true}
      aria-labelledby="MenuModalLabel"
      aria-hidden="true"
      backdrop="static"
    >
      <div className="modal-content accept">
        {currentStep === 1 && (
          <CancelReqStep1
            onNextStep={handleNextStep}
            handleClose={handleClose}
            step1DataCallback={handleCancelResDataStep1Callback}
            firstReasons={firstReasons}
          />
        )}
        {currentStep === 2 && (
          <CancelReqStep2
            onPreviousStep={handlePreviousStep}
            step2DataCallback={handleCancelResDataStep2Callback}
            firstReason={cancelReason.cancelReasonId}
            isRemarke={isRemarke}
          />
        )}
      </div>
    </Modal>
  );
};

export default CancelReqModule;
