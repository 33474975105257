import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useNavigate } from "react-router-dom";
import { useToast } from "../../contexts/ToastContext";

import {
  CreateValidator,
  ValidateFields,
  ValidateField,
} from "../../utils/Validator";
import { setAuthToken } from "../../utils/JWToken";
import { apiUserLogin } from "../../utils/Api";
import { Link } from "react-router-dom";

/**
 * 資料顯示頁面
 */
const InfoPanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); //跳轉Router
  const { showToast } = useToast();
  const validator = CreateValidator();
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [account, setAccount] = useState(""); //帳號
  const pawRef = useRef(""); //密碼
  const [errors, setErrors] = useState({
    account: "",
    paw: "",
  });

  //#region 改變Input的欄位
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAccount(value);
  };
  //#endregion

  //#region 失去焦點Input的欄位
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    if (name != "") {
      let newErrors = { ...errors };
      await ValidateField(name, value, newErrors, validator);
      setErrors(newErrors);
    }
  };
  //#endregion

  //#region 欄位驗證
  const checkValidator = async () => {
    let obj = {
      account: account,
      paw: pawRef.current.value,
    };

    let newErrors = { ...errors };
    var validateFieldsResult = await ValidateFields(obj, newErrors);
    if (!validateFieldsResult.result) {
      setErrors(newErrors);
    }

    return validateFieldsResult.result;
  };
  //#endregion

  //#region 登入
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (await checkValidator()) {
      setAuthLoading(true);

      var params = {
        username: account,
        password: pawRef.current.value,
      };

      let userLoginResponse = await apiUserLogin(params);
      if (userLoginResponse && userLoginResponse.code == 0) {
        setAuthToken(userLoginResponse.data.token);

        let tempMyUser = {
          personnelCode: userLoginResponse.data.personnelCode,
          personnelName: userLoginResponse.data.personnelName,
          rolePermossion: userLoginResponse.data.rolePermossion,
        };

        // localStorage.setItem("myUser", JSON.stringify(tempMyUser));

        //TODO 目前先暫時由登入之後直接setUser資料
        // setAuthToken(userLoginResponse.data);
        navigate("/Choose");
      } else {
        setAuthLoading(false);
        showToast("error", "帳號或密碼錯誤");
      }
    } else {
      setAuthLoading(false);
    }
  };
  //#endregion

  return (
    <>
      <div className="main-background login mb-5">
        <form onSubmit={handleSubmit} className="content mb-0 pb-[1.875rem]">
          <div className="flex flex-col gap-[1.5rem]">
            <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
              <div className="flex items-center gap-[0.5625rem] flex-none">
                <svg
                  width="30"
                  height="41"
                  viewBox="0 0 30 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_10_273)">
                    <path
                      d="M2.08911 41V30.4496C2.08911 23.5202 7.86766 17.906 15 17.906C22.1323 17.906 27.9108 23.5202 27.9108 30.4496V41"
                      fill="#9E3223"
                    />
                    <path
                      d="M30 41H25.8217V30.4496C25.8217 24.6528 20.9666 19.9357 15 19.9357C9.03343 19.9357 4.17827 24.6528 4.17827 30.4496V41H0V30.4496C0 22.412 6.7312 15.8763 15 15.8763C23.2688 15.8763 30 22.416 30 30.4496V41Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M14.9457 17.902C19.457 17.902 23.1142 14.3488 23.1142 9.96583C23.1142 5.58282 19.457 2.02969 14.9457 2.02969C10.4343 2.02969 6.77716 5.58282 6.77716 9.96583C6.77716 14.3488 10.4343 17.902 14.9457 17.902Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M14.9457 19.9357C9.28833 19.9357 4.68805 15.4623 4.68805 9.9699C4.68805 4.47752 9.28833 0 14.9457 0C20.6031 0 25.2034 4.47346 25.2034 9.96584C25.2034 15.4582 20.5989 19.9317 14.9457 19.9317V19.9357ZM14.9457 4.05941C11.5906 4.05941 8.86632 6.7102 8.86632 9.96584C8.86632 13.2215 11.5947 15.8723 14.9457 15.8723C18.2967 15.8723 21.0251 13.2215 21.0251 9.96584C21.0251 6.7102 18.2967 4.05941 14.9457 4.05941Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_10_273">
                      <rect width="30" height="41" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <label
                  className="text-[1.5rem] text-primary font-bold leading-[1.4]"
                  htmlFor="account"
                >
                  帳號｜
                </label>
              </div>
              <input
                id="account"
                type="text"
                name="account"
                value={account}
                className="min-w-0 bg-transparent text-[1.5rem] text-primary font-bold leading-[1.4] focus-visible:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.account}
              />
            </div>
            <div className="flex items-center pb-[0.625rem] border-b-[0.1875rem] border-primary">
              <div className="flex items-center gap-[0.5625rem] flex-none">
                <svg
                  width="28"
                  height="39"
                  viewBox="0 0 28 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_10_281)">
                    <path
                      d="M0 11.7823V39H28V11.7823H0ZM15.4926 27.2249V31.5578H12.5074V27.2249C10.8952 26.6381 9.74681 25.1282 9.74681 23.3571C9.74681 21.0743 11.6498 19.2209 14.0018 19.2209C16.3539 19.2209 18.2569 21.0707 18.2569 23.3571C18.2569 25.1282 17.1085 26.6381 15.4963 27.2249H15.4926Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M26.1191 11.7823H22.4383C22.4383 7.25972 18.6544 3.57798 13.9982 3.57798C9.34194 3.57798 5.55806 7.25615 5.55806 11.7823H1.88092C1.88092 5.28468 7.31749 0 14.0019 0C20.6862 0 26.1228 5.28468 26.1228 11.7823H26.1191Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_10_281">
                      <rect width="28" height="39" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <label
                  className="text-[1.5rem] text-primary font-bold leading-[1.4]"
                  htmlFor="password"
                >
                  密碼｜
                </label>
              </div>
              <input
                className="min-w-0 bg-transparent text-[1.5rem] text-primary font-bold leading-[1.4] focus-visible:outline-none"
                type="password"
                name="paw"
                onBlur={handleBlur}
                ref={pawRef}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-[0.75rem] mt-[1.5625rem]">
            <a
              href={void 0}
              className="flex-auto px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] flex items-baseline justify-center"
            >
              <button
                type="submit"
                className="flex-auto py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                disabled={isAuthLoading}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登入&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </a>
            {/* <a
                            href=""
                            className="flex items-baseline justify-center gap-[0.4375rem] flex-auto py-1 px-[0.75rem] bg-line rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                        >
                            <span>使用</span>
                            <svg width="58" height="20" viewBox="0 0 58 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_10_303)">
                                    <path
                                        d="M56.2963 19.9502C56.7387 19.9502 57.1 19.5925 57.1 19.1464V16.1636C57.1 15.7212 56.7387 15.3599 56.2963 15.3599H48.2697V12.2628H56.2963C56.7387 12.2628 57.1 11.9052 57.1 11.4591V8.47633C57.1 8.03389 56.7387 7.67257 56.2963 7.67257H48.2697V4.57552H56.2963C56.7387 4.57552 57.1 4.21789 57.1 3.77176V0.803758C57.1 0.361322 56.7387 0 56.2963 0H44.4869C44.0445 0 43.6832 0.357635 43.6832 0.803758V0.822193V19.1353V19.1501C43.6832 19.5925 44.0408 19.9538 44.4869 19.9538H56.2963V19.9502Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M12.6131 19.9502C13.0555 19.9502 13.4169 19.5925 13.4169 19.1464V16.1636C13.4169 15.7212 13.0555 15.3599 12.6131 15.3599H4.58658V0.803758C4.58658 0.361322 4.22526 0 3.78282 0H0.803758C0.357635 0 0 0.357635 0 0.803758V19.1353V19.1501C0 19.5925 0.357635 19.9538 0.803758 19.9538H12.6131V19.9502Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M19.7215 0H16.7425C16.2986 0 15.9387 0.359855 15.9387 0.803758V19.1464C15.9387 19.5903 16.2986 19.9502 16.7425 19.9502H19.7215C20.1654 19.9502 20.5253 19.5903 20.5253 19.1464V0.803758C20.5253 0.359855 20.1654 0 19.7215 0Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M40.0146 0H37.0318C36.5894 0 36.2281 0.357635 36.2281 0.803758V11.6987L27.8402 0.365009C27.8218 0.335514 27.7997 0.309705 27.7739 0.283896C27.7739 0.283896 27.7739 0.280209 27.7702 0.276522C27.7554 0.258087 27.737 0.24334 27.7223 0.224905C27.7186 0.221218 27.7112 0.217531 27.7075 0.210157C27.6928 0.199096 27.678 0.184348 27.6633 0.173287C27.6559 0.165913 27.6485 0.162226 27.6411 0.158539C27.6264 0.147479 27.6116 0.140105 27.6006 0.129044C27.5932 0.125357 27.5858 0.117983 27.5748 0.114296C27.56 0.106922 27.5453 0.095861 27.5305 0.0884871C27.5232 0.0848001 27.5121 0.0811132 27.5047 0.0774262C27.49 0.0700523 27.4752 0.0626784 27.4568 0.0589914C27.4494 0.0589914 27.4384 0.0516175 27.431 0.0479305C27.4162 0.0405566 27.3978 0.0368696 27.3831 0.0331827C27.372 0.0331827 27.3646 0.0294957 27.3536 0.0258087C27.3388 0.0221218 27.3204 0.0184348 27.3056 0.0147479C27.2946 0.0147479 27.2835 0.0147479 27.2724 0.0110609C27.2577 0.0110609 27.243 0.00737393 27.2282 0.00737393C27.2135 0.00737393 27.1987 0.00737393 27.184 0.00737393C27.1766 0.00737393 27.1655 0.00737393 27.1582 0.00737393H24.1938C23.7514 0.00737393 23.3901 0.365009 23.3901 0.811132V19.1575C23.3901 19.5999 23.7477 19.9612 24.1938 19.9612H27.1766C27.619 19.9612 27.9803 19.6036 27.9803 19.1575V8.26617L36.3829 19.6146C36.4419 19.6958 36.512 19.7621 36.5894 19.8174C36.5894 19.8174 36.5968 19.8211 36.6005 19.8248C36.6152 19.8359 36.6336 19.8469 36.6521 19.8543C36.6595 19.858 36.6668 19.8617 36.6742 19.8654C36.6853 19.8727 36.7 19.8764 36.7148 19.8838C36.7295 19.8912 36.7406 19.8949 36.7553 19.9022C36.7627 19.9022 36.7738 19.9096 36.7811 19.9133C36.7996 19.9207 36.818 19.9243 36.8364 19.9317C36.8401 19.9317 36.8438 19.9317 36.8475 19.9317C36.9139 19.9502 36.9839 19.9612 37.0576 19.9612H40.022C40.4644 19.9612 40.8257 19.6036 40.8257 19.1575V0.803758C40.8257 0.361322 40.4681 0 40.022 0H40.0146Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_10_303">
                                        <rect width="57.1" height="19.9502" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="text-center">登入</span>
                        </a> */}
          </div>
        </form>
      </div>
      <div className="content content-login flex flex-col items-start gap-[1.25rem] text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
        <Link to="/ForgetAccount">
          <a href={void 0} className="" title="忘記帳號？">
            忘記帳號？
          </a>
        </Link>
        <Link to="/ForgetPassword">
          <a href={void 0} className="" title="忘記密碼？">
            忘記密碼？
          </a>
        </Link>
        <Link to="/RegisiterAccount">
          <a href={void 0} className="" title="註冊為新會員">
            註冊為新會員
          </a>
        </Link>
      </div>
    </>
  );
};

export default InfoPanel;
