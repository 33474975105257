import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import HamburgerFamilyModal from "../HamburgerModal";

/**
 * 失效/取消案件
 */
const InfoPanel = ({
  keyword,
  CareServiceItemLists,
  refreshAllCareServiceItemList,
  name,
  creatorNum,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  //#region 星星評分
  function Star({ marked, starId }) {
    return (
      <span
        star-id={starId}
        style={{ color: "#ff9933", fontSize: "2.5rem" }}
        role="button"
      >
        {/* 空星，實星 */}
        {marked ? "\u2605" : "\u2606"}
      </span>
    );
  }

  function StarRating(props) {
    // 分數顯示
    const [rating, setRating] = React.useState(
      typeof props.rating == "number" ? props.rating : 0
    );
    // 鼠標移入效果
    const [selection, setSelection] = React.useState(0);
    const hoverOver = (event) => {
      let val = 0;
      if (event && event.target && event.target.getAttribute("star-id"))
        val = event.target.getAttribute("star-id");
      setSelection(val);
    };
    return (
      <div
        // 鼠標移入效果
        onMouseOut={() => hoverOver(null)}
        // 點擊選中分數
        onClick={(event) =>
          setRating(event.target.getAttribute("star-id") || rating)
        }
        onMouseOver={hoverOver}
      >
        {/* 創建5個组件 */}
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1} `}
            marked={selection ? selection >= i + 1 : rating >= i + 1}
          />
        ))}
      </div>
    );
  }
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[10rem]">
          <form
            className="flex items-start justify-between gap-[0.625rem]"
            action="./pairing3-fail.html"
          >
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              失效/取消案件
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>
          <form
            className="flex flex-col gap-[2.1875rem]"
            action="./findCareStep6.html"
          >
            <input type="hidden" name="id" value="1" />
            <div className="flex flex-col items-center gap-[1.5625rem]">
              <div className="flex flex-col gap-[0.8125rem]">
                <div className="w-[8.125rem] aspect-square mx-auto border border-primary rounded-full overflow-hidden">
                  <img
                    src="./img/user.png"
                    width="130"
                    height="130"
                    alt="user avator"
                  />
                </div>
                <span className="text-primary text-[1.25rem] text-center leading-[1.5]">
                  李XX(男 / 26歲)
                </span>
              </div>
              <div className="w-full flex flex-col items-center gap-[1.625rem]">
                <textarea
                  className="w-full p-[1rem] bg-white border-[0.125rem] border-primary text-primary text-base placeholder:text-xs md:text-sm lg:text-base placeholder:text-[rgba(0,0,0,0.3)]"
                  name="comment"
                  id="comment"
                  maxlength="50"
                  cols="30"
                  rows="6"
                  placeholder="照服員評價(0/50)"
                  aria-label="評論"
                ></textarea>
                <div className="-mb-[1.0625rem]">
                  <StarRating />
                  <input type="hidden" name="rating" id="rating" value="0" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-[0.75rem]">
              <button
                type="submit"
                className="flex-none py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5]"
                title="送出評價"
              >
                送出評價
              </button>
            </div>
          </form>
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default InfoPanel;
