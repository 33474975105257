import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate } from "react-router-dom";


const Header = () => {
    const navigate = useNavigate(); //跳轉Router


    return (
        <>
            <header style={{paddingLeft: "30px",paddingRight: "30px",}}>
                <a href="/Login" className="logo" title="馬上就來"></a>
            </header>
        </>
    );
}

export default Header;