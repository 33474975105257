import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import ResetPassword from "../../components/Reset/ResetPassword";

/**
 * 重設密碼
 * @returns
 */
const InfoPanel = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header/>
        <ResetPassword/>
      </div>
    </>
  );
};

export default InfoPanel;
