import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import HamburgerFamilyModal from "../HamburgerModal";
import {
  apiCareCaseFamilyMainGetProgressPair,
  apiCareCaseFamilyMainGetWaitingPair,
  apiCareCaseFamilyMainReplyWaitingPair,
  apiDownloadFile,
  apiGetProgressPairNotYetTime,
} from "../../utils/Api";
import WaitingPairDetail from "./WaitingPairDetail";

/**
 * 進行中案件列表
 */
const InfoPanel = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const tRef = useRef(t("toast.unknownError")); // [回傳的訊息] : 網路連線錯誤
  const iseEffectcalledRef = useRef(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showHamburgerModal, setShowHamburgerModal] = useState(false); //顯示modal
  const [progressPairs, setProgressPairs] = useState([]); //進行中的照服員列表
  const [waitingPairs, setWaitingPairs] = useState([]); //等待配對的照服員
  const [unpairedDates, setUnpairDates] = useState([]); //未配對的日期
  const [selectedWaitingPair, setSelectedWaitingPair] = useState(null); // 選擇的待配對照服員

  //#region 刷新進行中照服員及配對日期
  const refreshAllProgressPairs = useCallback(async () => {
    const params = {
      CareCaseId: parseInt(id),
    };
    try {
      const [waitingPairResponse, progressPairResponse, notPairTimeResponse] =
        await Promise.all([
          apiCareCaseFamilyMainGetWaitingPair(params),
          apiCareCaseFamilyMainGetProgressPair(params),
          apiGetProgressPairNotYetTime(params),
        ]);

      if (
        waitingPairResponse &&
        waitingPairResponse.code === 0 &&
        progressPairResponse &&
        progressPairResponse.code === 0 &&
        notPairTimeResponse &&
        notPairTimeResponse.code === 0
      ) {
        console.log(progressPairResponse.data.result);
        console.log(waitingPairResponse.data.result);
        // console.log(notPairTimeResponse.data);
        const progressPairResult = progressPairResponse.data.result;
        const waitingPairResult = waitingPairResponse.data.result;
        let progressPairs = [];
        let waitingPairs = [];
        if (progressPairResult) {
          progressPairs = await Promise.all(
            progressPairResult.map(async (result) => {
              if (result.headshotFilePath) {
                const param = encodeURI(result.headshotFilePath);

                return {
                  ...result,
                  headshotFile: await apiDownloadFile(param),
                };
              }
              return { ...result, headshotFile: "" };
            })
          );
        }
        if (waitingPairResult) {
          waitingPairs = await Promise.all(
            waitingPairResult.map(async (result) => {
              if (result.headshotFilePath) {
                const param = encodeURI(result.headshotFilePath);

                return {
                  ...result,
                  headshotFile: await apiDownloadFile(param),
                };
              }
              return { ...result, headshotFile: "" };
            })
          );
        }
        console.log(progressPairs);
        setProgressPairs(progressPairs);
        setWaitingPairs(waitingPairs);
        setUnpairDates(notPairTimeResponse.data);
      } else {
        showToast(
          "error",
          waitingPairResponse ? waitingPairResponse.message : tRef.current
        );
      }
    } catch (err) {
      console.log(err);
      // showToast("error", err);
    }
  }, [id, showToast]);
  //#endregion

  // region 初始載入
  useEffect(() => {
    const fetchData = async () => {
      await refreshAllProgressPairs();
    };

    if (!iseEffectcalledRef.current) {
      iseEffectcalledRef.current = true;
      fetchData();
    }

    return () => {};
  }, [refreshAllProgressPairs]);
  //#endregion

  //#region 選擇進行中的照服員
  const handleSelectedProgressPair = (nurseAidesId) => {
    navigate(`/ProgressPairDetail/careCaseId/${id}/id/${nurseAidesId}`);
  };
  //#endregion

  //#region 選擇等待配對的照服員
  const handleSelectWaitingPair = (id) => {
    const selected = waitingPairs.find((x) => x.pairId === id);
    console.log("選中的照服員", selected);
    setSelectedWaitingPair(selected);
  };
  //#endregion

  //#region 選擇配對照服員
  const handleReplyWaitingPair = async (isAccept) => {
    var params = {
      pairId: selectedWaitingPair.pairId,
      isAccept: isAccept,
    };

    const replyWaitingPairResponse =
      await apiCareCaseFamilyMainReplyWaitingPair(params);
    if (replyWaitingPairResponse && replyWaitingPairResponse.code === 0) {
      refreshAllProgressPairs();
      setSelectedWaitingPair(null);
    } else {
      showToast(
        "error",
        replyWaitingPairResponse
          ? replyWaitingPairResponse.message
          : tRef.current
      ); // [回傳的訊息] : 網路連線錯誤
    }
  };
  //#endregion

  //#region 漢堡modal
  const handleShowHamburgerModal = () => {
    setShowHamburgerModal(true);
  };

  const handleHamburgerClose = () => {
    setShowHamburgerModal(false);
  };
  //#endregion

  return (
    <div className=" main-background normal">
      <div className="content">
        <div className="flex flex-col gap-[2.1875rem] mb-[2.1875rem]">
          <form
            className="flex items-start justify-between gap-[0.625rem]"
            action="./pairing2.html"
          >
            <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
              我的進行中
            </strong>
            <div className="flex items-center gap-[0.625rem] flex-none">
              <a
                data-bs-toggle="modal"
                href="#MenuModal"
                role="button"
                title="Sort Button"
                onClick={handleShowHamburgerModal}
              >
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_87_2023)">
                    <path
                      d="M25.7245 4.52459H2.2755C1.02018 4.52459 0 3.51033 0 2.2623C0 1.01426 1.02018 0 2.2755 0H25.7245C26.9798 0 28 1.01426 28 2.2623C28 3.51033 26.9798 4.52459 25.7245 4.52459Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 13.7624H2.2755C1.02018 13.7624 0 12.7481 0 11.5001C0 10.2521 1.02018 9.23779 2.2755 9.23779H25.7245C26.9798 9.23779 28 10.2521 28 11.5001C28 12.7481 26.9798 13.7624 25.7245 13.7624Z"
                      fill="#9E3223"
                    />
                    <path
                      d="M25.7245 22.9999H2.2755C1.02018 22.9999 0 21.9857 0 20.7376C0 19.4896 1.02018 18.4753 2.2755 18.4753H25.7245C26.9798 18.4753 28 19.4896 28 20.7376C28 21.9857 26.9798 22.9999 25.7245 22.9999Z"
                      fill="#9E3223"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_2023">
                      <rect width="28" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </form>

          {selectedWaitingPair === undefined || selectedWaitingPair === null ? (
            <>
              <form className="flex flex-col gap-[2.1875rem]">
                {progressPairs && progressPairs.length > 0 ? (
                  progressPairs.map((progressPair, index) => {
                    return (
                      <div
                        href={void 0}
                        className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden cursor-pointer hover:drop-shadow-xl"
                        key={index}
                        onClick={(e) =>
                          handleSelectedProgressPair(progressPair.nurseAidesId)
                        }
                      >
                        <div className="bg-[#FFDA5E] container-img ">
                          <img
                            src={
                              progressPair.headshotFile !== "" &&
                              progressPair.headshotFile !== undefined
                                ? URL.createObjectURL(
                                    progressPair.headshotFile.data
                                  )
                                : "./img/user.png"
                            }
                            width="110"
                            height="100"
                            alt="user avator"
                            className="h-full object-center object-cover"
                          />
                        </div>
                        <ul className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6]">
                          <li>照服員姓名：{progressPair.nurseAidesName}</li>
                          <li>性別：{progressPair.nurseAidesGender}</li>
                          <li>年齡：{progressPair.nurseAidesAge}</li>
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </form>
              <div className="flex flex-col gap-[1rem] mb-[2.1875rem]">
                <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
                  尚未配對日期
                </strong>
                <ul className="flex flex-col gap-[0.1875rem]">
                  {unpairedDates &&
                    unpairedDates.map((unpairedDate) => (
                      <li className="pl-[0.625rem] text-primary text-[1.25rem] leading-[1.5]">
                        {unpairedDate.careCaseTimeNotYet}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="flex flex-col gap-[2.1875rem] mb-[2.1875rem]">
                <form
                  className="flex items-start justify-between gap-[0.625rem]"
                  action="./pairing2.html"
                >
                  <strong className="max-w-[7em] grow text-primary text-[1.5rem] leading-[1.5]">
                    新增配對照服員
                  </strong>
                </form>
                <form className="flex flex-col gap-[2.1875rem]">
                  {waitingPairs && waitingPairs.length > 0 ? (
                    waitingPairs.map((waitingPair, index) => {
                      return (
                        <div
                          href={void 0}
                          className="flex items-stretch bg-primary border-[0.125rem] border-primary rounded-[1.25rem] overflow-hidden cursor-pointer hover:drop-shadow-xl"
                          key={index}
                          onClick={() =>
                            handleSelectWaitingPair(waitingPair.pairId)
                          }
                        >
                          <div className="bg-[#FFDA5E] container-img ">
                            <img
                              src={
                                waitingPair.headshotFile !== "" &&
                                waitingPair.headshotFile !== undefined
                                  ? URL.createObjectURL(
                                      waitingPair.headshotFile.data
                                    )
                                  : "./img/user.png"
                              }
                              width="110"
                              height="100"
                              alt="user avator"
                              className="h-full object-center object-cover"
                            />
                          </div>
                          <ul className="flex flex-col grow pt-[1.125rem] pb-[1.375rem] pl-[1rem] pr-[0.625rem] relative text-white text-[0.9375rem] lg:text-base leading-[1.6]">
                            <li>照服員姓名：{waitingPair.nurseAidesName}</li>
                            <li>性別：{waitingPair.nurseAidesGender}</li>
                            <li>年齡：{waitingPair.nurseAidesAge}</li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            </>
          ) : (
            <WaitingPairDetail
              id={id}
              waitingPair={selectedWaitingPair}
              onReplyWaitingPair={handleReplyWaitingPair}
            />
          )}
        </div>
      </div>
      <HamburgerFamilyModal
        showHamburgerModal={showHamburgerModal}
        handleHamburgerClose={handleHamburgerClose}
      />
    </div>
  );
};

export default InfoPanel;
