import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import { useToast } from "../../contexts/ToastContext";
import Line from "../../layouts/Line";
import { Link } from "react-router-dom";

import {
    apiCreateFamily,
} from "../../utils/Api";

/**
 * 資料顯示頁面
 */
const InfoPanel = ({ stepAllData, onPreviousStep }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const [showEmailVerification, setShowEmailVerification] = useState(false);//按下重新發送驗證信
    const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);//下一步按鈕是否應該被啟用

    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表單的默認提交行為
        if (!showEmailVerification) {
            setShowEmailVerification(true); // 點擊"下一步"後顯示郵箱驗證區域
        }
    };

    async function handleRegister(e) {
        e.preventDefault();
        onPreviousStep();
        try {
            const response = await apiCreateFamily(stepAllData);
            console.log("API response:", response);
        } catch (error) {
            console.error("Error occurred during API call:", error);
            // 其他處理錯誤情況的程式碼
        }
    }

    return (
        <>
            <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">信箱驗證</h1>
            <div className="flex items-center gap-[0.625rem] mt-[1.6875rem]">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_32_1151)">
                        <path d="M35 35H0V0H32.5864V4.82724H4.82724V30.1728H30.1728V8.93069H35V35Z" fill="#FF0000" />
                        <path
                            d="M24.6208 7.20861L7.14258 24.3484L10.5224 27.795L28.0006 10.6552L24.6208 7.20861Z"
                            fill="#FF0000"
                        />
                        <path
                            d="M10.5074 7.18913L7.15833 10.6656L12.3746 15.6906L15.7236 12.2141L10.5074 7.18913Z"
                            fill="#FF0000"
                        />
                        <path
                            d="M22.6261 19.3072L19.2771 22.7838L24.4933 27.8087L27.8424 24.3322L22.6261 19.3072Z"
                            fill="#FF0000"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_32_1151">
                            <rect width="35" height="35" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span className="text-primary text-[1.25rem] leading-[1.5]">驗證失敗</span>
            </div>
            <p className="text-primary text-[0.9375rem] lg:text-base leading-[1.5] mt-[0.8125rem]">
                Hi, XXX，您的信箱驗證已逾時趕快點擊下方按鈕，重新開始驗證吧！
            </p>
            <div className="flex flex-col items-center justify-center gap-[0.625rem] mt-[1.5625rem]">
                <a
                    href={void 0}
                    className="min-w-[8.3125rem] mx-a py-1 px-[1.125rem] bg-primary rounded-full text-white text-[1.5rem] font-bold text-center leading-[1.5]"
                    title="重新發送驗證信"
                    onClick={handleSubmit}
                    disabled={!isNextButtonEnabled}
                >重新發送驗證信</a
                >
                {/* <span className="text-primary text-[0.9375rem] lg:text-base leading-[1.5]">
                    <span>| </span>
                    90 秒之後可重新發信
                    <span> |</span>
                </span> */}
            </div>
            {showEmailVerification && (
                <>
                    <h1 className="text-primary text-[1.5rem] font-bold leading-[1.5]">信箱驗證</h1>
                    <p className="text-primary text-[0.9375rem] leading-[1.5] mt-[0.8125rem]">
                        請至信箱查收驗證信，點擊連結進行驗證
                    </p>
                    <div className="flex items-center justify-end gap-[0.75rem]">
                        <button
                            type="submit"
                            className="py-1 px-[0.75rem] bg-primary rounded-full text-white text-[1.5rem] font-bold leading-[1.5] disabled:opacity-60"
                            title="下一步"
                            onClick={handleRegister}
                        >
                            完成驗證
                        </button>
                    </div>
                </>
            )}
            <Line />
        </>
    );
};

export default InfoPanel;
