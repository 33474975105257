import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; //語系
import Header from "../../layouts/Header";
import InfoPanel from "../../components/Chats/InfoPanel";

/**
 * 照服員提問
 * @returns
 */
const Chats = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="design">
        <Header />
        <InfoPanel />
      </div>
    </>
  );
};

export default Chats;
