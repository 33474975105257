import React from "react";
import Header from "../../layouts/Header";
import FindInHomeCare from "../../components/FindCareStep/FindInHomeCare";

/**
 * 建立居家看護頁面
 * @returns
 */
const FindInHomeCarePage = () => {
  return (
    <>
      <div className="design">
        <Header/>
        <FindInHomeCare/>
      </div>
    </>
  );
};

export default FindInHomeCarePage;
