import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //語系
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate(); //跳轉Router


    return (
        <>
            <header style={{paddingLeft: "30px",paddingRight: "30px",}}>
                <a href={void (0)}><img src="/img/LINE.png" width="102" height="102" alt="LINE app logo" /></a>
            </header>
        </>
    );
}

export default Header;